import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FteFormat } from './../pipes/fte-format.pipe';

@Pipe({ name: 'formatCardByType' })
export class FormatCardByType implements PipeTransform {
  private static LOCATE = 'en-US';
  private static fteFormat: FteFormat = new FteFormat();
  private static date: DatePipe = new DatePipe(FormatCardByType.LOCATE);

  transform(value: string, type: string): string {
    if (value === '' || value == null) {
      return '-';
    }

    switch (type) {
      case 'dateTime':
        value = FormatCardByType.date.transform(value, 'MM/dd/yyyy hh:mm');
        break;
      case 'date':
        value = FormatCardByType.date.transform(value, 'MM/dd/yyyy');
        break;
      case 'fte':
        value = FormatCardByType.fteFormat.transform(value);
        break;
    }

    return value;
  }
}

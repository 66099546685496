import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'formatCardBool' })
export class FormatCardBool implements PipeTransform {
  transform(value: boolean): string {
    if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}

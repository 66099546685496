import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectCommonFieldsComponent } from '../project-common-fields/project-common-fields.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-opportunity-optional-fields',
  templateUrl: './opportunity-optional-fields.component.html',
  styleUrls: ['./opportunity-optional-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class OpportunityOptionalFieldsComponent
  extends ProjectCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  offeringNameSubscription: any;
  dcResponsibleDeputySubscription: any;

  data = {
    propLeadContact: [],
    salBidContact: [],
    projManContact: [],
    qaDirContact: [],
    othContact: [],
    dcResponsibleDeputy: [],
    techAccLeadContact: [],
    delLeadContact: [],
  };

  filteredValues = {
    propLeadContact: new Observable<any[]>(),
    salBidContact: new Observable<any[]>(),
    projManContact: new Observable<any[]>(),
    othContact: new Observable<any[]>(),
    techAccLeadContact: new Observable<any[]>(),
    delLeadContact: new Observable<any[]>(),
  };

  ngOnInit() {
    super.ngOnInit();

    this.contactService.getData().subscribe(data => {
      this.data['propLeadContact'] = data;
      this.data['othContact'] = data;
      this.data['solArchContact'] = data;
      this.data['qaDirContact'] = data;
      this.data['salesLead'] = data;
      this.data['salBidContact'] = data;
      this.data['projManContact'] = data;
      this.data['delLeadContact'] = data;
    });

    this.dcResponsibleService.getData().subscribe(data => {
      this.data['dcResponsibleDeputy'] = data;
    });

    // this.expansionPanel.open();
    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes));

    this.opportunitySettings.optional.forEach(setting => {
      if (setting.cardType === 'autosearch') {
        this.filteredValues[setting.id] = this.optional.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value =>
            value ? this.filterStates(value, setting.id) : this.data[setting.id].slice()
          )
        );
      }
    });
  }

  public subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.data['offeringNameEnum'] = enums['offering-name'].filter(value => value.deleted !== true);
    this.data['sharedServiceEnum'] = enums['project-domain'];
    this.data['projectDeliveryEnum'] = enums['project-delivery'];
  }

  onDcResponsibleDeputyChange(value: any) {}

  setValidatorsForDcResponsibleDeputy() {}

  removeValidationFromDcResponsibleDeputy() {}

  protected onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.optional.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }
}

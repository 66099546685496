import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../types/dialog-data';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
@Injectable()
export class DialogComponent implements OnInit {
  constructor(
    private sanitized: DomSanitizer,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.data.content = this.sanitized.bypassSecurityTrustHtml(this.data.content);
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DemandCommonFieldsComponent } from '../demand-common-fields/demand-common-fields.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-demand-optional-fields',
  templateUrl: './demand-optional-fields.component.html',
  styleUrls: ['./demand-optional-fields.component.scss'],
})
export class DemandOptionalFieldsComponent extends DemandCommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  ngOnInit() {
    super.ngOnInit();

    this.route.queryParams.subscribe(params => {
      if (params['id'] || params['ids']) {
        this.expansionPanel.open();
      }
    });

    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes, this.optional));
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.data['prolognationProbabilityEnum'] = enums['prolongation-probability'];
    this.data['programmingSkillsMustHaveEnum'] = enums['programmer'];
    this.data['programmingSkillLevelEnum'] = enums['level'];
    this.data['applicationSkillsMustHaveEnum'] = enums['application'];
    this.data['applicationSkillLevelEnum'] = enums['level'];
    this.data['languageSkillsMustHaveEnum'] = enums['language'];
    this.data['languageSkillLevelEnum'] = enums['level'];
  }
}

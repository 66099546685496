import { distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ProjectDetails } from '../../types/project-details';
import { ProjectService } from '../../services/project.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { User } from '../../services/user-roles/user';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EnumsService } from '../../services/enumerations/enums.service';
import { projectsEnumMapping } from '../../types/enum.mapping';
import { fieldsSettings } from '../../types/project-demand-column-settings';
import { Common } from '../../shared/common';
import { DemandDetails } from '../../types/project-demand';
import { HostListener } from '@angular/core';
import { HASHED_EMPLOYEE_STRING } from '../../../constants';

@Component({
  selector: 'app-project-demands',
  templateUrl: './project-demands.component.html',
  styleUrls: [
    './project-demands.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
})
export class ProjectDemandsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() projectDetails: ProjectDetails;
  @Input() activeDemands;
  @Input() reloadDemands;
  @Input() screenWidth;
  @Input() screenHeight;
  @Input() reloadSettings;
  @Output() multipleIdsNotify = new EventEmitter();
  @Output() multipleNamesNotify = new EventEmitter();
  @Output() demandsDataNotify = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public HASHED_EMPLOYEE_STRING: string = HASHED_EMPLOYEE_STRING;

  demandsLoaded;
  displayedColumns = [];
  defaultColumnsSettings = fieldsSettings;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  active = true;

  editingRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;
  user: User;
  rowButtons: boolean;
  demandColSpan: number;
  supplyColSpan: number;
  programmingColSpan: number;
  applicationColSpan: number;
  languageColSpan: number;
  otherColSpan: number;
  allColumnsWithOpt = [];

  constructor(
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    private userRolesService: UserRolesService,
    private dialog: MatDialog,
    private router: Router,
    private enumsService: EnumsService
  ) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight - 191;
    this.screenWidth = window.innerWidth - 49;
  }

  ngOnInit() {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);
        this.creatingRole = this.user.getRoles().creating;
        this.editingRole = this.user.getRoles().editing;
        this.deletingRole = this.user.getRoles().deleting;

        this.getColumnsSettings(this.user);
      });
    if (this.projectDetails.statusId == 5 || this.projectDetails.statusId == 6) {
      this.rowButtons = true;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDemands && !changes.activeDemands.firstChange) {
      this.active = changes.activeDemands.currentValue;
    }
    if (changes.reloadDemands && !changes.reloadDemands.firstChange) {
      this.getDemands();
    }
    if (changes.reloadSettings && !changes.reloadSettings.firstChange) {
      this.getColumnsSettings(this.user);
    }
  }

  getColumnsSettings(user: User) {
    this.projectService.getColumnsSettings(user.eid).subscribe(
      result => this.handleColumnSettings(result),
      error => this.handleError(error)
    );
  }

  handleColumnSettings(result) {
    if (result) {
      const data = ['select', 'actions'];

      Object.keys(result).forEach(field => {
        if (!result[field] && field !== 'id') {
          data.push(field);
        }
      });

      this.displayedColumns = data;
    } else {
      this.setDefaultColumns();
    }

    this.setColSpans();
    this.getDemands();
  }

  handleError(error: Response | string) {
    const errorText = error instanceof Response ? error.statusText : error;
    this.snackbar.error(errorText);
  }

  getDemands() {
    this.demandsLoaded = false;

    this.projectService.getDemands(this.projectDetails.projectId, this.active).subscribe({
      next: result => this.handleDemands(result),
      error: error => this.handleError(error),
    });
  }

  setDefaultColumns() {
    const data = ['select', 'actions'];

    Object.keys(this.defaultColumnsSettings).forEach(part => {
      this.defaultColumnsSettings[part].forEach(setting => {
        if (!setting.values[0]) {
          data.push(setting.id);
        }
      });
    });

    this.displayedColumns = data;
  }

  getColumnNames() {
    return this.allColumnsWithOpt;
  }

  handleDemands(data: any): void {
    const enumNamesToLoad = [
      'domainId',
      'platformId',
      'careerLevelId',
      'sourcingLocationId2',
      'sourcingChannelIds',
      'rateTypeId',
    ];

    this.selection.clear();
    this.multipleIdsNotify.emit(this.getSelectedIds());
    this.multipleNamesNotify.emit(this.getSelectedNames());

    this.demandsLoaded = true;
    const today = new Date().toISOString().substring(0, 10);
    data.forEach(demand => {
      if (demand.canceled) {
        demand.color = '#CE231A';
      } else if (!demand.canceled && demand.rollOffDateDemand.substring(0, 10) < today) {
        demand.color = '#ff8c1a';
      } else {
        demand.color = '#00541C';
      }

      demand.sourcingLocationId2 = demand.sourcingLocationId;
      enumNamesToLoad.forEach(enumName =>
        this.enumsService.setEnumText(demand, enumName, projectsEnumMapping)
      );

      if (demand.employeeDetailId === null) {
        this.projectService.getDemandSoftlockCount(demand.id).subscribe(
          result => {
            demand.softlockCount = result;
          },
          error => this.handleError(error)
        );
      }
    });

    if (data.length > 0) {
      this.demandsDataNotify.emit(true);
    } else {
      this.demandsDataNotify.emit(false);
    }

    this.dataSource.data = data;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkSelection() {
    this.multipleNamesNotify.emit(this.getSelectedNames());
    this.multipleIdsNotify.emit(this.getSelectedIds());
  }

  getSelectedIds(): Array<number> {
    const ids = Array<number>();

    this.selection.selected.forEach(e => {
      ids.push(e.id);
    });

    return ids;
  }

  getSelectedNames(): Array<String> {
    const names = Array<String>();

    this.selection.selected.forEach(e => {
      names.push(e.supplyName);
    });

    return names;
  }

  masterChange(event) {
    if (this.projectDetails.statusId == 5 || this.projectDetails.statusId == 6) {
      return;
    }
    if (event) {
      this.masterToggle();
    }

    this.checkSelection();
  }

  oneChange(event, row: DemandDetails) {
    if (event) {
      this.selection.toggle(row);
    }

    this.checkSelection();
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  editDemand(row: DemandDetails) {
    if (window.location.href.includes('opportunity')) {
      this.router.navigate(['/opportunity-details/add-new-demand'], {
        queryParams: {
          id: row.id,
          projectId: this.projectDetails.projectId,
          employeeDetailId: row.employeeDetailId,
        },
      });
    } else {
      this.router.navigate(['/project-details/add-new-demand'], {
        queryParams: {
          id: row.id,
          projectId: this.projectDetails.projectId,
          employeeDetailId: row.employeeDetailId,
        },
      });
    }
  }

  editSoftlocksHardlocks(row: DemandDetails) {
    this.router.navigate(['/project-details/softlocks-hardlocks'], {
      queryParams: {
        id: row.id,
      },
    });
  }

  handleDeleteDemandsSuccess(response: any) {
    this.snackbar.success(response);
    this.getDemands();
  }

  deleteDemand(row: DemandDetails) {
    Common.deleteDialog(this.dialog).subscribe(result => {
      if (result) {
        this.projectService.deleteDemand(row.id).subscribe(
          response => this.handleDeleteDemandsSuccess(response),
          error => this.handleError(error)
        );
      }
    });
  }

  setColSpans() {
    this.allColumnsWithOpt = [];
    this.demandColSpan = this.getColumnSpan('Demand');
    this.supplyColSpan = this.getColumnSpan('Supply');
    this.applicationColSpan = this.getColumnSpan('ApplicationSkills');
    this.programmingColSpan = this.getColumnSpan('ProgrammingSkills');
    this.languageColSpan = this.getColumnSpan('LanguageSkills');
    this.otherColSpan = this.getColumnSpan('Other');
  }

  getColumnSpan(headerName: String): number {
    let colSpan = 0;
    const demandColumns = [
      'softlockCount',
      'role',
      'demandId',
      'platformId',
      'domainId',
      'demandFte',
      'startDateDemand',
      'rollOffDateDemand',
      'careerLevelId',
      'sourcingLocationId',
      'sourcingChannelIds',
      'germanRequired',
      'frenchRequired',
      'mySchedulingID',
      'dcso',
      'demandRateTypeId',
    ];
    const supplyColumns = ['supplyName', 'supplyFrom', 'supplyTo', 'supplyLevel'];
    const otherColumns = ['roleDescription', 'notes', 'probabilityId', 'createdOn', 'lastModified'];

    const demandColumnsName = [
      'SL Count',
      'Role',
      'Demand ID',
      'Platform',
      'Domain',
      'FTE',
      'Start Date',
      'Roll-off Date',
      'Management Level',
      'Sourcing Location',
      'Sourcing Channel',
      'German Required',
      'French Required',
      'myScheduling ID',
      'DCSO',
      'Rate Type',
    ];
    const supplyColumnsName = ['Name', 'From', 'To', 'Level'];
    const otherColumnsName = [
      'Role Description',
      'Notes',
      'Probability',
      'Created On',
      'Last Modified',
    ];

    this.displayedColumns.forEach(column => {
      let index;
      if (headerName === 'Demand') {
        index = demandColumns.indexOf(column);
        if (index >= 0) {
          this.allColumnsWithOpt.push(demandColumnsName[index]);
          colSpan++;
        }
      } else if (headerName === 'Supply') {
        index = supplyColumns.indexOf(column);
        if (index >= 0) {
          this.allColumnsWithOpt.push(supplyColumnsName[index]);
          colSpan++;
        }
      } else if (headerName === 'Other') {
        index = otherColumns.indexOf(column);
        if (index >= 0) {
          this.allColumnsWithOpt.push(otherColumnsName[index]);
          colSpan++;
        }
      }
    });

    return colSpan;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { HiringRequestOptionalFieldsComponent } from './hiring-request-optional-fields/hiring-request-optional-fields.component';
import { HiringRequestMandatoryFieldsComponent } from './hiring-request-mandatory-fields/hiring-request-mandatory-fields.component';
import { fieldsSettings } from '../../types/new-hiring-request';
import { HiringRequestDetails } from 'app/types/hiring-request-details';
import { Common } from 'app/shared/common';
import { ProjectService } from 'app/services/project.service';
import * as validationUtils from '../../shared/validators';
import { distinctUntilChanged } from 'rxjs/operators';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { ProjectNameDto } from '../../types/ProjectNameDto';
import { openDemands } from '../../types/openDemands';

@Component({
  selector: 'app-add-new-hiring-request',
  templateUrl: './add-new-hiring-request.component.html',
  styleUrls: ['../../shared/add-new.scss', './add-new-hiring-request.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class AddNewHiringRequestComponent implements OnInit {
  @ViewChild('optionalChild', { static: false })
  optionalChild: HiringRequestOptionalFieldsComponent;
  @ViewChild('mandatoryChild', { static: false })
  mandatoryChild: HiringRequestMandatoryFieldsComponent;

  newRequestForm: FormGroup;
  requestDetails = new HiringRequestDetails();
  saving: boolean;

  dateFormatter = new DatePipe('en-US');
  projectsAndOpportunities;
  id: number;
  done: number;
  enums: any;
  data = {};

  constructor(
    public snackbar: SnackbarPanelComponent,
    public router: Router,
    public location: Location,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public authService: AuthService,
    public route: ActivatedRoute,
    public projectService: ProjectService,
    protected enumsService: EnumsService
  ) {}

  ngOnInit(): void {
    this.snackbar.close();
    this.saving = false;

    this.projectService
      .getAllProjects()
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (response: Array<ProjectNameDto>) => this.handleProjects(response),
        error: error => this.handleProjectsError(error),
      });

    const [fieldFormMandatory, fieldFormOptional] = [{}, {}];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    this.newRequestForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    this.enumsService.getData().subscribe({
      next: enums => this.populateDropdowns(enums),
      error: error => this.handleLoadEnumsError(),
    });

    if (this.id !== undefined) {
      this.projectService.getOpenDemand(this.id).subscribe({
        next: (response: openDemands) => this.handleOpenDemand(response),
        error: error => this.handleError(error, null),
      });
    }

    this.setValidators();
    this.setFields();

    Object.keys(this.newRequestForm.controls).forEach(group => {
      const groupControl = this.newRequestForm.get(group);
      Object.keys(groupControl['controls']).forEach(controller => {
        this.requestDetails[controller] = groupControl.get(controller).value;
      });
    });
  }

  protected populateDropdowns(enums) {
    this.enums = enums;
    this.data['requestQualificationEnum'] = enums['request-qualification-industry'];
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  protected transformProjectsAndOpportunities(
    data: Array<ProjectNameDto>
  ): { id: number; text: string }[] {
    return data.map((project: ProjectNameDto) => ({
      id: project.projectId,
      text: project.projectName,
    }));
  }

  handleProjects(data: Array<ProjectNameDto>) {
    this.projectsAndOpportunities = this.transformProjectsAndOpportunities(data);
  }

  handleOpenDemand(data: any) {
    const form = this.newRequestForm;
    this.requestDetails = data;
    console.log(this.requestDetails);
    this.newRequestForm.get('mandatory').get('domainId').enable({ emitEvent: false });
    this.newRequestForm.get('mandatory').get('typeId').enable({ emitEvent: false });

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        if (controlerName == 'projectId' && this.projectsAndOpportunities !== undefined) {
          let projectName = this.projectsAndOpportunities.find(obj => {
            return obj.id === this.requestDetails[controlerName];
          });
          if (projectName !== undefined) {
            groupControl
              .get(controlerName)
              .setValue(projectName.text, { onlySelf: true, emitEvent: false });
          }
        } else if (controlerName == 'mySchedulingId') {
          if (this.data['requestQualificationEnum'] != null && data.projectId != null) {
            let filteredDataIndustry = this.data['requestQualificationEnum'].filter(
              item => item.id === data.projectId
            );
            if (
              filteredDataIndustry[0]['clientGroupId'] != 6 &&
              filteredDataIndustry[0]['clientGroupId'] != 10
            ) {
              this.newRequestForm
                .get('mandatory')
                .get('mySchedulingId')
                .enable({ emitEvent: false });
              groupControl.get(controlerName).setValue('', { onlySelf: true, emitEvent: false });
            }
          }
        } else {
          groupControl
            .get(controlerName)
            .setValue(this.requestDetails[controlerName], { onlySelf: true, emitEvent: false });
        }
      });
    });

    if (this.mandatoryChild.mandatory.get('profileId').value !== 1) {
      this.mandatoryChild.removeStudentDemandType();
    }
  }

  handleProjectsError(error: Error) {
    this.snackbar.error(error.message);
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        const validators = [];
        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });
        this.newRequestForm.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newRequestForm
            .get(set)
            .get(setting.id)
            .disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }

  save() {
    let invalid_mySchedulingId = false;
    let invalid_projectName = false;
    this.saving = true;
    const form = this.newRequestForm;
    const newRequest = {};
    this.mandatoryChild.unsubscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.unsubscribeSpecialEnums();
    }

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        const controler = groupControl.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.enabled && controler.value !== null) {
          if (
            controlerName == 'mySchedulingId' &&
            (controler.value.length != 7 || !/^\d+$/.test(controler.value))
          ) {
            invalid_mySchedulingId = true;
          }
          if (controler.value instanceof Date) {
            newRequest[controlerName] = Common.timezoneFix(controler.value);
          } else if (controlerName == 'projectId') {
            const filteredProject = this.projectsAndOpportunities.filter(
              project => project.text === controler.value
            );
            if (filteredProject.length != 0) {
              newRequest[controlerName] = filteredProject[0].id;
            } else {
              invalid_projectName = true;
            }
          } else {
            newRequest[controlerName] = controler.value;
          }
        }
      });
    });

    this.mandatoryChild.subscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.subscribeSpecialEnums();
    }

    this.subscribeSpecialEnums();

    if (invalid_mySchedulingId) {
      this.snackbar.error('Please provide myScheduling ID (7 digits).');
      this.saving = false;
      return;
    }

    if (invalid_projectName) {
      this.snackbar.error('Please provide existing Opportunity or Project name.');
      this.saving = false;
      return;
    }

    if (form.valid) {
      this.projectService.addOpenDemand(newRequest).subscribe(
        res => {
          this.handleSuccess(res);
        },
        err => {
          this.handleError(err, newRequest);
        }
      );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
    }
  }

  onTypeChange($subco) {
    if ($subco) {
      this.mandatoryChild.enableSubcoFields();
      this.optionalChild.enableSubcoFields();
    } else {
      this.mandatoryChild.disableSubcoFields();
      this.optionalChild.disableSubcoFields();
    }
  }

  handleSuccess(response: any) {
    this.saving = false;
    this.snackbar.success('New hiring request created successfully!');
    this.done = 1;
    setTimeout(() => this.routeToAllRequests(), 2000);
  }

  routeToAllRequests() {
    const routerLink = '/hiring-requests';
    this.router.navigate([routerLink]);
  }

  handleError(error: Error, newRequest: any) {
    this.saving = false;
    this.snackbar.error(error.message);
  }

  subscribeSpecialEnums() {}

  back() {
    this.snackbar.close();
    this.router.navigate(['/hiring-requests']).then();
  }

  canDeactivate() {
    const touched = Object.keys(this.newRequestForm.controls).some(group => {
      const groupControl = this.newRequestForm.get(group);
      return Object.keys(groupControl['controls']).some(
        (controllerName: string) => groupControl.get(controllerName).touched
      );
    });

    return touched && this.done !== 1 ? Common.editWarningDialog(this.dialog) : true;
  }
}

export const errorMessages = {
  exceedLength500: 'Please enter no more than 500 characters',
  exceedLength255: 'Please enter no more than 255 characters',
  exceedLength150: 'Please enter no more than 150 characters',
  exceedLength100: 'Please enter no more than 100 characters',
  exceedLength40: 'Please enter no more than 40 characters',
  exceedLength20: 'Please enter no more than 20 characters',
  exceedLength9: 'Please enter no more than 9 characters',
  exceedLength8: 'Please enter no more than 8 characters',
  required: 'Please enter valid value',
  requiredPrimarySkill: 'Primary skill is required',
  requiredPrimaryPro: 'Primary proficiency is required',
  requiredIndustrySkill: 'Industry skill is required',
  requiredIndustryPro: 'Industry proficiency is required',
  requiredRotationPref: 'This value is required for selected Rotation Preferences',
  requiredSharedService: 'This value is required for selected Work Category',
  notDate: 'Please enter valid date',
  notFlInterval: 'Please enter value between 0 and 1 (e.g. 0.75)',
  notTaskEffort: 'Please enter value with 2 decimals maximum (e.g. 12.75)',
  notInteger: 'Please enter positive integer number',
  notPositive: 'Please enter positive number',
  notNumber: 'Enter a number',
  max10Digits: 'Max 10 digits',
  wrongStatus: 'Status is wrong',
  wrongBillCode: "Bill code doesn't belong to given location",
  notSeat: 'Please enter correct seat format based on location',
  matDatepickerParse: 'Please enter valid date',
  higherDate: 'Start date must be before end date',
  startDatesEquals: 'Start date must be after original start date',
  notTLT: 'Please enter value between 1 and 3',
  notEmail: 'Please enter valid email address',
  dcResponsibleRoleISM:
    'DC Responsible Role ISM not allowed for Operational Responsibility Managed Delivery and E2E Projects',
  dcResponsibleRolePDL:
    'DC Responsible Role must be changed, not valid for Capacity Services and Non E2E Project',
  dcResponsibleAndDeputy: 'DC Responsible Deputy cannot be the same as DC Responsible Name',
  dcResponsibleAndDeputyFormat: 'Please select/enter value from dropdown menu',
  prolongationDate: 'Please enter prolongation date AFTER roll-off date',
  prolongationProbability: 'Please enter prolongation probability',
  myWizardDMSIdFormat:
    'Incorrect format myWizard ID. Please use "123456-123456-123456" format or "SF" or "NA" values instead of numbers.',
  myWizardDMSIdLength: 'The maximum length of myWizard ID is 20 characters.',
  eidOrMail: 'Only Accenture Enterprise ID or Avanade email address is allowed.',
  seatRegex: 'Wrong seat format.',
};

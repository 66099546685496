import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'select',
    data: 'buildingEnum',
    id: 'building',
    label: 'Building',
    validation: ['valRequired'],
    values: ['', []],
  },
  {
    cardType: 'input',
    id: 'numberOfSeats',
    label: 'Number of Seats',
    validation: ['valInteger', 'valRequired'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'seatFrom',
    label: 'From',
    validation: ['valDate', 'valRequired', 'valFromToDates'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'seatTo',
    label: 'To',
    validation: ['valDate', 'valRequired', 'valToFromDates'],
    values: ['', []],
  },
  { cardType: 'input', id: 'wbs', label: 'WBS', validation: ['valString8'], values: ['', []] },
  { cardType: 'input', id: 'pdlComment', label: 'PDL Comment', validation: [], values: ['', []] },
];

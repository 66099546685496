import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { HiringRequestCommonFieldsComponent } from '../hiring-request-common-fields/hiring-request-common-fields.component';

@Component({
  selector: 'app-hiring-request-optional-fields',
  templateUrl: './hiring-request-optional-fields.component.html',
  styleUrls: ['./hiring-request-optional-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class HiringRequestOptionalFieldsComponent
  extends HiringRequestCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: AbstractControl;

  ngOnInit(): void {
    super.ngOnInit();

    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes, this.optional));
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);
    this.data['additionalLanguageSkillEnum'] = enums['open-demands-language-skill'];
    this.data['industryEnum'] = enums['client-group'];
  }

  enableSubcoFields() {
    this.enableOptionalField('industry');
  }

  disableSubcoFields() {
    this.disableOptionalField('industry');
  }

  enableOptionalField(name: string) {
    this.optional.get(name).enable();
    this.optional.get(name).updateValueAndValidity();
  }

  disableOptionalField(name: string) {
    this.optional.get(name).disable();
    this.optional.get(name).updateValueAndValidity();
  }

  subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}
}

import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'formatCardEnum' })
export class FormatCardEnum implements PipeTransform {
  transform(value: any, enumeration: any): string {
    let itemValue = '-';
    if (value === '' || value == null) {
      return (itemValue = '-');
    } else if (enumeration === null) {
      return (itemValue = 'loading ...');
    }

    if (enumeration && enumeration.values[value] === null) {
      return (itemValue = '-');
    }

    enumeration.forEach(element => {
      if (element.id === value) {
        if (element.costCentrumNumber) {
          return (itemValue = element.text.concat(' ').concat(element.costCentrumNumber));
        } else {
          return (itemValue = element.text);
        }
      } else if (typeof value === 'string' && element === value) {
        return (itemValue = element);
      }
    });
    return itemValue;
  }
}

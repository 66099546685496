import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { REST } from './../../rest.endpoints';
import { throwError } from 'rxjs';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  exportReport(): Observable<any> {
    return this.http
      .get(REST.exportReport, { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportNSMetricsReport(month, year): Observable<any> {
    return this.http
      .get(REST.exportNSMetricsReport(month, year), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportAwsSeatingReport(month, year): Observable<any> {
    return this.http
      .get(REST.exportAwsSeatingReport(month, year), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportNBMReport(): Observable<any> {
    return this.http
      .get(REST.exportNBMReport(), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportVacationAndOvertimeReport(): Observable<any> {
    return this.http
      .get(REST.exportVacationAndOvertimeReport(), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportOpenDemandsReport(date, month, year): Observable<any> {
    return this.http
      .get(REST.exportOpenDemandsReport(date, month, year), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }
  exportOpenPositionsReport(platform, location, onHold, dateFrom, dateTo): Observable<any> {
    return this.http
      .get(REST.exportOpenPositionsReport(platform, location, onHold, dateFrom, dateTo), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportDataFreshnessReport(platform, location): Observable<any> {
    return this.http
      .get(REST.exportDataFreshnessReport(platform, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportProjectSummaryReport(type, inactive, location): Observable<any> {
    return this.http
      .get(REST.exportProjectSummaryReport(type, inactive, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportSupplySummaryReport(month, year, location): Observable<any> {
    return this.http
      .get(REST.exportSupplySummaryReport(month, year, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportLanguageSkillsUsageReport(month, year, location): Observable<any> {
    return this.http
      .get(REST.exportLanguageSkillsUsageReport(month, year, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportSchedulingReport(
    inactive,
    supplyHistoryFrom,
    supplyHistoryTo,
    reportingDate,
    platform,
    location,
    slovakFormat,
    country
  ): Observable<any> {
    return this.http
      .get(
        REST.exportSchedulingReport(
          inactive,
          supplyHistoryFrom,
          supplyHistoryTo,
          reportingDate,
          platform,
          location,
          slovakFormat,
          country
        ),
        { responseType: 'blob', observe: 'response' }
      )
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportPlatformReport(platform, location, month, year): Observable<any> {
    return this.http
      .get(REST.exportPlatformReport(platform, location, month, year), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportCostCenterReport(location): Observable<any> {
    return this.http
      .get(REST.exportCostCenterReport(location), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportOpportunitySummaryReport(platform, numberOfMonths, reportDate, location): Observable<any> {
    return this.http
      .get(REST.exportOpportunitySummaryReport(platform, numberOfMonths, reportDate, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportHiringRequestsReport(platform, location): Observable<any> {
    return this.http
      .get(REST.exportHiringRequestsReport(platform, location), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportProjectCostReport(project, month, year): Observable<any> {
    return this.http
      .get(REST.exportProjectCostReport(project, month, year), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportProjectsRollOffsOverview(): Observable<any> {
    return this.http
      .get(REST.exportProjectsRollOffsOverview(), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  exportTrainingDataReport(): Observable<any> {
    return this.http
      .get(REST.exportTrainingDataReport(), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return throwError(() => error);
  }
}

import { QA } from './new-form';
export const fieldsSettingsQa: QA = {
  mandatory: [
    {
      cardType: 'input',
      id: 'reportingPpqa',
      label: 'PPQA',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'reportingDrr',
      label: 'DRR',
      validation: ['valRequired'],
      values: ['', []],
    },
  ],
};

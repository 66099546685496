import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonCardComponent } from '../common-card/common-card.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import * as cards from '../../../types/lead-cards-content';
import { ProjectCardItemEnumMultipleComponent } from 'app/project/project-card-item/project-card-item-enum-multiple/project-card-item-enum-multiple.component';

@Component({
  selector: 'app-lead-card',
  templateUrl: './lead-card.component.html',
  styleUrls: ['./lead-card.component.scss', '../../../shared/card.scss'],
  providers: [SnackbarPanelComponent],
})
export class LeadCardComponent extends CommonCardComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.projectOriginVal = Object.assign({}, this.projectDetails);
    this.projectOriginVal.client = Object.assign({}, this.projectDetails.client);
    this.projectOriginVal.projectInfo = Object.assign({}, this.projectDetails.projectInfo);
    this.projectOriginVal.projectSchedule = Object.assign({}, this.projectDetails.projectSchedule);
    this.projectOriginVal.reqQualification = Object.assign(
      {},
      this.projectDetails.reqQualification
    );

    const group = {};
    this.projectCard.forEach(item => {
      if (item.type !== 'read') {
        group[item.id] = ['', []];
      }
    });
    this.formGroup = this.fb.group(group);

    this.initEditingRole();

    if (this.formGroup.get('regionId')) {
      this.formGroup.get('regionId').valueChanges.subscribe(value => this.onRegionChanges(value));
    }

    if (this.formGroup.get('platformId')) {
      this.formGroup
        .get('platformId')
        .valueChanges.subscribe(value => this.onPlatformChanges(value));
    }

    if (this.formGroup.get('clientGroupId')) {
      this.formGroup
        .get('clientGroupId')
        .valueChanges.subscribe(value => this.onIndustryGroupChanges(value));
    }

    if (this.formGroup.get('commitment')) {
      this.formGroup
        .get('commitment')
        .valueChanges.subscribe(value => this.onCommitmentChanges(value));
    }

    if (this.formGroup.get('newBusinessMeeting')) {
      this.formGroup
        .get('newBusinessMeeting')
        .valueChanges.subscribe(value => this.onNewBusinessMeetingChange(value));
    }

    if (this.formGroup.get('nbmStatus')) {
      this.formGroup
        .get('nbmStatus')
        .valueChanges.subscribe(value => this.onNbmStatusChange(value));
    }

    if (this.formGroup.get('sourcingLocationId')) {
      this.sourcingLocVal = this.projectDetails.projectInfo.sourcingLocation;
      this.formGroup
        .get('sourcingLocationId')
        .valueChanges.subscribe(value => this.onSourcingLocationChanges(value));
    }

    this.checkNbmStatus(Number(this.formGroup.get('nbmStatus')));
    this.checkNewBusinessMeeting(Boolean(this.formGroup.get('newBusinessMeeting')));
  }

  onSourcingLocationChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (
          item.id === 'sourcingLocationId' &&
          item instanceof ProjectCardItemEnumMultipleComponent
        ) {
          this.sourcingLocVal = value;
          return true;
        }
        return false;
      });
    }
  }

  // if nbm status === conditionaly approved -> condition enabled; else -> disabled
  checkNbmStatus(nbmStatus: number) {
    if (this.formGroup.get('condition')) {
      if (nbmStatus === 5) {
        this.enableField(this.formGroup.get('condition'), true, '');
      } else {
        this.disableField(this.formGroup.get('condition'), true, '');
      }
    }
  }

  onNbmStatusChange(nbmStatus: number) {
    this.checkNbmStatus(nbmStatus);
  }

  checkNewBusinessMeeting(newBusinessMeeting: boolean) {
    if (this.formGroup.get('nbmStatus')) {
      if (newBusinessMeeting) {
        this.enableField(this.formGroup.get('nbmStatus'), false);
      } else {
        this.disableField(this.formGroup.get('nbmStatus'), true, 7);
      }
    }
  }

  onNewBusinessMeetingChange(newBusinessMeeting: boolean) {
    this.checkNewBusinessMeeting(newBusinessMeeting);
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProjectDetails } from '../../types/project-details';
import { CommonComponent } from '../../shared/common/common.component';
import { ProjectService } from '../../services/project.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-history',
  templateUrl: './project-history.component.html',
  styleUrls: [
    './project-history.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectHistoryComponent extends CommonComponent implements OnInit, AfterViewInit {
  @Input() projectDetails: ProjectDetails;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['logDate', 'employeeId', 'target', 'description'];
  typesOfHistory = [
    { type: 'le', name: 'Project / Demand' },
    { type: 'sl', name: 'Softlock / Hardlock' },
  ];
  selectedType = 'le';
  dataSource = new MatTableDataSource();
  historyLoaded = false;
  test = '<b>test</b>';

  totalElements = 0;
  pageIndex = 0;
  pageSize = 15;

  constructor(
    protected fb: FormBuilder,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getHistory();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  setPageSizeOptions(totalElements) {
    let pageSize = [];

    if (totalElements > 50) {
      pageSize = [15, 30, 50, totalElements];
    } else if (totalElements > 30) {
      pageSize = [15, 30, totalElements];
    } else if (totalElements > 15) {
      pageSize = [15, totalElements];
    } else {
      pageSize = [15];
    }

    return pageSize;
  }

  getHistory() {
    this.historyLoaded = false;

    const baseParams = {
      pageSize: this.pageSize,
      pageNumber: this.pageIndex,
      type: this.selectedType,
    };

    this.projectService.getHistory(this.projectDetails.projectId, baseParams).subscribe(
      response => this.handleHistory(response),
      error => this.handleError(error)
    );
  }

  handleHistory(data: any): void {
    this.totalElements = data.totalElements;
    this.dataSource.data = data.history;
    this.historyLoaded = true;
  }

  page(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getHistory();
  }

  onHistoryChange(event: MatSelectChange) {
    if (this.selectedType !== event.value) {
      this.selectedType = event.value;
      this.getHistory();
    }
  }
}

import { throwError as observableThrowError } from 'rxjs';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AutocompleteSearchComponent } from './autocomplete-search/autocomplete-search.component';
import { EmpOption } from '../../types/emp-search-option';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-employee-auto-search',
  templateUrl: './employee-auto-search.component.html',
  styleUrls: ['./employee-auto-search.component.scss'],
})
export class EmployeeAutoSearchComponent implements OnInit {
  searchedEnterpriseDomainId: string;
  @ViewChild('autocompleteSearch', { static: false })
  autocompleteSearch: AutocompleteSearchComponent;
  @Input() navigationBar;
  @Input('isChecked')
  set isChecked(checked) {
    this.autocompleteSearch.searchInactive = checked;
    this.autocompleteSearch.onCheckedChange();
  }

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  navigateToEmployeeDetails(): void {
    if (this.searchedEnterpriseDomainId != null && this.autocompleteSearch.optionCtrl.valid) {
      if (window.location.href.indexOf('new') !== -1) {
        this.router.navigate(['/new/employee-details', this.searchedEnterpriseDomainId]);
      } else {
        this.router.navigate(['/employee-details', this.searchedEnterpriseDomainId]);
      }
    }
  }

  modelChange(val: string | EmpOption) {
    if (typeof val === 'object') {
      this.searchedEnterpriseDomainId = val.id;
      this.navigateToEmployeeDetails();
    } else {
      this.searchedEnterpriseDomainId = val;
    }
  }

  private handleError(error: Response | any) {
    return observableThrowError(error);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { DatePipe } from '@angular/common';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ReportService } from '../../services/report.service';
import { AppComponent } from '../../app.component';
import { EnumsService } from '../../services/enumerations/enums.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { MONTH_YEAR_FORMAT } from '../../types/date-format';

@Component({
  selector: 'app-platform-report',
  templateUrl: './platform-report.component.html',
  styleUrls: ['./platform-report.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MONTH_YEAR_FORMAT,
    },
  ],
})
export class PlatformReportComponent implements OnInit {
  dateFormatter = new DatePipe('en-US');
  exporting = false;
  exportForm: FormGroup;
  data = {};
  choosedMonth: number = new Date().getMonth() + 1;
  choosedYear: number = new Date().getFullYear();
  date = new Date();
  title: string = 'Platform Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent
  ) {}

  ngOnInit() {
    this.snackbar.close();

    this.exportForm = this.fb.group({
      platform: ['', []],
      location: ['', []],
      period: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    this.data['employeeLocation'] = enums['employee-location'];
    this.data['platform'] = enums['platform'];

    const all = { id: 0, stage: 0, text: 'All', deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }
    if (this.data['platform'].findIndex(item => item.id === all.id) < 0) {
      this.data['platform'].unshift(all);
    }

    this.exportForm.get('period').setValue(this.date);
    this.exportForm.get('platform').setValue(0);
    this.exportForm.get('location').setValue(0);
  }

  exportPlatformReport() {
    this.exporting = true;

    const platform = this.exportForm.get('platform').value;
    const location = this.exportForm.get('location').value;

    this.reportService
      .exportPlatformReport(platform, location, this.choosedMonth, this.choosedYear)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  chosenMonthHandler(date: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue = this.exportForm.get('period').value;
    ctrlValue.setMonth(date.getMonth());
    ctrlValue.setFullYear(date.getFullYear());
    this.choosedMonth = new Date(ctrlValue).getMonth() + 1;
    this.choosedYear = new Date(ctrlValue).getFullYear();
    this.exportForm.get('period').setValue(ctrlValue);
    datepicker.close();
  }

  handleExportSucces(result: any): void {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any): void {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { fieldsSettings } from '../../types/project-demand-column-settings';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../services/project.service';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { User } from '../../services/user-roles/user';

@Component({
  selector: 'app-column-dialog',
  templateUrl: './column-dialog.component.html',
  styleUrls: ['./column-dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ColumnDialogComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  form: FormGroup;
  user: User;
  // parts = ['demand', 'supply', 'programmingSkills', 'applicationSkills', 'languageSkills', 'other'];
  parts = ['demand', 'supply', 'other'];
  id: number;
  disabled = true;

  constructor(
    private userRolesService: UserRolesService,
    public dialogRef: MatDialogRef<ColumnDialogComponent>,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);

        this.getColumnsSettings(this.user);
      });

    this.form = this.fb.group({});

    this.parts.forEach(part => {
      const fg = this.fb.group({});

      fieldsSettings[part].forEach(setting => {
        fg.addControl(setting.id, new FormControl(setting.values[0]));
      });

      fg.addControl('all', new FormControl(false));

      this.form.addControl(part, fg);
    });
    //  this.disableInputs();
  }

  getColumnsSettings(user: User) {
    this.projectService.getColumnsSettings(user.eid).subscribe(
      result => this.handleColumnSettings(result),
      error => this.handleError(error)
    );
  }

  handleColumnSettings(result) {
    if (result) {
      Object.keys(result).forEach(field => {
        this.parts.forEach(part => {
          const groupControl = this.form.get(part);

          if (groupControl.get(field) && field !== 'id') {
            this.form.get(part).get(field).setValue(result[field]);
          } else if (field === 'id') {
            this.id = result[field];
          }
        });
      });
    }

    this.parts.forEach(part => {
      this.form.get(part).get('all').setValue(this.checkAll(part));
    });

    this.disabled = false;
  }

  checkAll(group): boolean {
    const groupControl = this.form.get(group);
    let all = true;

    Object.keys(groupControl['controls']).forEach(controlerName => {
      if (controlerName !== 'all' && !groupControl.get(controlerName).value) {
        all = false;
      }
    });

    return all;
  }

  disableInputs() {
    this.parts.forEach(part => {
      fieldsSettings[part].forEach(setting => {
        if (setting.values[0]) {
          this.form.get(part).get(setting.id).disable();
        }
      });
    });
  }

  onAllChange(event: Event, group) {
    const groupControl = this.form.get(group);
    const value = !groupControl.get('all').value;

    Object.keys(groupControl['controls']).forEach(controler => {
      if (controler !== 'all' && !groupControl.get(controler).disabled) {
        groupControl.get(controler).setValue(value);
      }
    });

    event.stopPropagation();
  }

  create(create: boolean) {
    if (create) {
      const columnSettings = {
        id: this.id,
      };

      Object.keys(this.form.controls).forEach(group => {
        const groupControl = this.form.get(group);
        Object.keys(groupControl['controls']).forEach(controlerName => {
          if (controlerName !== 'all') {
            columnSettings[controlerName] = groupControl.get(controlerName).value;
          }
        });
      });

      this.projectService.editColumnsSettings(this.user.eid, columnSettings).subscribe(
        result => this.handleColumnsSettings(result),
        error => this.handleError(error)
      );
    } else {
      this.dialogRef.close(false);
    }
  }

  handleColumnsSettings(result) {
    this.snackbar.success('Columns settings has been saved');
    this.dialogRef.close(true);
  }

  handleError(error) {
    this.snackbar.error('Problem with columns settings');
  }

  checkSome(group) {
    const groupControl = this.form.get(group);
    let i = 0;

    Object.keys(groupControl['controls']).forEach(controlerName => {
      if (controlerName !== 'all' && groupControl.get(controlerName).value) {
        i++;
      }
    });

    if (
      Object.keys(groupControl['controls']).length - 1 === i &&
      i > 0 &&
      !this.form.get(group).get('all').value
    ) {
      this.form.get(group).get('all').setValue(true);
    } else if (i === 0 && this.form.get(group).get('all').value) {
      this.form.get(group).get('all').setValue(false);
    }

    // console.log(Object.keys(groupControl['controls']).length - 1 > i && i > 0);
    return Object.keys(groupControl['controls']).length - 1 > i && i > 0;
  }
}

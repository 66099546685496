import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EmployeeDetails } from '../../types/employee-details';
import { REST } from 'rest.endpoints';
import { catchError, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class EmployeeDetailsService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  deleteSupply(id: number): Observable<any> {
    return this.http
      .delete(REST.employeeDetailsSupplyDelete(id))
      .pipe(catchError(this.handleError));
  }

  deleteEmployee(id: number): Observable<any> {
    return this.http.delete(REST.employeeDetailsDelete(id)).pipe(catchError(this.handleError));
  }

  editEmployeeDetails(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.employeeDetailsEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addNewEmployee(empDetails: EmployeeDetails): Observable<any> {
    return this.http
      .post(REST.employeeDetailsInsert, empDetails, {})
      .pipe(catchError(this.handleError));
  }

  getActiveEmployeeDetailsList(enterpriseDomainId): Observable<any> {
    return this.http
      .get(REST.employeeDetailsActiveList(enterpriseDomainId))
      .pipe(catchError(this.handleError));
  }

  getAllEmployeeDetailsList(enterpriseDomainId): Observable<any> {
    return this.http
      .get(REST.employeeDetailsAllList(enterpriseDomainId))
      .pipe(catchError(this.handleError));
  }

  getAbsences(enterpriseDomainId: string): Observable<any> {
    return this.http
      .get(REST.employeeDetailsAbsences(enterpriseDomainId))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getEmployeeDetails(enterpriseDomainId: string): Observable<any> {
    return this.http
      .get(REST.employeeDetails(enterpriseDomainId), { observe: 'body' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  importAbsence(absence: any): Observable<any> {
    return this.http
      .post(REST.absenceInsert, absence)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  updateAbsence(id: any, absence: any): Observable<any> {
    return this.http
      .put(REST.absenceEdit(id), absence)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  importAbsences(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(REST.absencesInsert, formData).pipe(catchError(this.handleError));
  }

  importOvertime(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(REST.overtimeInsert, formData).pipe(catchError(this.handleError));
  }

  // If error occurs, it is obvious that employee wasn't found, so maybe we just need a boolean variable
  private handleError(error: HttpResponse<any> | any) {
    return observableThrowError(error);
  }
}

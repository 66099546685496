import { Component, OnInit } from '@angular/core';
import { CardItemComponent } from './../card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-card-item-bool',
  templateUrl: './card-item-bool.component.html',
  styleUrls: ['../card-item.component.scss', './card-item-bool.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class CardItemBoolComponent extends CardItemComponent implements OnInit {
  constructor(enumsService: EnumsService, snackbar: SnackbarPanelComponent) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.disabled && this.type !== 'read') {
      this.control.controls[this.id].enable();
    }
  }
}

import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomDateAdapter } from 'app/shared/date-utils';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { EnumsService } from '../../services/enumerations/enums.service';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../services/auth.service';
import { CommonComponent } from 'app/shared/common/common.component';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from 'app/services/user-roles/user';
import { ProjectDetails } from 'app/types/project-details';
import { Location } from '@angular/common';

@Component({
  selector: 'app-infrastructure-review',
  templateUrl: './infrastructure-review.component.html',
  styleUrls: [
    './infrastructure-review.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent, { provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class InfrastructureReviewComponent extends CommonComponent implements OnInit {
  @ViewChildren('charge') charges: QueryList<any>;

  month: any;
  year: any;
  projectDetails: any[];
  eid: any;
  pd0: ProjectDetails;
  projectDetailsLoaded = false;
  reviewMonthSelected = false;
  approving = false;
  projectNames: any;
  selected: any;

  constructor(
    private versionDataService: VersionDataService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public userRolesService: UserRolesService,
    public projectService: ProjectService,
    public enumsService: EnumsService,
    public snackbar: SnackbarPanelComponent,
    public app: AppComponent,
    router: Router,
    authService: AuthService,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.eid = user.eid;
      });

    this.form = this.fb.group({
      month: ['', []],
    });

    this.form.get('month').valueChanges.subscribe(value => this.onMonthChange(value));

    const d = new Date();
    d.setDate(1);
    const actualMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const actualYear = d.getFullYear();

    d.setMonth(d.getMonth() - 1);

    const previousMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const previousYear = d.getFullYear();

    d.setMonth(d.getMonth() + 2);

    const nextMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const nextYear = d.getFullYear();

    this.data['month'] = [
      {
        id: 0,
        text: previousMonth + ' / ' + previousYear + ' (reporting period)',
      },
      {
        id: 1,
        text: actualMonth + ' / ' + actualYear,
      },
      {
        id: 2,
        text: nextMonth + ' / ' + nextYear,
      },
    ];

    this.getNamesForProjects();
    this.getSeatsHardware(previousMonth, previousYear);
    this.form.get('month').setValue(previousMonth + ' / ' + previousYear + ' (reporting period)');
    this.selected = previousMonth + ' / ' + previousYear + ' (reporting period)';
    this.form.get('month').setValue(0, { emitEvent: true });
  }

  onMonthChange(value) {
    this.reviewMonthSelected = true;
    this.data['month'].forEach(val => {
      if (val.id === value) {
        this.month = val.text.substring(0, 2);
        this.year = val.text.substring(5, 9);

        this.getNamesForProjects();
        this.getSeatsHardware(this.month, this.year);
      }
    });
  }

  getSeatsHardware(month, year) {
    this.approving = false;
    this.projectDetailsLoaded = false;
    console.log(this.eid, month, year);
    this.projectService.getReviewSeatingsHardwareActive(this.eid, month, year).subscribe(
      response => this.handleCharges(response),
      error => this.handleError(error)
    );
  }

  getNamesForProjects() {
    this.projectService.projectNamesForDcResp(this.eid).subscribe(
      response => this.handleProjectNames(response),
      error => this.handleError(error)
    );
  }

  handleProjectNames(data) {
    const names = new Map();
    data.map(name => {
      names.set(name.id, name.projectName);
    });
    this.projectNames = names;
  }

  handleCharges(data: any[]) {
    this.projectDetails = data.sort((a, b) => (a.totalSum > b.totalSum ? -1 : 1));
    this.reviewMonthSelected = false;
    this.projectDetailsLoaded = true;
    console.log(data);
  }

  approveReviews() {
    this.approving = true;
    const detailsToSend = [];
    this.charges.toArray().map(pd => {
      if (pd.choosed && !pd.approved && pd.validity) {
        detailsToSend.push(pd.data);
      }
    });

    this.projectService.approveEmployeeSeatsHardware(detailsToSend).subscribe(
      response => this.handleApproveSeatsHardware(response),
      error => this.handleError(error)
    );
  }

  handleApproveSeatsHardware(response) {
    this.form.get('month').setValue(null);
    this.reviewMonthSelected = false;
    this.snackbar.success('Seats and hardware has been aproved');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

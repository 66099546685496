import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { User } from '../services/user-roles/user';
import { UserRolesService } from '../services/user-roles/user-roles.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  userName: string;
  sidenavLinks: Array<{ id: number; displayedName: string; icon: string; url: string }>;
  reportsLinks: Array<{ id: number; displayedName: string; url: string }>;

  constructor(
    private userRolesService: UserRolesService,
    private router: Router,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user: User = new User(data);
        this.userName = user.name;
        this.sidenavLinks = user.getRoles().sidenavLinks;
        this.reportsLinks = user.getRoles().reportsLinks;
      });
  }

  isActive(url: string): boolean {
    return this.router.url.indexOf(url) !== -1;
  }

  isCreated(url: string): boolean {
    return this.router.config.some(item => item.path === url);
  }
}

import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ProjectDetails } from '../../../types/project-details';
import { ProjectService } from '../../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnDialogComponent } from '../../column-dialog/column-dialog.component';
import { AttachmentDialogComponent } from '../../attachment-dialog/attachment-dialog.component';
import { AppComponent } from '../../../app.component';
import { bannerCard } from '../../../types/project-banner-card';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { User } from '../../../services/user-roles/user';
import * as cards from '../../../types/opportunity-cards-content';
import { ClientListService } from '../../../services/client-list.service';
import { Location, PlatformLocation } from '@angular/common';
import { FteDialogComponent } from '../../fte-dialog/fte-dialog.component';
import { DialogData } from '../../../types/dialog-data';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { VersionDataService } from 'app/version-data.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-common-details',
  templateUrl: './common-details.component.html',
  styleUrls: ['./common-details.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class CommonDetailsComponent implements OnInit {
  @ViewChildren('card') cardComponents: QueryList<any>;

  projectCards = [];
  bannerCard = bannerCard;
  projectDetails: ProjectDetails;
  searchedId: any;
  creatingRole: boolean;
  editingRole: boolean;
  deletingRole: boolean;
  anotherCard: any;
  selectedTab: number;
  clientEnum: any;
  ids: Array<number>;
  historyRole: boolean;
  activeDemands = true;
  reloadDemands: number;
  reloadSettings: number;
  reloadAttachments: number;
  names: Array<string>;
  eid: string;
  hasDomainId: Boolean;
  disabledFte = true;
  disabledEditMore = true;
  disabledCopyDemand = true;
  demandsData = false;
  demandsExport: string;
  enums: any;
  statusCancelledOrClosed: boolean;

  urlList = '../opportunity-list';
  urlDetails = '/opportunity-details';
  errorMessage = 'Invalid Opportunity ID route.';

  constructor(
    private versionDataService: VersionDataService,
    public dialog: MatDialog,
    public userRolesService: UserRolesService,
    public projectService: ProjectService,
    public snackbar: SnackbarPanelComponent,
    public enumsService: EnumsService,
    public route: ActivatedRoute,
    public clientListService: ClientListService,
    public router: Router,
    public location: Location,
    public pLocation: PlatformLocation,
    public authService: AuthService,
    public app: AppComponent
  ) {}

  ngOnInit() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.route.fragment.subscribe(fragment => {
      this.selectedTab = this.chooseTab(fragment);
    });

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
        this.historyRole = user.getRoles().adminOrHr ? true : false;
        this.eid = user.eid;
        this.hasDomainId = user.hasDomainId;
      });

    this.loadClient();
    // Workaround for navigating back to List
    // TODO: router routing on tabbed components instead of using fragments
    this.pLocation.onPopState(() => {
      this.router.navigate([this.urlList]);
    });
  }

  protected sortEnumValues(enums) {
    enums['region'].sort((a, b) => {
      return a.text >= b.text ? 1 : -1;
    });

    if (enums['offering-name'] !== null) {
      // Not Applicable first
      enums['offering-name'].sort(function (x, y) {
        if (x.text === 'Offering Not Used' || y.text === 'Offering Not Used') {
          return x.text === 'Offering Not Used' ? 1 : -1;
        }
        return x.text.localeCompare(y.text);
      });
    }

    if (enums['service-request'] !== null) {
      // Not Applicable first
      enums['service-request'].sort((a, b) => a.stage - b.stage);
    }

    if (enums['employee-country-enum'] !== null) {
      enums['employee-country-enum'].sort((a, b) => a.stage - b.stage);
    }

    return enums;
  }

  protected populateDropdowns(enums) {
    this.enums = JSON.parse(JSON.stringify(enums));

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  paramsToUrl(): string {
    const urlParams = new HttpParams();

    urlParams.append('projectId', this.projectDetails.projectId.toString());

    return urlParams.toString();
  }

  chooseTab(fragment: string): number {
    if (fragment === 'demands') {
      return 1;
    } else if (fragment === 'seating-hw') {
      return 2;
    } else if (fragment === 'attachments') {
      return 3;
    } else if (fragment === 'history') {
      return 4;
    } else if (fragment === 'reporting') {
      return 5;
    } else {
      return 0;
    }
  }

  onTabChange(e) {
    // If tab was changed then check for unfinished work on projectCards
    if (this.selectedTab === 0 && e.index > this.selectedTab) {
      this.canDeactivate();
    }
    this.selectedTab = e.index;
    const url = this.router.url
      .replace('#demands', '')
      .replace('#seating-hw', '')
      .replace('#attachments', '')
      .replace('#history', '')
      .replace('#reporting', '');

    if (this.selectedTab === 0) {
      this.location.go(url);
    } else if (this.selectedTab === 1) {
      this.location.go(url.concat('#demands'));
    } else if (this.selectedTab === 2) {
      this.location.go(url.concat('#seating-hw'));
    } else if (this.selectedTab === 3) {
      this.location.go(url.concat('#attachments'));
    } else if (this.selectedTab === 4) {
      this.location.go(url.concat('#history'));
    } else if (this.selectedTab === 5) {
      this.location.go(url.concat('#reporting'));
    } else {
      this.location.go(url);
    }
  }

  changeDemandsState() {
    this.activeDemands = !this.activeDemands;
    this.reloadDemands = new Date().getTime();
  }

  changeDemandsFte() {
    const data = {
      id: this.ids[0],
    };

    const dialogRef = this.dialog.open(FteDialogComponent, {
      width: '260px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadDemands = new Date().getTime();
      }
    });
  }

  handleProjectDetailsSuccess(projectDetails: any): void {
    this.projectDetails = projectDetails;

    this.checkStatus();
    // if (this.projectDetails.projectInfo.sourcingLocation !== null) {
    //   const val = this.projectDetails.projectInfo.sourcingLocation;

    //   for (let i = 0; i < val.length; i++) {
    //     this.projectDetails.projectInfo.sourcingLocation[i] = this.enums['primary-accountable-location'].filter(v => {
    //       if (v.stage === val[i]) {
    //         return v.text;
    //       }
    //     });
    //   }
    // }
  }

  handleProjectDetailsError(error: Response) {
    const errorText = error.statusText;
    const err: Error = new Error(errorText);
    this.projectDetails = null;
  }

  getProjectDetails(searchedId) {
    this.projectService.getProjectDetails(searchedId).subscribe(response => {
      this.handleProjectDetailsSuccess(response);
    });
    error => this.handleProjectDetailsError(error);
  }

  evaluateParams(params: Params): void {
    const searchedId = params['searchedId'];
    if (searchedId != null) {
      if (searchedId === '') {
        this.handleError(this.errorMessage);
      } else {
        this.searchedId = searchedId;

        this.getProjectDetails(searchedId);
      }
    } else {
      this.enumsService.clearAllEnums();
    }
  }

  demandsDataNotify(demandsData) {
    this.demandsData = demandsData;
  }

  multipleIdsNotify(ids) {
    this.ids = ids;

    this.disabledFte = true;

    if (ids.length === 1) {
      this.disabledCopyDemand = false;
    } else {
      this.disabledCopyDemand = true;
    }

    if (ids.length > 1) {
      this.disabledEditMore = false;
    } else {
      this.disabledEditMore = true;
    }
  }

  multipleNamesNotify(names: Array<string>) {
    this.names = names;

    if (names.length === 1) {
      if (names[0] === null) {
        this.disabledFte = true;
      }
    }
  }

  addDemand() {
    this.router.navigate([this.urlDetails + '/add-new-demand'], {
      queryParams: {
        projectId: this.projectDetails.projectId,
      },
    });
  }

  editMoreDemands() {
    this.router.navigate([this.urlDetails + '/add-new-demand'], {
      queryParams: {
        ids: this.ids,
        projectId: this.projectDetails.projectId,
      },
    });
  }

  copyDemand() {
    this.router.navigate([this.urlDetails + '/add-new-demand'], {
      queryParams: {
        toCopy: true,
        id: this.ids,
        projectId: this.projectDetails.projectId,
      },
    });
  }

  handleError(error: Response | string) {
    const errorText = error instanceof Response ? error.statusText : error;
    this.snackbar.error(errorText);
  }

  addAttachment() {
    const data = {
      eid: this.eid,
      projectId: this.projectDetails.projectId,
    };

    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: data,
      width: '450px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadAttachments = new Date().getTime();
      }
    });
  }

  columnSettings() {
    const dialogRef = this.dialog.open(ColumnDialogComponent, {
      width: '750px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadSettings = new Date().getTime();
      }
    });
  }

  anotherCardNotify(card) {
    this.anotherCard = card;
  }

  loadClient() {
    this.clientListService.getData().subscribe(data => {
      this.clientEnum = data;
    });
  }

  clientNotify(card) {
    this.loadClient();
  }

  canDeactivate(): boolean {
    let editingComponent;

    if (!this.cardComponents) {
      return true;
    }

    this.cardComponents.forEach(cardComponent => {
      if (cardComponent.editing) {
        editingComponent = cardComponent;
        cardComponent.tabChanged = true;
      } else {
        cardComponent.tabChanged = false;
      }
    });

    if (editingComponent) {
      return editingComponent.editWarningDialog();
    }

    return true;
  }

  prepareDownload(projectId: number) {
    this.projectService
      .demandsExport(projectId, 'xlsx')
      .pipe(distinctUntilChanged())
      .subscribe(result => {
        if (result) {
          const contenDispositionHeader = result.headers.get('Content-Disposition');
          const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
          const urlObject = window.URL.createObjectURL(result.body);
          const element = document.createElement('a');
          element.href = urlObject;
          element.download = filename;
          document.body.appendChild(element);
          element.click();
        }
      });
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  checkStatus() {
    if (this.projectDetails.statusId === 5 || this.projectDetails.statusId === 6) {
      //cancelled or closed
      this.statusCancelledOrClosed = true;
    } else {
      this.statusCancelledOrClosed = false;
    }
  }
}

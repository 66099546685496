import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CardItemComponent } from './../card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ActiveEmployeesAllCareerCounselorsService } from './../../../services/active-employees-all-career-counselors.service';

import { Observable } from 'rxjs';
import { startWith, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-card-item-autosearch',
  templateUrl: './card-item-autosearch.component.html',
  styleUrls: ['../card-item.component.scss', './card-item-autosearch.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class CardItemAutosearchComponent extends CardItemComponent implements OnInit {
  filteredValues: Observable<any[]>;
  values: any;

  constructor(
    enumsService: EnumsService,
    snackbar: SnackbarPanelComponent,
    private activeEmployeesAllCareerCounselorsService: ActiveEmployeesAllCareerCounselorsService
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
  }

  init() {
    if (this.id === 'careerCounsellorEid') {
      this.activeEmployeesAllCareerCounselorsService.deleteCache();
      this.activeEmployeesAllCareerCounselorsService
        .getData()
        .pipe(distinctUntilChanged())
        .subscribe(data => {
          this.values = data;
        });
    }

    this.filteredValues = this.control.controls[this.id]?.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map(value => {
        if (value != null) {
          return value.length >= 3 ? this.filterStates(value).slice(0, 50) : null;
        }
      })
    );
  }

  filterStates(searchedValue: string) {
    return this.values.filter(
      value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1
    );
  }
}

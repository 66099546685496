import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { UserRolesService } from 'app/services/user-roles/user-roles.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ProjectService } from 'app/services/project.service';
import { User } from 'app/services/user-roles/user';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './list-column-settings.component.html',
  styleUrls: ['./list-column-settings.component.scss', '../../shared/dialog/dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class ListColumnSettingsComponent implements OnInit {
  user: User;
  settings = {
    id: null,
    projectId: null,
    projectName: '',
    client: '',
    ddcIndustryGroup: null,
    avanade: null,
    dcResponsibleName: '',
    status: null,
    itemsPerPage: null,
    newBusinessMeeting: null,
  };
  data = {};
  primaryKey: number;
  project: boolean;

  displayedColumns: string[] = ['field', 'criteria'];
  dataSource = [
    { field: 'ID', type: 'text', criteria: 'projectId' },
    {
      field: 'Project Name',
      type: 'text',
      criteria: 'projectName',
    },
    { field: 'Client', type: 'text', criteria: 'client' },
    {
      field: 'DATC Industry',
      type: 'select',
      criteria: 'ddcIndustryGroup',
      data: 'clientGroupEnum',
      value: 'id',
    },
    {
      field: 'Avanade',
      type: 'select',
      criteria: 'avanade',
      data: 'avanadeEnum',
      value: 'value',
    },
    {
      field: 'ATC Responsible Name',
      type: 'text',
      criteria: 'dcResponsibleName',
    },
    {
      field: 'Status',
      type: 'select',
      criteria: 'status',
      data: 'statusEnum',
      value: 'id',
    },
    {
      field: 'Items per Page',
      type: 'select',
      criteria: 'itemsPerPage',
      data: 'itemsPerPageEnum',
      value: 'value',
    },
    {
      field: 'New Business Meeting',
      type: 'select',
      criteria: 'newBusinessMeeting',
      data: 'avanadeEnum',
      value: 'value',
    },
  ];

  constructor(
    private userRolesService: UserRolesService,
    private projectService: ProjectService,
    private snackbar: SnackbarPanelComponent,
    public dialogRef: MatDialogRef<ListColumnSettingsComponent>,
    private enumsService: EnumsService,
    @Inject(MAT_DIALOG_DATA) public dataMessage: any
  ) {}

  ngOnInit() {
    this.project = this.dataMessage.projectFlag;
    this.primaryKey = this.dataMessage.primaryKey;

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);
        this.projectService.getDefaultFilters(this.user.eid, this.project).subscribe(
          result => this.fillFilters(result),
          error => this.handleError(error)
        );
      });

    console.log("this.data['avanadeEnum']: ", this.data['avanadeEnum']);
  }

  protected populateDropdowns(enums) {
    const avanade: {
      id: number;
      stage: number;
      text: string;
      value: boolean;
    }[] = [
      { id: 1, stage: 1, text: 'Yes', value: true },
      { id: 2, stage: 2, text: 'No', value: false },
    ];

    const itemsPerPage: {
      id: number;
      stage: number;
      text: string;
      value: number;
    }[] = [
      { id: 1, stage: 1, text: '15', value: 15 },
      { id: 2, stage: 2, text: '30', value: 30 },
      { id: 3, stage: 3, text: '50', value: 50 },
    ];

    this.data['clientGroupEnum'] = enums['client-group'];
    this.data['avanadeEnum'] = avanade;
    this.data['statusEnum'] = enums['status-enum'];
    this.data['itemsPerPageEnum'] = itemsPerPage;
    this.data['newBusinessMeeting'] = avanade;
  }

  protected fillFilters(result) {
    if (result) {
      this.settings.projectId = result.projectId;
      this.settings.projectName = result.projectName;
      this.settings.client = result.client;
      this.settings.ddcIndustryGroup = result.ddcIndustryGroup;
      this.settings.avanade = result.avanade;
      this.settings.dcResponsibleName = result.dcResponsibleName;
      this.settings.itemsPerPage = result.itemsPerPage;
      this.settings.status = result.status;
      this.settings.newBusinessMeeting = result.newBusinessMeeting;
    }
  }

  storeDefaultFilters(create: boolean) {
    if (create) {
      this.settings.id = this.primaryKey;
      this.projectService.setDefaultFilters(this.user.eid, this.project, this.settings).subscribe(
        result => (this.settings.id = result),
        error => this.handleError(error)
      );

      this.dialogRef.close(this.settings);
    } else {
      this.dialogRef.close(false);
    }
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  handleError(error) {
    this.snackbar.error('Problem with columns settings');
  }
}

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'input',
    id: 'demandFte',
    label: 'FTE',
    validation: ['valRequired', 'valFlInterval'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'startDateDemand',
    label: 'Start Date',
    validation: ['valDate', 'valRequired', 'valFromToDates', 'valOrigStartFromDates'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'rollOffDateDemand',
    label: 'Roll-off Date',
    validation: ['valDate', 'valRequired', 'valToFromDates'],
    values: ['', []],
  },
  {
    cardType: 'slider',
    id: 'alignSeats',
    label: 'Align Seats',
    validation: [],
    values: [true, []],
    disabled: true,
  },
];

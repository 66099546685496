import { Component, OnInit, Input } from '@angular/core';
import { fieldsSettings } from '../../../types/report';
import { ActivatedRoute } from '@angular/router';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-report-common-fields',
  templateUrl: './report-common-fields.component.html',
  styleUrls: ['./report-common-fields.component.scss'],
})
export class ReportCommonFieldsComponent implements OnInit {
  fieldsSettings = fieldsSettings;

  data = {};

  constructor(
    protected route: ActivatedRoute,
    protected reportService: ReportService,
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {}
}

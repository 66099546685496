import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { projectsEnumMapping } from './../../../types/enum.mapping';
import { ProjectCardItemComponent } from './../project-card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ActivatedRoute } from '@angular/router';
import { ServiceGroupsTooltipText } from './../../../../constants';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-project-card-item-enum-multiple',
  templateUrl: './project-card-item-enum-multiple.component.html',
  styleUrls: [
    '../project-card-item.component.scss',
    './project-card-item-enum-multiple.component.scss',
    '../../../shared/card-item-enum-multiple.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardItemEnumMultipleComponent
  extends ProjectCardItemComponent
  implements OnInit, OnChanges
{
  slEnumClosed: boolean;

  tooltipText: string | null = null;

  constructor(
    enumsService: EnumsService,
    snackbar: SnackbarPanelComponent,
    private route: ActivatedRoute
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();

    this.route.params.subscribe(params => {
      this.init();
    });

    this.tooltipText = this.label === 'Service Groups' ? ServiceGroupsTooltipText : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.editing &&
      changes.editing.currentValue === false &&
      changes.editing.firstChange === false
    ) {
      this.init();
    }
  }

  init() {
    this.enumsService.getData().subscribe(enums => {
      this.enums = enums;
      this.isEnum = true;

      this.enums['platform'] = enums['platform'].filter(val => val.deleted !== true);
      this.enums['sourcingLocation'] = enums['employee-country-enum'];

      if (this.disabled) {
        this.control.get(this.id).disable({ onlySelf: true, emitEvent: false });
      }

      // let searchId = ""
      // if (this.id === "sourcingLocation") {
      //   searchId ="sourcingLocationId"
      // } else {
      //   searchId = this.id
      // }
      const enumName = projectsEnumMapping.get(this.id);
      this.enumeration = this.enums[enumName];

      // console.log(searchId)

      if (this.enumeration == null) {
        console.error(`missing enum ${this.id}`);
      }
    });
  }

  isClosed(event) {
    this.slEnumClosed = true;
  }

  changeSourcingLocation(value) {
    // this.control.get(this.id).setValue([]);
    this.control.get(this.id).setValue(value);
    this.model = value;
  }
}

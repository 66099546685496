import { Injectable } from '@angular/core';
import { distinctUntilChanged, retry, share, tap } from 'rxjs/operators';
import { REST } from '../../../rest.endpoints';
import { BaseService } from '../base.service';
import { EnumList } from '../../types/enum-types/common/allEnum';
import { timer } from 'rxjs';

@Injectable()
export class EnumsService extends BaseService {
  public setEnumText(obj: Object, enumName: string, enumMapping: Map<string, string>): void {
    this.getData().subscribe(enums => {
      const enumId = obj[enumName];

      if (enumId == null) {
        obj = null;
        return;
      }

      const enumerationName = enumMapping.get(enumName);
      const enumValues = enums[enumerationName];
      let data = '';

      enumValues.forEach(value => {
        if (enumId instanceof Array) {
          enumId.forEach(id => {
            if (value.id === id) {
              data += value.text + ', ';
            }
          });
        } else {
          if (value.id === enumId) {
            data = value.text;
          }
        }
      });

      if (data) {
        obj[enumName] = data;
      }
    });
  }

  public setEnumValue(obj: Object, enumName: string, enumMapping: Map<string, string>): void {
    this.getData().subscribe(enums => {
      const enumId = obj[enumName];

      if (obj != null) {
        if (enumId == null) {
          obj[enumName] = null;
          return;
        }
        const enumerationName = enumMapping.get(enumName);
        const enumValues = enums[enumerationName];
        enumValues.some(value => {
          if (value.id === enumId) {
            obj[enumName] = value;
            return true;
          }
          return false;
        });
      }
    });
  }

  protected requestData(): void {
    this.request = this.http.get<EnumList>(REST.enums).pipe(
      distinctUntilChanged(),
      tap(data => {
        this.data = data;
      }),
      retry({
        delay: error => {
          this.handleError(error);
          // Return an observable that emits after the specified delay
          return timer(this.reloadDelay);
        },
      }),
      share()
    );
  }

  clearAllEnums(): void {
    this.http
      .get(REST.clearEnums, { responseType: 'text' })
      .pipe(distinctUntilChanged())
      .subscribe(msg => this.handleClearAllCache(msg));
  }

  handleClearAllCache(msg): void {
    this.deleteCache();
  }
}

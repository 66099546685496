import { Injectable } from '@angular/core';

import { REST } from '../../../rest.endpoints';
import { BaseService } from '../base.service';
import { share, catchError, tap, distinctUntilChanged } from 'rxjs/operators';
import { UserProfile } from '../../types/user-profile';

@Injectable()
export class UserRolesService extends BaseService {
  protected requestData(): void {
    this.request = this.http.get<UserProfile>(REST.authentification).pipe(
      distinctUntilChanged(),
      tap((data: UserProfile) => (this.data = data)),
      catchError(this.handleError),
      share()
    );
  }
}

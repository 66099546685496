import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'empIdType' })
export class EmpIdType implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'number') {
      return '-';
    } else {
      return value;
    }
  }
}

import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, distinctUntilChanged } from 'rxjs/operators';

import { REST } from '../../rest.endpoints';
import { HiringRequestSearch } from 'app/types/hiring-request-search';
import { ProjectNameDto } from '../types/ProjectNameDto';
import { openDemands } from '../types/openDemands';
import { ListSearchParams } from '../types/list-search-params';

@Injectable()
export class ProjectService {
  private isHardlocked = new Subject<boolean>();
  private _opportunityListParams: ListSearchParams;

  constructor(private http: HttpClient) {}
  setIsHardlocked(value: boolean) {
    this.isHardlocked.next(value);
  }

  set opportunityListParams(params: ListSearchParams) {
    this._opportunityListParams = params;
  }

  get opportunityListParams() {
    return this._opportunityListParams;
  }

  getIsHardlocked(): Observable<boolean> {
    return this.isHardlocked.asObservable();
  }

  shiftSeatingDateTo(demandId: number, employeeDetailId: number, update: {}): Observable<any> {
    return this.http
      .put(REST.shiftSeatingDateToEdit(demandId, employeeDetailId), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  shiftSeatingsDateTo(demandIds: Array<number>, update: {}): Observable<any> {
    return this.http
      .put(REST.shiftSeatingsDateToEdit(demandIds), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getSeatingDateToShift(
    demandId: number,
    employeeDetailId: number,
    newRollOff: string
  ): Observable<any> {
    return this.http
      .get(REST.seatingDateToShift(demandId, employeeDetailId, newRollOff))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getSeatingDateToShiftMultiple(demandIds: Array<number>, newRollOff: string): Observable<any> {
    return this.http
      .get(REST.seatingDateToShiftMulitple(demandIds, newRollOff))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getHardlockEmail(supplyId: number): Observable<any> {
    return this.http
      .get(REST.hardlockEmail(supplyId), { responseType: 'text' as 'json' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  cancelHardlock(demandId: number, id: number): Observable<any> {
    return this.http
      .delete(REST.cancelHardlockDelete(demandId, id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  requestHardlock(demandId: number, supplyId: number, update: {}): Observable<any> {
    return this.http
      .post(REST.requestHardlockInsert(demandId, supplyId), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  moveFromSoftlocked(supplyId: number): Observable<any> {
    return this.http
      .delete(REST.moveFromSoftlockedDelete(supplyId))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  moveToSoftlocked(demandId: number, supplyId: number): Observable<any> {
    return this.http
      .post(REST.moveToSoftlockedAdd(demandId, supplyId), {})
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getAlignSeats(id: number): Observable<any> {
    return this.http
      .get(REST.projectDemandAlignSeats(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getStartDate(id: number): Observable<any> {
    return this.http
      .get(REST.projectDemandStartDate(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getAwsSeating(params: Object): Observable<any> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.getAwsSeating, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getAwsSeatingHistory(params: Object): Observable<any> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.getAwsSeatingHistory, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  updateAwsSeating(requestBody: any): Observable<any> {
    return this.http
      .put(REST.updateAwsSeating, requestBody)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  updateAwsSeatingHistory(requestBody: any): Observable<any> {
    return this.http
      .put(REST.updateAwsSeatingHistory, requestBody)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getProjectList(params: Object): Observable<any> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.projectList, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getActiveProjects(): Observable<any> {
    return this.http
      .get(REST.activeProjects)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getUserDcResp(): Observable<any> {
    return this.http
      .get(REST.projectIsUserDcResponsible)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editProjectDetails(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectDetailsEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addClient(client: any): Observable<string> {
    return this.http
      .post(REST.clientInsert, client, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  editClient(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.clientEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addProject(projectDetails: any): Observable<any> {
    return this.http.post(REST.projectInsert, projectDetails).pipe(catchError(this.handleError));
  }

  approveSeatsHardware(id: number, update): Observable<any> {
    return this.http
      .post(REST.approveSeatsHardwareInsert(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  approveEmployeeSeatsHardware(update): Observable<any> {
    return this.http
      .post(REST.approveEmployeeSeatsHardwareInsert, update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addDemand(id: number, demandDetails: any): Observable<any> {
    return this.http
      .post(REST.projectDemandInsert(id), demandDetails)
      .pipe(catchError(this.handleError));
  }

  editDemands(ids, update): Observable<any> {
    return this.http
      .put(REST.projectDemandsEdit, update, {
        params: {
          ids: ids,
        },
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  copyDemand(id, update): Observable<any> {
    return this.http
      .put(REST.projectDemandsCopy, update, {
        params: {
          id: id,
        },
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  deleteDemand(id: number): Observable<any> {
    return this.http
      .delete(REST.projectDemandDelete(id), { responseType: 'text' as 'json' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editColumnsSettings(eid: string, update: {}): Observable<any> {
    return this.http
      .put(REST.projectColumnSettingsEdit(eid), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getColumnsSettings(eid: string): Observable<any> {
    return this.http
      .get(REST.projectColumnSettings(eid))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getProjectDetails(id: string): Observable<any> {
    return this.http
      .get(REST.projectDetails(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getHistory(id: number, params: Object): Observable<any> {
    const httpParams = new HttpParams()
      .append('pageNumber', params['pageNumber'])
      .append('pageSize', params['pageSize'])
      .append('type', params['type']);

    return this.http
      .get(REST.projectHistory(id), { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getReporting(id: number): Observable<any> {
    return this.http
      .get(REST.projectReporting(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getMyWizardReporting(id: number): Observable<any> {
    return this.http
      .get(REST.myWizardReporting(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editReporting(id, update): Observable<any> {
    return this.http
      .put(REST.projectReportingEdit, update, {
        params: {
          id: id,
        },
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getSubcoEnding(id: number): Observable<any> {
    return this.http
      .get(REST.isSubcoEnding(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getAttachments(id: number): Observable<any> {
    return this.http
      .get(REST.projectAttachments(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addAttachment(file: any, attachment: any): Observable<ArrayBuffer> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('attachment', JSON.stringify(attachment));

    const options = { body: formData };

    return this.http
      .post<ArrayBuffer>(REST.projectAttachmentInsert, formData, { observe: 'body' })
      .pipe(catchError(this.handleError));
  }

  demandFte(update: {}): Observable<any> {
    return this.http
      .post(REST.projectDemandFteInsert, update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editAttachment(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectAttachmentEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  deleteAttachment(id: number): Observable<any> {
    return this.http
      .delete(REST.projectAttachmentDelete(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  // Try blob object as response later when debugging
  downloadAttachment(id: number): Observable<any> {
    return this.http
      .get(REST.projectAttachmentDownload(id), { responseType: 'blob', observe: 'response' })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  demandsExport(projectId: number, type: string): Observable<any> {
    return this.http
      .get(REST.projectDemandsExport(projectId, type), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  projectListExport(params: ListSearchParams, type: string): Observable<any> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.projectListExport(type), {
        params: httpParams,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemandSoftlocks(id, filter): Observable<any> {
    return this.http
      .get(REST.projectDemandSoftlocks(id), {
        params: filter,
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemandSoftlockCount(id): Observable<any> {
    return this.http
      .get(REST.projectDemandSoftlockCount(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemandSoftlocked(id): Observable<any> {
    return this.http
      .get(REST.projectDemandSoftlocked(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getHardlockRequests(demandId): Observable<any> {
    return this.http
      .get(REST.projectDemandHardlockRequest(demandId))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getHardlockRequestsHistory(demandId): Observable<any> {
    return this.http
      .get(REST.projectDemandHardlockRequestsHistory(demandId))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemandIntersection(ids: Array<string>): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { ids: ids } });
    return this.http
      .get(REST.projectDemandIntersection, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemandDetails(id: number): Observable<any> {
    return this.http
      .get(REST.projectDemand(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDemands(id: number, active: boolean): Observable<any> {
    return this.http
      .get(REST.projectDemands(id), { params: { active: active ? 'true' : 'false' } })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getHardware(id: number): Observable<any> {
    return this.http
      .get(REST.projectHardware(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getReviewHardware(id: number, notebook: boolean, month, year): Observable<any> {
    return this.http
      .get(REST.projectReviewHardware(id), {
        params: {
          notebook: notebook ? 'true' : 'false',
          month: month,
          year: year,
        },
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getSeatingEmployees(id: number): Observable<any> {
    return this.http
      .get(REST.seatingSupplies(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getSeatings(id: number): Observable<any> {
    return this.http
      .get(REST.projectSeatings(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getFlexibleSeatings(id: number): Observable<any> {
    return this.http
      .get(REST.projectFlexibleSeatings(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  saveSeatsRequested(id: number, projectSeatsDemand: boolean): Observable<any> {
    return this.http
      .get(REST.projectFlexibleSeatingsSaveSeatsRequested(id, projectSeatsDemand))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }
  editUniversalWbs(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectUniversalWbsEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getUniversalWbs(id: number): Observable<any> {
    return this.http
      .get(REST.projectUniversalWbs(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getReviewSeatingsHardware(id: number, month: string, year: string): Observable<any> {
    const httpParams = new HttpParams().append('month', month).append('year', year);

    return this.http
      .get(REST.projectReviewSeatingsHardware(id), { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getReviewSeatingsHardwareAll(eid: number, month: string, year: string): Observable<any> {
    const httpParams = new HttpParams().append('month', month).append('year', year);

    return this.http
      .get(REST.projectReviewSeatingsHardwareAll(eid), { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getReviewSeatingsHardwareActive(eid: number, month: string, year: string): Observable<any> {
    const httpParams = new HttpParams().append('month', month).append('year', year);

    return this.http
      .get(REST.projectReviewSeatingsHardwareActive(eid), { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getApprovalDate(id: number): Observable<any> {
    return this.http
      .get(REST.projectReviewSeatingsHardwareDate(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addSeating(seating: any): Observable<any> {
    return this.http.post(REST.projectSeatingInsert, seating).pipe(catchError(this.handleError));
  }

  addFlexibleSeating(seating: any): Observable<any> {
    return this.http
      .post(REST.projectFlexibleSeatingInsert, seating)
      .pipe(catchError(this.handleError));
  }

  editSeating(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectSeatingEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editFlexibleSeating(update: {}): Observable<any> {
    return this.http
      .put(REST.projectFlexibleSeatingEdit, update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  deleteFlexibleSeating(seatingId: number): Observable<any> {
    return this.http
      .get(REST.projectFlexibleSeatingDelete(seatingId))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  editHardware(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectHardwareEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  removeEmployeeFromSeat(id: number, update: {}): Observable<any> {
    return this.http
      .put(REST.projectSeatingRemoveEmployeeEdit(id), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return observableThrowError(error);
  }

  getAllProjects() {
    return this.http
      .get<Array<ProjectNameDto>>(REST.confirmResourcesAllProjectsAndOpportunities)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getOpenedDemands(value: any) {
    return this.http
      .get(REST.confirmResourcesOpenedDemands(value))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  saveChangedSupplies(projectId: String, update: any) {
    return this.http
      .put(REST.confirmResourcesChangedSupplies(projectId), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  deleteHardlock(supplyId: any, update: {}): Observable<any> {
    return this.http
      .put(REST.confirmResourcesDeleteHardlock(supplyId), update)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  setDefaultFilters(eid: string, projOpp: Boolean, settings: {}): Observable<any> {
    return this.http
      .put(REST.setDefaultFilters(eid, projOpp), settings)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getDefaultFilters(eid: string, projOpp: Boolean): Observable<any> {
    return this.http
      .get(REST.getDefaultFilters(eid, projOpp))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  projectNamesForDcResp(eid: string): Observable<any> {
    return this.http
      .get(REST.projectNamesForDcResp(eid))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  searchOpenDemands(searchParams: HiringRequestSearch) {
    let httpParams = new HttpParams();
    Object.entries(searchParams).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.searchOpenDemands, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  getOpenDemand(id: number): Observable<any> {
    return this.http
      .get<openDemands>(REST.getOpenDemand(id))
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  updateOpenDemand(details: openDemands): Observable<any> {
    return this.http
      .put(REST.updateOpenDemand, details)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addOpenDemand(request: any): Observable<any> {
    return this.http
      .post(REST.addOpenDemand, request)
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }
}

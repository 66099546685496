import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactService } from '../../services/contact.service';
import { FormBuilder } from '@angular/forms';
import { fieldsSettings } from '../../types/new-client';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../services/project.service';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { CommonComponent } from '../../shared/common/common.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-client-dialog',
  templateUrl: './new-client-dialog.component.html',
  styleUrls: ['./new-client-dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class NewClientDialogComponent extends CommonComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  clientId: number;

  data = {
    contactEId: [],
  };

  filteredValues = {
    contactEId: new Observable<any[]>(),
  };

  constructor(
    public dialogRef: MatDialogRef<NewClientDialogComponent>,
    private contactService: ContactService,
    protected snackbar: SnackbarPanelComponent,
    protected fb: FormBuilder,
    private projectService: ProjectService,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.contactService.getData().subscribe(data => (this.data.contactEId = data));

    this.fieldsSettings.forEach(setting => {
      if (setting.cardType === 'autosearch') {
        this.filteredValues[setting.id] = this.form.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value => {
            if (value !== null) {
              // searched string must be at least 3 characters long, also returning only first 50 results - both bacause of performance issues
              return value.length >= 3 ? this.filterStates(value, setting.id).slice(0, 50) : null;
            }
          })
        );
      }
    });
  }

  create(create: boolean) {
    if (create) {
      const client = {};

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();
        client[controlerName] = this.form.get(controlerName).value;
      });

      if (this.form.valid) {
        this.projectService.addClient(client).subscribe(
          result => this.handleClient(result, client),
          error => this.handleError(error)
        );
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  private handleClient(response, client) {
    client['clientId'] = Number(response);
    this.dialogRef.close({
      client: client,
    });
    this.snackbar.success('Client has been saved');
  }

  protected handleError(error: Response | string) {
    this.snackbar.error('Client already exists');
  }
}

import { EmployeeCard } from './card';

export const fieldsSettingsHistory: Array<EmployeeCard> = [
  { cardType: 'action', id: 'action', label: '', type: '', validation: [] },
  { cardType: '', id: 'id', label: 'Id', type: '', validation: [] },
  { cardType: '', id: 'seatStatus', label: 'Status', type: '', validation: [] },
  {
    cardType: 'editable',
    id: 'buildingFloor',
    label: 'Building/Floor',
    type: 'enum',
    data: 'buildingEnum',
    validation: [],
  },
  { cardType: 'editable', id: 'seat', label: 'Seat', type: 'input', validation: ['valRequired'] },
  {
    cardType: 'editable',
    id: 'seatFrom',
    label: 'Seat From',
    type: 'date',
    validation: ['valRequired', 'valDate'],
  },
  {
    cardType: 'editable',
    id: 'seatTo',
    label: 'Seat To',
    type: 'date',
    validation: ['valRequired', 'valDate'],
  },
  { cardType: '', id: 'pdlComment', label: 'PDL Comment', type: '', validation: [] },
  { cardType: 'editable', id: 'awsComment', label: 'AWS Comment', type: 'input', validation: [] },
  { cardType: '', id: 'projectName', label: 'Project Name', type: '', validation: [] },
  { cardType: '', id: 'requestedBy', label: 'Requested By', type: '', validation: [] },
  { cardType: '', id: 'lastUpdate', label: 'Last Update', type: 'date', validation: [] },
];

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'slider',
    id: 'useName',
    label: 'Search Employee Name',
    validation: [],
    values: ['', []],
  },
  {
    cardType: 'autosearch',
    id: 'employeeId',
    label: 'Employee Name',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'select',
    data: 'platformEnum',
    id: 'platformId',
    label: 'Platform',
    validation: [],
    values: ['', []],
  },
  {
    cardType: 'select',
    data: 'domainEnum',
    id: 'domainId',
    label: 'Domain',
    validation: [],
    values: ['', []],
  },
  {
    cardType: 'select',
    data: 'careerLevelEnum',
    id: 'careerLevelId',
    label: 'Management Level',
    validation: [],
    values: ['', []],
  },
  {
    cardType: 'select',
    data: 'placesEnum',
    id: 'sourcingLocationId',
    label: 'Locations',
    validation: [],
    values: ['', []],
  },
];

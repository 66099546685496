import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReviewApproveDialogNewApproachComponent } from 'app/project/project-seating-hardware/review-approve-dialog-new-approach/review-approve-dialog-new-approach.component';
import { ProjectService } from 'app/services/project.service';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-review-charges-new-approach',
  templateUrl: './review-charges-new-approach.component.html',
  styleUrls: ['./review-charges-new-approach.component.scss'],
})
export class ReviewChargesNewApproachComponent implements OnInit, AfterViewInit {
  @ViewChild('table1', { read: MatSort, static: false }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: false }) sort2: MatSort;
  @ViewChild('table3', { read: MatSort, static: false }) sort3: MatSort;
  @ViewChild('table4', { read: MatSort, static: false }) sort4: MatSort;
  @ViewChild('table5', { read: MatSort, static: false }) sort5: MatSort;
  @ViewChild('table6', { read: MatSort, static: false }) sort6: MatSort;

  @Input() dialogData: any;
  @Input() data: any;
  @Input() projectName: any;

  weekCnt = 0;

  seatFixedDisplayedColumns = ['info', 'seatDays', 'wbs'];
  seatFixedDataSource = new MatTableDataSource();

  seatFlexibleDisplayedColumns = ['info', 'days', 'seatCnt', 'seatDays', 'wbs'];
  seatFlexibleDataSource = new MatTableDataSource();

  seatByWeekDisplayedColumns = ['type', 'week1', 'week2', 'week3', 'week4', 'week5', 'week6'];
  seatByWeekDataSource = new MatTableDataSource();
  seatByWeekData: any[] = [];

  hardwareDesktopsDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareDesktopsDataSource = new MatTableDataSource();

  hardwareNotebooksDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareNotebooksDataSource = new MatTableDataSource();

  // @Input() form: FormGroup;

  seatsHardwareLoaded;
  projectId: number;
  eid: string;
  wbs: string;
  seatsCost = 0;
  hardwareNotebooksCost = 0;
  hardwareDesktopsCost = 0;
  totalCost = 0;
  seatsWbs: Array<Object>;
  hardwareNotebooksWbs: Array<Object>;
  hardwareDesktopsWbs: Array<Object>;
  month: any;
  year: any;
  validity = false;
  loadedData: any;
  approved: Boolean;
  isDisabled: boolean;
  allWbs: String[] = [];
  allWeeklyCost: Number[] = [];
  hasEmptyWbs: boolean = false;
  hasEmptyWbsHw: boolean = false;
  hasEmptyWbsDesc: boolean = false;
  choosed: boolean;
  wbsNotSet = false;

  constructor() {}

  ngOnInit() {
    this.projectId = this.data.projectId;

    // this.eid = this.dialogData.eid;
    // this.universalWbs = this.dialogData.universalWbs;
    // this.universalWbsValue = this.dialogData.universalWbsValue

    this.handleSeatsHardware(this.data);
    this.choosed = !this.wbsNotSet || this.validity;
  }

  ngAfterViewInit() {
    this.seatFixedDataSource.sort = this.sort1;
    this.seatFlexibleDataSource.sort = this.sort4;
    this.seatByWeekDataSource.sort = this.sort6;
    this.hardwareNotebooksDataSource.sort = this.sort2;
    this.hardwareDesktopsDataSource.sort = this.sort3;
  }

  private handleSeatsHardware(data: any): void {
    this.loadedData = data;
    if (data.isApproved) {
      this.approved = data.isApproved;
      this.totalCost = data.totalSum;
      this.validity = data.validity;
      this.seatsHardwareLoaded = true;
      return;
    }
    this.approved = false;

    this.seatsHardwareLoaded = true;
    this.seatFixedDataSource.data = data.fixedSeatCharges; //data.seatingCharges;
    this.seatFlexibleDataSource.data = data.flexibleSeatCharges;
    this.seatByWeekDisplayedColumns = [
      'type',
      'week1',
      'week2',
      'week3',
      'week4',
      'week5',
      'week6',
    ];

    this.seatsCost = 0;
    this.seatByWeekData = [];
    this.allWeeklyCost = [];
    this.hasEmptyWbs = false;
    let tmpWeekCharge = [
      {
        type: 'Flexible Seats',
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
        week6: 0,
        totalCost: 0,
      },
      {
        type: 'Fixed Seats',
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
        week6: 0,
        totalCost: 0,
      },
    ];

    if (data.weeklySeatCharges !== null && Object.keys(data.weeklySeatCharges).length > 0)
      //length of object
      data.weeklySeatCharges.forEach(data => {
        if (
          (data[0].wbs === '' || data[0].wbs === null) &&
          (data[1].wbs === '' || data[1].wbs === null)
        ) {
          this.hasEmptyWbs = true;
          this.wbsNotSet = true;
        }

        this.allWbs.push(data[0].wbs);

        this.allWeeklyCost.push(data[0].totalCost + data[1].totalCost);
        let seatByWeekDataSource = new MatTableDataSource();
        // data[0].week1 = data[0].week1 != undefined || null ? (data[0].week1 + 0).toFixed(2) : null;
        // data[0].week2 = data[0].week2 != undefined || null ? (data[0].week2 + 0).toFixed(2) : null;
        // data[0].week3 = data[0].week3 != undefined || null ? (data[0].week3 + 0).toFixed(2) : null;
        // data[0].week4 = data[0].week4 != undefined || null ? (data[0].week4 + 0).toFixed(2) : null;
        // data[0].week5 = data[0].week5 != undefined || null ? (data[0].week5 + 0).toFixed(2) : null;
        // data[0].week6 = data[0].week6 != undefined || null ? (data[0].week6 + 0).toFixed(2) : null;
        // data[1].week1 = data[1].week1 != undefined || null ? (data[1].week1 + 0).toFixed(2) : null;
        // data[1].week2 = data[1].week2 != undefined || null ? (data[1].week2 + 0).toFixed(2) : null;
        // data[1].week3 = data[1].week3 != undefined || null ? (data[1].week3 + 0).toFixed(2) : null;
        // data[1].week4 = data[1].week4 != undefined || null ? (data[1].week4 + 0).toFixed(2) : null;
        // data[1].week5 = data[1].week5 != undefined || null ? (data[1].week5 + 0).toFixed(2) : null;
        // data[1].week6 = data[1].week6 != undefined || null ? (data[1].week6 + 0).toFixed(2) : null;
        seatByWeekDataSource.data = data;
        this.seatByWeekData.push(seatByWeekDataSource);
      });
    // this.seatByWeekDataSource.data = data.weeklySeatCharges;
    this.hardwareNotebooksDataSource.data = data.notebooksCharges.concat(data.ignoredNotebooks);
    this.hardwareDesktopsDataSource.data = data.desktopsCharges.concat(data.ignoredDesktops);

    //get number of weeks to show
    if (
      data.weeklySeatCharges !== undefined &&
      data.weeklySeatCharges !== null &&
      data.weeklySeatCharges.length > 0
    ) {
      const weekFlex =
        data.weeklySeatCharges[0][0].week6 !== null
          ? 6
          : data.weeklySeatCharges[0][0].week5 !== null
            ? 5
            : 4;
      const weekFix =
        data.weeklySeatCharges[0][1].week6 !== null
          ? 6
          : data.weeklySeatCharges[0][1].week5 !== null
            ? 5
            : 4;
      this.weekCnt = weekFlex > weekFix ? weekFlex : weekFix;
    }

    this.seatByWeekDisplayedColumns = this.seatByWeekDisplayedColumns.slice(0, this.weekCnt + 1);
    this.seatsWbs = [];

    this.hardwareDesktopsWbs = [];

    if (data.groupedDesktopsSums !== null && Object.keys(data.groupedDesktopsSums).length > 0)
      //length of object
      Object.keys(data.groupedDesktopsSums).forEach(key => {
        this.hardwareDesktopsWbs.push({
          name: key,
          sum: data.groupedDesktopsSums[key],
          wbsnotset: key === 'WBS not set' ? true : false,
        });
        if (key === 'WBS not set') {
          this.wbsNotSet = true;
        } else {
          this.allWbs.push(key);
        }
        if (key === 'WBS not set') this.hasEmptyWbsDesc = true;
      });

    this.hardwareNotebooksWbs = [];

    if (data.groupedNotebooksSums !== null && Object.keys(data.groupedNotebooksSums).length > 0)
      //length of object
      Object.keys(data.groupedNotebooksSums).forEach(key => {
        this.hardwareNotebooksWbs.push({
          name: key,
          sum: data.groupedNotebooksSums[key],
          wbsnotset: key === 'WBS not set' ? true : false,
        });
        if (key === 'WBS not set') {
          this.wbsNotSet = true;
        } else {
          this.allWbs.push(key);
        }
        if (key === 'WBS not set') this.hasEmptyWbsHw = true;
      });

    this.seatsCost = data.seatingsSum;
    this.hardwareNotebooksCost = data.notebooksSum;
    this.hardwareDesktopsCost = data.desktopsSum;
    this.totalCost = data.totalSum;
    this.validity = data.validity;
    var canBeValid = true;
    if (this.hasEmptyWbs) {
      if (this.seatsCost === 0 && canBeValid) this.validity = true;
      else {
        this.validity = false;
        canBeValid = false;
      }
    }
    if (this.hasEmptyWbsHw) {
      if (this.hardwareNotebooksCost === 0 && canBeValid) this.validity = true;
      else {
        var stillValid = true;
        this.hardwareNotebooksWbs.forEach(hw => {
          if (hw['wbsnotset'] && hw['sum'] !== 0) stillValid = false;
        });
        this.validity = stillValid;
        canBeValid = stillValid;
      }
    }
    if (this.hasEmptyWbsDesc) {
      if (this.hardwareDesktopsCost === 0 && canBeValid) this.validity = true;
      else {
        var stillValid = true;
        this.hardwareDesktopsWbs.forEach(hw => {
          if (hw['wbsnotset'] && hw['sum'] !== 0) stillValid = false;
        });
        this.validity = stillValid;
        canBeValid = stillValid;
      }
    }
    if (this.allWbs.length === 0) this.validity = false;
    if (this.totalCost === 0) this.validity = true;
  }
}

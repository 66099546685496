import { CommonModule } from '../shared/common.module';
import { NgModule } from '@angular/core';

import { ProjectService } from '../services/project.service';
import { UserRolesService } from '../services/user-roles/user-roles.service';
import { CanDeactivateGuard } from '../services/can-deactivate-guard.service';
import { ContactService } from '../services/contact.service';
import { ClientListService } from '../services/client-list.service';
import { DcResponsibleService } from '../services/dcresponsible.service';

import { ProjectCardComponent } from './cards/project-card/project-card.component';
import { LeadCardComponent } from './cards/lead-card/lead-card.component';
import { CommonCardComponent } from './cards/common-card/common-card.component';

import { ProjectCardItemComponent } from './project-card-item/project-card-item.component';
import { ProjectCardItemEnumMultipleComponent } from './project-card-item/project-card-item-enum-multiple/project-card-item-enum-multiple.component';
import { ProjectCardItemEnumComponent } from './project-card-item/project-card-item-enum/project-card-item-enum.component';
import { ProjectCardItemComboboxComponent } from './project-card-item/project-card-item-combobox/project-card-item-combobox.component';
import { ProjectCardItemBoolComponent } from './project-card-item/project-card-item-bool/project-card-item-bool.component';
import { ProjectCardItemAutosearchComponent } from './project-card-item/project-card-item-autosearch/project-card-item-autosearch.component';
import { ProjectListComponent } from './lists/project-list/project-list.component';
import { ProjectDetailsComponent } from './details/project-details/project-details.component';
import { OpportunityDetailsComponent } from './details/opportunity-details/opportunity-details.component';
import { ProjectAttachmentsComponent } from './project-attachments/project-attachments.component';
import { ProjectBannerCardComponent } from './project-banner-card/project-banner-card.component';
import { ColumnDialogComponent } from './column-dialog/column-dialog.component';
import { AttachmentDialogComponent } from './attachment-dialog/attachment-dialog.component';

import { AddNewProjectComponent } from './add-new-project/add-new-project.component';
import { AddNewLeadComponent } from './add-new-project/add-new-lead/add-new-lead.component';
import { ProjectOptionalFieldsComponent } from './add-new-project/project-optional-fields/project-optional-fields.component';
import { ProjectMandatoryFieldsComponent } from './add-new-project/project-mandatory-fields/project-mandatory-fields.component';
import { ProjectCommonFieldsComponent } from './add-new-project/project-common-fields/project-common-fields.component';
import { NewClientDialogComponent } from './new-client-dialog/new-client-dialog.component';
import { ProjectSeatingHardwareComponent } from './project-seating-hardware/project-seating-hardware.component';
import { SeatDialogComponent } from './project-seating-hardware/seat-dialog/seat-dialog.component';
import { ProjectSeatingComponent } from './project-seating-hardware/project-seating/project-seating.component';
import { ProjectHardwareComponent } from './project-seating-hardware/project-hardware/project-hardware.component';
import { CommonComponent } from '../shared/common/common.component';
import { ProjectDemandsComponent } from './project-demands/project-demands.component';
import { AddNewDemandComponent } from './add-new-demand/add-new-demand.component';
import { DemandCommonFieldsComponent } from './add-new-demand/demand-common-fields/demand-common-fields.component';
import { DemandOptionalFieldsComponent } from './add-new-demand/demand-optional-fields/demand-optional-fields.component';
import { DemandMandatoryFieldsComponent } from './add-new-demand/demand-mandatory-fields/demand-mandatory-fields.component';
import { ReviewApproveDialogComponent } from './project-seating-hardware/review-approve-dialog/review-approve-dialog.component';
import { SeatingHardwareCommonComponent } from './project-seating-hardware/seating-hardware-common/seating-hardware-common.component';
import { ProjectSoftlocksHardlocksComponent } from './project-softlocks-hardlocks/project-softlocks-hardlocks.component';
import { ProjectSoftlocksComponent } from './project-softlocks-hardlocks/project-softlocks/project-softlocks.component';
import { ProjectHardlocksComponent } from './project-softlocks-hardlocks/project-hardlocks/project-hardlocks.component';
import { ProjectHistoryComponent } from './project-history/project-history.component';
import { FteDialogComponent } from './fte-dialog/fte-dialog.component';
import { HardwareDialogComponent } from './project-seating-hardware/hardware-dialog/hardware-dialog.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { AuthService } from '../services/auth.service';
import { ClientService } from 'app/services/client.service';
import { ProjectReportingComponent } from './project-reporting/project-reporting.component';
import { UpdateWizardComponent } from './update-wizard/update-wizard.component';
import { ListColumnSettingsComponent } from './list-column-settings/list-column-settings.component';
import { LeadMandatoryFieldsComponent } from './add-new-project/lead-mandatory-fields/lead-mandatory-fields.component';
import { LeadOptionalFieldsComponent } from './add-new-project/lead-optional-fields/lead-optional-fields.component';
import { DefaultListComponent } from './lists/default-list/default-list.component';
import { CommonDetailsComponent } from './details/common-details/common-details.component';
import { LeadDetailsComponent } from './details/lead-details/lead-details.component';
import { AddNewOpportunityComponent } from './add-new-project/add-new-opportunity/add-new-opportunity.component';
import { OpportunityMandatoryFieldsComponent } from './add-new-project/opportunity-mandatory-fields/opportunity-mandatory-fields.component';
import { OpportunityOptionalFieldsComponent } from './add-new-project/opportunity-optional-fields/opportunity-optional-fields.component';
import { OpportunityCardComponent } from './cards/opportunity-card/opportunity-card.component';
import { InfrastructureReviewComponent } from './infrastructure-review/infrastructure-review.component';
import { ReviewChargesComponent } from './infrastructure-review/review-charges/review-charges.component';
import { AddNewProjectViewComponent } from './add-new-project/add-new-project-view/add-new-project-view.component';
import { FlexibleSeatingComponent } from './project-seating-hardware/project-seating/flexible-seating/flexible-seating.component';
import { FlexibleSeatDialogComponent } from './project-seating-hardware/flexible-seat-dialog/flexible-seat-dialog.component';
import { ReviewChargesNewApproachComponent } from './infrastructure-review/review-charges-new-approach/review-charges-new-approach.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ProjectCardItemComponent,
    ProjectCardItemEnumMultipleComponent,
    ProjectCardItemEnumComponent,
    ProjectCardItemComboboxComponent,
    ProjectCardItemBoolComponent,
    ProjectCardItemAutosearchComponent,
    ProjectAttachmentsComponent,
    ProjectBannerCardComponent,
    ProjectOptionalFieldsComponent,
    ProjectMandatoryFieldsComponent,
    LeadMandatoryFieldsComponent,
    LeadOptionalFieldsComponent,
    OpportunityMandatoryFieldsComponent,
    OpportunityOptionalFieldsComponent,
    ProjectCommonFieldsComponent,
    AddNewProjectComponent,
    AddNewLeadComponent,
    AddNewOpportunityComponent,
    ProjectListComponent,
    ProjectDetailsComponent,
    OpportunityDetailsComponent,
    LeadDetailsComponent,
    ProjectCardComponent,
    LeadCardComponent,
    CommonCardComponent,
    ColumnDialogComponent,
    NewClientDialogComponent,
    AttachmentDialogComponent,
    ProjectSeatingHardwareComponent,
    SeatDialogComponent,
    ProjectSeatingComponent,
    ProjectHardwareComponent,
    CommonComponent,
    ProjectDemandsComponent,
    AddNewDemandComponent,
    DemandCommonFieldsComponent,
    DemandOptionalFieldsComponent,
    DemandMandatoryFieldsComponent,
    ReviewApproveDialogComponent,
    SeatingHardwareCommonComponent,
    ProjectSoftlocksHardlocksComponent,
    ProjectSoftlocksComponent,
    ProjectHardlocksComponent,
    ProjectHistoryComponent,
    FteDialogComponent,
    HardwareDialogComponent,
    WelcomePageComponent,
    ProjectReportingComponent,
    UpdateWizardComponent,
    ListColumnSettingsComponent,
    DefaultListComponent,
    CommonDetailsComponent,
    OpportunityCardComponent,
    InfrastructureReviewComponent,
    ReviewChargesComponent,
    ReviewChargesNewApproachComponent,
    AddNewProjectViewComponent,
    FlexibleSeatingComponent,
    FlexibleSeatDialogComponent,
  ],
  providers: [
    ProjectService,
    UserRolesService,
    ProjectService,
    ContactService,
    ClientListService,
    ClientService,
    CanDeactivateGuard,
    DcResponsibleService,
    AuthService,
  ],
})
export class ProjectModule {}

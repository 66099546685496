import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { User } from '../../services/user-roles/user';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { CommonComponent } from '../../shared/common/common.component';
import { DialogData } from '../../types/dialog-data';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VersionDataService } from '../../version-data.service';
import { Location } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class WelcomePageComponent extends CommonComponent implements OnInit {
  private months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  private days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  protected date =
    this.days[new Date().getDay()] +
    ' ' +
    this.months[new Date().getMonth()] +
    ' ' +
    new Date().getDate() +
    ' ' +
    new Date().getFullYear();
  protected creatingRole: boolean;
  constructor(
    private versionDataService: VersionDataService,
    private dialog: MatDialog,
    protected fb: FormBuilder,
    private userRolesService: UserRolesService,
    protected snackbar: SnackbarPanelComponent,
    protected app: AppComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
      });
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

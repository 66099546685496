import { ProjectCard } from './card';

export const card_0: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'projectName',
    label: 'Opportunity Name',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'primaryAccountableLocationId',
    label: 'Primary Accountable Location',
    part: 'reqQualification',
    type: '',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'platformId',
    label: 'Primary Platform',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'clientGroupId',
    label: 'Industry',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'subIndustryId',
    label: 'Sub-Industry',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'tgSalesId',
    label: 'TG Sales',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'enum-multiple',
    id: 'offeringNameId',
    label: 'Offering Name',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'offeringResp',
    label: 'Offering Responsible',
    type: 'read',
    disabled: true,
    part: 'reqQualification',
    validation: [],
  },
];

export const card_1: Array<ProjectCard> = [
  {
    cardType: 'autosearch',
    id: 'dcResponsible',
    label: 'ATC Responsible Name',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'operationalRespId',
    label: 'Operation Responsibility',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  { cardType: 'bool', id: 'end2End', label: 'E2E', type: '', part: 'projectInfo', validation: [] },
  {
    cardType: 'bool',
    id: 'localProject',
    label: 'Local Project',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'enum-multiple',
    id: 'serviceGroupIds',
    label: 'Service Groups',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'sapOpNumber',
    label: 'MMS ID',
    type: '',
    part: 'projectInfo',
    validation: ['valInteger'],
  },
  {
    cardType: 'enum',
    id: 'commitment',
    label: 'Probability / Commitment',
    type: '',
    part: 'projectSchedule',
    validation: ['valRequired'],
  },
  { cardType: '', id: 'piComment', label: 'Notes', type: '', part: 'projectInfo', validation: [] },
];

export const card_2: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    disabled: false,
    part: 'projectSchedule',
    validation: ['valDate', 'valRequired'],
  },
  {
    cardType: '',
    id: 'endDate',
    label: 'End Date',
    type: 'date',
    part: 'projectSchedule',
    validation: ['valDate', 'valRequired'],
  },
  { cardType: 'enum', id: 'statusId', label: 'Status', type: '', validation: ['valRequired'] },
  {
    cardType: 'enum',
    id: 'workCategoryId',
    label: 'Work Category',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'sharedService',
    label: 'One-To-Many Shared Services Name',
    type: '',
    part: 'projectInfo',
    validation: [''],
  },
  {
    cardType: '',
    id: 'selectedSolutionFactoryValues',
    label: 'One-To-Many Shared Services Used',
    type: 'read',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: '',
    id: 'deliveryVmuDescription',
    label: 'Opportunity Description',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired', 'valString500'],
  },
];

export const card_3: Array<ProjectCard> = [
  {
    cardType: 'autosearch',
    id: 'clientName',
    label: 'Client',
    type: '',
    part: 'client',
    validation: ['valRequired'],
  },
  {
    cardType: 'bool',
    id: 'diamondClient',
    label: 'Diamond Client',
    type: '',
    disabled: true,
    part: 'client',
    validation: [],
  },
  {
    cardType: 'bool',
    id: 'g150',
    label: 'G150',
    type: '',
    disabled: true,
    part: 'client',
    validation: [],
  },
  {
    cardType: 'link',
    id: '',
    label: 'Diamond Clients List',
    type: '',
    url: 'https://in.accenture.com/diamondclients/client-list/',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'regionId',
    label: 'Region',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'countryId',
    label: 'Country',
    type: '',
    url: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'primaryWorkLocId',
    label: 'Primary Work Location',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'workLocation',
    label: 'Work Location Country',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'bool',
    id: 'swfReqTravel',
    label: 'Required To Travel',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
];

export const card_4: Array<ProjectCard> = [
  {
    cardType: 'autosearch',
    id: 'clAccLeadContact',
    label: 'Client Account Lead',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired', 'valEidOrMail'],
  },
  {
    cardType: 'link',
    id: '',
    label: 'Client Account Lead Lookup',
    type: '',
    url: 'https://search.accenture.com/?aid=cal',
    validation: [],
  },
  {
    cardType: 'autosearch',
    id: 'solArchContact',
    label: 'Solution Architect',
    type: '',
    part: 'reqQualification',
    validation: ['valEidOrMail'],
  },
  {
    cardType: 'autosearch',
    id: 'salBidContact',
    label: 'Sales Lead',
    type: '',
    part: 'reqQualification',
    validation: ['valEidOrMail'],
  },
  {
    cardType: 'autosearch',
    id: 'qaDirContact',
    label: 'QA Director',
    type: '',
    part: 'reqQualification',
    validation: ['valEidOrMail'],
  },
  {
    cardType: 'autosearch',
    id: 'othContact',
    label: 'Other Contact',
    type: '',
    part: 'reqQualification',
    validation: ['valEidOrMail'],
  },
  {
    cardType: '',
    id: 'othContactRole',
    label: 'Other Contact Role',
    type: '',
    part: 'reqQualification',
    validation: [],
  },
];

export const card_5: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'expectedFte',
    label: 'Expected FTE',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'bool',
    id: 'newBusinessMeeting',
    label: 'New Business Meeting',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'nbmStatus',
    label: 'NBM Status',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'condition',
    label: 'Condition',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired', 'valString500'],
    values: ['', []],
  },
  {
    cardType: 'enum-multiple',
    data: 'sourcingLocation',
    id: 'sourcingLocationId',
    label: 'Sourcing Location',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'expectedDedicatedSeats',
    label: 'Expected Dedicated Seats',
    type: '',
    part: 'projectInfo',
    validation: ['valPositive'],
  },
  {
    cardType: '',
    id: 'expectedSharedSeats',
    label: 'Expected Shared Seats',
    type: '',
    part: 'projectInfo',
    validation: ['valPositive'],
  },
];

export const card_6: Array<ProjectCard> = [
  {
    cardType: 'bool',
    id: 'highProfitabilityDeal',
    label: 'High Profitability Deal',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'bool',
    id: 'growthOpportunity',
    label: 'Growth Opportunity',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'bool',
    id: 'upskilling',
    label: 'Upskilling',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
];

import { Component, OnInit } from '@angular/core';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-add-new-project-view',
  templateUrl: './add-new-project-view.component.html',
  styleUrls: [
    '../../../shared/add-new.scss',
    './add-new-project-view.component.scss',
    '../add-new-project.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AddNewProjectViewComponent implements OnInit {
  returnUrl: any;

  constructor(
    public snackbar: SnackbarPanelComponent,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public authService: AuthService
  ) {}

  routeToAddNewLead() {
    this.setReturnUrl();
    const routerLink = '/project-details/add-new-lead';
    this.router.navigate([routerLink], { queryParams: { returnUrl: this.returnUrl } });
  }

  routeToAddNewOpportunity() {
    this.setReturnUrl();
    const routerLink = '/project-details/add-new-opportunity';
    this.router.navigate([routerLink], { queryParams: { returnUrl: this.returnUrl } });
  }

  routeToAddNewProject() {
    this.setReturnUrl();
    const routerLink = '/project-details/add-new-project';
    this.router.navigate([routerLink], { queryParams: { returnUrl: this.returnUrl } });
  }

  setReturnUrl() {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });
  }

  back() {
    let returnUrl;
    this.route.queryParams.subscribe(params => {
      returnUrl = params.returnUrl;
    });

    this.snackbar.close();

    if (returnUrl) {
      this.router.navigate([returnUrl]);
    } else {
      this.router.navigate(['../project-list']);
    }
  }

  ngOnInit() {
    this.snackbar.close();
  }
}

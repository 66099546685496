import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'buildingFloorLocationOptGroup', pure: false })
export class BuildingFloorLocationOptGroup implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => item.idLocation === filter.id);
  }
}

import { NewBenchTracking } from './new-form';

export const fieldsSettings: NewBenchTracking = {
  mandatory: [
    {
      cardType: 'select',
      id: 'week',
      label: 'Week',
      validation: ['valRequired'],
      values: ['', []],
      data: 'lastWeeks',
    },
    {
      cardType: 'select',
      id: 'taskCategoryId',
      label: 'Task Category',
      validation: ['valRequired'],
      values: ['', []],
      data: 'taskCategoryEnum',
    },
    {
      cardType: 'input',
      id: 'taskDescription',
      label: 'Task Description',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'requestedBy',
      label: 'Requested By',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'weeklyCapacity',
      label: 'Weekly Bench Capacity (hours)',
      validation: ['valRequired'],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'input',
      id: 'actualEffort',
      label: 'Actual Effort on This Task (hours)',
      validation: ['valRequired', 'valPositive', 'valTaskEffort'],
      values: ['', []],
    },
  ],
  optional: [],
};

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed to ensure it appears above other content */
}

.report-button,
.right-round-button {
  margin-right: 250px;
}

.right-round-button {
  z-index: 9999;
  margin-top: 60px;
}

.flex-spacer {
  flex: 1;
}

.toolbar-title {
  font-size: 18px;
  font-weight: 500;
}

.middle-section {
  display: flex;
  margin-right: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

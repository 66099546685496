import { CommonModule } from '../shared/common.module';
import { NgModule } from '@angular/core';
import { ReportService } from 'app/services/report.service';
import { ReportCommonFieldsComponent } from './report/report-common-fields/report-common-fields.component';
import { ReportMandatoryFieldsComponent } from './report/report-mandatory-fields/report-mandatory-fields.component';
import { NsmetricsReportComponent } from './nsmetrics-report/nsmetrics-report.component';
import { NBMReportComponent } from './nbm-report/nbm-report.component';
import { ProjectSummaryReportComponent } from './project-summary-report/project-summary-report.component';
import { SchedulingReportComponent } from './scheduling-report/scheduling-report.component';
import { AwsSeatingReportComponent } from './awsseating-report/aws-seating-report.component';
import { OpenDemandsReportComponent } from './opendemands-report/opendemands-report.component';
import { SupplySummaryReportComponent } from './supply-summary-report/supply-summary-report.component';
import { VacationandovertimeReportComponent } from './vacationandovertime-report/vacationandovertime-report.component';
import { DataFreshnessReportComponent } from './data-freshness-report/data-freshness-report.component';
import { OpenPositionsReportComponent } from './open-positions-report/open-positions-report.component';
import { PlatformReportComponent } from './platform-report/platform-report.component';
import { CostCenterReportComponent } from './cost-center-report/cost-center-report.component';
import { OpportunitySummaryReportComponent } from './opportunity-summary-report/opportunity-summary-report.component';
import { HiringRequestsReportComponent } from './hiring-requests-report/hiring-requests-report.component';
import { ProjectCostReportComponent } from './project-cost-report/project-cost-report.component';
import { ProjectsRollOffsOverviewComponent } from './projects-roll-offs-overview/projects-roll-offs-overview.component';
import { LanguageSkillsUsageReportComponent } from './language-skills-usage-report/language-skills-usage-report.component';
import { TrainingDataReportComponent } from './training-data-report/training-data-report.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ReportCommonFieldsComponent,
    ReportMandatoryFieldsComponent,
    NsmetricsReportComponent,
    NBMReportComponent,
    ProjectSummaryReportComponent,
    SchedulingReportComponent,
    AwsSeatingReportComponent,
    OpenDemandsReportComponent,
    SupplySummaryReportComponent,
    VacationandovertimeReportComponent,
    DataFreshnessReportComponent,
    OpenPositionsReportComponent,
    PlatformReportComponent,
    CostCenterReportComponent,
    OpportunitySummaryReportComponent,
    HiringRequestsReportComponent,
    ProjectCostReportComponent,
    ProjectsRollOffsOverviewComponent,
    LanguageSkillsUsageReportComponent,
    TrainingDataReportComponent,
  ],
  providers: [ReportService],
})
export class ReportModule {}

import { ProjectCard } from './card';

export const card_0: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'projectName',
    label: 'Lead Name',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'platformId',
    label: 'Primary Platform',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'clientGroupId',
    label: 'Industry',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'subIndustryId',
    label: 'Sub-Industry',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'tgSalesId',
    label: 'TG Sales',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: '',
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    disabled: false,
    part: 'projectSchedule',
    validation: ['valDate', 'valRequired'],
  },
  { cardType: 'enum', id: 'statusId', label: 'Status', type: '', validation: ['valRequired'] },
];

export const card_1: Array<ProjectCard> = [
  {
    cardType: 'autosearch',
    id: 'dcResponsible',
    label: 'ATC Responsible Name',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'autosearch',
    id: 'clientName',
    label: 'Client',
    type: '',
    part: 'client',
    validation: ['valRequired'],
  },
  {
    cardType: 'bool',
    id: 'diamondClient',
    label: 'Diamond Client',
    type: '',
    disabled: true,
    part: 'client',
    validation: [],
  },
  {
    cardType: 'bool',
    id: 'g150',
    label: 'G150',
    type: '',
    disabled: true,
    part: 'client',
    validation: [],
  },
  {
    cardType: 'link',
    id: '',
    label: 'Diamond Clients List',
    type: '',
    url: 'https://in.accenture.com/diamondclients/client-list/',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'regionId',
    label: 'Region',
    type: '',
    part: 'reqQualification',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'commitment',
    label: 'Probability / Commitment',
    type: '',
    part: 'projectSchedule',
    validation: ['valRequired'],
  },
  { cardType: '', id: 'piComment', label: 'Notes', type: '', part: 'projectInfo', validation: [] },
  {
    cardType: '',
    id: 'sapOpNumber',
    label: 'MMS ID',
    type: '',
    part: 'projectInfo',
    validation: ['valInteger'],
  },
];

export const card_2: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'expectedFte',
    label: 'Expected FTE',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: 'bool',
    id: 'newBusinessMeeting',
    label: 'New Business Meeting',
    type: '',
    part: 'projectInfo',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'nbmStatus',
    label: 'NBM Status',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'condition',
    label: 'Condition',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired', 'valString500'],
    values: ['', []],
  },
  {
    cardType: 'enum-multiple',
    data: 'sourcingLocation',
    id: 'sourcingLocationId',
    label: 'Sourcing Location',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired'],
  },
  {
    cardType: '',
    id: 'expectedDedicatedSeats',
    label: 'Expected Dedicated Seats',
    type: '',
    part: 'projectInfo',
    validation: ['valPositive'],
  },
  {
    cardType: '',
    id: 'expectedSharedSeats',
    label: 'Expected Shared Seats',
    type: '',
    part: 'projectInfo',
    validation: ['valPositive'],
  },
  {
    cardType: '',
    id: 'deliveryVmuDescription',
    label: 'Lead Description',
    type: '',
    part: 'projectInfo',
    validation: ['valRequired', 'valString500'],
  },
];

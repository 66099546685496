import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, map, distinctUntilChanged } from 'rxjs/operators';
import { EmployeeDetailsService } from '../../employee-details/employee-details.service';
import { EmpOption } from '../../../types/emp-search-option';
import { MatInputModule } from '@angular/material/input';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { CounseleesSearchService } from '../../../services/counselees-search.service';
import { User } from '../../../services/user-roles/user';

@Component({
  selector: 'app-autocomplete-search',
  templateUrl: './autocomplete-search.component.html',
  styleUrls: ['./autocomplete-search.component.scss'],
})
export class AutocompleteSearchComponent implements OnInit {
  modelValue: String;
  @ViewChild('input', { static: true }) input: MatInputModule;
  searchInactive = false;
  @Output() modelChange = new EventEmitter();
  errorSearchedEnterpriseDomainIds: Error;
  filteredOptions: any;
  modelPrevValue: String;
  minEidLength = 3;
  optionCtrl: FormControl;
  options = [];
  loading: Boolean;
  filtering: Boolean;
  listCounselees: Boolean;
  debounceTime = 400;

  constructor(
    private employeeDetailsService: EmployeeDetailsService,
    private counseleesSearchService: CounseleesSearchService,
    private userRolesService: UserRolesService
  ) {
    this.optionCtrl = new FormControl();
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      // debounceTime(this.debounceTime),
      startWith(null),
      map(name => this.filterOptions(name))
    );
  }

  ngOnInit() {
    this.optionCtrl.updateValueAndValidity();
    this.optionCtrl.valueChanges.subscribe(searchedEnterpriseDomainId => {
      this.filtering = true;
      if (searchedEnterpriseDomainId) {
        this.modelPrevValue = this.modelValue;

        if (typeof searchedEnterpriseDomainId === 'object') {
          this.modelValue = (searchedEnterpriseDomainId as EmpOption).id;
          this.modelChange.emit(searchedEnterpriseDomainId);
        } else {
          this.modelValue = searchedEnterpriseDomainId;
          this.modelChange.emit(searchedEnterpriseDomainId);
        }
      } else if (!searchedEnterpriseDomainId && this.modelPrevValue) {
        this.modelValue = '';
      }
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.listCounselees = user.getRoles().cc;
      });
  }

  filterOptions(searchedEnterpriseDomainId) {
    if (searchedEnterpriseDomainId && this.optionCtrl.valid) {
      if (typeof searchedEnterpriseDomainId === 'string') {
        if (searchedEnterpriseDomainId.match(' ')) {
          if (this.options.length > 0) {
            return this.options.filter(option =>
              new RegExp(`^${searchedEnterpriseDomainId}`, 'gi').test(option.name)
            );
          } else {
            this.search(searchedEnterpriseDomainId.split(' ')[0]);
          }
        } else if (searchedEnterpriseDomainId.length < this.minEidLength) {
          return (this.options = []);
        } else if (
          this.modelPrevValue !== searchedEnterpriseDomainId ||
          (this.modelPrevValue !== undefined &&
            this.modelPrevValue.length !== searchedEnterpriseDomainId.length)
        ) {
          this.search(searchedEnterpriseDomainId);
        }
      }
    } else if (
      this.listCounselees &&
      (!searchedEnterpriseDomainId || searchedEnterpriseDomainId === '') &&
      this.optionCtrl.valid
    ) {
      this.counseleesSearchService
        .getData()
        .pipe(distinctUntilChanged())
        .subscribe(
          enterpriseDomainIds => this.handleCounseleesSearch(enterpriseDomainIds),
          error => this.handleError(error)
        );
    } else {
      return (this.options = []);
    }
    this.filtering = false;
    return this.options;
  }

  evaluateNoMatches() {
    return (
      this.loading === false &&
      this.optionCtrl.value !== null &&
      this.optionCtrl.value.length >= this.minEidLength &&
      this.options.length === 0 &&
      this.filtering === false
    );
  }

  private search(searchedEnterpriseDomainId) {
    this.loading = true;
    this.options = [];

    if (this.searchInactive) {
      this.employeeDetailsService
        .getAllEmployeeDetailsList(searchedEnterpriseDomainId)
        .pipe(distinctUntilChanged())
        .subscribe(
          enterpriseDomainIds => this.handleEnterpriseDomainIds(enterpriseDomainIds),
          error => this.handleError(error)
        );
    } else {
      this.employeeDetailsService
        .getActiveEmployeeDetailsList(searchedEnterpriseDomainId)
        .pipe(distinctUntilChanged())
        .subscribe(
          enterpriseDomainIds => this.handleEnterpriseDomainIds(enterpriseDomainIds),
          error => this.handleError(error)
        );
    }
  }

  //  @Input('model')
  // get model() {
  //   return this.modelValue;
  // }

  // set model(searchedEnterpriseDomainId: String | EmpOption) {
  //   this.filtering = true;
  //   if (searchedEnterpriseDomainId) {
  //     this.modelPrevValue = this.modelValue;

  //     if (typeof searchedEnterpriseDomainId === 'object') {
  //       this.modelValue = (searchedEnterpriseDomainId as EmpOption).id;
  //       this.modelChange.emit(searchedEnterpriseDomainId);
  //     } else {
  //       this.modelValue = searchedEnterpriseDomainId;
  //       this.modelChange.emit(searchedEnterpriseDomainId);
  //     }
  //   } else if (!searchedEnterpriseDomainId && this.modelPrevValue) {
  //     this.modelValue = '';
  //   }
  // }

  handleError(error: Error) {
    this.loading = false;
    this.options = [];
    this.errorSearchedEnterpriseDomainIds = new Error(error.message);
  }

  handleCounseleesSearch(enterpriseDomainIds) {
    if (this.searchInactive) {
      this.options = enterpriseDomainIds;
    } else {
      this.options = enterpriseDomainIds.filter(option => option.type !== 'inactive');
    }
    this.optionCtrl.setValue(this.modelValue);
  }

  handleEnterpriseDomainIds(enterpriseDomainIds) {
    this.options = enterpriseDomainIds;
    this.optionCtrl.setValue(this.modelValue);
    setTimeout(() => {
      this.loading = false;
    }, this.debounceTime);
  }

  onSelectedOption(value) {
    this.filterOptions(value);
    this.resetSearch();
  }

  resetSearch() {
    this.modelPrevValue = this.modelValue;
    this.options = [];
    this.modelValue = '';
    this.optionCtrl.setValue('');
  }

  onCheckedChange() {
    this.modelPrevValue = this.modelValue;
    this.options = [];
    this.modelValue = '';
    this.optionCtrl.setValue(this.optionCtrl.value);
  }

  displayFn(option: string | EmpOption): string {
    if (option) {
      return typeof option === 'object' ? option.id : option;
    } else {
      return '';
    }
  }
}

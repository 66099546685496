import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ProjectCardItemComponent } from './../project-card-item.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { FormatCardEnum } from 'app/pipes/format-card-enum.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-card-item-combobox',
  templateUrl: './project-card-item-combobox.component.html',
  styleUrls: ['../project-card-item.component.scss', './project-card-item-combobox.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardItemComboboxComponent
  extends ProjectCardItemComponent
  implements OnInit, OnChanges
{
  comboboxValues: any[];
  formatCardEnum: FormatCardEnum;
  dcRespRoleEnum: any;

  constructor(
    snackbar: SnackbarPanelComponent,
    private router: Router,
    enumsService: EnumsService
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectDetails && !changes.projectDetails.firstChange) {
      this.init();
    }
  }

  init() {
    this.enumsService.getData().subscribe(enums => {
      this.comboboxValues = enums['dcResponsibleRole'];
      this.dcRespRoleEnum = enums['dcResponsibleRole'];
    });
  }

  changeDcResponsibleRole(commitment: number, operRespValue?: any) {
    if (commitment === 100) {
      this.comboboxValues = this.enums['dcResponsibleRole'].filter(option => option.id !== 1);
    } else if (commitment) {
      this.comboboxValues = this.enums['dcResponsibleRole'].filter(option => option.id === 1);
    } else {
      this.comboboxValues = this.enums['dcResponsibleRole'];
    }
  }
}

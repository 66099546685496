import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as validationUtils from '../../../shared/validators';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-flexible-seat';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../../services/project.service';
import { Common } from 'app/shared/common';
import { EnumsService } from 'app/services/enumerations/enums.service';

@Component({
  selector: 'app-flexible-seat-dialog',
  templateUrl: './flexible-seat-dialog.component.html',
  styleUrls: [
    './flexible-seat-dialog.component.scss',
    './../project-seating-hardware.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class FlexibleSeatDialogComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  form: FormGroup;
  formDays: FormGroup;
  errors: { [key: string]: any } = {};
  enums: any;
  data = {};
  currentDate: Date = new Date();
  originalSeatTo: Date = null;

  disabled = false;
  isDisabled = false;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<FlexibleSeatDialogComponent>,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private enumsService: EnumsService
  ) {}

  ngOnInit(): void {
    const fields = new Object();

    this.enumsService.getData().subscribe(
      enums => {
        this.enums = enums;
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    if (this.dialogData.editing) {
      this.fieldsSettings.forEach(setting => {
        fields[setting.id] = [this.dialogData.row[setting.id], []];
        if (setting.id === 'seatTo') {
          this.originalSeatTo = this.dialogData.row[setting.id];
        }
        this.monday = this.dialogData.row.monday;
        this.tuesday = this.dialogData.row.tuesday;
        this.wednesday = this.dialogData.row.wednesday;
        this.thursday = this.dialogData.row.thursday;
        this.friday = this.dialogData.row.friday;
      });
    } else {
      this.fieldsSettings.forEach(setting => {
        fields[setting.id] = setting.values;
      });
    }

    this.form = this.fb.group(fields);
    this.setFields();
    this.setValidators();
    this.form.valueChanges.subscribe(changes => {
      this.onFormChanges(changes);
    });
  }

  create(create: boolean) {
    if (create) {
      const seat = {
        id: null,
        projectId: this.dialogData.projectId,
        monday: this.monday,
        tuesday: this.tuesday,
        wednesday: this.wednesday,
        thursday: this.thursday,
        friday: this.friday,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();
        if (controler.value instanceof Date) {
          seat[controlerName] = Common.timezoneFix(controler.value);
        } else {
          seat[controlerName] = controler.value;
        }
      });

      if (this.isFormValid() && this.atLeastOneDaySelected()) {
        if (this.dialogData.editing) {
          seat.id = this.dialogData.row.id;
          this.projectService.editFlexibleSeating(seat).subscribe(
            res => this.handleSeating(res),
            err => this.handleError(err)
          );
        } else {
          this.projectService.addFlexibleSeating(seat).subscribe(
            res => this.handleSeating(res),
            err => this.handleError(err)
          );
        }
      } else {
        this.handleError(this.errors);
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  minValidDate() {
    return this.dialogData.editing ? this.currentDate : null;
  }

  atLeastOneDaySelected() {
    if (
      this.monday === true ||
      this.tuesday === true ||
      this.wednesday === true ||
      this.thursday === true ||
      this.friday === true
    ) {
      return true;
    } else {
      this.snackbar.error('You must select at least one day');
      return false;
    }
  }

  setFields() {
    if (this.dialogData.editing) {
      this.currentDate.setHours(0);
      this.currentDate.setMinutes(0);
      this.currentDate.setSeconds(0);
      let date = new Date(this.form.get('seatFrom').value);
      if (date.getTime() < this.currentDate.getTime()) {
        this.form.get('building').disable({ onlySelf: true, emitEvent: false });
        this.form.get('numberOfSeats').disable({ onlySelf: true, emitEvent: false });
        this.form.get('seatFrom').disable({ onlySelf: true, emitEvent: false });
        this.isDisabled = true;
      }
    }
  }

  setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        if (validation === 'valFromToDates') {
          validators.push(validationUtils[validation](this.form.get('seatTo')));
        } else if (validation === 'valToFromDates') {
          validators.push(validationUtils[validation](this.form.get('seatFrom')));
        } else {
          validators.push(validationUtils[validation]());
        }
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  isFormValid() {
    if (this.form.valid && !this.form.get('wbs').value?.includes(' ')) {
      return true;
    }

    return Object.keys(this.form.controls).some(controllerName => {
      const controller = this.form.get(controllerName);
      return (
        controllerName === 'seatTo' &&
        controller.invalid &&
        controller.value === this.originalSeatTo
      );
    });
  }

  onCheckboxChange(day, value) {
    if (day === 1) {
      this.monday = !value;
    } else if (day === 2) {
      this.tuesday = !value;
    } else if (day === 3) {
      this.wednesday = !value;
    } else if (day === 4) {
      this.thursday = !value;
    } else if (day === 5) {
      this.friday = !value;
    }
  }

  private handleSeating(response) {
    this.snackbar.success('Seat has been saved');
    this.dialogRef.close(true);
  }

  private handleError(error) {
    if (!this.atLeastOneDaySelected()) {
      this.snackbar.error('Select at least one day of the week');
    } else if (this.form.get('wbs').invalid || this.form.get('wbs').value.includes(' ')) {
      this.snackbar.error('Incorrect WBS (please also check for empty spaces)');
    } else if (
      this.form.get('seatTo').invalid &&
      this.form.get('seatTo').value !== this.originalSeatTo
    ) {
      this.snackbar.error('Seat To date must be today or in the future only');
    } else {
      this.snackbar.error('Please fix invalid fields (marked in red)');
    }
  }

  populateDropdowns(enums) {
    this.data['buildingEnum'] = enums['seating-building'];
  }

  onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.form.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'app/app.component';
import { ReportService } from 'app/services/report.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { VersionDataService } from 'app/version-data.service';

@Component({
  selector: 'app-data-freshness-report',
  templateUrl: './data-freshness-report.component.html',
  styleUrls: ['./data-freshness-report.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
  ],
})
export class DataFreshnessReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportForm: FormGroup;
  data = {};
  title: string = 'Data Freshness Report';

  exporting = false;

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private location: Location,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent
  ) {}

  ngOnInit() {
    this.snackbar.close();
    this.exportForm = this.fb.group({
      platform: ['', []],
      location: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    this.data['employeeLocation'] = enums['employee-location'];
    this.data['platform'] = [];
    const all = { id: 0, stage: 0, text: 'All', value: 0, deleted: false };

    enums['platform'].forEach(e => {
      if (e.deleted != true) {
        this.data['platform'].push(e);
      }
    });
    if (this.data['platform'].findIndex(item => item.id === all.id) < 0) {
      this.data['platform'].unshift(all);
    }
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }

    const prague = { id: 3, stage: 3, text: 'Prague', deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === prague.id) < 0) {
      this.data['employeeLocation'].push(prague);
    }

    this.exportForm.get('location').setValue(0);
    this.exportForm.get('platform').setValue(0);
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }

  exportDataFreshnessReport() {
    this.exporting = true;

    const platform = this.exportForm.get('platform').value;
    const location = this.exportForm.get('location').value;

    this.reportService
      .exportDataFreshnessReport(platform, location)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

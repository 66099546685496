import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonFieldsComponent } from '../common-fields/common-fields.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { ActiveEmployeesAllCareerCounselorsService } from '../../../services/active-employees-all-career-counselors.service';
import { EnumsService } from '../../../services/enumerations/enums.service';

@Component({
  selector: 'app-optional-fields',
  templateUrl: './optional-fields.component.html',
  styleUrls: ['./optional-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class OptionalFieldsComponent extends CommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  values: any;
  filteredValues = {
    careerCounsellorEidCombobox: new Observable<any[]>(),
  };
  constructor(
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent,
    private activeEmployeesAllCareerCounselorsService: ActiveEmployeesAllCareerCounselorsService
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.expansionPanel.open();

    this.activeEmployeesAllCareerCounselorsService.deleteCache();
    this.activeEmployeesAllCareerCounselorsService
      .getData()
      .subscribe(data => (this.data['careerCounsellorEid'] = data));

    this.fieldsSettings.optional.forEach(setting => {
      if (setting.cardType === 'combo') {
        this.filteredValues[setting.id] = this.optional.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value => {
            if (value != null) {
              return value.length >= 3 ? this.filterStates(value, setting.id).slice(0, 50) : null;
            }
          })
        );
      }
    });
    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes));
  }

  onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.optional.controls[change];
      this.employeeDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
        this.expansionPanel.open();
      }
    });
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.data['primarySkillEnum'] = enums['primary-skill'];
    this.data['frenchEnum'] = enums['employee-french'];
    this.data['italianEnum'] = enums['employee-italian'];
    this.data['homeOfficeScenarioEnum'] = enums['employee-home-office-scenario'];
    this.data['acquisitionEnum'] = enums['employee-acquisition'];
  }

  filterStates(searchedValue: string, field: string) {
    return this.data[field].filter(
      value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1
    );
  }
}

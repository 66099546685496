import { NewLead } from './new-form';

export const fieldsSettings: NewLead = {
  mandatory: [
    {
      cardType: 'input',
      id: 'projectName',
      label: 'Lead Name',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'startDate',
      label: 'Start Date',
      part: 'projectSchedule',
      validation: ['valDate', 'valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      data: 'clientEnum',
      id: 'clientName',
      label: 'Client',
      part: 'client',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'clientGroupEnum',
      id: 'clientGroupId',
      label: 'Industry',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'subIndustryEnum',
      id: 'subIndustryId',
      label: 'Sub-Industry',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      id: 'tgSalesId',
      label: 'TG Sales',
      data: 'tgSalesEnum',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'regionEnum',
      id: 'regionId',
      label: 'Region',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'platformEnum',
      id: 'platformId',
      label: 'Primary Platform',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'dcResponsible',
      label: 'ATC Responsible Name',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'expectedFte',
      label: 'Expected FTE',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'newBusinessMeeting',
      label: 'New Business Meeting',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'select-multi',
      data: 'sourcingLocation',
      id: 'sourcingLocation',
      label: 'Sourcing Location',
      type: '',
      part: 'projectInfo',
      validation: ['valRequired'],
    },
    {
      cardType: 'input',
      id: 'deliveryVmuDescription',
      label: 'Lead Description',
      part: 'projectInfo',
      validation: ['valRequired', 'valString500'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'expectedDedicatedSeats',
      label: 'Expected Dedicated Seats',
      part: 'projectInfo',
      validation: ['valPositive'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'expectedSharedSeats',
      label: 'Expected Shared Seats',
      part: 'projectInfo',
      validation: ['valPositive'],
      values: ['', []],
    },
  ],
  optional: [
    {
      cardType: 'input',
      id: 'piComment',
      label: 'Notes',
      type: '',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'sapOpNumber',
      label: 'MMS ID',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
  ],
};

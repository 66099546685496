import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BenchTracking,
  BenchTrackingInput,
  BenchTrackingList,
  BenchTrackingSearchParams,
} from 'app/types/bench-tracking';
import { REST } from 'rest.endpoints';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BenchService {
  constructor(private http: HttpClient) {}

  getBenchWeeklyCapacity(week: number, year: number): Observable<number> {
    return this.http
      .get<number>(REST.benchWeeklyCapacity(week, year))
      .pipe(catchError(this.handleError));
  }

  getBenchTrackingDetails(id: number): Observable<BenchTracking> {
    return this.http.get<BenchTracking>(REST.benchTracking(id)).pipe(catchError(this.handleError));
  }

  getBenchTrackingList(params: BenchTrackingSearchParams): Observable<BenchTrackingList> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get<BenchTrackingList>(REST.benchTrackingList, { params: httpParams })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  addNewBenchTracking(
    benchTrackingList: Array<BenchTrackingInput>
  ): Observable<Array<BenchTracking>> {
    return this.http
      .post<Array<BenchTracking>>(REST.benchTrackingInsert, benchTrackingList)
      .pipe(catchError(this.handleError));
  }

  editBenchTracking(id: number, update: BenchTrackingInput): Observable<BenchTracking> {
    return this.http
      .put<BenchTracking>(REST.benchTrackingEdit(id), update)
      .pipe(catchError(this.handleError));
  }

  benchTrackingListExport(params: BenchTrackingSearchParams, type: string): Observable<any> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(entry => {
      httpParams = httpParams.append(entry['0'], entry['1']);
    });

    return this.http
      .get(REST.benchTrackingListExport(type), {
        params: httpParams,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(distinctUntilChanged(), catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return throwError(() => new Error(error));
  }
}

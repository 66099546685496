import { Component, OnInit } from '@angular/core';
import { CommonCardComponent } from '../common-card/common-card.component';
import * as cards from '../../../types/opportunity-cards-content';
import { ProjectCardItemEnumMultipleComponent } from 'app/project/project-card-item/project-card-item-enum-multiple/project-card-item-enum-multiple.component';

@Component({
  selector: 'app-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss', '../../../shared/card.scss'],
})
export class OpportunityCardComponent extends CommonCardComponent implements OnInit {
  ngOnInit() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.projectOriginVal = Object.assign({}, this.projectDetails);
    this.projectOriginVal.client = Object.assign({}, this.projectDetails.client);
    this.projectOriginVal.projectInfo = Object.assign({}, this.projectDetails.projectInfo);
    this.projectOriginVal.projectSchedule = Object.assign({}, this.projectDetails.projectSchedule);
    this.projectOriginVal.reqQualification = Object.assign(
      {},
      this.projectDetails.reqQualification
    );

    const primaryContact = 'PrimaryContact';
    const group = {};

    this.projectCard.forEach(item => {
      if (item.type !== 'read' || item.id === 'offeringResp') {
        group[item.id] = ['', []];
      }
    });
    this.formGroup = this.fb.group(group);

    this.initEditingRole();

    if (this.formGroup.get('regionId')) {
      this.formGroup.get('regionId').valueChanges.subscribe(value => this.onRegionChanges(value));
    }

    if (this.formGroup.get('platformId')) {
      this.formGroup
        .get('platformId')
        .valueChanges.subscribe(value => this.onPlatformChanges(value));
    }

    if (this.formGroup.get('clientGroupId')) {
      this.formGroup
        .get('clientGroupId')
        .valueChanges.subscribe(value => this.onIndustryGroupChanges(value));
    }

    if (this.formGroup.get('offeringNameId')) {
      this.formGroup
        .get('offeringNameId')
        .valueChanges.subscribe(value => this.onOfferingNameChanges(value));
    }

    if (this.formGroup.get('workCategoryId')) {
      this.formGroup
        .get('workCategoryId')
        .valueChanges.subscribe(value => this.onWorkCategoryChanges(value));
    }

    if (this.formGroup.get('nbmStatus')) {
      this.formGroup
        .get('nbmStatus')
        .valueChanges.subscribe(value => this.onNbmStatusChange(value));
    }

    if (this.formGroup.get('newBusinessMeeting')) {
      this.formGroup
        .get('newBusinessMeeting')
        .valueChanges.subscribe(value => this.onNewBusinessMeetingChange(value));
    }

    if (this.formGroup.get('commitment')) {
      this.formGroup
        .get('commitment')
        .valueChanges.subscribe(value => this.onCommitmentChanges(value));
    }

    if (this.formGroup.get('clAccLeadContact')) {
      this.formGroup
        .get('clAccLeadContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('clAccLead' + primaryContact))
        );
    }

    if (this.formGroup.get('salBidContact')) {
      this.formGroup
        .get('salBidContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('salBid' + primaryContact))
        );
    }

    if (this.formGroup.get('othContact')) {
      this.formGroup
        .get('othContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('oth' + primaryContact))
        );
    }

    if (this.formGroup.get('sourcingLocationId')) {
      this.sourcingLocVal = this.projectDetails.projectInfo.sourcingLocation;
      this.formGroup
        .get('sourcingLocationId')
        .valueChanges.subscribe(value => this.onSourcingLocationChanges(value));
    }

    if (this.formGroup.get('operationalRespId')) {
      this.formGroup
        .get('operationalRespId')
        .valueChanges.subscribe(value => this.onOperationalResponsibilityChanges(value));
    }

    if (this.formGroup.get('end2End')) {
      this.formGroup
        .get('end2End')
        .valueChanges.subscribe(value =>
          this.onE2Echanges(
            value,
            this.projectDetails.reqQualification.dcResponsibleRoleId,
            this.projectDetails.projectInfo.operationalRespId
          )
        );
    }

    this.checkNbmStatus(Number(this.formGroup.get('nbmStatus')));
    this.checkNewBusinessMeeting(Boolean(this.formGroup.get('newBusinessMeeting')));
  }

  onOperationalResponsibilityChanges(value) {
    if (value) {
      this.formGroup.get('end2End').enable({ onlySelf: true, emitEvent: false });
    }
  }

  // if nbm status === conditionaly approved -> condition enabled; else -> disabled
  checkNbmStatus(nbmStatus: number) {
    if (this.formGroup.get('condition')) {
      if (nbmStatus === 5) {
        this.enableField(this.formGroup.get('condition'), true, '');
      } else {
        this.disableField(this.formGroup.get('condition'), true, '');
      }
    }
  }

  onNbmStatusChange(nbmStatus: number) {
    this.checkNbmStatus(nbmStatus);
  }

  checkNewBusinessMeeting(newBusinessMeeting: boolean) {
    if (this.formGroup.get('nbmStatus')) {
      if (newBusinessMeeting) {
        this.enableField(this.formGroup.get('nbmStatus'), false);
      } else {
        this.disableField(this.formGroup.get('nbmStatus'), true, 7);
      }
    }
  }

  onNewBusinessMeetingChange(newBusinessMeeting: boolean) {
    this.checkNewBusinessMeeting(newBusinessMeeting);
  }

  onE2Echanges(value, role, responsibility) {
    this.formGroup.get('operationalRespId').enable({ onlySelf: true, emitEvent: false });
    if (value === '') {
      return;
    }
    if (value) {
      if (role === 'ISM') {
        this.snackbar.error(
          `E2E is not valid for this DC Responsible Role: ${role} and all Operational Responsibilities`
        );
      }
    } else {
      if ((role === 'ISM' && responsibility === 2) || (role === 'PDL' && responsibility === 1)) {
        this.snackbar.error(
          `Non E2E is not valid for this DC Responsible Role: ${role} and current Operational Responsibility`
        );
      }
    }
  }

  onSourcingLocationChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (
          item.id === 'sourcingLocationId' &&
          item instanceof ProjectCardItemEnumMultipleComponent
        ) {
          this.sourcingLocVal = value;
          return true;
        }
        return false;
      });
    }
  }

  onContactChanges(value, control) {
    if (control !== null) {
      value
        ? control.enable({ onlySelf: true, emitEvent: false })
        : control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  // TODO: REFACTOR should not use a global variable
  onCommitmentChanges(value) {
    if (value < 100) {
      this.commitmentValue = value;
    }
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonFieldsComponent } from '../common-fields/common-fields.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-mandatory-fields',
  templateUrl: './mandatory-fields.component.html',
  styleUrls: ['./mandatory-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class MandatoryFieldsComponent extends CommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: FormGroup;

  platformSubscription: any;
  locationSubscription: any;
  billCodeSubscription: any;

  ngOnInit() {
    super.ngOnInit();
    // if (this.expansionPanel) {
    //   this.expansionPanel.open();
    // }
    this.mandatory.valueChanges.subscribe(changes => this.onFormChanges(changes));
  }

  subscribeSpecialEnums() {
    this.platformSubscription = this.mandatory
      .get('platformId')
      .valueChanges.subscribe(value => this.onPlatformIdChange(value));
    this.locationSubscription = this.mandatory
      .get('locationId')
      .valueChanges.subscribe(value => this.onLocationChange(value));
    this.billCodeSubscription = this.mandatory
      .get('billcodeId')
      .valueChanges.subscribe(value => this.onBillCodeChange(value));
  }

  public unsubscribeSpecialEnums() {
    this.platformSubscription.unsubscribe();
    this.locationSubscription.unsubscribe();
    this.billCodeSubscription.unsubscribe();
  }

  onLocationChange(value) {
    if (value) {
      this.data['billCodesEnum'] = this.enums['bill-code'];

      const locationCountry = this.data['homeLocationEnum'].find(lcm => lcm.id === value);

      this.data['employeeActivityTypeEnum'] = null;
      this.data['employeeActivityFiltered'] = this.enums['employee-activity-type'].filter(
        val => val.deleted !== true
      );
      this.data['employeeActivityFiltered'] = this.data['employeeActivityFiltered'].filter(
        val => val.locationId === locationCountry.id_country
      );

      const idLocationsAscending = [
        ...new Set(
          this.data['employeeActivityFiltered'].map(lcm => lcm.billcodeId).sort((a, b) => a - b)
        ),
      ];

      this.data['billCodesEnum'] = this.data['billCodesEnum'].filter(
        val => idLocationsAscending.indexOf(val.id) !== -1
      );
      if (this.data['billCodesEnum'].length > 0) {
        this.mandatory.get('billcodeId').setValue(null);
      }
    }
  }

  onPlatformIdChange(value) {
    if (value) {
      this.data['domainEnum'] = this.enums['employee-domain'];
      this.data['domainEnum'] = this.data['domainEnum'].filter(
        val => val.platformId === value && val.deleted !== true
      );
      if (this.data['domainEnum'].length > 0) {
        this.mandatory.get('employeeDomainId').setValue(null);
      }
    }
  }

  onBillCodeChange(value) {
    if (value) {
      const billCode = this.enums['bill-code'].filter(val => val.id === value);
      if (billCode.length > 0) {
        const newCareerLevelId = billCode[0].newCarrerLevel;
        const newCareerLevel = this.enums['employee-new-career-level'].filter(
          val => val.id === newCareerLevelId
        );
        if (newCareerLevel.length > 0) {
          this.mandatory.get('newCareerLevelId').setValue(newCareerLevel[0].id);
        }
      }

      this.data['employeeActivityTypeEnum'] = this.data['employeeActivityFiltered'].filter(
        val => val.billcodeId === value
      );
      if (this.data['employeeActivityTypeEnum'].length === 1) {
        this.mandatory
          .get('employeeActivityTypeId')
          .setValue(this.data['employeeActivityTypeEnum'][0].id);
      } else {
        this.mandatory.get('employeeActivityTypeId').setValue(null);
      }
    }
  }

  onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.mandatory.controls[change];
      this.employeeDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
        this.expansionPanel.open();
      }
    });
  }
  populateDropdowns(enums) {
    super.populateDropdowns(enums);
    this.data['statusEnum'] = enums['employee-status'];
    this.data['newCareerLevelEnum'] = enums['employee-new-career-level'];
    this.data['platformEnum'] = enums['platform'].filter(value => value.deleted !== true);
    this.data['domainEnum'] = enums['domain'].filter(value => value.deleted !== true);
    this.data['universityEnum'] = enums['employee-university'];
    this.data['positionEnum'] = enums['employee-position'];
    this.data['englishEnum'] = enums['employee-english'];
    this.data['germanEnum'] = enums['employee-german'];
    this.data['homeLocationEnum'] = enums['employee-location'];
    this.data['travelEnum'] = enums['employee-travel'];
  }
}

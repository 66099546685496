import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
const dateFormat = 'MM/yyyy';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    // var formatString = 'MM/YYYY';
    return format(date, dateFormat);
  }
}

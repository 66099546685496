import { Component, OnInit } from '@angular/core';
import { employeeDetailsEnumMapping } from './../../../types/enum.mapping';
import { CardItemComponent } from './../card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-card-item-enum',
  templateUrl: './card-item-enum.component.html',
  styleUrls: [
    '../card-item.component.scss',
    './card-item-enum.component.scss',
    '../../../shared/card-item-enum.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class CardItemEnumComponent extends CardItemComponent implements OnInit {
  constructor(enumsService: EnumsService, snackbar: SnackbarPanelComponent) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();

    this.enumsService.getData().subscribe(enums => {
      this.enums = enums;
      this.isEnum = true;

      if (this.disabled) {
        this.control.get(this.id).disable({ onlySelf: true, emitEvent: false });
      }

      if (this.enums) {
        const enumName = employeeDetailsEnumMapping.get(this.id);

        if (enumName === 'employee-cost-centrum') {
          this.enumeration = this.enums[enumName].filter(element => {
            if (
              element.deleted == false &&
              element.workLocation === this.employeeDetails.locationId
            ) {
              return true;
            }
          });
        } else if (enumName === 'employee-type') {
          this.enumeration = this.enums[enumName].filter(element => {
            if (element.id !== 5 && element.id !== 6) {
              return element;
            }
          });
        } else {
          this.enumeration = this.enums[enumName];
        }

        if (this.enumeration == null) {
          console.error(`missing enum ${this.id}`);
        }
      }
    });
  }

  addDigitalUnit(platformValue, domainValue): number | void {
    if (platformValue && domainValue) {
      let digitUnitId = null;

      if (platformValue === 22 && domainValue === 86) {
        digitUnitId = 1;
      } else if (platformValue === 22 && domainValue === 189) {
        digitUnitId = 2;
      } else if (platformValue === 22 && domainValue === 192) {
        digitUnitId = 2;
      } else if (platformValue === 14 && domainValue === 190) {
        digitUnitId = 2;
      } else if (platformValue === 4 && domainValue === 187) {
        digitUnitId = 1;
      } else if (platformValue === 4 && domainValue === 43) {
        digitUnitId = 1;
      }

      if (digitUnitId) {
        return digitUnitId;
      }
    }

    return null;
  }

  changeDomains(platform: number, domainId?: number) {
    this.enumeration = this.enums['employee-domain'].filter(value => value.platformId === platform);
    if (this.editing) {
      if (this.enumeration.length > 0 && domainId == null) {
        this.control.get(this.id).setValue(this.enumeration[0].id);
      } else if (this.enumeration.length > 0 && domainId != null) {
        this.control.get(this.id).setValue(domainId);
      }
    } else if (this.enumeration.length > 0 && domainId != null) {
      this.control.get(this.id).setValue(domainId);
    }
  }

  changeBillCodes(value) {
    if (this.editing) {
      //const billCodesIds = [];
      const billCodesEnum = this.enums['bill-code'];

      const locationCountry = this.enums['employee-location'].find(lcm => lcm.id === value);
      let employeeActivityFiltered = this.enums['employee-activity-type'].filter(
        val => val.deleted !== true
      );
      employeeActivityFiltered = employeeActivityFiltered.filter(
        val => val.locationId === locationCountry.id_country
      );

      const idLocationsAscending = [
        ...new Set(employeeActivityFiltered.map(lcm => lcm.billcodeId).sort((a, b) => a - b)),
      ];

      this.enumeration = billCodesEnum.filter(val => idLocationsAscending.indexOf(val.id) !== -1);
      if (this.enumeration.length > 0) {
        this.control.get(this.id).setValue(null);
      }
    }
  }

  changeCarrierLevel(value) {
    if (this.editing) {
      const billCodesEnum = this.enums['bill-code'].filter(val => val.id === value);
      const enumeration = this.enums['employee-new-career-level'].filter(
        val => val.id === billCodesEnum[0].newCarrerLevel
      );
      if (this.enumeration !== null) {
        this.control.get(this.id).setValue(enumeration[0].id);
      }
    }
  }

  changeActivityType(value, location, activityType) {
    const locationCountry = this.enums['employee-location'].find(lcm => lcm.id === location);
    this.enumeration = this.enums['employee-activity-type'].filter(
      val =>
        val.billcodeId === value &&
        val.locationId === locationCountry.id_country &&
        val.deleted !== true
    );
    if (this.editing) {
      if (this.enumeration.length === 1) {
        this.control.get(this.id).setValue(this.enumeration[0].id);
      } else {
        //this.control.get(this.id).setValue(null);
      }
    } else {
      this.control.get(this.id).setValue(activityType);
    }
  }

  changeCostCenterModifiedEnum(employeeLocation) {
    this.enumeration = this.enums['employee-cost-centrum'].filter(
      val => val.workLocation == employeeLocation && val.deleted == false
    );
  }
}

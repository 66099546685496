import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { AppComponent } from '../../app.component';
import { fieldsSettings } from '../../types/confirm-resources-column-settings';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { errorMessages } from '../../types/input-errors';
import * as validationUtils from '../../shared/validators';
import { DatePipe } from '@angular/common';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-resources-open-demands',
  templateUrl: './resources-open-demands.component.html',
  styleUrls: [
    './resources-open-demands.component.scss',
    '../confirm-resources.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ResourcesOpenDemandsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() childMessage: string;

  dateFormatter = new DatePipe('en-US');
  dateFormat = 'MM/dd/yyyy';
  form: FormGroup;
  demandsDataSource = new MatTableDataSource();
  fieldsSettings = fieldsSettings;
  errors: { [key: string]: String } = {};
  displayedDemandsColumns = [
    'role',
    'platformName',
    'demandFte',
    'startDateDemand',
    'rollOffDateDemand',
    'careerLevel',
    'mySchedulingId',
    'name',
    'supplyFTE',
    'supplyFrom',
    'supplyTo',
    'notes',
  ];
  dateColumns = ['startDate', 'rollOffDate', 'from', 'to'];
  openDemandsSearchParamsOrder = [];
  openDemands: any;
  normalColor = '#000000';
  changedObject: any;
  errorMessages = errorMessages;

  constructor(
    public app: AppComponent,
    protected fb: FormBuilder,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit(): void {
    this.errors['supplyFTE'] = '(0, 1>';
    this.errors['supplyFrom'] = 'From < To';
    this.errors['supplyTo'] = 'From < To';
    this.form = this.fb.group({
      role: ['', []],
      platformName: ['', []],
      demandFte: ['', []],
      careerLevel: ['', []],
      mySchedulingId: ['', []],
      supplyFTE: ['', []],
      notes: ['', []],
      demandsForm: this.fb.array([]),
    });
  }

  ngAfterViewInit() {
    this.demandsDataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.childMessage.firstChange !== true) {
      this.emptyFormArray(this.form.get('demandsForm') as FormArray);
      this.openDemands = changes.childMessage.currentValue[0];
      this.demandsDataSource.data = this.transformData(
        this.createDataForOpenedDemandsTable(this.openDemands),
        this.normalColor,
        false,
        0
      );
      this.demandsDataSource.filterPredicate = this.openDemandsPredicate();
      this.changedObject = [];
      this.form.markAsPristine();
    }
  }

  createDataForOpenedDemandsTable(data) {
    // data must be ordered by iddemand
    const openedDemandsSupplies = [];
    for (let i = 0; i < data.length; i++) {
      if (i === 0 || data[i].iddemand !== data[i - 1].iddemand) {
        const demand = {
          iddemand: data[i].iddemand,
          role: data[i].role,
          platformName: data[i].platformName,
          demandFte: data[i].demandFte,
          startDateDemand: data[i].startDateDemand,
          rollOffDateDemand: data[i].rollOffDateDemand,
          careerLevel: data[i].careerLevel,
          mySchedulingId: data[i].mySchedulingID,
          supplies: [
            {
              idsupply: data[i].idsupply,
              name: this.getCorrectName(data[i].firstName, data[i].lastName),
            },
          ],
          supplyFTE: data[i].demandFte,
          supplyFrom: data[i].startDateDemand,
          supplyTo: data[i].rollOffDateDemand,
          notes: data[i].notes,
        };

        openedDemandsSupplies.push(demand);
      } else {
        const supply = {
          idsupply: data[i].idsupply,
          name: this.getCorrectName(data[i].firstName, data[i].lastName),
        };
        openedDemandsSupplies[openedDemandsSupplies.length - 1].supplies.push(supply);
      }
    }
    return openedDemandsSupplies;
  }

  transformData(demands, color, sort, type) {
    if (sort) {
      demands.sort((a, b) => a.lastName.localeCompare(b.lastName));
    }
    demands.forEach(demand => {
      this.addRowsToDemandsArray(demand.iddemand, demand.mySchedulingId);
      if (demand.startDateDemand) {
        demand.startDateDemand = this.dateFormatter.transform(
          demand.startDateDemand,
          this.dateFormat
        );
      }
      if (demand.rollOffDateDemand) {
        demand.rollOffDateDemand = this.dateFormatter.transform(
          demand.rollOffDateDemand,
          this.dateFormat
        );
      }
      if (demand.supplyFrom) {
        demand.supplyFrom = this.dateFormatter.transform(demand.supplyFrom, this.dateFormat);
      }
      if (demand.supplyTo) {
        demand.supplyTo = this.dateFormatter.transform(demand.supplyTo, this.dateFormat);
      }
      if (demand.firstName) {
        demand.name = this.getCorrectName(demand.firstName, demand.lastName);
      }
      demand.color = color;
    });
    return demands;
  }

  getCorrectName(firstName, lastName) {
    if (firstName !== null || firstName !== undefined) {
      if (firstName === lastName) {
        return firstName;
      } else {
        return lastName + ' ' + firstName;
      }
    }
  }

  addRowsToDemandsArray(demandId: String, mySchedulingId): void {
    const demandsArray: FormArray = this.form.get('demandsForm') as FormArray;
    const group = this.prepareSettingsDemands(demandId, mySchedulingId);
    group.get('supplyFrom').setValidators(validationUtils['valFromToDates'](group.get('supplyTo')));
    group.get('supplyTo').setValidators(validationUtils['valToFromDates'](group.get('supplyFrom')));
    group.get('supplyFTE').setValidators([validationUtils['valFlInterval']()]);
    group
      .get('mySchedulingId')
      .setValidators([validationUtils['valInteger'](), validationUtils['valMax10Digits']()]);
    demandsArray.push(group);
  }

  prepareSettingsDemands(idDemand: String, mySchedulingId): FormGroup {
    return this.fb.group({
      demandId: [idDemand, []],
      supplyId: [null, []],
      mySchedulingId: [{ value: mySchedulingId, disabled: true }],
      name: [null, []],
      supplyFTE: [{ value: null, disabled: true }],
      supplyFrom: [{ value: null, disabled: true }],
      supplyTo: [{ value: null, disabled: true }],
    });
  }

  nameChange(i: Number, row) {
    const group = (this.form.get('demandsForm') as FormArray).get(i.toString());
    if (row.iddemand === group.get('demandId').value) {
      this.setInputsDemands(i, row);
    } else {
      this.setInputsDemands(this.findDemandGroupByDemandId(row.iddemand), row);
    }
  }

  setInputsDemands(id, row) {
    const group = (this.form.get('demandsForm') as FormArray).get(id.toString());
    const supply = row.supplies.find(x => x.name === group.get('name').value);
    if (group.get('name').value) {
      group.get('supplyId').setValue(supply.idsupply);
      group.get('mySchedulingId').enable();
      group.get('supplyFTE').enable();
      group.get('supplyFrom').enable();
      group.get('supplyTo').enable();
    } else {
      this.resetDemandsFormArray(group);
    }
  }

  findDemandGroupByDemandId(id) {
    let j = 0;
    let final = 0;
    (this.form.get('demandsForm') as FormArray).controls.forEach(element => {
      if (element.get('demandId').value.toString() === id.toString()) {
        final = j;
      }
      j++;
    });
    return final;
  }

  // used to remember order of filter params
  addParamToOpenDemandsOrder(id: string) {
    // get index of search param in order of searched params
    const index = this.openDemandsSearchParamsOrder.indexOf(id);
    // if length of values for specific param is bigger than 0, it  is valid value
    if (this.form.get(id).value.trim().length > 0) {
      // if search param is already in order
      if (index !== -1) {
        // delete old position of param
        this.deleteParamFromOrder(this.openDemandsSearchParamsOrder, index);
        // add it to the end
        this.openDemandsSearchParamsOrder.push(id);
        // if param is not in order, add it to the end
      } else {
        this.openDemandsSearchParamsOrder.push(id);
      }
      // delete param from order if his values is not valid, its length is 0
    } else {
      this.deleteParamFromOrder(this.openDemandsSearchParamsOrder, index);
    }
  }

  deleteParamFromOrder(order: string[], index) {
    if (index !== -1) {
      order.splice(index, 1);
    }
  }

  applyFiltersOpenDemands() {
    this.resetDemandsFormArrays();
    if (!this.openDemandsSearchParamsOrder.length) {
      this.demandsDataSource.filter = '';
    } else {
      this.openDemandsSearchParamsOrder.forEach((key: string) => {
        this.demandsDataSource.filter = this.form.get(key).value;
      });
    }
  }

  resetDemandsFormArrays() {
    (this.form.get('demandsForm') as FormArray).controls.forEach(element => {
      this.resetDemandsFormArray(element);
      element.markAsPristine();
    });
  }

  resetDemandsFormArray(element) {
    element.get('supplyId').setValue(null);
    element.get('mySchedulingId').disable();
    element.get('name').setValue(null);
    element.get('supplyFTE').setValue(null);
    element.get('supplyFTE').disable();
    element.get('supplyFrom').setValue(null);
    element.get('supplyFrom').disable();
    element.get('supplyTo').setValue(null);
    element.get('supplyTo').disable();
    element.markAsPristine();
  }

  // setting filtering for columns
  openDemandsPredicate() {
    return (data: any, filter: string): boolean => {
      if (
        (data.role &&
          data.role
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('role').value.toString().trim().toLowerCase())) ||
        (data.platformName &&
          data.platformName
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('platformName').value.toString().trim().toLowerCase())) ||
        data.demandFte
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(this.form.get('demandFte').value.toString().trim().toLowerCase()) ||
        (data.careerLevel &&
          data.careerLevel
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('careerLevel').value.toString().trim().toLowerCase())) ||
        (data.mySchedulingID &&
          data.mySchedulingID
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('mySchedulingId').value.toString().trim().toLowerCase())) ||
        data.supplyFTE
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(this.form.get('supplyFTE').value.toString().trim().toLowerCase()) ||
        (data.notes &&
          data.notes
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('notes').value.toString().trim().toLowerCase()))
      ) {
        return false;
      } else {
        return true;
      }
    };
  }

  createArrayOfNames(row) {
    const names = [];
    names.push(null);
    row.supplies.forEach(element => {
      if (element.name) {
        names.push(element.name);
      }
    });
    return names;
  }

  save() {
    if (this.form.valid) {
      if (this.form.dirty) {
        (this.form.get('demandsForm') as FormArray).controls.forEach(element => {
          if (element.dirty) {
            this.changedObject.push(
              this.transformDatesForBackend(this.getUpdateObjectDemands(this.openDemands, element))
            );
          }
        });
      }
      return true;
    } else {
      return false;
    }
  }

  transformDatesForBackend(obj) {
    obj.startDateDemand = this.dateFormatter.transform(obj.startDateDemand, 'yyyy-MM-ddT00:mm:ss');
    obj.rollOffDateDemand = this.dateFormatter.transform(
      obj.rollOffDateDemand,
      'yyyy-MM-ddT00:mm:ss'
    );
    obj.supplyFrom = this.dateFormatter.transform(obj.supplyFrom, 'yyyy-MM-ddT00:mm:ss');
    obj.supplyTo = this.dateFormatter.transform(obj.supplyTo, 'yyyy-MM-ddT00:mm:ss');
    return obj;
  }

  getUpdateObjectDemands(values, element) {
    const tmp = values.find(
      x =>
        x.iddemand === element.get('demandId').value && x.idsupply === element.get('supplyId').value
    );
    const obj = Object.assign({}, tmp);
    obj.supplyFTE = this.setValueForUpdateObject(obj.demandFte, element.get('supplyFTE').value);
    obj.mySchedulingID = this.setValueForUpdateObject(
      obj.mySchedulingID,
      element.get('mySchedulingId').value,
      true
    );
    obj.supplyFrom = this.setValueForUpdateObject(
      obj.startDateDemand,
      element.get('supplyFrom').value
    );
    obj.supplyTo = this.setValueForUpdateObject(
      obj.rollOffDateDemand,
      element.get('supplyTo').value
    );
    return obj;
  }

  setValueForUpdateObject(object, element, mySchedulingId?) {
    let tmp;
    if (!element && !mySchedulingId) {
      tmp = object;
    } else {
      tmp = element;
    }
    return tmp;
  }

  emptyFormArray(array): void {
    while (array.length > 0) {
      array.removeAt(0);
    }
  }
}

import { EmployeeCard } from './card';

export const card_0: Array<EmployeeCard> = [
  {
    cardType: 'editable',
    id: 'seatStatus',
    label: 'Action Required',
    type: 'radio',
    validation: [],
  },
  {
    cardType: 'editable',
    id: 'buildingFloor',
    label: 'Building/Floor',
    type: 'enum',
    data: 'buildingEnum',
    validation: ['valRequired'],
  },
  { cardType: 'editable', id: 'seat', label: 'Seat', type: 'input', validation: ['valRequired'] },
  {
    cardType: 'editable',
    id: 'seatFrom',
    label: 'Seat From',
    type: 'date',
    validation: ['valRequired', 'valDate'],
  },
  {
    cardType: 'editable',
    id: 'seatTo',
    label: 'Seat To',
    type: 'date',
    validation: ['valRequired', 'valDate'],
  },
  { cardType: '', id: 'empFullName', label: 'Employee Name', type: '', validation: [] },
  { cardType: '', id: 'pdlComment', label: 'PDL Comment', type: '', validation: [] },
  { cardType: 'editable', id: 'awsComment', label: 'AWS Comment', type: 'input', validation: [] },
  { cardType: '', id: 'projectName', label: 'Project Name', type: '', validation: [] },
  { cardType: '', id: 'requestedBy', label: 'Requested By', type: '', validation: [] },
  { cardType: 'hidden', id: 'id', label: 'Id', type: '', validation: [] },
  { cardType: 'hidden', id: 'seatLabel', label: 'Seat', type: '', disabled: true, validation: [] },
];

export const card_1: Array<EmployeeCard> = [
  {
    cardType: 'editable',
    id: 'seatStatus',
    label: 'Action Required',
    type: 'radio',
    validation: [],
  },
  {
    cardType: '',
    id: 'buildingFloor',
    label: 'Building/Floor',
    type: 'enum',
    data: 'buildingEnum',
    validation: [],
  },
  { cardType: '', id: 'seat', label: 'Seat', type: 'input', validation: [] },
  { cardType: '', id: 'seatFrom', label: 'Seat From', type: 'date', validation: [] },
  { cardType: '', id: 'oldSeatTo', label: 'Old Seat To', type: 'date', validation: [] },
  {
    cardType: 'editable',
    id: 'seatTo',
    label: 'Seat To',
    type: 'date',
    validation: ['valRequired', 'valDate'],
  },
  { cardType: '', id: 'empFullName', label: 'Employee Name', type: '', validation: [] },
  { cardType: '', id: 'pdlComment', label: 'PDL Comment', type: '', validation: [] },
  { cardType: 'editable', id: 'awsComment', label: 'AWS Comment', type: 'input', validation: [] },
  { cardType: '', id: 'projectName', label: 'Project Name', type: '', validation: [] },
  { cardType: '', id: 'requestedBy', label: 'Requested By', type: '', validation: [] },
  { cardType: 'hidden', id: 'id', label: 'Id', type: '', validation: [] },
];

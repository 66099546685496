import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { HiringRequestsActiveComponent } from './hiring-requests-active/hiring-requests-active.component';
import { HiringRequestsHistoryComponent } from './hiring-requests-history/hiring-requests-history.component';

@Component({
  selector: 'app-hiring-requests',
  templateUrl: './hiring-requests.component.html',
  styleUrls: [
    './hiring-requests.component.scss',
    '../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class HiringRequestsComponent {
  @ViewChild('activeChild', { static: false }) activeChild: HiringRequestsActiveComponent;
  @ViewChild('historyChild', { static: false }) historyChild: HiringRequestsHistoryComponent;

  history: Boolean = false;

  constructor(private router: Router) {}

  changeHistoryState() {
    this.history = !this.history;
  }

  addNewRequest() {
    this.router.navigate(['/hiring-requests/add-new-request']).then();
  }
}

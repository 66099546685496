// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled {
  color: #a8a8a8 !important;
}

.mat-mdc-progress-spinner {
  margin: 125px auto;
}

.mat-paginator {
  display: flex;
  justify-content: center;
}

#projects {
  margin: 125px 25px 50px 25px;
  min-width: 700px;
}

mat-header-row {
  min-height: 35px;
}

mat-row {
  cursor: pointer;
  min-height: 35px;
  height: 35px;
}

.mat-mdc-table {
  cursor: pointer;
  overflow-x: auto;
}
.mat-mdc-table .mat-mdc-header-cell,
.mat-mdc-table .mat-mdc-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 14ch;
  padding: 8px;
}
.mat-mdc-table .mat-mdc-header-cell:nth-of-type(1),
.mat-mdc-table .mat-mdc-cell:nth-of-type(1) {
  max-width: 8ch;
  min-width: 8ch;
}
.mat-mdc-table .mat-mdc-header-cell:nth-of-type(5),
.mat-mdc-table .mat-mdc-cell:nth-of-type(5) {
  max-width: 13ch;
  min-width: 13ch;
}
.mat-mdc-table .mat-mdc-header-cell:nth-of-type(4),
.mat-mdc-table .mat-mdc-cell:nth-of-type(4) {
  max-width: 28ch;
}
.mat-mdc-table .mat-mdc-header-cell:nth-of-type(6), .mat-mdc-table .mat-mdc-header-cell:nth-of-type(7), .mat-mdc-table .mat-mdc-header-cell:nth-of-type(8), .mat-mdc-table .mat-mdc-header-cell:nth-of-type(9), .mat-mdc-table .mat-mdc-header-cell:nth-of-type(10),
.mat-mdc-table .mat-mdc-cell:nth-of-type(6),
.mat-mdc-table .mat-mdc-cell:nth-of-type(7),
.mat-mdc-table .mat-mdc-cell:nth-of-type(8),
.mat-mdc-table .mat-mdc-cell:nth-of-type(9),
.mat-mdc-table .mat-mdc-cell:nth-of-type(10) {
  max-width: 20ch;
}

::ng-deep .mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-form-field-infix label {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

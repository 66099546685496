import { Wizard } from './new-form';
export const fieldsSettingsWizard: Wizard = {
  mandatory: [
    {
      cardType: 'slider',
      id: 'reportingExceptionManual',
      label: 'Exception',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'levelsEnum',
      id: 'reportingLevelManual',
      label: 'Reporting Required on Level',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'reportingRequiredManual',
      label: 'Reporting Required',
      validation: ['valRequired'],
      values: ['', []],
    },
  ],
};

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fieldsSettings } from '../../types/new-demand';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { Common } from '../../shared/common';
import * as validationUtils from '../../shared/validators';
import { DemandDetails } from '../../types/project-demand';
import { DemandOptionalFieldsComponent } from './demand-optional-fields/demand-optional-fields.component';
import { DemandMandatoryFieldsComponent } from './demand-mandatory-fields/demand-mandatory-fields.component';
import { ProjectService } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../types/dialog-data';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-add-new-demand',
  templateUrl: './add-new-demand.component.html',
  styleUrls: ['../../shared/add-new.scss', './add-new-demand.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class AddNewDemandComponent implements OnInit {
  @ViewChild('optionalChild', { static: false })
  optionalChild: DemandOptionalFieldsComponent;
  @ViewChild('mandatoryChild', { static: false })
  mandatoryChild: DemandMandatoryFieldsComponent;

  newDemandForm: FormGroup;
  demandDetails = new DemandDetails();
  ids: Array<number>;
  toCopy: boolean;
  id: number;
  projectId: number;
  employeeDetailId: number;
  success: boolean;
  supplyDateTo = false;
  rollOffDateSubscription: any;
  suppliesWithSeats: Array<number>;
  dateFormatter = new DatePipe('en-US');
  dateFormat = 'YYYY-MM-dd';
  saving: boolean;

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    public snackbar: SnackbarPanelComponent,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  back() {
    this.snackbar.close();
    this.redirectToDemands(true);
  }

  ngOnInit() {
    this.snackbar.close();
    const [fieldFormMandatory, fieldFormOptional] = [new Object(), new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      if (setting.cardType !== 'text') {
        fieldFormOptional[setting.id] = setting.values;
      }
    });

    this.newDemandForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.toCopy = params['toCopy'];
      this.ids = params['ids'];
      this.projectId = params['projectId'];
      this.employeeDetailId = params['employeeDetailId'];
      if (this.id) {
        this.getDemandDetails(this.id);
      } else if (this.ids) {
        this.getDemandIntersection(this.ids);
      }
    });

    this.setValidators();
    this.setFields();
    this.subscribeSpecialEnums();

    Object.keys(this.newDemandForm.controls).forEach(group => {
      const groupControl = this.newDemandForm.get(group);
      Object.keys(groupControl['controls']).forEach(controler => {
        this.demandDetails[controler] = groupControl.get(controler).value;
      });
    });

    if (window.location.href.includes('opportunity')) {
      this.newDemandForm
        .get('mandatory')
        .get('probabilityId')
        .disable({ onlySelf: true, emitEvent: false });
    }
  }

  getDemandIntersection(ids) {
    this.projectService.getDemandIntersection(ids).subscribe(
      demandDetails => {
        this.handleDemandIntersection(demandDetails);
      },
      error => this.handleError(error)
    );
  }

  getDemandDetails(id) {
    this.projectService.getDemandDetails(id).subscribe(
      demandDetails => {
        this.handleDemandDetails(demandDetails);
      },
      error => this.handleError(error)
    );
  }

  handleDemandIntersection(demandDetails: any) {
    const form = this.newDemandForm;
    this.demandDetails = demandDetails;

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        if (this.demandDetails[controlerName] !== null) {
          groupControl.get(controlerName).setValue(this.demandDetails[controlerName], {
            onlySelf: true,
            emitEvent: true,
          });
        } else {
          groupControl.get(controlerName).disable({ onlySelf: true, emitEvent: false });
        }
      });
    });

    if (this.newDemandForm.get('mandatory').get('probabilityId').value == 100) {
      this.newDemandForm
        .get('mandatory')
        .get('probabilityId')
        .disable({ onlySelf: true, emitEvent: false });
    }
    this.newDemandForm
      .get('mandatory')
      .get('demandFte')
      .disable({ onlySelf: true, emitEvent: false });
  }

  handleDemandDetails(demandDetails: any) {
    const form = this.newDemandForm;
    this.demandDetails = demandDetails;

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        let part = null;

        fieldsSettings.mandatory.forEach(setting => {
          if (controlerName === setting.id) {
            // WTF is this 'part' ? it is never used
            part = setting.part;
          }
        });

        fieldsSettings.optional.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        if (part) {
          groupControl.get(controlerName).setValue(this.demandDetails[part][controlerName], {
            onlySelf: true,
            emitEvent: false,
          });
        } else {
          groupControl.get(controlerName).setValue(this.demandDetails[controlerName], {
            onlySelf: true,
            emitEvent: false,
          });
        }
      });
    });
    if (this.employeeDetailId) {
      this.newDemandForm
        .get('mandatory')
        .get('demandFte')
        .disable({ onlySelf: true, emitEvent: false });
    }
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.cardType !== 'text') {
          const validators = [];
          setting.validation.forEach(validation => {
            if (validation === 'valRequiredSkillLevel') {
              validators.push(validationUtils[validation](setting.id, this.newDemandForm));
            } else if (validation === 'valProlongationProbability') {
              validators.push(validationUtils[validation](setting.id, this.newDemandForm));
            } else {
              validators.push(validationUtils[validation]());
            }
          });
          if (this.id != null && setting.id === 'demandFte') {
            validators.push(validationUtils['valFlInterval']());
          }
          this.newDemandForm.get(set).get(setting.id).setValidators(validators);
        }
      });
    });
  }

  subscribeSpecialEnums() {
    this.rollOffDateSubscription = this.newDemandForm
      .get('mandatory')
      .get('rollOffDateDemand')
      .valueChanges.subscribe(value => this.onRollOffDateChange(value));
  }

  unsubscribeSpecialEnums() {
    this.rollOffDateSubscription.unsubscribe();
  }

  onRollOffDateChange(value) {
    const newRollOff = this.dateFormatter.transform(value, this.dateFormat);
    if (this.ids) {
      // edit multiple demands
      if (new Date(value) > new Date(this.demandDetails.rollOffDateDemand)) {
        this.projectService.getSeatingDateToShiftMultiple(this.ids, newRollOff).subscribe(
          res => this.handleSeatingDateToShift(res),
          error => this.handleError(error)
        );
      }
    } else {
      if (
        this.id &&
        this.employeeDetailId &&
        new Date(value) > new Date(this.demandDetails.rollOffDateDemand)
      ) {
        this.projectService
          .getSeatings(this.projectId)
          .subscribe(values => this.checkForSeatDate(values, value, newRollOff));
      }
    }
  }

  checkForSeatDate(valueArr, valueDate, newRollOff) {
    valueArr.forEach(element => {
      if (element.employeeDetailId == this.employeeDetailId) {
        if (new Date(element.seatTo) < new Date(valueDate)) {
          this.projectService
            .getSeatingDateToShift(this.demandDetails.id, this.employeeDetailId, newRollOff)
            .subscribe(
              res => this.handleSeatingDateToShift(res),
              error => this.handleError(error)
            );
        }
      }
    });
  }

  handleSeatingDateToShift(response) {
    if (response) {
      const data: DialogData = {
        title: 'Seating alignment',
        content:
          '<div>Do you want to align seat end date according to demand roll-off-date?</div>' +
          '<div>If no, you have to do changes manually in Seating & HW tab.</div>',
        action1: 'Yes',
        action2: 'No',
      };
      const dialogRef = this.dialog.open(DialogComponent, { data });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.supplyDateTo = result;
        } else {
          this.supplyDateTo = false;
        }
      });
    }
  }

  rateTypeCheck(): Observable<boolean> {
    const demandRateTypeId = this.newDemandForm.get('mandatory').get('demandRateTypeId').value;
    const rollOffDateDemand = this.newDemandForm.get('mandatory').get('rollOffDateDemand').value;
    const startDateDemand = this.newDemandForm.get('mandatory').get('startDateDemand').value;
    const daysBetween = Math.ceil(
      (new Date(rollOffDateDemand).getTime() - new Date(startDateDemand).getTime()) /
        (1000 * 3600 * 24)
    );

    // long term
    if (daysBetween > 365 && demandRateTypeId === 1) {
      const data: DialogData = {
        title: 'Warning',
        content: 'Rate Type is Short Term but demand duration is more than one year!',
        action1: 'OK',
      };
      return Common.rateTypeWarningDialog(this.dialog, data);
      // short term
    } else if (daysBetween <= 365 && demandRateTypeId === 2) {
      const data: DialogData = {
        title: 'Warning',
        content: 'Rate Type is Long Term but demand duration is less than one year!',
        action1: 'OK',
      };
      return Common.rateTypeWarningDialog(this.dialog, data);
    } else {
      return of(false);
    }
  }

  save() {
    this.saving = true;
    // this.rateTypeCheck().subscribe(result => {
    let roleSuffix = 1;
    let role = '';
    let demandCnt = 0;
    const form = this.newDemandForm;
    const newDemand = {};

    this.mandatoryChild.unsubscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.unsubscribeSpecialEnums();
    }

    this.unsubscribeSpecialEnums();

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        const controler = groupControl.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        // values can be set to null
        if (controler.enabled /* && controler.value !== null */) {
          if (controler.value instanceof Date) {
            newDemand[controlerName] = Common.timezoneFix(controler.value);
          } else {
            newDemand[controlerName] = controler.value;
          }
        }
      });
    });

    this.mandatoryChild.subscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.subscribeSpecialEnums();
    }

    this.subscribeSpecialEnums();

    if (form.valid) {
      if (this.toCopy) {
        this.projectService.copyDemand(this.id, newDemand).subscribe(
          res => {
            this.handleSucces(res);
          },
          err => {
            this.handleError(err);
          }
        );
      } else if (this.ids) {
        this.projectService.editDemands(this.ids, newDemand).subscribe(
          res => {
            this.handleSucces(res);
          },
          err => {
            this.handleError(err);
          }
        );
      } else if (this.id) {
        this.projectService.editDemands([this.id], newDemand).subscribe(
          res => {
            this.handleSucces(res);
          },
          err => {
            this.handleError(err);
          }
        );
      } else {
        newDemand['projectId'] = this.projectId;
        role = newDemand['role'];
        demandCnt = newDemand['demandFte'];
        while (demandCnt > 0) {
          newDemand['role'] = demandCnt > 1 ? role + ' ' + roleSuffix++ : role;
          newDemand['demandFte'] = demandCnt < 1 ? demandCnt : 1;
          this.projectService.addDemand(this.projectId, newDemand).subscribe(
            res => {
              this.handleSucces(res);
            },
            err => {
              this.handleError(err);
            }
          );
          demandCnt -= 1;
        }
      }
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
    }
    // });
  }

  handleSucces(response) {
    this.saving = false;
    if (this.ids) {
      if (this.supplyDateTo) {
        this.snackbar.success('Demand updated');

        const data = {
          rollOffDateDemand: Common.timezoneFix(this.demandDetails.rollOffDateDemand),
        };

        this.projectService.shiftSeatingsDateTo(this.ids, data).subscribe(
          res => this.handleShiftSeatingDateTo(),
          error => this.handleError(error)
        );
      } else {
        this.snackbar.success('Demands updated - redirecting to demands');
        this.redirectToDemands();
      }
    } else if (this.toCopy) {
      this.snackbar.success('Demand copied - redirecting to demands');
      this.redirectToDemands();
    } else if (this.id) {
      if (this.supplyDateTo) {
        this.snackbar.success('Demand updated');

        const data = {
          rollOffDateDemand: Common.timezoneFix(this.demandDetails.rollOffDateDemand),
        };

        this.projectService
          .shiftSeatingDateTo(this.demandDetails.id, this.employeeDetailId, data)
          .subscribe(
            res => this.handleShiftSeatingDateTo(),
            error => this.handleError(error)
          );
      } else {
        this.snackbar.success('Demand updated - redirecting to demands');
        this.redirectToDemands();
      }
    } else {
      this.snackbar.success('Demand created - redirecting to demands');
      this.redirectToDemands();
    }
  }

  handleShiftSeatingDateTo() {
    this.snackbar.success('Seating end date updated - redirecting to demands');
    this.redirectToDemands();
  }

  checkIfTouched(backButton): Observable<boolean> {
    if (backButton === undefined) {
      return of(true);
    }
    const form = this.newDemandForm;
    let touched;

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        if (groupControl.get(controlerName).touched) {
          touched = true;
        }
      });
    });

    if (touched && !this.success) {
      return Common.editWarningDialog(this.dialog);
    } else {
      return of(true);
    }
  }

  redirectToDemands(backButton?) {
    this.checkIfTouched(backButton).subscribe(result => {
      if (result) {
        this.success = true;

        setTimeout(() => {
          if (window.location.href.includes('opportunity')) {
            this.router.navigate(['/opportunity-details', this.projectId], {
              fragment: 'demands',
            });
          } else {
            this.router.navigate(['/project-details', this.projectId], {
              fragment: 'demands',
            });
          }
        }, 1500);
      }
    });
  }

  handleError(error: Response) {
    this.saving = false;
    const body = error;
    let errorMessage;

    if (body['error']) {
      const bodyError = body['error'];
      if (bodyError[0].field === 'rollOffDateDemand') {
        errorMessage = 'Demand Roll-off Date cannot be before Start Date.';
      } else {
        errorMessage =
          'Could not create Demand: ' +
          (bodyError[0].field === 'dcso' ? bodyError[0].field.toUpperCase() : bodyError[0].field) +
          ' ' +
          bodyError[0].code;
      }
    } else {
      errorMessage = 'Wrong input values';
    }

    this.snackbar.error(errorMessage);
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newDemandForm.get(set).get(setting.id).disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }
}

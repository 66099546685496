import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'filterSelectedEnums' })
export class FilterSelectedEnums implements PipeTransform {
  transform(enumeration: Array<any>, values: Array<Number>): Array<any> {
    if (!values || !enumeration) {
      return enumeration;
    }
    // Filter only selected enums
    return enumeration.filter(item => values.indexOf(item.id) !== -1);
  }
}

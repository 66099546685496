import { Injectable } from '@angular/core';

import { REST } from './../../rest.endpoints';
import { BaseService } from './base.service';
import { delay, take, retryWhen, share, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class CounseleesSearchService extends BaseService {
  protected requestData(): void {
    this.request = this.http.get(REST.allCounselees).pipe(
      distinctUntilChanged(),
      retryWhen(errors => errors.pipe(delay(this.reloadDelay), take(3), this.handleError)),
      share()
    );
  }
}

import { catchError, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { REST } from './../../rest.endpoints';
import { BaseService } from './base.service';

@Injectable()
export class SuppliesService extends BaseService {
  protected requestData(enterpriseDomainId): void {
    this.request = this.http
      .get(REST.employeeDetailsSupplies(enterpriseDomainId))
      .pipe(catchError(this.handleError), share());
  }

  // request stay saved after looking for another employee so we have to call requestdata eatch time
  public getData(param?: any): Observable<any> {
    this.requestData(param);

    if (this.data == null && this.request != null) {
      return this.request;
    }

    return of(this.data);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as validationUtils from '../../shared/validators';
import { errorMessages } from '../../types/input-errors';
import { SnackbarPanelComponent } from '../snackbar-panel/snackbar-panel.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss'],
})
export class CommonComponent implements OnInit {
  form: FormGroup;
  fieldsSettings = [];
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  data = {};

  constructor(
    protected snackbar: SnackbarPanelComponent,
    protected fb: FormBuilder,
    protected authService: AuthService,
    protected router: Router,
    protected location: Location
  ) {}

  ngOnInit() {
    const fields = {};
    this.fieldsSettings.forEach(setting => {
      fields[setting.id] = setting.values;
    });

    this.form = this.fb.group(fields);
    this.setValidators();
    this.setFields();
    this.form.valueChanges.subscribe(changes => this.onFormChanges(changes));
  }

  protected setFields() {
    this.fieldsSettings.forEach(setting => {
      if (setting.disabled) {
        this.form.get(setting.id).disable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  protected setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        validators.push(validationUtils[validation]());
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  protected onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.form.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  protected handleError(error: Response | string) {
    const errorText = error instanceof HttpErrorResponse ? error.error.message : error;
    this.snackbar.error(errorText);
  }

  protected filterStates(searchedValue: string, field: string) {
    return this.data[field].filter(
      value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1
    );
  }
}

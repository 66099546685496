import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'empType' })
export class EmpType implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Career Counselor':
        value = 'CC';
        break;
      default:
        value = value[0].toUpperCase();
        break;
    }
    return value;
  }
}

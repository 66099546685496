import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar-panel/custom-snackbar-panel.component';

@Component({
  selector: 'app-snackbar-panel',
  templateUrl: './snackbar-panel.component.html',
  styleUrls: ['./snackbar-panel.component.scss'],
})
export class SnackbarPanelComponent {
  protected hideTime = 3000;

  constructor(public snackBar: MatSnackBar) {}

  openCustomSnackbar(message: string, panelClass: string[], duration: number) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message },
      panelClass,
      duration,
    });
  }

  close() {
    this.snackBar.dismiss();
  }

  success(message: string, time?: number) {
    this.openCustomSnackbar(message, ['success-message', 'material-icons'], time || this.hideTime);
  }

  info(message: string, time?: number) {
    this.openCustomSnackbar(message, ['info-message', 'material-icons'], time || this.hideTime);
  }

  warning(message: string, time?: number) {
    this.openCustomSnackbar(message, ['warning-message', 'material-icons'], time || this.hideTime);
  }

  error(message: string, time?: number) {
    this.openCustomSnackbar(message, ['error-message', 'material-icons'], time || 0);
  }
}

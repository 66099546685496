// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forms form:not(:first-child) {
  padding-top: 30px !important;
}

.forms form:last-child {
  padding-bottom: 100px !important;
}

.card-header {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 16px;
}

.add-form-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}

.remove-form-button {
  position: absolute;
  top: 0;
  right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

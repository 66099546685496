import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'cc',
    label: 'sample.email1@accenture.com; sample.email2@accenture.com',
    validation: ['valEmails'],
    values: ['', []],
  },
  {
    cardType: 'enum',
    id: 'hardlockState',
    label: 'State',
    data: 'hardlockStateEnum',
    validation: [],
    values: ['', []],
  },
];

/**
 * String which is displayed when employee name is hashed
 * @constant
 */
export const HASHED_EMPLOYEE_STRING = 'hashed value (GDPR)';

/**
 * String which is displayed on hover on Service Groups div
 * @constant
 */
export const ServiceGroupsTooltipText: string = `AMS: Application Managed Services
    SI: System Integration
    IMS: Infrastructure Manage Services
    CON: Consulting
    SC: Strategy Consulting
    BPMS: Business Process Managed Services`;

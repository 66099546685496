import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  AfterViewInit,
  Injectable,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectService } from '../../../services/project.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-review-approve-dialog-new-approach',
  templateUrl: './review-approve-dialog-new-approach.component.html',
  styleUrls: [
    './review-approve-dialog-new-approach.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class ReviewApproveDialogNewApproachComponent implements OnInit, AfterViewInit {
  @ViewChild('table1', { read: MatSort, static: false }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: false }) sort2: MatSort;
  @ViewChild('table3', { read: MatSort, static: false }) sort3: MatSort;
  @ViewChild('table4', { read: MatSort, static: false }) sort4: MatSort;
  @ViewChild('table5', { read: MatSort, static: false }) sort5: MatSort;
  @ViewChild('table6', { read: MatSort, static: false }) sort6: MatSort;

  DummyDataSource = {
    seatFixedDataSource: [
      { info: 'Bratislava 5', seatDays: 20 },
      { info: 'Bratislava 4', seatDays: 20 },
    ],
    seatFlexibleDataSource: [
      { info: 'Plynarenska Office', days: 'Mon, Wed', seatCnt: 2, seatDays: 16 },
      { info: 'Plynarenska Office', days: 'Tue, Thu', seatCnt: 4, seatDays: 32 },
    ],
    seatByWeekDataSource: [
      [
        {
          type: 'Fixed Seats',
          week1: 48,
          week2: 120,
          week3: 120,
          week4: 120,
          week5: null,
          week6: null,
        },
        {
          type: 'Flexible Seats',
          week1: 60,
          week2: 150,
          week3: 150,
          week4: 150,
          week5: 120,
          week6: 33,
        },
      ],
      [
        {
          type: 'Fixed Seats',
          week1: 48,
          week2: 130,
          week3: 130,
          week4: 130,
          week5: 96,
          week6: 30,
        },
        {
          type: 'Flexible Seats',
          week1: 60,
          week2: 160,
          week3: 160,
          week4: 160,
          week5: 120,
          week6: 80,
        },
      ],
    ],
  };

  weekCnt = 0;

  seatFixedDisplayedColumns = ['info', 'seatDays', 'wbs'];
  seatFixedDataSource = new MatTableDataSource();

  seatFlexibleDisplayedColumns = ['info', 'days', 'seatCnt', 'seatDays', 'wbs'];
  seatFlexibleDataSource = new MatTableDataSource();

  seatByWeekDisplayedColumns = ['type', 'week1', 'week2', 'week3', 'week4', 'week5', 'week6'];
  seatByWeekDataSource = new MatTableDataSource();
  seatByWeekData: any[] = [];

  hardwareDesktopsDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareDesktopsDataSource = new MatTableDataSource();

  hardwareNotebooksDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareNotebooksDataSource = new MatTableDataSource();

  @Input() form: FormGroup;

  seatsHardwareLoaded;
  projectId: number;
  eid: string;
  wbs: string;
  seatsCost = 0;
  hardwareNotebooksCost = 0;
  hardwareDesktopsCost = 0;
  totalCost = 0;
  seatsWbs: Array<Object>;
  hardwareNotebooksWbs: Array<Object>;
  hardwareDesktopsWbs: Array<Object>;
  month: any;
  year: any;
  validity = false;
  loadedData: any;
  approved: Boolean;
  isDisabled: boolean;
  data = {};
  allWbs: String[] = [];
  allWeeklyCost: Number[] = [];
  hasEmptyWbs: boolean = false;
  hasEmptyWbsHw: boolean = false;
  hasEmptyWbsDesc: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<ReviewApproveDialogNewApproachComponent>,
    private projectService: ProjectService,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.projectId = this.dialogData.projectId;
    this.eid = this.dialogData.eid;

    this.form = this.fb.group({
      month: ['', []],
    });

    this.form.get('month').valueChanges.subscribe(value => this.onMonthChange(value));

    const d = new Date();
    d.setDate(1);
    const actualMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const actualYear = d.getFullYear();

    d.setMonth(d.getMonth() - 1);

    const previousMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const previousYear = d.getFullYear();

    d.setMonth(d.getMonth() + 2);

    const nextMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const nextYear = d.getFullYear();
    this.data['month'] = [
      {
        id: 0,
        text: previousMonth + ' / ' + previousYear + ' (reporting period)',
      },
      {
        id: 1,
        text: actualMonth + ' / ' + actualYear,
      },
      {
        id: 2,
        text: nextMonth + ' / ' + nextYear,
      },
    ];

    this.getInitDate(this.projectId);
  }

  ngAfterViewInit() {
    this.seatFixedDataSource.sort = this.sort1;
    this.seatFlexibleDataSource.sort = this.sort4;
    this.seatByWeekDataSource.sort = this.sort6;
    this.hardwareNotebooksDataSource.sort = this.sort2;
    this.hardwareDesktopsDataSource.sort = this.sort3;
  }

  getInitDate(id) {
    this.projectService.getApprovalDate(id).subscribe(
      response => this.handleReviewApprovalDate(response),
      error => this.handleError(error)
    );
  }

  getSeatsHardware(month, year) {
    this.seatsHardwareLoaded = false;

    this.projectService.getReviewSeatingsHardware(this.projectId, month, year).subscribe(
      response => this.handleSeatsHardware(response),
      error => this.handleError(error)
    );
  }

  private handleSeatsHardware(data: any): void {
    this.loadedData = data;
    if (data.isApproved) {
      this.approved = data.isApproved;
      this.totalCost = data.totalSum;
      this.validity = data.validity;
      this.seatsHardwareLoaded = true;
      return;
    }
    this.approved = false;

    this.seatsHardwareLoaded = true;
    this.seatFixedDataSource.data = data.fixedSeatCharges; //data.seatingCharges;
    this.seatFlexibleDataSource.data = data.flexibleSeatCharges;
    this.seatByWeekDisplayedColumns = [
      'type',
      'week1',
      'week2',
      'week3',
      'week4',
      'week5',
      'week6',
    ];

    this.seatsCost = 0;
    this.seatByWeekData = [];
    this.allWeeklyCost = [];
    this.hasEmptyWbs = false;
    let tmpWeekCharge = [
      {
        type: 'Flexible Seats',
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
        week6: 0,
        totalCost: 0,
      },
      {
        type: 'Fixed Seats',
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
        week6: 0,
        totalCost: 0,
      },
    ];
    data.weeklySeatCharges.forEach(data => {
      if (
        (data[0].wbs === '' || data[0].wbs === null) &&
        (data[1].wbs === '' || data[1].wbs === null)
      )
        this.hasEmptyWbs = true;

      this.allWbs.push(data[0].wbs);

      this.allWeeklyCost.push(data[0].totalCost + data[1].totalCost);
      let seatByWeekDataSource = new MatTableDataSource();
      // data[0].week1 = data[0].week1 != undefined || null ? (data[0].week1 + 0).toFixed(2) : null;
      // data[0].week2 = data[0].week2 != undefined || null ? (data[0].week2 + 0).toFixed(2) : null;
      // data[0].week3 = data[0].week3 != undefined || null ? (data[0].week3 + 0).toFixed(2) : null;
      // data[0].week4 = data[0].week4 != undefined || null ? (data[0].week4 + 0).toFixed(2) : null;
      // data[0].week5 = data[0].week5 != undefined || null ? (data[0].week5 + 0).toFixed(2) : null;
      // data[0].week6 = data[0].week6 != undefined || null ? (data[0].week6 + 0).toFixed(2) : null;
      // data[1].week1 = data[1].week1 != undefined || null ? (data[1].week1 + 0).toFixed(2) : null;
      // data[1].week2 = data[1].week2 != undefined || null ? (data[1].week2 + 0).toFixed(2) : null;
      // data[1].week3 = data[1].week3 != undefined || null ? (data[1].week3 + 0).toFixed(2) : null;
      // data[1].week4 = data[1].week4 != undefined || null ? (data[1].week4 + 0).toFixed(2) : null;
      // data[1].week5 = data[1].week5 != undefined || null ? (data[1].week5 + 0).toFixed(2) : null;
      // data[1].week6 = data[1].week6 != undefined || null ? (data[1].week6 + 0).toFixed(2) : null;
      seatByWeekDataSource.data = data;
      this.seatByWeekData.push(seatByWeekDataSource);
    });
    // this.seatByWeekDataSource.data = data.weeklySeatCharges;
    this.hardwareNotebooksDataSource.data = data.notebooksCharges.concat(data.ignoredNotebooks);
    this.hardwareDesktopsDataSource.data = data.desktopsCharges.concat(data.ignoredDesktops);

    //get number of weeks to show
    if (
      data.weeklySeatCharges !== undefined &&
      data.weeklySeatCharges !== null &&
      data.weeklySeatCharges.length > 0
    ) {
      const weekFlex =
        data.weeklySeatCharges[0][0].week6 !== null
          ? 6
          : data.weeklySeatCharges[0][0].week5 !== null
            ? 5
            : 4;
      const weekFix =
        data.weeklySeatCharges[0][1].week6 !== null
          ? 6
          : data.weeklySeatCharges[0][1].week5 !== null
            ? 5
            : 4;
      this.weekCnt = weekFlex > weekFix ? weekFlex : weekFix;
    }

    this.seatByWeekDisplayedColumns = this.seatByWeekDisplayedColumns.slice(0, this.weekCnt + 1);
    this.seatsWbs = [];

    this.hardwareDesktopsWbs = [];

    Object.keys(data.groupedDesktopsSums).forEach(key => {
      this.hardwareDesktopsWbs.push({
        name: key,
        sum: data.groupedDesktopsSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
      if (key === 'WBS not set') this.hasEmptyWbsDesc = true;
      if (key !== 'WBS not set') this.allWbs.push(key);
    });

    this.hardwareNotebooksWbs = [];

    Object.keys(data.groupedNotebooksSums).forEach(key => {
      this.hardwareNotebooksWbs.push({
        name: key,
        sum: data.groupedNotebooksSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
      if (key === 'WBS not set') this.hasEmptyWbsHw = true;
      if (key !== 'WBS not set') this.allWbs.push(key);
    });

    this.seatsCost = data.seatingsSum;
    this.hardwareNotebooksCost = data.notebooksSum;
    this.hardwareDesktopsCost = data.desktopsSum;
    this.totalCost = data.totalSum;
    this.validity = data.validity;
    var canBeValid = true;
    if (this.hasEmptyWbs) {
      if (this.seatsCost === 0 && canBeValid) this.validity = true;
      else {
        this.validity = false;
        canBeValid = false;
      }
    }
    if (this.hasEmptyWbsHw) {
      if (this.hardwareNotebooksCost === 0 && canBeValid) this.validity = true;
      else {
        var stillValid = true;
        this.hardwareNotebooksWbs.forEach(hw => {
          if (hw['wbsnotset'] && hw['sum'] !== 0) stillValid = false;
        });
        this.validity = stillValid;
        canBeValid = stillValid;
      }
    }
    if (this.hasEmptyWbsDesc) {
      if (this.hardwareDesktopsCost === 0 && canBeValid) this.validity = true;
      else {
        var stillValid = true;
        this.hardwareDesktopsWbs.forEach(hw => {
          if (hw['wbsnotset'] && hw['sum'] !== 0) stillValid = false;
        });
        this.validity = stillValid;
        canBeValid = stillValid;
      }
    }

    if (this.allWbs.length === 0) this.validity = false;
    if (this.totalCost === 0) this.validity = true;
  }

  handleReviewApprovalDate(data) {
    const format = new Date(data.reviewDate);
    this.month =
      format.getMonth() + 1 < 10
        ? '0' + (format.getMonth() + 1).toString()
        : (format.getMonth() + 1).toString();
    this.year = format.getFullYear().toString();
    const date = this.month + ' / ' + this.year;
    this.data['month'].forEach(element => {
      if (element.text.substring(0, 9) === date) {
        this.form.get('month').setValue(element.id, { emitEvent: true });
      }
    });
    this.getSeatsHardware(this.month, this.year);
  }

  private handleError(error) {
    this.snackbar.error('problem with approving');
  }

  onMonthChange(value) {
    this.data['month'].forEach(val => {
      if (val.id === value) {
        this.month = val.text.substring(0, 2);
        this.year = val.text.substring(5, 9);

        this.getSeatsHardware(this.month, this.year);
      }
    });
  }

  handleApproveSeatsHardware(response) {
    this.isDisabled = false;
    this.snackbar.success('Seats and hardware has been aproved');
    this.dialogRef.close(true);
  }

  create(create: boolean) {
    if (create) {
      this.isDisabled = create;
      this.projectService.approveSeatsHardware(this.projectId, this.loadedData).subscribe(
        response => this.handleApproveSeatsHardware(response),
        error => this.handleError(error)
      );
    } else {
      this.isDisabled = false;
      this.dialogRef.close(false);
    }
  }
}

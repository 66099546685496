import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { fieldsSettings } from '../../../types/new-opportunity';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { Params } from '@angular/router';
import { Common } from '../../../shared/common';
import * as validationUtils from '../../../shared/validators';
import { ProjectDetails } from '../../../types/project-details';
import { AddNewProjectComponent } from '../add-new-project.component';
import { OpportunityMandatoryFieldsComponent } from '../opportunity-mandatory-fields/opportunity-mandatory-fields.component';
import { OpportunityOptionalFieldsComponent } from '../opportunity-optional-fields/opportunity-optional-fields.component';
import { leadOpportunityMapping } from '../../../types/lead-to-opportunity.mapping';

@Component({
  selector: 'app-add-new-opportunity',
  templateUrl: './add-new-opportunity.component.html',
  styleUrls: [
    '../../../shared/add-new.scss',
    './add-new-opportunity.component.scss',
    '../add-new-project.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AddNewOpportunityComponent extends AddNewProjectComponent implements OnInit {
  @ViewChild('optionalChild', { static: false })
  declare optionalChild: OpportunityOptionalFieldsComponent;
  @ViewChild('mandatoryChild', { static: false })
  declare mandatoryChild: OpportunityMandatoryFieldsComponent;

  projectDetails = new ProjectDetails();
  newBussinessMeetingSubscription: any;
  nbmStatusSubscription: any;
  saving = false;

  back() {
    this.snackbar.close();
    this.redirectToAddNewProjectView(this.projectId);
  }

  ngOnInit() {
    this.snackbar.close();
    const [fieldFormMandatory, fieldFormOptional] = [new Object(), new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
      const searchedId = params['projectId'];
      if (searchedId != null) {
        this.evaluateParams(params, fieldFormMandatory, fieldFormOptional);
      } else {
        this.newProjectForm = this.fb.group({
          mandatory: this.fb.group(fieldFormMandatory),
          optional: this.fb.group(fieldFormOptional),
        });

        this.setValidators();
        this.setFields();
        this.subscribeSpecialEnums();

        Object.keys(this.newProjectForm.controls).forEach(group => {
          const groupControl = this.newProjectForm.get(group);
          Object.keys(groupControl['controls']).forEach(controler => {
            this.projectDetails[controler] = groupControl.get(controler).value;
          });
        });

        this.setNbmCondtitions();
        this.disableField(this.newProjectForm.get('mandatory').get('subIndustryId'), true);
      }
    });
  }

  setNbmCondtitions() {
    this.checkNewBusinessMeeting(
      Boolean(this.newProjectForm.get('mandatory').get('newBusinessMeeting').value)
    );
    this.checkNbmStatus(Number(this.newProjectForm.get('mandatory').get('nbmStatus').value), true);
  }

  getProjectDetails(searchedId, fieldFormMandatory, fieldFormOptional) {
    this.projectService.getProjectDetails(searchedId).subscribe(
      response => {
        this.handleProjectDetailsSuccess(response, fieldFormMandatory, fieldFormOptional);
      },
      error => this.handleProjectDetailsError(error)
    );
  }

  evaluateParams(params: Params, fieldFormMandatory, fieldFormOptional): void {
    const searchedId = params['projectId'];
    if (searchedId != null) {
      this.getProjectDetails(searchedId, fieldFormMandatory, fieldFormOptional);
    }
  }

  handleProjectDetailsSuccess(projectDetails: any, fieldFormMandatory, fieldFormOptional): void {
    this.projectDetails = projectDetails;
    this.projectDetails.reqQualification['dcResponsible'] =
      this.projectDetails.reqQualification['dcResponsible'].toString() +
      ' (' +
      this.projectDetails.reqQualification['dcResponsibleEid'].toString() +
      ')';

    leadOpportunityMapping.mandatory.map(map => {
      if (typeof this.projectDetails[map.class][map.atribute] === 'object') {
        // type select-multi(e.g. Sourcing Location) needs array ... why ?
        fieldFormMandatory[map.id] = [this.projectDetails[map.class][map.atribute]];
      } else {
        fieldFormMandatory[map.id] = this.projectDetails[map.class][map.atribute];
      }
    });

    leadOpportunityMapping.optional.map(map => {
      fieldFormOptional[map.id] = this.projectDetails[map.class][map.atribute];
    });

    this.newProjectForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.setValidators();
    this.setFields();
    this.subscribeSpecialEnums();

    Object.keys(this.newProjectForm.controls).forEach(group => {
      const groupControl = this.newProjectForm.get(group);
      Object.keys(groupControl['controls']).forEach(controler => {
        this.projectDetails[controler] = groupControl.get(controler).value;
      });
    });

    this.setNbmCondtitions();
  }

  subscribeSpecialEnums() {
    this.newBussinessMeetingSubscription = this.newProjectForm
      .get('mandatory')
      .get('newBusinessMeeting')
      .valueChanges.subscribe(value => this.onNewBusinessMeetingChange(value));
    this.nbmStatusSubscription = this.newProjectForm
      .get('mandatory')
      .get('nbmStatus')
      .valueChanges.subscribe(value => this.onNbmStatusChange(value));
    this.industrySubscription = this.newProjectForm
      .get('mandatory')
      .get('clientGroupId')
      .valueChanges.subscribe(value => this.onIndustryChange(true));
    this.e2eSubscription = this.newProjectForm
      .get('mandatory')
      .get('end2End')
      .valueChanges.subscribe(value => this.onE2EChange(value));
    this.operationalRespSubscription = this.newProjectForm
      .get('mandatory')
      .get('operationalRespId')
      .valueChanges.subscribe(value => this.onOperationalRespChange(value));
    this.commitmentSubscription = this.newProjectForm
      .get('mandatory')
      .get('commitment')
      .valueChanges.subscribe(value => this.onCommitmentChange(value));
    this.workCategorySubscription = this.newProjectForm
      .get('mandatory')
      .get('workCategoryId')
      .valueChanges.subscribe(value => this.onWorkCategoryChange(value));
  }

  // Removing validation from specific fields
  clearValidationForRejected(fields: AbstractControl[]) {
    fields.forEach(field => {
      field.clearValidators();
      field.updateValueAndValidity();
    });
  }

  enableSpecialControl() {
    this.newProjectForm.get('mandatory').get('operationalRespId').updateValueAndValidity();
    this.newProjectForm.get('mandatory').get('end2End').updateValueAndValidity();
  }

  unsubscribeSpecialEnums() {
    this.commitmentSubscription.unsubscribe();
    this.operationalRespSubscription.unsubscribe();
    this.e2eSubscription.unsubscribe();
    this.industrySubscription.unsubscribe();
  }

  setPredefinedValues(newProject) {
    newProject.statusId = 3;
    newProject.projectInfo.salesStage = 2;
    newProject.reqQualification.dcResponsibleRoleId = 1;
    if (this.projectDetails.projectId) {
      newProject.projectId = this.projectDetails.projectId;
    }
  }

  save() {
    const optionalFields = this.newProjectForm.get('optional');
    const othContact = optionalFields.get('othContact');
    const val = optionalFields.get('othContactRole')?.value;
    if (val && (!othContact.value || othContact.invalid)) {
      this.snackbar.error('To add Other Contact Role, there must be valid Other Contact ');
      return;
    }

    this.saving = true;
    const form = this.newProjectForm;
    const newProject = {
      client: {},
      projectInfo: {},
      reqQualification: {},
      projectSchedule: {},
    };

    this.setPredefinedValues(newProject);

    this.mandatoryChild.unsubscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.unsubscribeSpecialEnums();
    }

    this.unsubscribeSpecialEnums();

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        let part = null;
        fieldsSettings.mandatory.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        fieldsSettings.optional.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        const controler = groupControl.get(controlerName);
        if (controlerName === 'dmsId') {
          controler.value !== '' && controler.value.length > 0 && controler.validator === null
            ? controler.setValidators(validationUtils['valDMSmyWizardId']())
            : controler.clearValidators();
        }
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.enabled && controler.value !== null) {
          if (part) {
            if (controler.value instanceof Date) {
              newProject[part][controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[part][controlerName] = controler.value;
            }
          } else {
            if (controler.value instanceof Date) {
              newProject[controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[controlerName] = controler.value;
            }
          }
        }
      });
    });

    if (Object.keys(newProject.client).length === 0) {
      delete newProject.client;
    }

    if (Object.keys(newProject.projectInfo).length === 0) {
      delete newProject.projectInfo;
    }

    if (Object.keys(newProject.reqQualification).length === 0) {
      delete newProject.reqQualification;
    }

    if (Object.keys(newProject.projectSchedule).length === 0) {
      delete newProject.projectSchedule;
    }

    this.mandatoryChild.subscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.subscribeSpecialEnums();
    }

    this.subscribeSpecialEnums();
    if (form.valid) {
      newProject.reqQualification['country'] = this.mandatoryChild.enums['country'].filter(
        item => item.id === newProject.reqQualification['countryId']
      )[0].text;
      newProject.reqQualification['client'] = newProject.client['clientName'];
      // newProject.reqQualification['client'] = this.mandatoryChild.data['clientEnum'].filter(item =>
      //   item.id === newProject.reqQualification['clientId'])[0].text;
      if (newProject.projectInfo['nbmStatus'] === undefined) {
        newProject.projectInfo['nbmStatus'] = 7;
      }
      this.normalizeDCRespName(newProject);
      this.projectService.addProject(newProject).subscribe(
        res => {
          this.handleSucces(res);
        },
        err => {
          this.handleError(err, newProject);
        }
      );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
    }
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        const validators = [];
        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });
        this.newProjectForm.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newProjectForm
            .get(set)
            .get(setting.id)
            .disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }

  handleSucces(response) {
    this.snackbar.success('Opportunity created - redirecting to opportunity details');
    this.done = 1;
    this.saving = false;
    setTimeout(() => {
      this.router.navigate(['/opportunity-details', response.projectId]);
    }, 1500);
  }

  handleError(error: any, newProject: any) {
    // Error response can be different probably depends on the situation
    let name;
    let body: any;
    let errorMessage: any;
    if (error.error instanceof Array && error.error.length > 1) {
      const message = error.error[0].code;
      const itemId = error.error[0].field;
      if (itemId === 'ContactEnterpriseId') {
        const reqQualificationFields = Object.keys(newProject.reqQualification);
        const reqQualificationValues = Object.keys(newProject.reqQualification).map(
          k => newProject.reqQualification[k]
        );
        for (let i = 0; i < reqQualificationFields.length; i++) {
          if (
            typeof reqQualificationValues[i] === 'string' &&
            reqQualificationValues[i].localeCompare(message, 'en', { sensitivity: 'base' }) === 0
          ) {
            name = reqQualificationFields[i];
            if (name === 'clAccLeadContact') {
              name = 'Client Account Lead';
            } else if (name === 'salBidContact') {
              name = 'Sales Lead';
            } else if (name === 'othContact') {
              name = 'Other Contact';
            } else if (name === 'qaDirContact') {
              name = 'QA Director';
            } else if (name === 'solArchContact') {
              name = 'Solution Architect';
            } else if (name === 'delLeadContact') {
              name = 'Delivery Lead';
            } else if (name === 'projManContact') {
              name = 'Project Manager';
            } else if (name === 'dcResponsible') {
              name = 'DC Responsible';
            }
            errorMessage =
              'Please correct ' +
              name +
              ' name - used Enterpise ID is not recognized as Accenture employee.';
            break;
          }
        }
      } else if (error.error.length > 2) {
        body = error.error[1];
        if (body.field === 'reqQualification.dcResponsible') {
          errorMessage = 'Please correct ATC Responsible Name' + ' - ' + body.code;
        } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
          errorMessage = 'Please correct ATC Responsible Deputy' + ' - ' + body.code;
        } else {
          errorMessage = body.code;
        }
      } else if (error.error.length > 1) {
        body = error.error[0];
        if (body.field === 'reqQualification.dcResponsible') {
          errorMessage = 'ATC Responsible Name' + ' ' + body.code;
        } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
          errorMessage = 'ATC Responsible Deputy' + ' ' + body.code;
        } else {
          errorMessage = body.code;
        }
      } else {
        body = error.error[1];
        errorMessage = body.code;
      }
    } else {
      body = error.error;
      this.newProjectForm['clientName'].errorMessage =
        'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    }
    // const errorMessage = 'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    this.saving = false;
    this.snackbar.error(errorMessage);
  }

  handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  onDMSmyWizardChange(valueBefore: string, valueNow: string) {
    const field = this.newProjectForm.get('optional').get('dmsId');
    if (valueBefore !== valueNow) {
      if (valueNow && valueNow.length > 8) {
        field.setValidators(validationUtils['valDMSmyWizardId']());
        field.markAsTouched();
        field.updateValueAndValidity();
      } else if (!valueNow || valueNow === '') {
        field.clearValidators();
        field.markAsUntouched();
      }
    }
  }

  // if nbm status === conditionaly approved -> condition enabled; else -> disabled
  checkNbmStatus(nbmStatus: number, init?: boolean) {
    const mandatory = this.newProjectForm.get('mandatory');
    if (nbmStatus === 5) {
      if (init) {
        this.enableField(
          mandatory.get('condition'),
          true,
          this.newProjectForm.get('mandatory').get('condition').value
        );
      } else {
        this.enableField(mandatory.get('condition'), true, '');
      }
    } else {
      this.disableField(mandatory.get('condition'), true, '');
    }
  }

  onNbmStatusChange(nbmStatus: number) {
    this.checkNbmStatus(nbmStatus);
  }

  onIndustryChange(checked: boolean) {
    const mandatory = this.newProjectForm.get('mandatory');
    if (checked) {
      this.enableField(mandatory.get('subIndustryId'), true);
    }
  }

  checkNewBusinessMeeting(newBusinessMeeting: boolean) {
    const mandatory = this.newProjectForm.get('mandatory');
    if (newBusinessMeeting) {
      this.enableField(mandatory.get('nbmStatus'), false);
    } else {
      this.disableField(mandatory.get('nbmStatus'), true, 7);
    }
  }

  onNewBusinessMeetingChange(newBusinessMeeting: boolean) {
    this.checkNewBusinessMeeting(newBusinessMeeting);
  }

  onWorkCategoryChange(workCategory: number) {
    const form = this.newProjectForm;

    if (workCategory === 1 || workCategory === 3 || workCategory === 5 || workCategory === 7) {
      form.get('optional').get('sharedService').disable();
    } else {
      form.get('optional').get('sharedService').enable();
    }

    if (workCategory === 4 || workCategory === 8) {
      form.get('optional').get('sharedService').setValidators(validationUtils['valRequired']());
    } else {
      form.get('optional').get('sharedService').setValidators(null);
    }
  }

  onE2EChange(e2e: boolean) {
    if (e2e === true || this.mandatoryChild.mandatory.get('operationalRespId').value === 2) {
      const validators = [];
      validators.push(validationUtils['valRequired']());
      validators.push(validationUtils['valEidOrMail']());
      this.newProjectForm.get('mandatory').get('solArchContact').setValidators(validators);
      this.newProjectForm.get('mandatory').get('salBidContact').setValidators(validators);
    } else {
      this.newProjectForm
        .get('mandatory')
        .get('solArchContact')
        .setValidators(validationUtils['valEidOrMail']());
      this.newProjectForm
        .get('mandatory')
        .get('salBidContact')
        .setValidators(validationUtils['valEidOrMail']());
    }
  }

  onOperationalRespChange(opResp: number) {
    if (opResp === 2 || this.mandatoryChild.mandatory.get('end2End').value === true) {
      const validators = [];
      validators.push(validationUtils['valRequired']());
      validators.push(validationUtils['valEidOrMail']());
      this.newProjectForm.get('mandatory').get('solArchContact').setValidators(validators);
      this.newProjectForm.get('mandatory').get('salBidContact').setValidators(validators);
    } else {
      this.newProjectForm
        .get('mandatory')
        .get('solArchContact')
        .setValidators(validationUtils['valEidOrMail']());
      this.newProjectForm
        .get('mandatory')
        .get('salBidContact')
        .setValidators(validationUtils['valEidOrMail']());
    }
  }

  onCommitmentChange(value) {
    const fieldControls = [
      this.mandatoryChild.mandatory.get('operationalRespId'),
      this.mandatoryChild.mandatory.get('end2End'),
    ];
    this.commitment = value;
    if (value === 100) {
      const validators = [];
      fieldControls.forEach(field => {
        field.setValidators(validators);
      });
      validators.push(validationUtils['valRequired']());
      fieldControls[0].setValidators(validators);
      fieldControls[1].setValidators(validators);
    } else if (value) {
      fieldControls.forEach(field => {
        field.clearValidators();
      });
      this.resetValidatorsForMandatory(null);
    } else {
      fieldControls.forEach(field => {
        field.clearValidators();
      });
      this.resetValidatorsForMandatory(null);
    }
  }

  // disableField(control, emitEvent: boolean, newValue?) {
  //   const opts = { onlySelf: true, emitEvent: emitEvent };
  //   if (control.enabled) {
  //     control.disable(opts);
  //     if (newValue !== undefined) {
  //       control.setValue(newValue, opts);
  //     }
  //   }
  // }

  // enableField(control, emitEvent: boolean, newValue?) {
  //   const opts = { onlySelf: true, emitEvent: emitEvent };
  //   if (control.disabled) {
  //     control.enable(opts);
  //     if (newValue !== undefined) {
  //       control.setValue(newValue, opts);
  //     }
  //   }
  // }

  resetValidatorsForMandatory(fieldsAfterStatusChange: any) {
    if (fieldsAfterStatusChange === null) {
      const fields = [this.mandatoryChild.mandatory.get('operationalRespId')];
      fields.forEach(field => {
        field.setValidators(validationUtils['valRequired']());
        field.updateValueAndValidity();
      });
    } else {
      let num = 0;
      // Setting validations back
      fieldsAfterStatusChange.forEach(field => {
        if (!field.validator) {
          field.setValidators(validationUtils['valRequired']());
          num += 1;
        }
      });
      // Setting validation for date Checking
      if (num === fieldsAfterStatusChange.length) {
        fieldsAfterStatusChange[fieldsAfterStatusChange.length - 1].setValidators([
          validationUtils['valDate'](),
          validationUtils['valRequired'](),
        ]);
        fieldsAfterStatusChange.forEach(field => field.updateValueAndValidity());
      }
    }
  }
}

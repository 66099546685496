import { NewDemand } from './new-form';
const today = new Date();
export const fieldsSettings: NewDemand = {
  mandatory: [
    { cardType: 'input', id: 'role', label: 'Role', validation: ['valRequired'], values: ['', []] },
    {
      cardType: 'input',
      id: 'demandFte',
      label: 'FTE',
      validation: ['valRequired', 'valPositive'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'probabilityEnum',
      id: 'probabilityId',
      label: 'Probability',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'startDateDemand',
      label: 'Start Date',
      validation: ['valDate', 'valRequired'],
      values: [new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0), []],
    },
    {
      cardType: 'date',
      id: 'rollOffDateDemand',
      label: 'Roll-off Date',
      validation: ['valDate', 'valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'rateTypeEnum',
      id: 'demandRateTypeId',
      label: 'Rate Type',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'platformEnum',
      id: 'platformId',
      label: 'Platform',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'domainEnum',
      id: 'domainId',
      label: 'Domain',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'careerLevelEnum',
      id: 'careerLevelId',
      label: 'Management Level',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'sourcingLocationEnum',
      id: 'sourcingLocationId',
      label: 'Sourcing Location',
      validation: [],
      values: [1, []],
    },
    {
      cardType: 'select-multi',
      data: 'sourcingChannelEnum',
      id: 'sourcingChannelIds',
      label: 'Sourcing Channel',
      validation: ['valRequired'],
      values: ['', []],
    },
    { cardType: 'slider', id: 'dcso', label: 'DCSO', validation: [], values: [false, []] },
  ],

  optional: [
    {
      cardType: 'input',
      id: 'mySchedulingID',
      label: 'myScheduling ID',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'germanRequired',
      label: 'German Required',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'slider',
      id: 'frenchRequired',
      label: 'French Required',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'input',
      id: 'roleDescription',
      label: 'Role Description',
      validation: [],
      values: ['', []],
    },
    { cardType: 'input', id: 'notes', label: 'Notes', validation: [], values: ['', []] },
  ],
};

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { FlexibleSeatDialogComponent } from '../../flexible-seat-dialog/flexible-seat-dialog.component';
import { ProjectSeatingComponent } from '../project-seating.component';

@Component({
  selector: 'app-flexible-seating',
  templateUrl: './flexible-seating.component.html',
  styleUrls: [
    './flexible-seating.component.scss',
    '../../../../shared/tables-management/table-common-style.scss',
  ],
})
export class FlexibleSeatingComponent extends ProjectSeatingComponent implements OnInit {
  displayedColumns = [
    'numberOfSeats',
    'building',
    'seatFrom',
    'seatTo',
    'wbs',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'pdlComment',
    'action',
  ];

  @Input() addNewSeatDisabledMessage;
  @Input() useUniversalWbsMessage;
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() messageEventEmptyFlexible = new EventEmitter<boolean>();

  currentDate: Date = new Date(new Date().setHours(0, 0, 0));

  ngOnInit(): void {
    super.ngOnInit();

    if (this.projectDetails.projectInfo.seatsRequested === null) {
      this.showActionRequired();
    } else if (this.projectDetails.projectInfo.seatsRequested == true) {
      this.addNewSeatDisabled = false;
    } else {
      this.addNewSeatDisabled = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addNewSeatDisabledMessage !== undefined) {
      this.addNewSeatDisabled = changes.addNewSeatDisabledMessage.currentValue;
    } else if (changes.useUniversalWbsMessage !== undefined) {
      this.useUniversalWbs = changes.useUniversalWbsMessage.currentValue;
    }
  }

  populateDropdowns(enums) {
    this.data['buildingEnum'] = enums['seating-building'].values;
  }

  getData() {
    this.loaded = false;

    this.projectService.getFlexibleSeatings(this.projectDetails.projectId).subscribe({
      next: response => {
        this.handleGetData(response);
        this.setStartedSeat();
        this.messageEventEmptyFlexible.emit(this.dataSource.data.length <= 0);
      },
      error: error => this.handleError(error),
    });
  }

  addSeat() {
    const data = {
      projectId: this.projectDetails.projectId,
      editing: false,
    };

    const dialogRef = this.dialog.open(FlexibleSeatDialogComponent, {
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  editSeat(row: any) {
    const data = {
      projectId: this.projectDetails.projectId,
      row: row,
      editing: true,
      universalWbs: this.useUniversalWbs,
    };

    const dialogRef = this.dialog.open(FlexibleSeatDialogComponent, {
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  deleteSeat(row: any) {
    let deleteDialogData: string = `
      <div id="info">
        <ul>
          <li>
            Do you really want to delete this Flexible Seats Demand?
          </li>
        </ul>
      </div>
    `;
    const data: DialogData = {
      title: 'Warning',
      content: deleteDialogData,
      action1: 'Yes',
      action2: 'No',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectService.deleteFlexibleSeating(row.id).subscribe({
          next: response => {
            this.snackbar.success('Flexible Seating was successfully deleted');
            this.getData();
          },
          error: error => this.handleError(error),
        });
      }
    });
  }

  setStartedSeat() {
    this.dataSource.data.forEach(element => {
      let date = new Date(element['seatFrom']);
      if (date.getTime() < this.currentDate.getTime()) {
        element['startedSeat'] = true;
      } else {
        element['startedSeat'] = false;
      }
    });
  }

  showActionRequired() {
    let actionRequiredData: string = `
      <div id="info">
        <ul>
          <li>
            Will project anticipate any demand for DATC office seats (including future)?
          </li>
        </ul>
      </div>
    `;
    const data: DialogData = {
      title: 'Action Required',
      content: actionRequiredData,
      action1: 'No',
      action2: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      this.projectService.saveSeatsRequested(this.projectDetails.projectId, !result).subscribe({
        next: response => {
          if (!result) {
            this.addNewSeatDisabled = false;
            this.projectDetails.projectInfo.seatsRequested = true;
            this.messageEvent.emit(true);
          } else {
            this.addNewSeatDisabled = true;
            this.projectDetails.projectInfo.seatsRequested = false;
            this.messageEvent.emit(false);
          }
        },
        error: error => this.handleError(error),
      });
    });
  }
}

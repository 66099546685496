import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { fieldsSettings } from '../../types/new-project';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Common } from '../../shared/common';
import * as validationUtils from '../../shared/validators';
import { ProjectDetails } from '../../types/project-details';
import { ProjectOptionalFieldsComponent } from './project-optional-fields/project-optional-fields.component';
import { ProjectMandatoryFieldsComponent } from './project-mandatory-fields/project-mandatory-fields.component';
import { ProjectService } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { leadProjectMapping } from '../../types/lead-to-project.mapping';
import { opportunityProjectMapping } from '../../types/opportunity-to-project.mapping';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-add-new-project',
  templateUrl: './add-new-project.component.html',
  styleUrls: ['../../shared/add-new.scss', './add-new-project.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class AddNewProjectComponent implements OnInit {
  @ViewChild('optionalChild', { static: false }) optionalChild: ProjectOptionalFieldsComponent;
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ProjectMandatoryFieldsComponent;

  newProjectForm: FormGroup;
  projectDetails = new ProjectDetails();
  done: Number;
  commitment: number;
  statusSubscription: any;
  workCategorySubscription: any;
  commitmentSubscription: any;
  dcResponsibleRoleSubscription: any;
  industrySubscription: any;
  e2eSubscription: any;
  optlRespSubscription: any;
  operationalRespSubscription: any;
  end2EndSubscription: any;
  dmsMyWizardIdSubscription: any;
  saving = false;
  returnUrl: any;
  projectId: number;
  typeFrom: any;

  constructor(
    public dialog: MatDialog,
    public projectService: ProjectService,
    public fb: FormBuilder,
    public snackbar: SnackbarPanelComponent,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public authService: AuthService
  ) {}

  back() {
    this.snackbar.close();
    this.redirectToAddNewProjectView();
  }

  ngOnInit() {
    this.snackbar.close();

    const [fieldFormMandatory, fieldFormOptional] = [new Object(), new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
      this.typeFrom = params['typeFrom'];
      const searchedId = params['projectId'];
      if (searchedId != null) {
        this.evaluateParams(params, fieldFormMandatory, fieldFormOptional);
      } else {
        this.newProjectForm = this.fb.group({
          mandatory: this.fb.group(fieldFormMandatory),
          optional: this.fb.group(fieldFormOptional),
        });

        this.setValidators();
        this.setFields();
        this.subscribeSpecialEnums();

        Object.keys(this.newProjectForm.controls).forEach(group => {
          const groupControl = this.newProjectForm.get(group);
          Object.keys(groupControl['controls']).forEach(controler => {
            this.projectDetails[controler] = groupControl.get(controler).value;
          });
        });

        this.disableField(this.newProjectForm.get('mandatory').get('subIndustryId'), true);
      }
    });

    // fieldFormMandatory['projectName'] = 'name of the project';
  }

  getProjectDetails(searchedId, typeFrom, typeTo, fieldFormMandatory, fieldFormOptional) {
    this.projectService.getProjectDetails(searchedId).subscribe(
      response => {
        this.handleProjectDetailsSuccess(
          response,
          typeFrom,
          typeTo,
          fieldFormMandatory,
          fieldFormOptional
        );
      },
      error => this.handleProjectDetailsError(error)
    );
  }

  evaluateParams(params: Params, fieldFormMandatory, fieldFormOptional): void {
    const searchedId = params['projectId'];
    const typeFrom = params['typeFrom'];
    const typeTo = params['typeTo'];
    if (searchedId != null) {
      this.getProjectDetails(searchedId, typeFrom, typeTo, fieldFormMandatory, fieldFormOptional);
    }
  }

  handleProjectDetailsSuccess(
    projectDetails: any,
    typeFrom,
    typeTo,
    fieldFormMandatory,
    fieldFormOptional
  ): void {
    this.projectDetails = projectDetails;
    this.projectDetails.reqQualification['dcResponsible'] =
      this.projectDetails.reqQualification['dcResponsible'].toString() +
      ' (' +
      this.projectDetails.reqQualification['dcResponsibleEid'].toString() +
      ')';
    this.projectDetails.reqQualification['dcResponsibleRoleId'] = null;

    if (typeFrom === 'opportunity') {
      opportunityProjectMapping.mandatory.map(map => {
        if (typeof this.projectDetails[map.class][map.atribute] === 'object') {
          fieldFormMandatory[map.id] = [this.projectDetails[map.class][map.atribute]];
        } else {
          fieldFormMandatory[map.id] = this.projectDetails[map.class][map.atribute];
        }
      });

      opportunityProjectMapping.optional.map(map => {
        fieldFormOptional[map.id] = this.projectDetails[map.class][map.atribute];
      });
    } else {
      leadProjectMapping.mandatory.map(map => {
        if (typeof this.projectDetails[map.class][map.atribute] === 'object') {
          fieldFormMandatory[map.id] = [this.projectDetails[map.class][map.atribute]];
        } else {
          fieldFormMandatory[map.id] = this.projectDetails[map.class][map.atribute];
        }
      });

      leadProjectMapping.optional.map(map => {
        fieldFormOptional[map.id] = this.projectDetails[map.class][map.atribute];
      });
    }

    this.newProjectForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.setValidators();
    this.setFields();
    this.subscribeSpecialEnums();

    Object.keys(this.newProjectForm.controls).forEach(group => {
      const groupControl = this.newProjectForm.get(group);
      Object.keys(groupControl['controls']).forEach(controler => {
        this.projectDetails[controler] = groupControl.get(controler).value;
      });
    });
  }

  handleProjectDetailsError(error: Response) {
    const errorText = error.statusText;
    const err: Error = new Error(errorText);
    this.projectDetails = null;
  }

  checkIfTouched(): Observable<boolean> {
    const form = this.newProjectForm;
    let touched;
    if (form.controls) {
      Object.keys(form.controls).forEach(group => {
        const groupControl = form.get(group);
        Object.keys(groupControl['controls']).forEach(controlerName => {
          if (groupControl.get(controlerName).touched) {
            touched = true;
          }
        });
      });
    }

    if (touched && this.done !== 1) {
      return Common.editWarningDialog(this.dialog);
    } else {
      return of(true);
    }
  }

  redirectToAddNewProjectView(projectId?) {
    if (this.projectId !== undefined || projectId !== undefined) {
      // transfer from lead/opp to opp/proj
      this.checkIfTouched().subscribe(result => {
        if (result) {
          if (this.typeFrom === undefined) {
            this.router.navigate(['/lead-details/' + this.projectId]);
          } else {
            this.router.navigate(['/' + this.typeFrom + '-details/' + this.projectId]);
          }
        }
      });
    } else {
      // add new project
      this.route.queryParams.subscribe(params => {
        this.returnUrl = params.returnUrl;
      });
      this.checkIfTouched().subscribe(result => {
        if (result) {
          this.router.navigate(['/project-details/add-new-project-view'], {
            queryParams: { returnUrl: this.returnUrl },
          });
        }
      });
    }
  }

  subscribeSpecialEnums() {
    this.e2eSubscription = this.newProjectForm
      .get('mandatory')
      .get('end2End')
      .valueChanges.subscribe(value => this.onE2EChange(value));
    this.optlRespSubscription = this.newProjectForm
      .get('mandatory')
      .get('operationalRespId')
      .valueChanges.subscribe(value => this.onOperationalRespChange(value));
    this.workCategorySubscription = this.newProjectForm
      .get('mandatory')
      .get('workCategoryId')
      .valueChanges.subscribe(value => this.onWorkCategoryChange(value));
    this.industrySubscription = this.newProjectForm
      .get('mandatory')
      .get('clientGroupId')
      .valueChanges.subscribe(value => this.onIndustryChange(true));

    /*this.dcResponsibleRoleSubscription = this.newProjectForm.get('mandatory').get('dcResponsibleRoleId').valueChanges.pipe(
																		distinctUntilChanged()
																	 ).subscribe(
																		value => {
																		  this.newProjectForm.get('mandatory').get('dcResponsibleRoleId').patchValue(value);
																		  // Pre-select values based on end2End field and selected dcResponsible Role (1 Cap, 2 Manag)
																		  if (!this.newProjectForm.get('optional').get('end2End').value) {
																			 if (value === 2) {
																				this.newProjectForm.get('mandatory').get('operationalRespId').setValue(1);
																			 } else if (value === 3) {
																				this.newProjectForm.get('mandatory').get('operationalRespId').setValue(2);
																			 }
																		  } else {
																			 if (value === 3) {
																				this.newProjectForm.get('mandatory').get('operationalRespId').setValue(1);
																			 }
																		  }
																		  this.enableSpecialControl();
																		});*/
    this.operationalRespSubscription = this.newProjectForm
      .get('mandatory')
      .get('operationalRespId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        this.newProjectForm.get('mandatory').get('operationalRespId').patchValue(value);
        this.enableSpecialControl();
      });
    this.end2EndSubscription = this.newProjectForm
      .get('mandatory')
      .get('end2End')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        this.newProjectForm.get('mandatory').get('end2End').patchValue(value);
        this.enableSpecialControl();
      });
    this.dmsMyWizardIdSubscription = this.newProjectForm
      .get('optional')
      .get('dmsId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        this.onDMSmyWizardChange(this.newProjectForm.value.optional.dmsId, value);
      });
  }

  // Removing validation from specific fields
  clearValidationForRejected(fields: AbstractControl[]) {
    fields.forEach(field => {
      field.clearValidators();
      field.updateValueAndValidity();
    });
  }

  enableSpecialControl() {
    this.newProjectForm.get('mandatory').get('dcResponsibleRoleId').updateValueAndValidity();
    this.newProjectForm.get('mandatory').get('operationalRespId').updateValueAndValidity();
    this.newProjectForm.get('mandatory').get('end2End').updateValueAndValidity();
  }

  unsubscribeSpecialEnums() {
    this.workCategorySubscription.unsubscribe();
    // this.dcResponsibleRoleSubscription.unsubscribe();
    this.operationalRespSubscription.unsubscribe();
    this.end2EndSubscription.unsubscribe();
    this.optlRespSubscription.unsubscribe();
    this.e2eSubscription.unsubscribe();
    this.industrySubscription.unsubscribe();
  }

  setPredefinedValues(newProject) {
    newProject.statusId = 3;
    newProject.projectInfo.salesStage = 3;
    newProject.projectSchedule.commitment = 100;
    if (this.projectDetails.projectId) {
      newProject.projectId = this.projectDetails.projectId;
    }
  }

  save() {
    const optionalFields = this.newProjectForm.get('optional');
    const othContact = optionalFields.get('othContact');
    const val = optionalFields.get('othContactRole')?.value;
    if (val && (!othContact.value || othContact.invalid)) {
      this.snackbar.error('To add Other Contact Role, there must be valid Other Contact ');
      return;
    }

    this.saving = true;
    const form = this.newProjectForm;
    const newProject = {
      client: {},
      projectInfo: {},
      reqQualification: {},
      projectSchedule: {},
    };

    this.setPredefinedValues(newProject);

    this.mandatoryChild.unsubscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.unsubscribeSpecialEnums();
    }

    this.unsubscribeSpecialEnums();

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        let part = null;

        fieldsSettings.mandatory.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        fieldsSettings.optional.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        const controler = groupControl.get(controlerName);
        if (controlerName === 'dmsId') {
          controler.value !== '' && controler.value.length > 0 && controler.validator === null
            ? controler.setValidators(validationUtils['valDMSmyWizardId']())
            : controler.clearValidators();
        }
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.enabled && controler.value !== null) {
          if (part) {
            if (controler.value instanceof Date) {
              newProject[part][controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[part][controlerName] = controler.value;
            }
          } else {
            if (controler.value instanceof Date) {
              newProject[controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[controlerName] = controler.value;
            }
          }
        }
      });
    });

    if (Object.keys(newProject.client).length === 0) {
      delete newProject.client;
    }

    if (Object.keys(newProject.projectInfo).length === 0) {
      delete newProject.projectInfo;
    } else if (
      Object.keys(newProject.projectInfo).includes('dmsId') &&
      newProject.projectInfo['dmsId'] === ''
    ) {
      newProject.projectInfo['dmsId'] = null;
    }

    if (Object.keys(newProject.reqQualification).length === 0) {
      delete newProject.reqQualification;
    }

    if (Object.keys(newProject.projectSchedule).length === 0) {
      delete newProject.projectSchedule;
    }

    this.mandatoryChild.subscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.subscribeSpecialEnums();
    }

    this.subscribeSpecialEnums();
    if (form.valid) {
      if (newProject.reqQualification['dcResponsibleRoleId']) {
        newProject.reqQualification['dcResponsibleRole'] = this.mandatoryChild.enums[
          'dcResponsibleRole'
        ].filter(item => item.id === newProject.reqQualification['dcResponsibleRoleId'])[0].text;
      }
      newProject.reqQualification['country'] = this.mandatoryChild.enums['country'].filter(
        item => item.id === newProject.reqQualification['countryId']
      )[0].text;
      newProject.reqQualification['client'] = newProject.client['clientName'];
      // newProject.reqQualification['client'] = this.mandatoryChild.data['clientEnum'].filter(item =>
      //   item.id === newProject.reqQualification['clientId'])[0].text;

      this.normalizeDCRespName(newProject);
      this.projectService.addProject(newProject).subscribe(
        res => {
          this.handleSucces(res);
        },
        err => {
          this.handleError(err, newProject);
        }
      );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
    }
  }

  normalizeDCRespName(newProject) {
    if (newProject.reqQualification.dcResponsible.includes('(')) {
      const dcResp = newProject.reqQualification.dcResponsible.split('(');
      newProject.reqQualification.dcResponsible = dcResp[1].replace(')', '');
    }
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        const validators = [];
        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });
        this.newProjectForm.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newProjectForm
            .get(set)
            .get(setting.id)
            .disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }

  handleSucces(response) {
    this.snackbar.success('Project created - redirecting to project details');
    this.done = 1;
    this.saving = false;
    setTimeout(() => {
      this.router.navigate(['/project-details', response.projectId]);
    }, 1500);
  }

  handleError(error: any, newProject: any) {
    // Error response can be different probably depends on the situation
    let name;
    let body: any;
    let errorMessage: any;
    if (error.error instanceof Array && error.error.length > 1) {
      const message = error.error[0].code;
      const itemId = error.error[0].field;
      if (itemId === 'ContactEnterpriseId') {
        const reqQualificationFields = Object.keys(newProject.reqQualification);
        const reqQualificationValues = Object.keys(newProject.reqQualification).map(
          k => newProject.reqQualification[k]
        );
        for (let i = 0; i < reqQualificationFields.length; i++) {
          if (
            typeof reqQualificationValues[i] === 'string' &&
            reqQualificationValues[i].localeCompare(message, 'en', { sensitivity: 'base' }) === 0
          ) {
            name = reqQualificationFields[i];
            if (name === 'clAccLeadContact') {
              name = 'Client Account Lead';
            } else if (name === 'salBidContact') {
              name = 'Project Manager';
            } else if (name === 'projManContact') {
              name = 'Project Manager';
            } else if (name === 'propLeadContact') {
              name = 'Delivery Lead';
            } else if (name === 'delLeadContact') {
              name = 'Delivery Lead';
            } else if (name === 'techAccLeadContact') {
              name = 'Technology Service Lead';
            } else if (name === 'othContact') {
              name = 'Other Contact';
            } else if (name === 'qaDirContact') {
              name = 'QA Director';
            } else if (name === 'dcResponsible') {
              name = 'DC Responsible';
            } else if (name === 'dcResponsibleDeputy') {
              name = 'DC Responsible Deputy';
            }
            errorMessage =
              'Please correct ' +
              name +
              ' name - used Enterpise ID is not recognized as Accenture employee.';
            break;
          }
        }
      } else if (error.error.length > 2) {
        body = error.error[1];
        if (body.field === 'reqQualification.dcResponsible') {
          errorMessage = 'Please correct ATC Responsible Name' + ' - ' + body.code;
        } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
          errorMessage = 'Please correct ATC Responsible Deputy' + ' - ' + body.code;
        } else {
          errorMessage = body.code;
        }
      } else if (error.error.length > 1) {
        body = error.error[0];
        if (body.field === 'reqQualification.dcResponsible') {
          errorMessage = 'ATC Responsible Name' + ' ' + body.code;
        } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
          errorMessage = 'ATC Responsible Deputy' + ' ' + body.code;
        } else {
          errorMessage = body.code;
        }
      } else {
        body = error.error[1];
        errorMessage = body.code;
      }
    } else {
      body = error.error;
      this.newProjectForm['clientName'].errorMessage =
        'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    }
    // const errorMessage = 'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    this.saving = false;
    this.snackbar.error(errorMessage);
  }

  handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  onDMSmyWizardChange(valueBefore: string, valueNow: string) {
    const field = this.newProjectForm.get('optional').get('dmsId');
    if (valueBefore !== valueNow) {
      if (valueNow && valueNow.length > 8) {
        field.setValidators(validationUtils['valDMSmyWizardId']());
        field.markAsTouched();
        field.updateValueAndValidity();
      } else if (!valueNow || valueNow === '') {
        field.clearValidators();
        field.markAsUntouched();
      }
    }
  }

  onWorkCategoryChange(workCategory: number) {
    const form = this.newProjectForm;

    if (workCategory === 1 || workCategory === 5) {
      // form.get('optional').get('unchargedProject').disable();
      // form.get('optional').get('unchargedProject').setValue(false);
      form.get('optional').get('sharedService').disable();
    } else if (workCategory === 3 || workCategory === 7) {
      // form.get('optional').get('unchargedProject').enable();
      form.get('optional').get('sharedService').disable();
    } else {
      // form.get('optional').get('unchargedProject').disable();
      // form.get('optional').get('unchargedProject').setValue(false);
      form.get('optional').get('sharedService').enable();
    }

    if (workCategory === 4 || workCategory === 8) {
      form.get('optional').get('sharedService').setValidators(validationUtils['valRequired']());
    } else {
      form.get('optional').get('sharedService').setValidators(null);
    }
  }

  disableField(control, emitEvent: boolean, newValue?) {
    const opts = { onlySelf: true, emitEvent: emitEvent };
    if (control.enabled) {
      control.disable(opts);
      if (newValue !== undefined) {
        control.setValue(newValue, opts);
      }
    }
  }

  enableField(control, emitEvent: boolean, newValue?) {
    const opts = { onlySelf: true, emitEvent: emitEvent };
    if (control.disabled) {
      control.enable(opts);
      if (newValue !== undefined) {
        control.setValue(newValue, opts);
      }
    }
  }

  onCommitmentChange(value) {
    const fieldControls = [
      this.mandatoryChild.mandatory.get('dcResponsibleRoleId'),
      this.mandatoryChild.mandatory.get('operationalRespId'),
      this.optionalChild.optional.get('end2End'),
    ];
    this.commitment = value;
    if (value === 100) {
      const validators = [];
      this.mandatoryChild.data['dcResponsibleRoleEnum'] = this.mandatoryChild.enums[
        'dcResponsibleRole'
      ].filter(option => option.id !== 1);
      validators.push(validationUtils['valDcResponsibleRole'](this.projectDetails, true));
      fieldControls.forEach(field => {
        field.setValidators(validators);
      });
      validators.push(validationUtils['valRequired']());
      fieldControls[0].setValidators(validators);
      fieldControls[1].setValidators(validators);
      this.mandatoryChild.mandatory.get('dcResponsibleRoleId').setValue(null);
    } else if (value) {
      this.mandatoryChild.data['dcResponsibleRoleEnum'] = this.mandatoryChild.enums[
        'dcResponsibleRole'
      ].filter(option => option.id === 1);
      this.mandatoryChild.mandatory.get('dcResponsibleRoleId').setValue(1);
      fieldControls.forEach(field => {
        field.clearValidators();
      });
      this.resetValidatorsForMandatory(null);
    } else {
      this.mandatoryChild.data['dcResponsibleRoleEnum'] =
        this.mandatoryChild.enums['dcResponsibleRole'];
      this.mandatoryChild.mandatory.get('dcResponsibleRole').setValue(null);
      fieldControls.forEach(field => {
        field.clearValidators();
      });
      this.resetValidatorsForMandatory(null);
    }
  }

  onIndustryChange(checked: boolean) {
    const mandatory = this.newProjectForm.get('mandatory');
    if (checked) {
      this.enableField(mandatory.get('subIndustryId'), true);
    }
  }

  onE2EChange(e2e: boolean) {
    if (e2e === true || this.mandatoryChild.mandatory.get('operationalRespId').value === 2) {
      const validators = [];
      validators.push(validationUtils['valRequired']());
      validators.push(validationUtils['valEidOrMail']());
      this.newProjectForm.get('optional').get('qaDirContact').setValidators(validators);
    } else {
      this.newProjectForm
        .get('optional')
        .get('qaDirContact')
        .setValidators(validationUtils['valEidOrMail']());
    }
  }

  onOperationalRespChange(opResp: number) {
    if (opResp === 2 || this.mandatoryChild.mandatory.get('end2End').value === true) {
      const validators = [];
      validators.push(validationUtils['valRequired']());
      validators.push(validationUtils['valEidOrMail']());
      this.newProjectForm.get('optional').get('qaDirContact').setValidators(validators);
    } else {
      this.newProjectForm
        .get('optional')
        .get('qaDirContact')
        .setValidators(validationUtils['valEidOrMail']());
    }
  }

  resetValidatorsForMandatory(fieldsAfterStatusChange: any) {
    if (fieldsAfterStatusChange === null) {
      const fields = [
        this.mandatoryChild.mandatory.get('dcResponsibleRoleId'),
        this.mandatoryChild.mandatory.get('operationalRespId'),
      ];
      fields.forEach(field => {
        field.setValidators(validationUtils['valRequired']());
        field.updateValueAndValidity();
      });
    } else {
      let num = 0;
      // Setting validations back
      fieldsAfterStatusChange.forEach(field => {
        if (!field.validator) {
          field.setValidators(validationUtils['valRequired']());
          num += 1;
        }
      });
      // Setting validation for date Checking
      if (num === fieldsAfterStatusChange.length) {
        fieldsAfterStatusChange[fieldsAfterStatusChange.length - 1].setValidators([
          validationUtils['valDate'](),
          validationUtils['valRequired'](),
        ]);
        fieldsAfterStatusChange.forEach(field => field.updateValueAndValidity());
      }
    }
  }

  protected readonly FormGroup = FormGroup;
}

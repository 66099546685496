import { distinctUntilChanged } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { User } from '../../../services/user-roles/user';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { CustomDateAdapter } from 'app/shared/date-utils';
import { DefaultListComponent } from '../default-list/default-list.component';
import { ListSearchParams } from '../../../types/list-search-params';

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: [
    './opportunity-list.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent, { provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class OpportunityListComponent
  extends DefaultListComponent
  implements OnInit, AfterViewInit
{
  displayedColumns = [
    'projectId',
    'name',
    'client',
    'salesStage',
    'ddcIndustryGroup',
    'avanade',
    'dcResponsibleName',
    'startDate',
    'endDate',
    'lastUpdate',
    'status',
  ];
  salesStage = 2;
  isProject = false;
  completedUrl = '/opportunity-details/';
  completedLeadUrl = '/lead-details/';
  selectedStatuses = null;

  ngOnInit() {
    super.ngOnInit();

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
      });

    this.projectService.getUserDcResp().subscribe(data => {
      if (data) {
        if (data['dcResponsible'] !== 'null') {
          this.dcResponsible = data['dcResponsible'];
        }
        this.enumsService.getData().subscribe(
          enums => {
            this.populateDropdowns(enums);
          },
          error => {
            this.handleLoadEnumsError();
          }
        );
      }
    });
  }

  // routeToAddNewOpportunity() {
  //   // const routerLink = '/project-details/add-new-project-view';
  //   // this.router.navigate([routerLink], { queryParams: { returnUrl: this.router.url } });
  //   const routerLink = '/project-details/add-new-opportunity';
  //   this.router.navigate([routerLink]);
  // }

  protected populateDropdowns(enums) {
    const avanade: { id: number; stage: number; text: string; value: boolean }[] = [
      { id: 1, stage: 1, text: 'Yes', value: true },
      { id: 2, stage: 2, text: 'No', value: false },
    ];

    this.data['avanadeEnum'] = avanade;
    this.data['clientGroupEnum'] = enums['client-group'];
    this.data['statusEnum'] = enums['status-enum'];
    this.data['salesStageEnum'] = enums['sales-stage'].slice(0, 2);
    this.getUserAndDefaultFilters();
  }

  createSearchObjectForOpportunityList(): ListSearchParams {
    let searchParams = {} as ListSearchParams;
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.controls[key];
      if (control.valid && control.value) {
        if (key === 'status') {
          const status = this.data['statusEnum'].filter(option => option.id === control.value)[0][
            'text'
          ];
          searchParams[key] = status;
        } else if (key === 'avanade') {
          searchParams[key] =
            this.data['avanadeEnum'].filter(option => option.id === control.value)[0]['text'] ===
            'Yes'
              ? true
              : false;
        } else if (key === 'salesStage') {
          searchParams['salesStage'] = this.data['salesStageEnum'].filter(
            option => option.id === control.value
          )[0]['id'];
        } else if (control.value instanceof Date) {
          searchParams[key] = this.dateFormatter.transform(control.value, this.dateFormatMmYyyy);
        } else {
          searchParams[key] = control.value;
        }
      }
    });

    searchParams = Object.assign(searchParams, {
      pageSize: this.pageSize,
      pageNumber: this.pageIndex,
    });
    this.form.controls.dcResponsibleName.valueChanges.subscribe(data => {
      if (data != null) {
        this.dcResponsible = data.value;
      }
    });

    if (this.userDatabasePrimaryKey == null) {
      this.form.get('status').setValue(3);
      searchParams['status'] = 'In-Progress    ';
      if (this.dcResponsible !== undefined) {
        searchParams['dcResponsibleName'] = this.dcResponsible;
      } else {
        this.dcResponsible = undefined;
      }
    }

    searchParams['project'] = this.isProject;
    searchParams['include'] = this.selectedStatuses != null ? true : false;

    return searchParams;
  }

  openOpportunityDetail(row) {
    if (row.salesStage === 'Lead') {
      this.router.navigate([this.completedLeadUrl, row.projectId]);
    } else {
      this.router.navigate([this.completedUrl, row.projectId]);
    }
  }
}

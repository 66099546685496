import { Component, OnInit } from '@angular/core';
import { SeatingHardwareCommonComponent } from '../seating-hardware-common/seating-hardware-common.component';
import { HardwareDialogComponent } from '../hardware-dialog/hardware-dialog.component';

@Component({
  selector: 'app-project-hardware',
  templateUrl: './project-hardware.component.html',
  styleUrls: [
    './project-hardware.component.scss',
    '../seating-hardware-common/seating-hardware-common.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
})
export class ProjectHardwareComponent extends SeatingHardwareCommonComponent implements OnInit {
  displayedColumns = [
    'empNameFte',
    'hwType',
    'startDate',
    'endDate',
    'supplyFrom',
    'supplyTo',
    'serialNumber',
    'wbs',
    'comment',
    'action',
  ];
  dialogRefHardwareDialog: any;
  useHwUniversalWbs: boolean;

  ngOnInit() {
    super.ngOnInit();
  }

  getData(): void {
    this.loaded = false;

    this.projectService.getHardware(this.projectDetails.projectId).subscribe(
      response => this.handleGetData(response),
      error => this.handleError(error)
    );
  }

  saveUniversalWbs(useHwUniversalWbs, hwUniversalWbs) {
    const change = {
      useHwUniversalWbs: useHwUniversalWbs,
      hwUniversalWbs: hwUniversalWbs,
    };

    this.useHwUniversalWbs = useHwUniversalWbs;

    if (this.useHwUniversalWbs) {
      this.form.get('wbs').enable();
    } else {
      this.form.get('wbs').disable();
    }

    this.projectService.editUniversalWbs(this.projectDetails.projectId, change).subscribe(
      response => this.handleEditWbs(response),
      error => this.handleError(error)
    );
  }

  handleGetUniversalWbs(data: any): void {
    this.form.get('useUniversalWbs').setValue(data.useHwUniversalWbs, { emitEvent: false });
    this.form.get('wbs').setValue(data.hwUniversalWbs, { emitEvent: false });
    this.useHwUniversalWbs = this.form.get('useUniversalWbs').value;
    if (!this.useHwUniversalWbs) {
      this.form.get('wbs').disable({ emitEvent: false });
    } else {
      this.form.get('wbs').enable({ emitEvent: false });
    }
    this.useUniversalWbs = this.form.get('useUniversalWbs').value;
  }

  editHardware(row) {
    const data = {
      projectId: this.projectDetails.projectId,
      row: row,
    };

    const dialogRef = this.dialog.open(HardwareDialogComponent, {
      width: '300px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }
}

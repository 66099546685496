import { Component, Input, OnInit } from '@angular/core';
import { DemandDetails } from '../../../types/project-demand';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-demand';
import { ProjectService } from '../../../services/project.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-demand-common-fields',
  templateUrl: './demand-common-fields.component.html',
  styleUrls: ['./demand-common-fields.component.scss'],
})
export class DemandCommonFieldsComponent implements OnInit {
  @Input() demandDetails: DemandDetails;

  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  enums: any;
  projectId: number;

  data = {};

  constructor(
    protected route: ActivatedRoute,
    protected projectService: ProjectService,
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.queryParams['projectId'];
    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.subscribeSpecialEnums();
  }

  protected filterStates(searchedValue: string, field: string) {
    return this.data[field].filter(
      value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1
    );
  }

  protected onFormChanges(changes, part) {
    Object.keys(changes).forEach(change => {
      const control = part.controls[change];
      this.demandDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleError(error: Response | string) {
    const errorText = error instanceof Response ? error.statusText : error;
    this.snackbar.error(errorText);
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  public subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}

  protected populateDropdowns(enums) {
    this.enums = JSON.parse(JSON.stringify(enums));
  }
}

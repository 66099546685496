import { BannerCard } from './employee-banner-card';

export const bannerCard: Array<BannerCard> = [
  { cardType: '', label: 'Project ID', id: 'projectId', type: '', icon: '' },
  { cardType: 'enum', label: 'Status', id: 'statusId', type: '', icon: '' },
  {
    cardType: '',
    part: 'reqQualification',
    label: 'Last Updated Date',
    id: 'lastUpdateDate',
    type: '',
    icon: '',
  },
  {
    cardType: '',
    part: 'reqQualification',
    label: 'Last Updated By',
    id: 'lastUpdatedBy',
    type: '',
    icon: '',
  },
  { cardType: 'enum', part: 'client', label: 'Client', id: 'clientId', type: '', icon: 'star' },
  {
    cardType: 'enum',
    part: 'reqQualification',
    label: 'Industry',
    id: 'clientGroupId',
    type: '',
    icon: 'domain',
  },
];

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { AwsSeatingPlanningDetails } from 'app/types/aws-seating-details';
import { AwsSeatingPlanningTableComponent } from '../aws-seating-planning-table/aws-seating-planning-table.component';
import { Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-aws-seating-planning-history',
  templateUrl: './aws-seating-planning-history.component.html',
  styleUrls: [
    './aws-seating-planning-history.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AwsSeatingPlanningHistoryComponent
  extends AwsSeatingPlanningTableComponent
  implements OnInit
{
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    snackbar: SnackbarPanelComponent,
    fb: FormBuilder,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  save(control: FormGroup, row: AwsSeatingPlanningDetails) {
    const requestBody = [];
    let valid = true;
    if (control.touched) {
      control.updateValueAndValidity();
      if (control.valid && this.validateDates(control)) {
        requestBody.push(this.getFinalValue(control));
      } else {
        valid = false;
        if (control.get('seat').invalid) {
          this.snackbar.error('Wrong seat format.');
        }
      }
    }

    if (valid) {
      this.projectService.updateAwsSeatingHistory(requestBody).subscribe(
        response => this.handleUpdateResponse(response),
        error => this.handleUpdateError(error)
      );
    }
  }

  handleUpdateResponse(response) {
    this.snackbar.success('Records succesfully updated!');
  }

  handleUpdateError(error: Error) {
    this.snackbar.error('An error occured!');
  }

  validateDates(control: AbstractControl): Boolean {
    const dateFrom = new Date(control.get('seatFrom').value);
    const dateTo = new Date(control.get('seatTo').value);
    if (dateTo < dateFrom) {
      // control.setErrors({'incorrect': true});
      control.get('seatTo').setErrors({ incorrect: true });
      control.get('seatTo').markAsTouched();
      control.updateValueAndValidity();
      this.snackbar.error('Seat To date is before Seat From date!');
      return false;
    }

    return true;
  }
}

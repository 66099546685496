import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  DateAdapter,
  NativeDateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { hiringRequestsEnumMapping } from 'app/types/enum.mapping';
import { HiringRequestDetails } from 'app/types/hiring-request-details';
import { HiringRequestSearch } from 'app/types/hiring-request-search';
import { HiringRequestsCommonComponent } from '../hiring-requests-common/hiring-requests-common.component';
import { US_DATE_FORMAT } from '../../types/date-format';
import { OpenDemandsListDto } from '../../types/openDemands';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { AppComponent } from '../../app.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../services/project.service';
import { EnumsService } from '../../services/enumerations/enums.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-hiring-requests-history',
  templateUrl: './hiring-requests-history.component.html',
  styleUrls: [
    './hiring-requests-history.component.scss',
    '../hiring-requests-common/hiring-requests-common.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: US_DATE_FORMAT,
    },
  ],
})
export class HiringRequestsHistoryComponent
  extends HiringRequestsCommonComponent
  implements OnInit, AfterViewInit
{
  constructor(
    router: Router,
    route: ActivatedRoute,
    authService: AuthService,
    snackbar: SnackbarPanelComponent,
    fb: FormBuilder,
    userRolesService: UserRolesService,
    app: AppComponent,
    dialog: MatDialog,
    projectService: ProjectService,
    enumsService: EnumsService,
    location: Location
  ) {
    super(
      router,
      route,
      authService,
      snackbar,
      fb,
      userRolesService,
      app,
      dialog,
      projectService,
      enumsService,
      location
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.snackbar.close();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  prepareSearchParams(): HiringRequestSearch {
    return {
      history: true,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
  }

  setDefaultColumns() {
    this.displayedColumns = this.fieldsSettings
      .filter(setting => !setting.values[0] && setting.id !== 'actions')
      .map(setting => setting.id);
  }

  handleOpenDemands(data: OpenDemandsListDto): void {
    this.totalElements = data.totalElements;
    if (this.firstLoad) {
      if (data.platform !== undefined) {
        this.headerForm.get('platform').setValue(data.platform);
      }
      this.firstLoad = false;
    } else {
      this.pageSizeOptions.splice(this.pageSizeOptions.indexOf(this.totalElements), 1); //refresh total elements with new value
    }
    this.pageSizeOptions.push(this.totalElements);
    this.paginator.pageSizeOptions = this.pageSizeOptions;
    this.originalData = JSON.parse(JSON.stringify(data.openDemands));
    this.dataSource.data = this.transformData(data.openDemands);
  }

  transformData(requests: Array<any>): Array<HiringRequestDetails> {
    const enumNamesToLoad = [
      'locationId',
      'typeId',
      'additionalLanguageSkillId',
      'profileId',
      'domainId',
      'platformId',
      'priorityId',
      'statusId',
    ];

    for (let request of requests) {
      enumNamesToLoad.forEach(enumName => {
        this.enumsService.setEnumText(request, enumName, hiringRequestsEnumMapping);
      });
      request.creationDate = this.dateFormatter.transform(
        request.creationDate,
        this.dateFormatMmDdYyyy
      );
      request.estimatedHireDate = this.dateFormatter.transform(
        request.estimatedHireDate,
        this.dateFormatMmDdYyyy
      );
      request.specialEstimatedHireDate = this.dateFormatter.transform(
        request.specialEstimatedHireDate,
        this.dateFormatMmDdYyyy
      );
      request.targetDate = this.dateFormatter.transform(
        request.targetDate,
        this.dateFormatMmDdYyyy
      );

      request.platform = request.platformId;
      request.domain = request.domainId;

      if (request.projectId !== null) {
        const project = this.data.projectEnum.filter(option => option.id === request.projectId)[0];
        request.projectName = project !== undefined ? project.text : '';
      }
      request.rtStScComment =
        request.rtComment + ' / ' + request.stComment + ' / ' + request.scComment;
    }
    this.listLoaded = true;
    return requests;
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);
    this.data['statusEnum'] = enums['open-demands-status'];
  }
}

export const leadOpportunityMapping = {
  mandatory: [
    { id: 'projectName', class: 'projectInfo', atribute: 'projectName' },
    { id: 'expectedFte', class: 'projectInfo', atribute: 'expectedFte' },
    { id: 'nbmStatus', class: 'projectInfo', atribute: 'nbmStatus' },
    { id: 'deliveryVmuDescription', class: 'projectInfo', atribute: 'deliveryVmuDescription' },
    { id: 'condition', class: 'projectInfo', atribute: 'condition' },
    { id: 'expectedDedicatedSeats', class: 'projectInfo', atribute: 'expectedDedicatedSeats' },
    { id: 'expectedSharedSeats', class: 'projectInfo', atribute: 'expectedSharedSeats' },
    { id: 'startDate', class: 'projectSchedule', atribute: 'startDate' },
    { id: 'clientName', class: 'client', atribute: 'clientName' },
    { id: 'clientGroupId', class: 'reqQualification', atribute: 'clientGroupId' },
    { id: 'subIndustryId', class: 'reqQualification', atribute: 'subIndustryId' },
    { id: 'regionId', class: 'reqQualification', atribute: 'regionId' },
    { id: 'platformId', class: 'reqQualification', atribute: 'platformId' },
    { id: 'dcResponsible', class: 'reqQualification', atribute: 'dcResponsible' },
    { id: 'commitment', class: 'projectSchedule', atribute: 'commitment' },
    { id: 'tgSalesId', class: 'projectInfo', atribute: 'tgSalesId' },
    { id: 'reqDate', class: 'reqQualification', atribute: 'reqDate' },
    { id: 'newBusinessMeeting', class: 'projectInfo', atribute: 'newBusinessMeeting' },
    { id: 'sourcingLocation', class: 'projectInfo', atribute: 'sourcingLocation' },
    { id: 'lastUpdateDate', class: 'reqQualification', atribute: 'lastUpdateDate' },
    { id: 'dcResponsibleRoleId', class: 'reqQualification', atribute: 'dcResponsibleRoleId' },
    { id: 'sapOpNumber', class: 'projectInfo', atribute: 'sapOpNumber' },
  ],
  optional: [{ id: 'piComment', class: 'projectInfo', atribute: 'piComment' }],
};

import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonComponent } from '../../shared/common/common.component';
import { fieldsSettings } from '../../types/new-fte';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../services/project.service';
import { FormBuilder } from '@angular/forms';
import * as validationUtils from '../../shared/validators';
import { Common } from '../../shared/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fte-dialog',
  templateUrl: './fte-dialog.component.html',
  styleUrls: ['./fte-dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class FteDialogComponent extends CommonComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  id: number;
  alignSeats: boolean;
  isDisabled: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<FteDialogComponent>,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    protected fb: FormBuilder,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    this.id = this.dialogData.id;

    super.ngOnInit();
    this.getAlignSeats();
  }

  getAlignSeats() {
    this.projectService.getAlignSeats(this.id).subscribe(
      response => this.handleAlignSeats(response),
      error => this.handleError(error)
    );
  }

  handleAlignSeats(response) {
    if (response) {
      this.form.get('alignSeats').enable();
    } else {
      this.form.get('alignSeats').disable();
    }
  }

  setValidators() {
    this.projectService.getStartDate(this.id).subscribe(
      response => {
        this.fieldsSettings.forEach(setting => {
          const validators = [];

          setting.validation.forEach(validation => {
            if (validation === 'valFromToDates') {
              validators.push(validationUtils[validation](this.form.get('rollOffDateDemand')));
            } else if (validation === 'valToFromDates') {
              validators.push(validationUtils[validation](this.form.get('startDateDemand')));
            } else if (validation === 'valOrigStartFromDates') {
              validators.push(validationUtils[validation](response));
            } else {
              validators.push(validationUtils[validation]());
            }
          });
          this.form.get(setting.id).setValidators(validators);
        });
      },
      error => this.handleError(error)
    );
  }

  create(create: boolean) {
    if (create) {
      const data = {
        id: this.id,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.value instanceof Date) {
          data[controlerName] = Common.timezoneFix(this.form.get(controlerName).value);
        } else {
          data[controlerName] = this.form.get(controlerName).value;
        }
      });

      if (this.form.valid) {
        this.isDisabled = create;
        this.projectService.demandFte(data).subscribe(
          result => this.handleFte(result),
          error => this.handleError(error)
        );
      }
    } else {
      this.isDisabled = false;
      this.dialogRef.close(false);
    }
  }

  handleFte(result) {
    this.isDisabled = false;
    this.snackbar.success('FTE has been used');
    this.dialogRef.close(true);
  }
}

import { Component, OnInit } from '@angular/core';
import { DcResponsibleService } from 'app/services/dcresponsible.service';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { errorMessages } from 'app/types/input-errors';
import { fieldsSettings } from 'app/types/new-bench-tracking';

@Component({
  selector: 'app-bench-tracking-common-fields',
  templateUrl: './bench-tracking-common-fields.component.html',
  styleUrls: ['./bench-tracking-common-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class BenchTrackingCommonFieldsComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  enums: any;
  data = {};

  constructor(
    protected enumsService: EnumsService,
    protected dcResponsibleService: DcResponsibleService,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {
    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError(error);
      }
    );

    this.subscribeSpecialEnums();
  }

  addEidToDuplicates(values) {
    const alreadySeen = [];

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]] === false) {
        alreadySeen[values[0][i]] = true;
        continue;
      }
      if (alreadySeen[values[0][i]] !== true) {
        alreadySeen[values[0][i]] = false;
        continue;
      }
    }

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]]) {
        values[0][i] = values[0][i] + ' (' + values[1][i] + ')';
      }
    }
  }

  protected filterStates(searchedValue: string, field: string) {
    if (field === 'requestedBy') {
      this.addEidToDuplicates(this.data[field]);
      return this.data[field][0]
        .filter(value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1)
        .sort();
      // indexOf due to IE
    } else {
      return this.data[field]
        .filter(value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1)
        .sort();
    }
  }

  protected onFormChanges(changes, part) {
    Object.keys(changes).forEach(change => {
      const control = part.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected populateDropdowns(enums: any) {
    this.enums = this.sortEnumValues(enums);
  }

  protected handleLoadEnumsError(error: any) {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  protected sortEnumValues(enums: any) {
    return enums;
  }

  public subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmployeeDetails } from '../../types/employee-details';
import { fieldsSettings } from '../../types/new-employee';
import { EmployeeDetailsService } from '../employee-details/employee-details.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Common } from '../../shared/common';
import * as validationUtils from '../../shared/validators';
import { OptionalFieldsComponent } from './optional-fields/optional-fields.component';
import { MandatoryFieldsComponent } from './mandatory-fields/mandatory-fields.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-employee',
  templateUrl: './add-new-employee.component.html',
  styleUrls: ['./add-new-employee.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class AddNewEmployeeComponent implements OnInit {
  @ViewChild('optionalChild', { static: false }) optionalChild: OptionalFieldsComponent;
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: MandatoryFieldsComponent;

  newJoinerForm: FormGroup;
  employeeDetails = new EmployeeDetails();
  done: Number;
  employeeTypeSubscription: any;
  isDisabled: boolean;

  constructor(
    private dialog: MatDialog,
    private employeeDetailsService: EmployeeDetailsService,
    private fb: FormBuilder,
    public snackbar: SnackbarPanelComponent,
    private router: Router,
    private location: Location
  ) {}

  back() {
    this.snackbar.close();
    this.location.back();
  }

  ngOnInit() {
    this.snackbar.close();
    this.isDisabled = false;

    const [fieldFormTop, fieldFormMandatory, fieldFormOptional] = [{}, {}, {}];

    fieldsSettings.top.forEach(setting => {
      fieldFormTop[setting.id] = setting.values;
    });

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    this.newJoinerForm = this.fb.group({
      top: this.fb.group(fieldFormTop),
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.setValidators();
    this.setFields();

    this.newJoinerForm.valueChanges.subscribe(changes => this.onFormChanges(changes));
    this.subscribeSpecialEnums();

    Object.keys(this.newJoinerForm.controls).forEach(group => {
      const groupControl = this.newJoinerForm.get(group);
      Object.keys(groupControl['controls']).forEach(controler => {
        this.employeeDetails[controler] = groupControl.get(controler).value;
      });
    });
  }

  canDeactivate() {
    let touched;

    Object.keys(this.newJoinerForm.controls).forEach(group => {
      const groupControl = this.newJoinerForm.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        if (groupControl.get(controlerName).touched) {
          touched = true;
        }
      });
    });

    if (touched && this.done !== 1) {
      return Common.editWarningDialog(this.dialog);
    } else {
      return true;
    }
  }

  subscribeSpecialEnums() {
    this.employeeTypeSubscription = this.newJoinerForm
      .get('top')
      .get('employeeTypeId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => this.employeeTypeChange());

    this.newJoinerForm
      .get('optional')
      .get('trainee')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => this.enableAndDisableTraineeEndDate(value));
  }

  enableAndDisableTraineeEndDate(value) {
    if (value) {
      this.newJoinerForm.get('optional').get('traineeEndDate').enable();
      this.newJoinerForm
        .get('optional')
        .get('traineeEndDate')
        .addValidators([validationUtils['valRequired']()]);
      this.newJoinerForm.get('optional').get('traineeEndDate').markAsTouched();
      this.newJoinerForm.get('optional').get('traineeEndDate').updateValueAndValidity();
    } else {
      this.newJoinerForm
        .get('optional')
        .get('traineeEndDate')
        .removeValidators([validationUtils['valRequired']()]);
      this.newJoinerForm.get('optional').get('traineeEndDate').setValue(null);
      this.newJoinerForm.get('optional').get('traineeEndDate').markAsUntouched();
      this.newJoinerForm.get('optional').get('traineeEndDate').updateValueAndValidity();
      this.newJoinerForm.get('optional').get('traineeEndDate').disable();
    }
  }

  unsubscribeSpecialEnums() {
    this.employeeTypeSubscription.unsubscribe();
  }

  employeeTypeChange() {
    const employeeType = this.newJoinerForm.get('top').get('employeeTypeId').value;
    const mandatory = this.newJoinerForm.get('mandatory');
    if (employeeType === 2) {
      // Contractor
      mandatory.get('travelId').setValue(2, { onlySelf: true, emitEvent: true }); // limited
      mandatory.get('universityId').setValue(3, { onlySelf: true, emitEvent: true }); // unknown
    }
  }

  save() {
    this.isDisabled = true;
    const newJoiner = new EmployeeDetails();

    this.mandatoryChild.unsubscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.unsubscribeSpecialEnums();
    }

    this.unsubscribeSpecialEnums();

    Object.keys(this.newJoinerForm.controls).forEach(group => {
      const groupControl = this.newJoinerForm.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        const controler = groupControl.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();
        if (controler.enabled && controler.value) {
          if (controler.value instanceof Date) {
            newJoiner[controlerName] = Common.timezoneFix(controler.value);
          } else if (typeof controler.value === 'string') {
            newJoiner[controlerName] =
              isNaN(controler.value as any) || controler.value === ''
                ? controler.value.trim()
                : Number(controler.value);
            if (controlerName === 'enterpriseDomainId') {
              controler.setValue(controler.value.replace(/\s/g, ''));
            }
          } else {
            newJoiner[controlerName] = controler.value;
          }
        }
      });
    });

    this.mandatoryChild.subscribeSpecialEnums();

    if (this.optionalChild) {
      this.optionalChild.subscribeSpecialEnums();
    }

    this.subscribeSpecialEnums();

    newJoiner.hashed = false;
    if (newJoiner.trainee === undefined) {
      newJoiner.trainee = false;
    }
    if (this.newJoinerForm.valid) {
      this.employeeDetailsService.addNewEmployee(newJoiner).subscribe(
        res => {
          this.handleSucces(res);
        },
        err => {
          this.handleError(err);
        }
      );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.isDisabled = false;
    }
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        const validators = [];
        setting.validation.forEach(validation => {
          if (
            validation === 'valRequiredEId' ||
            validation === 'valRequiredPersonalNumber' ||
            validation === 'valRequiredForNoCc' ||
            validation === 'valOtherStatus' ||
            validation === 'valDigitalPlatformRequired' ||
            validation === 'valRequiredIndustrySkill' ||
            validation === 'valRequiredIndustryPro' ||
            validation === 'valRequiredPrimarySkill' ||
            validation === 'valRequiredPrimaryPro'
          ) {
            validators.push(validationUtils[validation](this.employeeDetails));
          } else {
            validators.push(validationUtils[validation]());
          }
        });
        this.newJoinerForm.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newJoinerForm.get(set).get(setting.id).disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }

  onFormChanges(changes) {
    const mandatory = this.newJoinerForm.get('mandatory');
    const optional = this.newJoinerForm.get('optional');
    const top = this.newJoinerForm.get('top');
    const employeeType = top.get('employeeTypeId').value;
    const isTrainee =
      mandatory.get('statusId').value === 7 ||
      mandatory.get('statusId').value === 9 ||
      mandatory.get('statusId').value === 10;

    Object.keys(mandatory['controls']).forEach(controler => {
      this.enableField(mandatory.get(controler), true);
    });
    Object.keys(optional['controls']).forEach(controler => {
      this.enableField(optional.get(controler), true);
    });

    switch (employeeType) {
      case 2: // Contractor
        this.disableField(mandatory.get('personalNumber'), true, '');
        this.disableField(optional.get('careerCounsellorEid'), true, '');
        break;
      case 3: // New Joiner
        this.disableField(mandatory.get('personalNumber'), true, '');
        if (!isTrainee) {
          this.disableField(mandatory.get('enterpriseDomainId'), true, '');
        }
        break;
      case 7: // People Lead
        Object.keys(mandatory['controls']).forEach(controler => {
          if (controler !== 'enterpriseDomainId') {
            if (controler === 'active') {
              this.disableField(mandatory.get(controler), true);
            } else {
              this.disableField(mandatory.get(controler), true, '');
            }
          }
        });
        Object.keys(optional['controls']).forEach(controler => {
          this.disableField(optional.get(controler), false, '');
        });
        break;
      default:
        break;
    }
  }

  disableField(control, emitEvent: boolean, newValue?) {
    const opts = { onlySelf: true, emitEvent: emitEvent };
    if (control.enabled) {
      control.disable(opts);
      if (newValue !== undefined) {
        control.setValue(newValue, opts);
      }
    }
  }

  enableField(control, emitEvent: boolean, newValue?) {
    const opts = { onlySelf: true, emitEvent: emitEvent };
    if (control.disabled) {
      control.enable(opts);
      if (newValue !== undefined) {
        control.setValue(newValue, opts);
      }
    }
  }

  handleSucces(response) {
    this.snackbar.success('Employee created - redirecting to employee details');
    this.done = 1;

    setTimeout(() => {
      this.router.navigate(['/employee-details', response.employee_detail_id]);
    }, 1500);
  }

  handleError(error: HttpErrorResponse) {
    const body = error.error;
    const errorMessage = 'Could not create employee: ' + body[0].field + ' ' + body[0].code;

    this.snackbar.error(errorMessage);
  }

  handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { fieldsSettings } from '../../types/report';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { AppComponent } from '../../app.component';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MONTH_YEAR_FORMAT } from '../../types/date-format';

@Component({
  selector: 'app-nsmetrics-report',
  templateUrl: './nsmetrics-report.component.html',
  styleUrls: ['./nsmetrics-report.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MONTH_YEAR_FORMAT,
    },
  ],
})
export class NsmetricsReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportOptionsForm: FormGroup;

  creatingRole: boolean;
  editingRole: boolean;
  date = new FormControl(new Date());
  choosedMonth: number = new Date().getMonth() + 1;
  choosedYear: number = new Date().getFullYear();
  exporting = false;
  title: string = 'NS & Metrics Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private location: Location,
    private fb: FormBuilder,
    private reportService: ReportService,
    private dialog: MatDialog,
    public app: AppComponent
  ) {}

  ngOnInit() {
    this.snackbar.close();
    const [fieldFormMandatory] = [new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    this.exportOptionsForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
    });
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }

  exportNSMetricsReport() {
    this.exporting = true;
    this.reportService
      .exportNSMetricsReport(this.choosedMonth, this.choosedYear)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  chosenMonthHandler(date: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue = this.date.value;
    ctrlValue.setMonth(date.getMonth());
    ctrlValue.setFullYear(date.getFullYear());
    const dateValue: Date = ctrlValue;
    this.choosedMonth = new Date(dateValue).getMonth() + 1;
    this.choosedYear = new Date(dateValue).getFullYear();
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  { cardType: 'bool', id: 'dcso', label: 'DCSO', validation: [], values: [false, []] },
  {
    cardType: 'input',
    id: 'validity',
    label: 'DCSO Validity Date',
    type: 'date',
    validation: ['valDate', 'valRequired'],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'bool',
    id: 'credentials',
    label: 'Credentials',
    validation: [],
    values: [false, []],
  },
  {
    cardType: 'input',
    id: 'credentialsUpdate',
    label: 'Credentials Last Update',
    type: 'date',
    validation: ['valDate', 'valRequired'],
    values: ['', []],
    disabled: true,
  },
  { cardType: 'input', id: 'description', label: '', validation: [], values: ['', []] },
];

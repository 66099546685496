import { distinctUntilChanged } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { fieldsSettings } from '../../../types/project-search-fields';
import { errorMessages } from '../../../types/input-errors';
import { User } from '../../../services/user-roles/user';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { CommonComponent } from '../../../shared/common/common.component';
import { ProjectSearch } from '../../../types/project-search-result';
import { DialogData } from '../../../types/dialog-data';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { VersionDataService } from 'app/version-data.service';
import { AuthService } from '../../../services/auth.service';
import { CustomDateAdapter } from 'app/shared/date-utils';
import { ListColumnSettingsComponent } from '../../list-column-settings/list-column-settings.component';
import { ListSearchParams } from '../../../types/list-search-params';

@Component({
  selector: 'app-default-list',
  templateUrl: './default-list.component.html',
  styleUrls: [
    './default-list.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent, { provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class DefaultListComponent extends CommonComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dateFormatter = new DatePipe('en-US');
  dateFormatMmYyyy = 'MM/yyyy';
  dateFormatMmDdYyyy = 'MM/dd/yyyy';

  salesStage = 2;
  isProject = false;
  completedUrl = '/opportunity-details/';
  status = 'In-Progress    ';
  statusId = 3;
  selectedStatuses = null;

  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  defaultSearchObject = {
    pageNumber: 0,
    pageSize: 15,
    project: this.isProject,
    status: this.status,
    salesStage: this.salesStage,
  };

  displayedColumns = [
    'projectId',
    'name',
    'client',
    'ddcIndustryGroup',
    'avanade',
    'dcResponsibleName',
    'startDate',
    'endDate',
    'lastUpdate',
    'status',
  ];
  dateColumns = ['startDate', 'endDate', 'lastUpdate', 'lastReview'];
  dataSource = new MatTableDataSource();

  creatingRole: boolean;
  editingRole: boolean;

  dcResponsible: string;

  totalElements = 0;
  pageIndex = 0;
  pageSize = 15;
  pageSizeOptions = [15, 30, 50];

  dialogRefInfoDialog: any;
  listLoaded = false;
  reloadSettings: number;
  searchParams: ListSearchParams;
  exporting = false;

  userDatabasePrimaryKey: number;

  constructor(
    private versionDataService: VersionDataService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public userRolesService: UserRolesService,
    public projectService: ProjectService,
    public enumsService: EnumsService,
    public snackbar: SnackbarPanelComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  protected populateDropdowns(enums) {
    const avanade: { id: number; stage: number; text: string; value: boolean }[] = [
      { id: 1, stage: 1, text: 'Yes', value: true },
      { id: 2, stage: 2, text: 'No', value: false },
    ];

    this.data['avanadeEnum'] = avanade;
    this.data['statusEnum'] = enums['status-enum'];
    this.data['clientGroupEnum'] = enums['client-group'];
    this.data['salesStageEnum'] = enums['sales-stage'].slice(0, 2);
    this.getUserAndDefaultFilters();
  }

  getUserAndDefaultFilters() {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.projectService.getDefaultFilters(user.eid, this.isProject).subscribe(
          result => this.saveFilterSettings(result),
          error => this.handleError(error)
        );
      });
  }

  onMonthSelect(datePickerData, pickerRef, pickerId) {
    // set the value to form and close the picker
    this.form.get(pickerId).setValue(datePickerData);
    pickerRef.close();
    this.prepareSearch();
  }

  openOpportunityDetail(row) {
    if (window.location.href.indexOf('new') !== -1) {
      this.router.navigate(['/new' + this.completedUrl, row.projectId]);
    } else {
      this.router.navigate([this.completedUrl, row.projectId]);
    }
  }

  prepareSearch(init?: boolean) {
    let searchParams = this.createSearchObjectForOpportunityList();
    // when search is default object
    if (Object.is(this.defaultSearchObject, searchParams)) {
      // but lets check if we have something saved up
      const savedSearchParams = this.projectService.opportunityListParams;
      // if we have something there and its init so we can retrieve state
      if (savedSearchParams && init) {
        // check if its like the default one then return default
        // if not then retrieve it and set it for the needed argument
        searchParams = Object.is(this.defaultSearchObject, savedSearchParams)
          ? this.defaultSearchObject
          : savedSearchParams;
        // if not then we save it to service
      } else {
        this.projectService.opportunityListParams = searchParams;
      }
      // when search is different from default then save it to service
    } else {
      this.projectService.opportunityListParams = searchParams;
    }
    this.pageIndex = 0;
    this.searchProjects(searchParams);
  }

  createSearchObjectForOpportunityList(): ListSearchParams {
    let searchParams = {} as ListSearchParams;
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.controls[key];
      if (control.valid && control.value) {
        if (key === 'status') {
          searchParams[key] = this.data['statusEnum'].filter(
            option => option.id === control.value
          )[0]['text'];
        } else if (key === 'avanade') {
          searchParams[key] =
            this.data['avanadeEnum'].filter(option => option.id === control.value)[0]['text'] ===
            'Yes'
              ? true
              : false;
        } else if (control.value instanceof Date) {
          searchParams[key] = this.dateFormatter.transform(control.value, this.dateFormatMmYyyy);
        } else {
          searchParams[key] = control.value;
        }
      }
    });

    searchParams = Object.assign(searchParams, {
      pageSize: this.pageSize,
      pageNumber: this.pageIndex,
    });
    this.form.controls.dcResponsibleName.valueChanges.subscribe(data => {
      if (data != null) {
        this.dcResponsible = data.value;
      }
    });

    if (this.userDatabasePrimaryKey == null) {
      this.form.get('status').setValue(3);
      searchParams['status'] = 'In-Progress    ';
      if (this.dcResponsible !== undefined) {
        searchParams['dcResponsibleName'] = this.dcResponsible;
      } else {
        this.dcResponsible = undefined;
      }
    }

    searchParams['project'] = this.isProject;

    if (this.selectedStatuses != null) {
      searchParams['selectedStatuses'] = this.selectedStatuses;
      searchParams['include'] = true;
    }

    return searchParams;
  }

  saveFilterSettings(result: ListColumnSettingsComponent['settings']) {
    let searchParams = {} as ListSearchParams;
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValue(null);
    });

    if (result != null) {
      this.userDatabasePrimaryKey = result.id;
      if (result.projectId !== null) {
        searchParams['projectId'] = result.projectId;
      }
      if (result.projectName !== '') {
        searchParams['name'] = result.projectName;
      }
      if (result.client !== '') {
        searchParams['client'] = result.client;
      }
      if (result.dcResponsibleName !== '') {
        searchParams['dcResponsibleName'] = result.dcResponsibleName;
      }
      if (result.ddcIndustryGroup !== null) {
        searchParams['ddcIndustryGroup'] = result.ddcIndustryGroup;
      }
      if (result.avanade !== null) {
        searchParams['avanade'] = result.avanade;
      }
      if (result.newBusinessMeeting !== null) {
        searchParams['newBusinessMeeting'] = result.newBusinessMeeting;
      }
      if (result.status !== null) {
        searchParams['status'] = result.status;
        if (searchParams['status'] === undefined) {
          delete searchParams['status'];
        }
      }
      if (result.itemsPerPage !== null) {
        this.pageSize = result.itemsPerPage;
      }

      searchParams = Object.assign(searchParams, {
        pageSize: this.pageSize,
        pageNumber: this.pageIndex,
      });
      searchParams['project'] = this.isProject;
      this.searchProjects(searchParams, true);
    } else {
      this.prepareSearch(true);
    }
  }

  routeToAddNewProject() {
    const routerLink = '/project-details/add-new-project-view';
    this.router.navigate([routerLink], { queryParams: { returnUrl: this.router.url } });
  }

  searchProjects(searchParams?: ListSearchParams, savedParams?: boolean) {
    this.listLoaded = false;

    // Set parameters for project/opportunity list export
    this.searchParams = searchParams;

    // Set values of form by searched params
    Object.keys(this.form.controls).forEach(key => {
      if (searchParams[key] || (key === 'avanade' && searchParams[key] === false)) {
        if (key === 'status') {
          let status = '';
          if (savedParams) {
            status = this.data['statusEnum'].filter(option => option.id === searchParams[key])[0][
              'text'
            ];
          }
          if (status.includes('+')) {
            this.selectedStatuses = [3, 8];
            delete searchParams[key];
            this.form.controls[key].setValue(9);
            searchParams['selectedStatuses'] = this.selectedStatuses;
            searchParams['include'] = this.selectedStatuses != null ? true : false;
          } else if (savedParams) {
            const resultStatusId = this.data['statusEnum'].filter(
              option => option.id === searchParams[key]
            )[0].id;
            this.form.controls[key].setValue(resultStatusId);
            const resultStatusText = this.data['statusEnum'].filter(
              option => option.id === searchParams[key]
            )[0].text;
            searchParams[key] = resultStatusText;
          } else {
            const resultStatusId = this.data['statusEnum'].filter(
              option => option.text === searchParams[key]
            )[0].id;
            this.form.controls[key].setValue(resultStatusId);
          }
        } else if (key === 'avanade') {
          const resultAvanadeId = this.data['avanadeEnum'].filter(
            option => option.value === searchParams[key]
          )[0].id;
          this.form.controls[key].setValue(resultAvanadeId);
        } else if (key === 'salesStage') {
          const resultSalesStageId = this.data['salesStageEnum'].filter(
            option => option.stage === searchParams[key]
          )[0].id;
          searchParams[key] = resultSalesStageId;
          this.form.controls[key].setValue(resultSalesStageId);
        } else if (this.dateColumns.some(column => column === key)) {
          // create format of DD/MM/YYYY where DD is the first day and set it to the form
          const split = searchParams[key].split('/');
          const dateValue = new Date(`${split[0]}/01/${split[1]}`);
          if (dateValue instanceof Date) {
            this.form.controls[key].setValue(dateValue);
          }
        } else if (key === 'ddcIndustryGroup' && savedParams) {
          let resultDdcIndustryGroupText = this.data['clientGroupEnum'].filter(
            option => option.id === searchParams[key]
          )[0].text;
          resultDdcIndustryGroupText = resultDdcIndustryGroupText.trim();
          this.form.controls[key].setValue(resultDdcIndustryGroupText);
          searchParams[key] = resultDdcIndustryGroupText;
        } else {
          this.form.controls[key].setValue(searchParams[key]);
        }
      }
    });
    this.projectService.getProjectList(searchParams).subscribe(
      response => this.handleProjects(response),
      error => this.handleError(error)
    );
  }

  page(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.prepareSearch();
  }

  transformData(projects: Array<ProjectSearch>): Array<ProjectSearch> {
    projects.forEach(project => {
      project.startDate = this.dateFormatter.transform(project.startDate, this.dateFormatMmYyyy);
      project.endDate = this.dateFormatter.transform(project.endDate, this.dateFormatMmYyyy);
      if (project.avanade != null) {
        project.avanade ? (project.avanade = 'Yes') : (project.avanade = 'No');
      }
      project.lastUpdate = this.dateFormatter.transform(
        project.lastUpdate,
        this.dateFormatMmDdYyyy
      );
    });

    return projects;
  }

  handleProjects(data: any): void {
    console.log({ projects: data });
    data.projects.forEach(element => {
      switch (element.salesStage) {
        case 1:
          element.salesStage = 'Lead';
          break;
        case 2:
          element.salesStage = 'Opportunity';
          break;
        case 3:
          element.salesStage = 'Project';
          break;
      }
    });

    this.totalElements = data.totalElements;
    this.dataSource.data = this.transformData(data.projects);
    this.listLoaded = true;
  }

  columnSettings() {
    const dialogRef = this.dialog.open(ListColumnSettingsComponent, {
      width: '500px',
      data: {
        projectFlag: this.isProject,
        primaryKey: this.userDatabasePrimaryKey,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveFilterSettings(result);
        this.reloadSettings = new Date().getTime();
      }
    });
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  exportProjectList() {
    this.exporting = true;
    this.projectService
      .projectListExport(this.searchParams, 'xlsx')
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSuccess(result),
        error => this.handleExportError(error)
      );
  }

  handleExportSuccess(result: any) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    // IE
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(result.body, filename);
    } else {
      // other browsers
      const urlObject = window.URL.createObjectURL(result.body);
      const element = document.createElement('a');
      element.href = urlObject;
      element.download = filename;
      document.body.appendChild(element);
      element.click();
    }
  }

  handleExportError(error: any) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }
}

import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as validationUtils from '../../../shared/validators';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-hardware';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../../services/project.service';
import { EnumsService } from '../../../services/enumerations/enums.service';

@Component({
  selector: 'app-hardware-dialog',
  templateUrl: './hardware-dialog.component.html',
  styleUrls: ['./hardware-dialog.component.scss', './../project-seating-hardware.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class HardwareDialogComponent implements OnInit {
  editing: boolean;
  fieldsSettings = fieldsSettings;
  form: FormGroup;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};

  data = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<HardwareDialogComponent>,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private enumsService: EnumsService
  ) {}

  ngOnInit() {
    const fields = new Object();

    this.fieldsSettings.forEach(setting => {
      fields[setting.id] = [this.dialogData.row[setting.id], []];
    });

    this.form = this.fb.group(fields);
    this.setValidators();
    this.setFields();
    this.form.valueChanges.subscribe(changes => this.onFormChanges(changes));

    this.form.get('wbs').valueChanges.subscribe(value => this.onWbsCommentChange(value));
    this.form.get('comment').valueChanges.subscribe(value => this.onWbsCommentChange(value));
  }

  setFields() {
    if (this.form.get('wbs').value && !this.form.get('comment').value) {
      this.form.get('wbs').enable({ onlySelf: true, emitEvent: false });
      this.form.get('comment').disable({ onlySelf: true, emitEvent: false });
    } else if (!this.form.get('wbs').value && this.form.get('comment').value) {
      this.form.get('wbs').disable({ onlySelf: true, emitEvent: false });
      this.form.get('comment').enable({ onlySelf: true, emitEvent: false });
    } else if (!this.form.get('wbs').value && !this.form.get('comment').value) {
      this.form.get('wbs').enable({ onlySelf: true, emitEvent: false });
      this.form.get('comment').enable({ onlySelf: true, emitEvent: false });
    }
  }

  onWbsCommentChange(value: boolean) {
    this.setFields();
  }

  setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        validators.push(validationUtils[validation]());
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.form.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  create(create: boolean) {
    if (create) {
      const hardware = {
        projectId: this.dialogData.projectId,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        hardware[controlerName] = this.form.get(controlerName).value;
      });

      if (this.form.valid) {
        this.projectService.editHardware(this.dialogData.row.id, hardware).subscribe(
          res => this.handleHardware(res),
          err => this.handleError(err)
        );
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  private handleHardware(response) {
    this.snackbar.success('Hardware has been saved');
    this.dialogRef.close(true);
  }

  private handleError(error) {
    this.snackbar.error('Problem with saving hardware');
  }
}

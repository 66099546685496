import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  { cardType: '', id: 'actions', label: 'Actions', validation: [], type: '', values: ['', []] },
  { cardType: '', id: 'id', label: 'ID', validation: [], type: '', values: ['', []] },
  {
    cardType: 'enum',
    data: 'statusEnum',
    id: 'statusId',
    label: 'Status',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'plComment',
    label: 'PL Comment',
    validation: [],
    type: 'textarea',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'creationDate',
    label: 'Creation Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'targetDate',
    label: 'Target Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'updatedTargetDate',
    label: 'Updated Target Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    data: 'projectEnum',
    id: 'projectId',
    label: 'Project / Opportunity Name',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    data: 'platformEnum',
    id: 'platform',
    label: 'Platform',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    data: 'domainEnum',
    id: 'domain',
    label: 'Domain',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: 'enum',
    data: 'hiringPriorityEnum',
    id: 'priorityId',
    label: 'Hiring Priority',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: 'enum',
    data: 'profileEnum',
    id: 'profileId',
    label: 'Profile',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: 'enum',
    data: 'typeEnum',
    id: 'typeId',
    label: 'Type',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: 'enum',
    data: 'locationEnum',
    id: 'locationId',
    label: 'Location',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'mainSkill',
    label: 'Main Skill',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: 'enum',
    id: 'additionalLanguageSkillId',
    label: 'Additional Language Skill',
    validation: [],
    type: 'enum',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'mySchedulingId',
    label: 'myScheduling ID',
    validation: [],
    type: 'input',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'newJoinerName',
    label: 'NJ Name',
    validation: [],
    type: 'input',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'estimatedHireDate',
    label: 'Estimated Hire Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'specialEstimatedHireDate',
    label: 'Special Estimated Hire Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'updateddHireDate',
    label: 'Updated Hire Date',
    validation: ['valDate'],
    type: 'date',
    values: ['', []],
  },
  // { cardType: '', id: 'rtScStComment', label: 'RT/SC/ST Comment',
  //   validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'requisitionId',
    label: 'Recruiting Requisition ID',
    validation: ['valInteger'],
    type: 'input',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'commonDemandId',
    label: 'Common Demand ID',
    validation: ['valInteger'],
    type: 'input',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'assignedTo',
    label: 'Assigned To',
    validation: [],
    type: 'input',
    values: ['', []],
  },
];

export const newRequestSettings: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'plComment',
    label: 'PL Comment',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'targetDate',
    label: 'Target Date',
    validation: [],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'projectOpportunityName',
    label: 'Project / Opportunity Name',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'platform', label: 'Platform', validation: [], type: '', values: ['', []] },
  { cardType: '', id: 'domain', label: 'Domain', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'hiringPriority',
    label: 'Hiring Priority',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'profile', label: 'Profile', validation: [], type: '', values: ['', []] },
  { cardType: '', id: 'type', label: 'Type', validation: [], type: '', values: ['', []] },
  { cardType: '', id: 'location', label: 'Location', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'mainSkill',
    label: 'Main Skill',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'languageSkill',
    label: 'Additional Language Skill',
    validation: [],
    type: '',
    values: ['', []],
  },
];

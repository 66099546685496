import { Component, OnInit } from '@angular/core';
import { projectsEnumMapping } from '../../../types/enum.mapping';
import { ProjectCardItemComponent } from '../project-card-item.component';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ClientListService } from '../../../services/client-list.service';
import { FormGroup } from '@angular/forms';
import * as validationUtils from '../../../shared/validators';
import _ from 'lodash';

@Component({
  selector: 'app-project-card-item-enum',
  templateUrl: './project-card-item-enum.component.html',
  styleUrls: [
    '../project-card-item.component.scss',
    './project-card-item-enum.component.scss',
    '../../../shared/card-item-enum.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardItemEnumComponent extends ProjectCardItemComponent implements OnInit {
  constructor(
    enumsService: EnumsService,
    snackbar: SnackbarPanelComponent,
    private clientListService: ClientListService
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.id === 'clientId') {
      this.clientListService.getData().subscribe(data => (this.enumeration = data));
    } else {
      this.enumsService.getData().subscribe(enums => {
        this.enums = JSON.parse(JSON.stringify(enums));
        this.isEnum = true;

        if (this.id === 'statusId') {
          this.enums['status-enum'] = enums['status-enum'].filter(value => value.id !== 7);
        }

        if (this.id === 'regionId') {
          this.enums[projectsEnumMapping.get(this.id)].sort((a, b) => {
            return a.text >= b.text ? 1 : -1;
          });
        }

        if (
          this.disabled ||
          (this.id === 'commitment' && this.projectDetails.projectSchedule.commitment === 100)
        ) {
          this.control.get(this.id).disable({ onlySelf: true, emitEvent: false });
        }

        if (this.enums) {
          const enumName = projectsEnumMapping.get(this.id);
          this.enumeration = this.enums[enumName];

          if (this.id === 'subIndustryId') {
            this.enumeration = this.enums['sub-industries'].filter(
              value => value.idIndustry === this.control.get('clientGroupId').value
            );
          }
          if (this.id === 'countryId') {
            this.enumeration = this.enums['country'].filter(
              value => value.regionId === this.control.get('regionId').value
            );
          }
          if (this.id === 'commitment') {
            this.enumeration = this.enumeration.filter(value => value.id > 0 && value.id < 100);
          }

          if (this.enumeration == null) {
            console.error(`missing enum ${this.id}`);
          }
        }
      });
    }
  }

  changeCountry(region: number) {
    this.enumeration = this.enums['country'].filter(value => value.regionId === region);
    if (this.enumeration.length > 0) {
      this.control.get(this.id).setValue(this.enumeration[0].id);
    }
  }

  changeDomain(platform: number) {
    this.enumeration = this.enums['domain'].filter(
      value => value.platformId === platform && value.sharedService !== true
    );
    if (this.enumeration.length > 0) {
      this.control.get(this.id).setValue(this.enumeration[0].id);
    }
  }

  changeClient(data: any) {
    this.enumeration = data;
  }

  changeSharedService(workCategory: number) {
    if (
      workCategory === 1 ||
      workCategory === 5 ||
      workCategory === 3 ||
      workCategory === 7 ||
      workCategory === 9
    ) {
      this.control.get(this.id).disable();
    } else {
      this.control.get(this.id).enable();
    }
    this.handleSharedServiceValidations(workCategory);
  }

  handleSharedServiceValidations(workCategory: number) {
    if (workCategory === 4 || workCategory === 8) {
      this.control.get(this.id).setValidators([validationUtils['valRequiredSharedService']()]);
    } else {
      this.control.get(this.id).setValidators([]);
    }
    this.control.get(this.id).markAsTouched();
    this.control.get(this.id).updateValueAndValidity();
  }

  changeSubIndustry(industryGroup: any) {
    this.enumeration = this.enums['sub-industries'].filter(
      value => value.idIndustry === industryGroup
    );
    if (this.enumeration.length > 0) {
      this.control.get(this.id).setValue(this.enumeration[0].id);
    }
  }
}

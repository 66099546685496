import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { EmployeeDetailsService } from './employee-details.service';
import { EmployeeDetails } from '../../types/employee-details';
import { bannerCard } from '../../types/employee-banner-card-content';
import * as cards from '../../types/employee-cards-content';
import { User } from '../../services/user-roles/user';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { Location } from '@angular/common';
import { DialogData } from '../../types/dialog-data';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { CardComponent } from '../card/card.component';
import { CanComponentDeactivate } from '../../services/can-deactivate-guard.service';
import { VersionDataService } from '../../version-data.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class EmployeeDetailsComponent implements OnInit, CanComponentDeactivate {
  @ViewChildren('card') cardComponents: QueryList<CardComponent>;

  originCostCentersId: any;
  employeeDetails: EmployeeDetails;
  errorEmployeeDetails: Error;
  errorCode: number;
  searchedId: any;
  selectedTab: number;
  employeeEditRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;
  importingRole: boolean;
  anotherCard: any;

  employeeCards = [];
  bannerCard = bannerCard;

  constructor(
    private versionDataService: VersionDataService,
    private snackbar: SnackbarPanelComponent,
    private dialog: MatDialog,
    private employeeDetailsService: EmployeeDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private userRolesService: UserRolesService,
    private location: Location,
    public app: AppComponent
  ) {}

  ngOnInit() {
    if (this.route.snapshot.routeConfig.path.includes('old/new')) {
      this.router.navigate(['/employee-details']);
    }

    Object.keys(cards).forEach(element => {
      this.employeeCards.push(cards[element]);
    });

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.employeeEditRole = user.getRoles().employeeEdit;
        this.creatingRole = user.getRoles().creating;
        this.deletingRole = user.getRoles().employeeDelete;
        this.importingRole = user.getRoles().importing;
      });
    this.selectedTab = this.router.url.indexOf('#assignments') !== -1 ? 1 : 0;
  }

  canDeactivate() {
    let editingComponent;

    this.cardComponents.forEach(cardComponent => {
      if (cardComponent.editing) {
        editingComponent = cardComponent;
      }
    });

    if (editingComponent) {
      return editingComponent.editWarningDialog();
    }

    return true;
  }

  navigateToEmployeeDetails(): void {
    this.router.navigate(['/employee-details', 'add-new-joiner']);
  }

  import(): void {
    this.router.navigate(['/absences', 'import']);
  }

  deleteEmployeeDialog() {
    const data: DialogData = {
      title: 'Delete employee',
      content: 'Do you really want to delete employee?',
      action1: 'delete',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteEmployee(this.employeeDetails.id);
      }
    });
  }

  deleteEmployee(id): void {
    this.employeeDetailsService
      .deleteEmployee(id)
      .pipe(distinctUntilChanged())
      .subscribe(
        response => this.handleEmployeeDeletition(response),
        error => this.handleDeleteError(error)
      );
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab === 0) {
      this.location.go(this.router.url.replace('#assignments', ''));
    } else if (this.router.url.indexOf('#assignments') === -1) {
      this.location.go(this.router.url.concat('#assignments'));
    }
  }

  evaluateParams(params: Params): void {
    const searchedId = params['searchedId'];
    if (searchedId != null) {
      if (searchedId === '') {
        this.handleError('Invalid Employee ID route.', 500);
      } else {
        this.searchedId = searchedId;
        // When user is searching repeatedly, change to first tab
        if (this.employeeDetails) {
          this.selectedTab = 0;
        }

        this.getEmployeeDetails(searchedId);
      }
    } else {
      // this.enumsService.clearAllEnums();
    }
  }

  getAllEmployeeDetails(enterpriseDomainId: string) {
    this.getEmployeeDetails(enterpriseDomainId);
  }

  getEmployeeDetails(searchedId): void {
    this.employeeDetailsService
      .getEmployeeDetails(searchedId)
      .pipe(distinctUntilChanged())
      .subscribe(
        employeeDetails => {
          this.handleEmployeeDetails(employeeDetails);
        },
        error => this.handleError(error, error['status'])
      );
  }

  handleEmployeeDeletition(response): void {
    this.employeeDetails = null;
    this.errorEmployeeDetails = null;
    this.errorCode = null;

    this.snackbar.success('Employee has been deleted');

    setTimeout(() => {
      this.router.navigate(['/employee-details']);
    }, 1500);
  }

  handleEmployeeDetails(employeeDetails: any): void {
    //  saving original value of costCentersId
    this.originCostCentersId = employeeDetails.costCentersId;

    //  setting costCentersId based on updateCC

    if (employeeDetails.updateCc) {
      employeeDetails.costCentersId = [employeeDetails.cctypeId];
    }
    this.employeeDetails = employeeDetails;
    this.errorEmployeeDetails = null;
    this.errorCode = null;
  }

  handleDeleteError(error: Response) {
    this.snackbar.error('We could not delete Employee due to error');
  }

  handleError(error: Response | string, errorCode: number) {
    const errorText = error instanceof Response ? error.statusText : error;
    const err: Error = new Error(errorText);
    this.errorEmployeeDetails = err;
    this.employeeDetails = null;

    if (errorCode === 404) {
      console.log('error employee not found', this.errorEmployeeDetails, this.employeeDetails);
    } else if (errorCode === 403) {
      console.log('access forbidden', this.errorEmployeeDetails, this.employeeDetails);
    } else if (errorCode === 500) {
      console.log('server error', this.errorEmployeeDetails, this.employeeDetails);
    }

    this.errorCode = errorCode;
  }

  notify(card) {
    this.anotherCard = card;
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

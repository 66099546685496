export class HiringRequestDetails {
  id: number;
  statusId: string;
  plComment: string;
  creationDate: string;
  targetDate: string;
  updatedTargetDate: string;
  projectId: number;
  platformId: string;
  domainId: string;
  priorityId: string;
  profileId: string;
  typeId: string;
  locationId: string;
  mainSkill: string;
  additionalLanguageSkillId: string;
  mySchedulingId: number;
  newJoinerName: string;
  estimatedHireDate: string;
  specialEstimatedHireDate: string;
  assignedTo: string;

  location: string;
  status: string;
  projectName: string;
  platform: string;
  domain: string;
  type: string;
  profile: string;
  editable: boolean;
}

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'input',
    id: 'clientName',
    label: 'Client Name',
    validation: ['valRequired'],
    values: ['', []],
  },
  {
    cardType: 'checkbox',
    id: 'diamondClient',
    label: 'Diamond Client',
    validation: [],
    values: ['', []],
  },
  { cardType: 'checkbox', id: 'g150', label: 'G150', validation: [], values: ['', []] },
];

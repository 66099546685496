import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BenchService } from 'app/services/bench.service';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import {
  BenchTracking,
  BenchTrackingList,
  BenchTrackingSearchParams,
} from 'app/types/bench-tracking';
import { fieldsSettings } from 'app/types/bench-tracking-column-settings';
import { benchCard } from 'app/types/employee-banner-card-content';
import { EmployeeDetails } from 'app/types/employee-details';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonComponent } from 'app/shared/common/common.component';
import { FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Location } from '@angular/common';
import {
  addDays,
  endOfISOWeek,
  format,
  getISOWeek,
  getYear,
  setISOWeek,
  setYear,
  startOfISOWeek,
  subWeeks,
} from 'date-fns';

@Component({
  selector: 'app-bench-tracking',
  templateUrl: './bench-tracking.component.html',
  styleUrls: [
    './bench-tracking.component.scss',
    '../../../app/shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class BenchTrackingComponent extends CommonComponent implements OnInit {
  @Input() employeeDetails: EmployeeDetails;

  @Input() platformLead: boolean = false;
  @Output() platformLeadChange = new EventEmitter<boolean>();

  @Input() exporting: boolean = false;
  @Output() exportingChange = new EventEmitter<boolean>();

  // Employee banner
  benchCard = benchCard;

  // Table
  enums = {};
  fieldsSettings = fieldsSettings;
  dataSource = new MatTableDataSource();
  displayedColumns = [];

  // Paginator
  totalElements = 0;
  pageIndex = 0;
  pageSize = 30;
  pageSizeOptions = [10, 30, 50];
  listLoaded = false;

  constructor(
    public fb: FormBuilder,
    public snackbar: SnackbarPanelComponent,
    private benchService: BenchService,
    private enumsService: EnumsService,
    protected router: Router,
    authService: AuthService,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();
    this.enumsService.getData().subscribe({
      next: enums => {
        this.populateDropdowns(enums);
        this.prepareSearch();
      },
      error: error => {
        this.handleLoadEnumsError();
      },
    });
    this.setDefaultColumns();
  }

  setDefaultColumns() {
    this.displayedColumns = this.fieldsSettings.map(setting => setting.id);
  }

  prepareSearch() {
    const searchParams = this.prepareSearchParams();
    this.searchBenchTracking(searchParams);
  }

  prepareSearchParams() {
    const searchParams = {} as BenchTrackingSearchParams;

    Object.keys(this.form.controls).forEach(controlerName => {
      const controler = this.form.get(controlerName);
      if (!controler.disabled && controler.valid && controler.value) {
        if (controlerName === 'weekDetail') {
          const { week, year } = JSON.parse(controler.value);
          searchParams.week = week;
          searchParams.year = year;
        } else {
          searchParams[controlerName] = controler.value;
        }
      }
    });

    searchParams.pageNumber = this.pageIndex;
    searchParams.pageSize = this.pageSize;
    return searchParams;
  }

  searchBenchTracking(searchParams: BenchTrackingSearchParams) {
    this.listLoaded = false;
    this.benchService.getBenchTrackingList(searchParams).subscribe({
      next: response => this.handleBenchTracking(response),
      error: error => this.handleError(error),
    });
  }

  handleBenchTracking(data: BenchTrackingList) {
    this.dataSource.data = this.transformData(data);
    this.totalElements = data.totalElements;
    this.listLoaded = true;

    this.platformLead = data.platformLead;
    this.platformLeadChange.emit(data.platformLead);
  }

  handleError(error: Response | string) {
    const errorText = error instanceof HttpErrorResponse ? error.error.message : error;
    this.snackbar.error(errorText);
  }

  exportBenchTrackingList() {
    this.setExportStatus(true);
    const params = this.prepareSearchParams();
    this.benchService
      .benchTrackingListExport(params, 'xlsx')
      .pipe(distinctUntilChanged())
      .subscribe({
        next: result => this.handleExportSuccess(result),
        error: error => this.handleExportError(error),
      });
  }

  handleExportSuccess(result: any) {
    console.log(result);

    this.setExportStatus(false);
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];

    // other browsers
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(error: any) {
    this.setExportStatus(false);
    this.snackbar.error('Report failed');
  }

  setExportStatus(status: boolean) {
    this.exporting = status;
    this.exportingChange.emit(status);
  }

  page(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.prepareSearch();
  }

  getWeekDetail(weekNumber: number, year: number): string {
    const startDate = startOfISOWeek(setISOWeek(setYear(new Date(), year), weekNumber));
    const endDate = addDays(startDate, 4); // Monday + 4 days = Friday

    const formattedStartDate = format(startDate, 'MM/dd/yyyy');
    const formattedEndDate = format(endDate, 'MM/dd/yyyy');

    return `${formattedStartDate} - ${formattedEndDate} (week ${weekNumber})`;
  }

  transformData(data: BenchTrackingList) {
    data.benchTracking.forEach(tracking => {
      tracking['taskCategoryId'] = this.enums['taskCategoryEnum'].find(
        option => option.id === tracking.taskCategoryId
      )['text'];
      tracking['employeeLocationId'] = this.enums['locationEnum'].find(
        option => option.id === tracking.employeeLocationId
      )['text'];
      tracking['employeePlatformId'] = this.enums['platformEnum'].find(
        option => option.id === tracking.employeePlatformId
      )['text'];
      tracking['employeeDomainId'] = this.enums['domainEnum'].find(
        option => option.id === tracking.employeeDomainId
      )['text'];
      tracking['weekDetail'] = this.getWeekDetail(tracking.week, tracking.year);
    });
    return data.benchTracking;
  }

  populateDropdowns(enums: any) {
    this.enums['taskCategoryEnum'] = enums['task-category'];
    this.enums['locationEnum'] = enums['employee-location'];
    this.enums['platformEnum'] = enums['platform'];
    this.enums['domainEnum'] = enums['domain'];
    this.enums['lastWeeks'] = this.getLastWeeks();
  }

  getLastWeeks() {
    const currentDate = new Date();
    const lastWeeks = [];

    for (let i = 0; i < 6; i++) {
      const startDate = startOfISOWeek(subWeeks(currentDate, i - 2));
      const endDate = addDays(startDate, 4); // Monday + 4 days = Friday
      const week = getISOWeek(startDate);
      const year = getYear(startDate);

      lastWeeks.push({
        currentWeek: week === getISOWeek(currentDate),
        value: JSON.stringify({ week: week, year: year }),
        text: `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')} (week ${week})`,
      });
    }

    return lastWeeks;
  }

  editTracking(row: BenchTracking) {
    this.router
      .navigate(['/bench-management/add-new-tracking'], {
        queryParams: { id: row.id },
      })
      .then();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { fieldsSettings } from '../../types/report';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { AppComponent } from '../../app.component';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';

@Component({
  selector: 'app-projects-roll-offs-overview',
  templateUrl: './projects-roll-offs-overview.component.html',
  styleUrls: ['./projects-roll-offs-overview.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
  ],
})
export class ProjectsRollOffsOverviewComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportOptionsForm: FormGroup;

  creatingRole: boolean;
  editingRole: boolean;
  exporting = false;
  title: string = "Projects' Roll-Offs Overview";

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private reportService: ReportService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.snackbar.close();

    const [fieldFormMandatory] = [new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    this.exportOptionsForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
    });
  }

  sendOverviewEmail() {
    this.exporting = true;
    this.reportService
      .exportProjectsRollOffsOverview()
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Email sent');
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Email failed');
    console.log(err);
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

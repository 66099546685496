import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BenchTrackingCommonFieldsComponent } from '../bench-tracking-common-fields/bench-tracking-common-fields.component';
import * as validationUtils from '../../../shared/validators';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import {
  addDays,
  format,
  getISOWeek,
  getYear,
  setISOWeek,
  setYear,
  startOfISOWeek,
  subWeeks,
} from 'date-fns';

@Component({
  selector: 'app-bench-tracking-mandatory-fields',
  templateUrl: './bench-tracking-mandatory-fields.component.html',
  styleUrls: ['./bench-tracking-mandatory-fields.component.scss'],
})
export class BenchTrackingMandatoryFieldsComponent
  extends BenchTrackingCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: FormGroup;

  data = {
    lastWeeks: [],
    requestedBy: [],
    taskCategoryEnum: [],
  };

  filteredValues = {
    requestedBy: new Observable<any[]>(),
  };

  ngOnInit() {
    super.ngOnInit();

    this.dcResponsibleService.getData().subscribe(data => {
      this.data['requestedBy'] = data;
      this.setValidatorsForRequestedBy();
    });

    this.mandatory.valueChanges.subscribe(changes => this.onFormChanges(changes, this.mandatory));
    this.fieldsSettings.mandatory.forEach(setting => {
      if (setting.cardType === 'autosearch') {
        this.filteredValues[setting.id] = this.mandatory.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value =>
            value ? this.filterStates(value, setting.id) : this.data[setting.id].slice()
          )
        );
        this.filteredValues[setting.id] = this.mandatory.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value => {
            if (value != null) {
              return value.length >= 3 ? this.filterStates(value, setting.id).slice(0, 50) : null;
            }
          })
        );
      }
    });
  }

  setValidatorsForRequestedBy() {
    const validators = [];
    validators.push(validationUtils['valRequired']);
    validators.push(validationUtils['valDcResponsibleAndDeputyFormat'](this.data['requestedBy']));
    this.mandatory.get('requestedBy').setValidators(validators);
    this.mandatory.get('requestedBy').updateValueAndValidity();
  }

  populateDropdowns(enums: any) {
    super.populateDropdowns(enums);

    this.data['lastWeeks'] = this.getLastWeeks();
    this.data['taskCategoryEnum'] = enums['task-category'];
  }

  getLastWeeks() {
    const { week, year } = this.mandatory.get('week').value
      ? JSON.parse(this.mandatory.get('week').value)
      : JSON.parse('{}');

    const currentDate =
      week && year ? startOfISOWeek(setISOWeek(setYear(new Date(), year), week)) : new Date();
    const lastWeeks = [];

    for (let i = 0; i < 6; i++) {
      const startDate = startOfISOWeek(subWeeks(currentDate, i - 2));
      const endDate = addDays(startDate, 4); // Monday + 4 days = Friday
      const week = getISOWeek(startDate);
      const year = getYear(startDate);

      lastWeeks.push({
        currentWeek: week === getISOWeek(currentDate),
        value: JSON.stringify({ week: week, year: year }),
        text: `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')} (week ${week})`,
      });
    }

    return lastWeeks;
  }
}

import { environment } from './environments/environment';

const prefix = environment.backendURL;

export const REST = {
  employeeDetailsSupplyDelete: id => `${prefix}/employee-detail/supplies/${id}`,
  employeeDetailsEdit: id => `${prefix}/employee-detail/${id}`,
  employeeDetailsDelete: id => `${prefix}/employee-detail/${id}`,
  employeeDetailsInsert: `${prefix}/employee-detail`,
  employeeDetailsAllList: searchedId => `${prefix}/employee-detail/listAll/${searchedId}`,
  employeeDetailsActiveList: searchedId => `${prefix}/employee-detail/listActive/${searchedId}`,
  employeeDetails: searchedId => `${prefix}/employee-detail/${searchedId}`,
  employeeDetailsSupplies: searchedId => `${prefix}/employee-detail/${searchedId}/supplies`,
  employeeDetailsAbsences: searchedId => `${prefix}/employee-detail/${searchedId}/absences`,
  employeeDetailsActiveEmployeesAllCareerCounselors: `${prefix}/employee-detail/list-available-career-counselors`,
  allCounselees: `${prefix}/user/list-counselees`,
  enums: `${prefix}/enumerations/all`,
  clearEnums: `${prefix}/enumerations/invalidate-cache`,
  absencesInsert: `${prefix}/absences/import`,
  authentification: `${prefix}/user/profile`,
  absenceInsert: `${prefix}/absences`,
  overtimeInsert: `${prefix}/employee-detail/import`,
  absenceEdit: id => `${prefix}/absences/${id}`,
  projectList: `${prefix}/project/search`,
  activeProjects: `${prefix}/project/activeProjects`,
  projectInsert: `${prefix}/project`,
  exportReport: `${prefix}/reports/newReport`,
  exportNSMetricsReport: (month, year) => `${prefix}/reports/NSMetricsReport/${month}/${year}`,
  exportAwsSeatingReport: (month, year) => `${prefix}/reports/AwsSeatingReport/${month}/${year}`,
  exportNBMReport: () => `${prefix}/reports/NBMReport`,
  exportOpenDemandsReport: (date, month, year) =>
    `${prefix}/reports/OpenDemandsReport/${date}/${month}/${year}`,
  exportDataFreshnessReport: (platform, location) =>
    `${prefix}/reports/DataFreshnessReport/${platform}/${location}`,
  exportOpenPositionsReport: (platform, location, onHold, dateFrom, dateTo) =>
    `${prefix}/reports/OpenPositionsReport/${platform}/${location}/${onHold}/${dateFrom}/${dateTo}`,
  exportVacationAndOvertimeReport: () => `${prefix}/reports/VacationAndOvertimeReport`,
  exportProjectSummaryReport: (type, inactive, location) =>
    `${prefix}/reports/ProjectSummaryReport/${type}/${inactive}/${location}`,
  exportSupplySummaryReport: (month, year, location) =>
    `${prefix}/reports/SupplySummaryReport/${month}/${year}/${location}`,
  exportLanguageSkillsUsageReport: (month, year, location) =>
    `${prefix}/reports/LanguageSkillsUsageReport/${month}/${year}/${location}`,
  exportSchedulingReport: (
    inactive,
    supplyHistoryFrom,
    supplyHistoryTo,
    reportingDate,
    platform,
    location,
    slovakFormat,
    country
  ) =>
    `${prefix}/reports/SchedulingReport/${inactive}/${supplyHistoryFrom}/${supplyHistoryTo}/${reportingDate}/${platform}/${location}/${slovakFormat}/${country}`,
  exportPlatformReport: (platform, location, month, year) =>
    `${prefix}/reports/PlatformReport/${platform}/${location}/${month}/${year}`,
  exportCostCenterReport: location => `${prefix}/reports/CostCenterReport/${location}`,
  exportOpportunitySummaryReport: (platform, numberOfMonths, reportDate, location) =>
    `${prefix}/reports/OpportunitySummaryReport/${platform}/${numberOfMonths}/${reportDate}/${location}`,
  exportHiringRequestsReport: (platform, location) =>
    `${prefix}/reports/HiringRequestsReport/${platform}/${location}`,
  exportProjectCostReport: (project, month, year) =>
    `${prefix}/reports/ProjectCostReport/${project}/${month}/${year}`,
  exportProjectsRollOffsOverview: () => `${prefix}/reports/ProjectsRollOffsOverview`,
  exportTrainingDataReport: () => `${prefix}/reports/TrainingDataReport`,
  projectDetails: searchedId => `${prefix}/project/${searchedId}`,
  projectDetailsEdit: id => `${prefix}/project/${id}`,
  clientAll: `${prefix}/client/listAll`,
  clientInsert: `${prefix}/client`,
  clientEdit: id => `${prefix}/client/${id}`,
  clientNotDeleted: `${prefix}/client/listNotDeleted`,
  clientNotDeletedString: `${prefix}/client/listNotDeletedString`,
  clientGetByName: `${prefix}/client`,
  contactAll: `${prefix}/contact/listAllEIds`,
  projectDcResponsible: `${prefix}/project/listAllDc`,
  projectIsUserDcResponsible: `${prefix}/project/isDc`,
  projectAttachments: id => `${prefix}/project/attachments/${id}`,
  projectAttachmentEdit: id => `${prefix}/project/attachment/${id}`,
  projectAttachmentDelete: id => `${prefix}/project/attachment/${id}`,
  projectAttachmentInsert: `${prefix}/project/attachment`,
  projectAttachmentDownload: id => `${prefix}/project/attachment/${id}`,
  projectSeatings: id => `${prefix}/seating/project/${id}`,
  projectFlexibleSeatings: id => `${prefix}/seating/flexible/${id}`,
  projectFlexibleSeatingsSaveSeatsRequested: (id, projectSeatsDemand) =>
    `${prefix}/project/seatsRequested/${id}/${projectSeatsDemand}`,
  projectUniversalWbs: id => `${prefix}/project/projectInfo/${id}`,
  projectUniversalWbsEdit: id => `${prefix}/project/projectInfo/${id}`,
  projectSeatingEdit: id => `${prefix}/seating/${id}`,
  projectSeatingInsert: `${prefix}/seating`,
  projectFlexibleSeatingInsert: `${prefix}/seating/flexible`,
  projectFlexibleSeatingEdit: `${prefix}/seating/flexible`,
  projectFlexibleSeatingDelete: seatingId => `${prefix}/seating/flexible/delete/${seatingId}`,
  projectHardware: id => `${prefix}/pc-assignments/hwList/${id}`,
  projectHardwareEdit: id => `${prefix}/pc-assignments/${id}`,
  projectReviewSeatingsHardware: id => `${prefix}/project/chargesReview/${id}`,
  projectReviewSeatingsHardwareAll: eid => `${prefix}/project/chargesReviewAll/${eid}`,
  projectReviewSeatingsHardwareActive: eid => `${prefix}/project/chargesReviewActive/${eid}`,
  projectReviewSeatingsHardwareDate: id => `${prefix}/seating/reportingPeriod/${id}`,
  projectReviewHardware: id => `${prefix}/pc-assignments/review/${id}`,
  projectNamesForDcResp: id => `${prefix}/project/namesForProjects/${id}`,
  searchOpenDemands: `${prefix}/openDemands/search?`,
  getOpenDemand: id => `${prefix}/openDemands/${id}`,
  updateOpenDemand: `${prefix}/openDemands`,
  addOpenDemand: `${prefix}/openDemands`,
  projectDemands: id => `${prefix}/demand/project/${id}`,
  projectDemand: id => `${prefix}/demand/${id}`,
  projectDemandsEdit: `${prefix}/demand`,
  projectDemandsCopy: `${prefix}/demand/makeCopy`,
  projectDemandInsert: id => `${prefix}/demand/project/${id}`,
  projectDemandDelete: id => `${prefix}/demand/${id}`,
  projectDemandIntersection: `${prefix}/demand/intersection`,
  projectDemandSoftlocks: demandId => `${prefix}/demand/searchSoftlocks/${demandId}`,
  projectDemandSoftlockCount: demandId => `${prefix}/demand/softlockCount/${demandId}`,
  projectDemandSoftlocked: id => `${prefix}/demand/demandSoftlocks/${id}`,
  projectDemandHardlockRequest: demandId => `${prefix}/demand/requestHardlock/${demandId}`,
  projectDemandHardlockRequestsHistory: demandId =>
    `${prefix}/demand/hardlockRequestHistory/${demandId}`,
  projectColumnSettings: eid => `${prefix}/user/columnDemandSettings/${eid}`,
  projectColumnSettingsEdit: eid => `${prefix}/user/saveSettings/${eid}`,
  projectHistory: id => `${prefix}/project/history/${id}`,
  projectReporting: id => `${prefix}/project/reporting/${id}`,
  myWizardReporting: id => `${prefix}/project/myWizardReporting/${id}`,
  isSubcoEnding: id => `${prefix}/project/isSubco/${id}`,
  projectReportingEdit: `${prefix}/project/reporting`,
  approveSeatsHardwareInsert: id => `${prefix}/seating/approveSeatingCharge/${id}`,
  approveEmployeeSeatsHardwareInsert: `${prefix}/seating/approveEmployeeCharge`,
  seatingSupplies: id => `${prefix}/seating/supplies/${id}`,
  projectDemandFteInsert: `${prefix}/demand/changeFte`,
  projectSeatingRemoveEmployeeEdit: id => `${prefix}/seating/removeEmployee/${id}`,
  projectDemandAlignSeats: id => `${prefix}/demand/seat/${id}`,
  projectDemandStartDate: id => `${prefix}/demand/startDate/${id}`,
  projectListExport: type => `${prefix}/project/export/${type}`,
  projectDemandsExport: (projectId, type) => `${prefix}/demand/export/${projectId}/${type}`,
  moveToSoftlockedAdd: (demandId, supplyId) =>
    `${prefix}/demand/saveSoftlock/${demandId}/${supplyId}`,
  moveFromSoftlockedDelete: supplyId => `${prefix}/demand/deleteSoftlock/${supplyId}`,
  requestHardlockInsert: (demandId, supplyId) =>
    `${prefix}/demand/requestHardlock/${demandId}/${supplyId}`,
  cancelHardlockDelete: (demandId, id) => `${prefix}/demand/cancelHR/${demandId}/${id}`,
  hardlockEmail: supplyId => `${prefix}/demand/getHRMail/${supplyId}`,
  seatingDateToShift: (demandId, employeeDetailId, newRollOff) =>
    `${prefix}/seating/${demandId}/${employeeDetailId}/${newRollOff}`,
  seatingDateToShiftMulitple: (demandIds, newRollOff) =>
    `${prefix}/seating/multipleDemandsSeating/${demandIds}/${newRollOff}`,
  shiftSeatingDateToEdit: (demandId, employeeDetailId) =>
    `${prefix}/seating/${demandId}/${employeeDetailId}`,
  shiftSeatingsDateToEdit: demandIds => `${prefix}/seating/multipleDemandsEdit/${demandIds}`,
  setDefaultFilters: (eid, projOpp) => `${prefix}/filter/${eid}/${projOpp}`,
  getDefaultFilters: (eid, projOpp) => `${prefix}/filter/${eid}/${projOpp}`,
  oldProjectDetails: `${prefix}/old/project/projectList.do?type=Project/`,
  updateRoles: `${prefix}/updateRoles`,
  refreshToken: `/refresh-token`,
  confirmResourcesAllProjects: `${prefix}/confirm-resources/projects`,
  confirmResourcesAllProjectsAndOpportunities: `${prefix}/confirm-resources/projectsAndOpportunities`,
  confirmResourcesOpenedDemands: projectId =>
    `${prefix}/confirm-resources/openedDemands/${projectId}`,
  confirmResourcesChangedSupplies: projectId =>
    `${prefix}/confirm-resources/changedDemands/${projectId}`,
  confirmResourcesDeleteHardlock: supplyId =>
    `${prefix}/confirm-resources/deleteHardlock/${supplyId}`,
  getAwsSeating: `${prefix}/aws-seating/getAwsSeating`,
  getAwsSeatingHistory: `${prefix}/aws-seating/getAwsSeatingHistory`,
  updateAwsSeating: `${prefix}/aws-seating/updateRequested`,
  updateAwsSeatingHistory: `${prefix}/aws-seating/updateRequestedHistory`,
  loginUrl: `${prefix}/oauth2/authorization/dsmt`,
  benchTracking: trackingId => `${prefix}/benchTracking/${trackingId}`,
  benchTrackingListExport: type => `${prefix}/benchTracking/export/${type}`,
  benchTrackingList: `${prefix}/benchTracking/search`,
  benchTrackingInsert: `${prefix}/benchTracking`,
  benchTrackingEdit: id => `${prefix}/benchTracking/${id}`,
  benchWeeklyCapacity: (week, year) => `${prefix}/benchTracking/weeklyCapacity/${week}/${year}`,
};

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ReportCommonFieldsComponent } from '../report-common-fields/report-common-fields.component';

@Component({
  selector: 'app-report-mandatory-fields',
  templateUrl: './report-mandatory-fields.component.html',
  styleUrls: ['./report-mandatory-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ReportMandatoryFieldsComponent extends ReportCommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: FormGroup;

  ngOnInit() {
    super.ngOnInit();
    // this.expansionPanel.open();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const FTE_FORMAT = '1.1-2';
const LOCATE = 'en-US';

@Pipe({ name: 'fteFormat' })
export class FteFormat implements PipeTransform {
  private static decimalPipe: DecimalPipe = new DecimalPipe(LOCATE);

  transform(value: string): string {
    return FteFormat.decimalPipe.transform(value, FTE_FORMAT);
  }
}

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'actions',
    label: 'Actions',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'select',
    id: 'weekDetail',
    label: 'Week',
    validation: [],
    values: ['', []],
    data: 'lastWeeks',
    disabled: false,
  },
  {
    cardType: 'input',
    id: 'employeeEnterpriseDomainId',
    label: 'Employee ID',
    validation: [],
    values: ['', []],
    disabled: false,
  },
  {
    cardType: 'select',
    id: 'employeeLocationId',
    label: 'Location',
    validation: [],
    values: ['', []],
    data: 'locationEnum',
    disabled: false,
  },
  {
    cardType: 'input',
    id: 'daysOnBench',
    label: 'Days on Bench',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'select',
    id: 'taskCategoryId',
    label: 'Task Category',
    validation: [],
    values: ['', []],
    data: 'taskCategoryEnum',
    disabled: true,
  },
  {
    cardType: 'input',
    id: 'taskDescription',
    label: 'Task Description',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'input',
    id: 'actualEffort',
    label: 'Actual Effort on This Task (hours)',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'input',
    id: 'requestedByEnterpriseDomainId',
    label: 'Task Requested By',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'input',
    id: 'weeklyCapacity',
    label: 'Weekly Bench Capacity (hours)',
    validation: [],
    values: ['', []],
    disabled: true,
  },
  {
    cardType: 'select',
    id: 'employeePlatformId',
    label: 'Platform',
    validation: [],
    values: ['', []],
    data: 'platformEnum',
    disabled: true,
  },
  {
    cardType: 'select',
    id: 'employeeDomainId',
    label: 'Domain',
    validation: [],
    values: ['', []],
    data: 'domainEnum',
    disabled: true,
  },
];

import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(MatSidenav, { static: false })
  private sidenavValue: MatSidenav;

  public get sidenav() {
    return this.sidenavValue;
  }

  constructor(
    private location: Location,
    private router: Router
  ) {}

  // workaround for not getting user roles when login component is initialized
  isLogin() {
    let title: string = this.location.prepareExternalUrl(this.location.path());
    title = title.slice(1, 6);
    return title !== 'login';
  }
}

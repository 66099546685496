// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-header-cell:nth-of-type(2),
.mat-mdc-cell:nth-of-type(2) {
  min-width: unset !important;
  max-width: 60px !important;
}
.mat-mdc-header-cell:nth-of-type(3),
.mat-mdc-cell:nth-of-type(3) {
  min-width: unset !important;
  max-width: 100px !important;
}
.mat-mdc-header-cell:nth-of-type(5),
.mat-mdc-cell:nth-of-type(5) {
  min-width: unset !important;
  max-width: 120px !important;
}
.mat-mdc-header-cell:nth-of-type(6),
.mat-mdc-cell:nth-of-type(6) {
  min-width: unset !important;
  max-width: 120px !important;
}
.mat-mdc-header-cell:nth-of-type(7),
.mat-mdc-cell:nth-of-type(7) {
  min-width: unset !important;
  max-width: 160px !important;
}
.mat-mdc-header-cell:nth-of-type(11),
.mat-mdc-cell:nth-of-type(11) {
  min-width: unset !important;
  max-width: 120px !important;
}
.mat-mdc-header-cell:nth-of-type(12),
.mat-mdc-cell:nth-of-type(12) {
  min-width: unset !important;
  max-width: 120px !important;
}
.mat-mdc-header-cell:nth-of-type(13),
.mat-mdc-cell:nth-of-type(13) {
  min-width: unset !important;
  max-width: 100px !important;
}
.mat-mdc-header-cell:nth-of-type(14),
.mat-mdc-cell:nth-of-type(14) {
  min-width: unset !important;
  max-width: 120px !important;
}
.mat-mdc-header-cell:nth-of-type(17),
.mat-mdc-cell:nth-of-type(17) {
  min-width: unset !important;
  max-width: 120px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectCommonFieldsComponent } from '../project-common-fields/project-common-fields.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { NewClientDialogComponent } from '../../new-client-dialog/new-client-dialog.component';
import { DialogData } from '../../../types/dialog-data';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { probabilityCommitments } from '../../../settings/html-templates';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import * as validationUtils from '../../../shared/validators';
import { ServiceGroupsTooltipText } from '../../../../constants';

@Component({
  selector: 'app-project-mandatory-fields',
  templateUrl: './project-mandatory-fields.component.html',
  styleUrls: ['./project-mandatory-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectMandatoryFieldsComponent
  extends ProjectCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: FormGroup;

  commitmentSubscription: any;
  regionSubscription: any;
  platformSubscription: any;
  workCategorySubscription: any;
  industryGroupSubscription: any;
  offeringNameSubscription: any;
  tooltipText: string = ServiceGroupsTooltipText;

  data = {
    clAccLeadContact: [],
    dcResponsible: [],
    clientId: [],
    solArchContact: [],
    qaDirContact: [],
    propLeadContact: [],
    salBidContact: [],
    projManContact: [],
    delLeadContact: [],
  };

  filteredValues = {
    clAccLeadContact: new Observable<any[]>(),
    dcResponsible: new Observable<any[]>(),
    clientId: new Observable<any[]>(),
    solArchContact: new Observable<any[]>(),
    qaDirContact: new Observable<any[]>(),
    propLeadContact: new Observable<any[]>(),
    salBidContact: new Observable<any[]>(),
    projManContact: new Observable<any[]>(),
    delLeadContact: new Observable<any[]>(),
  };

  ngOnInit() {
    super.ngOnInit();

    this.contactService.getData().subscribe(data => {
      this.data['solArchContact'] = data;
      this.data['qaDirContact'] = data;
      this.data['propLeadContact'] = data;
      this.data['salBidContact'] = data;
      this.data['clAccLeadContact'] = data;
      this.data['projManContact'] = data;
      this.data['delLeadContact'] = data;
    });

    this.dcResponsibleService.getData().subscribe(data => {
      this.data['dcResponsible'] = data;
      this.setValidatorsForDcResponsible();
    });
    // this.expansionPanel.open();
    this.mandatory.valueChanges.subscribe(changes => this.onFormChanges(changes, this.mandatory));

    this.fieldsSettings.mandatory.forEach(setting => {
      if (setting.cardType === 'autosearch') {
        this.filteredValues[setting.id] = this.mandatory.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value =>
            value ? this.filterStates(value, setting.id) : this.data[setting.id].slice()
          )
        );
        if (setting.id === 'clientName') {
          // this has to change
          this.filteredValues[setting.id] = this.mandatory.get(setting.id).valueChanges.pipe(
            debounceTime(500),
            startWith(null),
            map(value => {
              if (value != null) {
                // return after first character entereed
                return value.length >= 1
                  ? this.filterStates(value, setting.data).slice(0, 50)
                  : null;
              }
            })
          );
        } else {
          this.filteredValues[setting.id] = this.mandatory.get(setting.id).valueChanges.pipe(
            debounceTime(500),
            startWith(null),
            map(value => {
              if (value != null) {
                console.log(setting.id, value);
                return value.length >= 3 ? this.filterStates(value, setting.id).slice(0, 50) : null;
              }
            })
          );
        }
      }
    });
  }

  addClient() {
    const dialogRef = this.dialog.open(NewClientDialogComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientListService.deleteCache();
        this.clientListService.getData().subscribe(data => {
          this.data['clientEnum'] = data;
          this.mandatory.get('clientName').setValue(result.client.clientName);
        });
      }
    });
  }

  showClient() {
    window.open('https://in.accenture.com/diamondclients/client-list/', '_blank');
  }

  showClientAccountLead() {
    window.open('https://search.accenture.com/?aid=cal', '_blank');
  }

  infoCommitment() {
    const data: DialogData = {
      title: 'Probability commitments details',
      content: probabilityCommitments,
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  subscribeSpecialEnums() {
    this.offeringNameSubscription = this.mandatory
      .get('offeringNameId')
      .valueChanges.subscribe(value => this.onOfferingNameChange(value));
    this.regionSubscription = this.mandatory
      .get('regionId')
      .valueChanges.subscribe(value => this.onRegionChange(value));
    this.industryGroupSubscription = this.mandatory
      .get('clientGroupId')
      .valueChanges.subscribe(value => this.onIndustryGroupChange(value));
  }

  public unsubscribeSpecialEnums() {
    this.offeringNameSubscription.unsubscribe();
    this.regionSubscription.unsubscribe();
    // this.platformSubscription.unsubscribe();
    this.industryGroupSubscription.unsubscribe();
  }

  setValidatorsForDcResponsible() {
    const validators = [];
    validators.push(validationUtils['valDcResponsibleAndDeputy'](this.projectDetails));
    validators.push(validationUtils['valDcResponsibleAndDeputyFormat'](this.data['dcResponsible']));
    validators.push(validationUtils['valRequired']);
    this.mandatory.get('dcResponsible').setValidators(validators);
    this.mandatory.get('dcResponsible').updateValueAndValidity();
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.clientListService.getData().subscribe(data => (this.data['clientEnum'] = data));

    this.data['statusEnum'] = enums['status-enum'].filter(value => value.id !== 8);
    this.data['rateTypeEnum'] = enums['rate-type'];
    this.data['dcResponsibleRoleEnum'] = enums['dcResponsibleRole'].filter(value => value.id > 1);
    this.data['primaryAccountableLocationEnum'] = enums['primary-accountable-location'];
    this.data['clientGroupEnum'] = enums['client-group'];
    this.data['subIndustryEnum'] = enums['sub-industries'];
    this.data['regionEnum'] = enums['region'];
    this.data['countryEnum'] = enums['country'];
    this.data['platformEnum'] = this.enums['platform'].filter(value => value.deleted !== true);
    this.data['domainEnum'] = this.enums['domain'].filter(value => value.deleted !== true);
    this.data['operationalRespEnum'] = enums['operational-responsibility'];
    this.data['servGroupEnum'] = enums['service-request'];
    this.data['primaryWorkLocEnum'] = enums['work-location'];
    this.data['workCategoryEnum'] = enums['work-category'];
    this.data['offeringNameEnum'] = enums['offering-name'].filter(value => value.deleted !== true);
    this.data['sharedServiceEnum'] = enums['project-domain'];
    this.data['projectDeliveryEnum'] = enums['project-delivery'];
    this.data['sourcingLocation'] = enums['employee-country-enum'];
    this.data['tgSalesEnum'] = enums['tg-sales'];

    if (this.mandatory.get('regionId').value !== null) {
      this.changeCountry(this.mandatory.get('regionId').value);
    }
  }

  onRegionChange(region: number) {
    this.data['countryEnum'] = this.enums['country'].filter(value => value.regionId === region);
    if (this.data['countryEnum'].length > 0) {
      this.mandatory.get('countryId').setValue(null);
    }
  }

  changeCountry(region: number) {
    this.data['countryEnum'] = this.enums['country'].filter(value => value.regionId === region);
  }

  onOfferingNameChange(offeringName: number) {
    this.data['offeringResponsibleEnum'] = this.enums['offering-name'].filter(
      value => value.offeringNameId === offeringName
    ).offeringResponsible;
    /*if (this.data['offeringResponsibleEnum'].length > 0) {
      this.mandatory.get('offeringResponsibleId').setValue(null);
    }*/
  }

  onPlatformChange(platform: number) {
    this.data['domainEnum'] = this.enums['domain'].filter(
      value =>
        value.platformId === platform && value.sharedService !== true && value.deleted !== true
    );
    if (this.data['domainEnum'].length > 0) {
      this.mandatory.get('domainId').setValue(null);
    }
  }

  onIndustryGroupChange(industryGroup: number) {
    this.data['subIndustryEnum'] = this.enums['sub-industries'].filter(
      value => value.idIndustry === industryGroup
    );
  }
}

import { distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
  QueryList,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { Common } from '../../shared/common';
import * as validationUtils from '../../shared/validators';
import { ProjectDetails } from '../../types/project-details';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../services/project.service';
import { CommonComponent } from '../../shared/common/common.component';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { User } from '../../services/user-roles/user';
import { MatSort } from '@angular/material/sort';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { ProjectAttachment } from '../../types/project-attachment';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-attachments',
  templateUrl: './project-attachments.component.html',
  styleUrls: [
    './project-attachments.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectAttachmentsComponent
  extends CommonComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() projectDetails: ProjectDetails;
  @Input() reloadAttachments;

  @ViewChild('cellDef') cellDef: QueryList<MatCellDef>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = [
    'name',
    'uploadTime',
    'uploadedBy',
    'dcso',
    'validity',
    'credentials',
    'credentialsUpdate',
    'description',
    'action',
  ];
  dataSource = new MatTableDataSource();

  creatingRole: boolean;
  editingRole: boolean;
  deletingRole: boolean;

  downloadPath: String;

  attachmentLoaded = false;

  constructor(
    private dialog: MatDialog,
    protected fb: FormBuilder,
    private userRolesService: UserRolesService,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    this.form = this.fb.group({});
    this.getAttachments();

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadAttachments && !changes.reloadAttachments.firstChange) {
      this.getAttachments();
    }
  }

  prepareDownload(attachmentId) {
    this.projectService.downloadAttachment(attachmentId).subscribe(result => {
      if (result) {
        // extract filename from header
        const contentDispositionHeader = result.headers.get('Content-Disposition');
        const filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        const urlObject = window.URL.createObjectURL(result.body);
        const element = document.createElement('a');
        element.href = urlObject;
        element.download = filename;
        document.body.appendChild(element);
        element.click();
      }
    });
  }

  getAttachments() {
    this.attachmentLoaded = false;

    this.projectService.getAttachments(this.projectDetails.projectId).subscribe({
      next: response => {
        this.handleAttachments(response);
      },
      error: error => this.handleError(error),
    });
  }

  onDCSOChange(value, row) {
    const validity = this.form.get('validity' + row.id.toString());
    let data;
    if (value) {
      this.form.get('credentials' + row.id.toString()).setValue(false);
    }
    validity.markAsTouched();
    validity.markAsDirty();
    validity.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    if (validity.valid && value) {
      this.form.get('validity' + row.id.toString()).enable();
      data = {
        dcso: value,
        validity: Common.timezoneFix(validity.value).substr(0, 23),
      };
    } else if (validity.valid && !value) {
      validity.setValue(null, { onlySelf: true, emitEvent: false });
      this.form.get('validity' + row.id.toString()).disable();
      data = {
        dcso: value,
        validity: null,
      };
    } else if (!validity.valid && value) {
      this.form.get('validity' + row.id.toString()).enable();
      return;
    }

    if (data) {
      this.projectService.editAttachment(row.id, data).subscribe({
        next: response => this.handleEdit(),
        error: error => this.handleError(error),
      });
    }
  }

  onValidityChange(value, row) {
    const validity = this.form.get('validity' + row.id.toString());
    const dcso = this.form.get('dcso' + row.id.toString());

    if (validity.valid && dcso.value) {
      const data = {
        dcso: dcso.value,
        validity: Common.timezoneFix(value).substring(0, 23),
      };

      this.projectService.editAttachment(row.id, data).subscribe({
        next: response => this.handleEdit(),
        error: error => this.handleError(error),
      });
    }
  }

  onCredentialsChange(value, row) {
    const credentialsUpdate = this.form.get('credentialsUpdate' + row.id.toString());
    let data;

    if (value) {
      this.form.get('dcso' + row.id.toString()).setValue(false);
    }

    credentialsUpdate.markAsTouched();
    credentialsUpdate.markAsDirty();
    credentialsUpdate.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    const credentialsUpdateControl = this.form.get('credentialsUpdate' + row.id.toString());
    if (value) {
      credentialsUpdateControl.enable();
      if (!credentialsUpdate.valid) {
        return;
      }
      data = {
        credentials: value,
        credentialsUpdate: Common.timezoneFix(credentialsUpdate.value).substring(0, 23),
      };
    } else if (credentialsUpdate.valid) {
      credentialsUpdateControl.disable();
      credentialsUpdate.setValue(null, { onlySelf: true, emitEvent: false });
      data = {
        credentials: value,
        credentialsUpdate: null,
      };
    }
    if (data) {
      this.projectService.editAttachment(row.id, data).subscribe({
        next: response => this.handleEdit(),
        error: error => this.handleError(error),
      });
    }
  }

  onCredentialsUpdateChange(value, row) {
    const credentialsUpdate = this.form.get('credentialsUpdate' + row.id.toString());
    const credentials = this.form.get('credentials' + row.id.toString());

    if (credentialsUpdate.valid && credentials.value) {
      const data = {
        credentials: credentials.value,
        credentialsUpdate: Common.timezoneFix(value).substr(0, 23),
      };

      this.projectService.editAttachment(row.id, data).subscribe(
        response => this.handleEdit(),
        error => this.handleError(error)
      );
    }
  }

  handleEdit(): void {
    this.snackbar.success('Changes has been saved');
  }

  setDynamicValidators(data: any) {
    data.forEach(row => {
      const validators = [];

      validators.push(validationUtils['valDate']());
      validators.push(
        validationUtils['valDcsoRequired'](this.form.get('dcso' + row.id.toString()))
      );

      this.form.get('validity' + row.id.toString()).setValidators(validators);
      if (this.form.get('validity' + row.id.toString()).value === '') {
        this.form.get('validity' + row.id.toString()).disable();
      }

      const validatorsCredentials = [];

      validatorsCredentials.push(validationUtils['valDate']());
      validatorsCredentials.push(
        validationUtils['valCredentialsRequired'](this.form.get('credentials' + row.id.toString()))
      );

      this.form.get('credentialsUpdate' + row.id.toString()).setValidators(validatorsCredentials);
      if (this.form.get('credentialsUpdate' + row.id.toString()).value === '') {
        this.form.get('credentialsUpdate' + row.id.toString()).disable();
      }
    });
  }

  createDynamicForm(data: any) {
    const fieldForm = new Object();

    data.forEach(row => {
      fieldForm['dcso' + row.id.toString()] = [row.dcso, []];
      if (row.validity !== null && row.dcso === true) {
        fieldForm['validity' + row.id.toString()] = [new Date(row.validity), []];
      } else {
        fieldForm['validity' + row.id.toString()] = ['', []];
      }
      fieldForm['credentials' + row.id.toString()] = [row.credentials, []];
      if (row.credentialsUpdate !== null && row.credentials === true) {
        fieldForm['credentialsUpdate' + row.id.toString()] = [new Date(row.credentialsUpdate), []];
      } else {
        fieldForm['credentialsUpdate' + row.id.toString()] = ['', []];
      }
    });

    this.form = this.fb.group(fieldForm);
  }

  handleAttachments(data: any): void {
    this.createDynamicForm(data);
    this.setDynamicValidators(data);
    this.form.valueChanges.subscribe(changes => this.onFormChanges(changes));

    this.dataSource.data = data;

    data.forEach(row => {
      //TODO: clean up code used for editing (we dont want to edit attachements)
      this.form.get('dcso' + row.id.toString()).disable(); //.valueChanges.subscribe(value => this.onDCSOChange(value, row));
      this.form.get('validity' + row.id.toString()).disable(); //.valueChanges.subscribe(value => this.onValidityChange(value, row));
      this.form.get('credentials' + row.id.toString()).disable(); //.valueChanges.subscribe(value => this.onCredentialsChange(value, row));
      this.form.get('credentialsUpdate' + row.id.toString()).disable(); //.valueChanges.subscribe(value => this.onCredentialsUpdateChange(value, row));
    });

    this.attachmentLoaded = true;
  }

  delete(row: ProjectAttachment) {
    Common.deleteDialog(this.dialog).subscribe(result => {
      if (result) {
        this.projectService.deleteAttachment(row.id).subscribe(
          response => this.handleDelete(),
          error => this.handleError(error)
        );
      }
    });
  }

  handleDelete(): void {
    this.getAttachments();
    this.snackbar.success('Attachment has been deleted');
  }
}

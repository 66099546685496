import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { CommonComponent } from '../../shared/common/common.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resources-search',
  templateUrl: './resources-search.component.html',
  styleUrls: ['./resources-search.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ResourcesSearchComponent extends CommonComponent implements OnInit {
  @Input() model: any;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() searchMessageEvent = new EventEmitter<string>();

  matchingProjects = [];
  allProjects = [];
  project: any;

  constructor(
    protected fb: FormBuilder,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    this.projectService.getAllProjects().subscribe({
      next: response => this.handleProjects(response),
      error: error => this.handleError(error),
    });
  }

  onSelectedOption(value) {
    this.searchMessageEvent.emit(value);
  }

  modelChange(val: string) {
    if (val && typeof val === 'string' && val.length > 1) {
      this.matchingProjects = this.allProjects.filter(item =>
        new RegExp(`${val}`, 'gi').test(item.projectName)
      );
    }
  }

  getOptionText(option) {
    if (option) {
      return option.projectName;
    }
  }

  evaluateNoMatches(): boolean {
    if (this.matchingProjects.length === 0) {
      return false;
    }
  }

  handleProjects(projects) {
    this.allProjects = projects;
  }

  searchProject(value) {
    this.messageEvent.emit('loading');
    this.project = value;
    return this.projectService.getOpenedDemands(this.project.projectId).subscribe({
      next: response => {
        this.messageEvent.emit('emptyFormArrays');
        this.sendMessage(response);
      },
      error: error => this.handleError(error),
    });
  }

  sendMessage(message) {
    this.messageEvent.emit(message);
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BenchTrackingCommonFieldsComponent } from '../bench-tracking-common-fields/bench-tracking-common-fields.component';

@Component({
  selector: 'app-bench-tracking-optional-fields',
  templateUrl: './bench-tracking-optional-fields.component.html',
  styleUrls: ['./bench-tracking-optional-fields.component.scss'],
})
export class BenchTrackingOptionalFieldsComponent
  extends BenchTrackingCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  ngOnInit() {
    super.ngOnInit();
  }
}

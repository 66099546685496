import { Component, OnInit } from '@angular/core';
import { OldJSPService } from '../../services/old-jsp-service';

@Component({
  selector: 'app-old-project-details',
  templateUrl: './old-project-details.component.html',
  styleUrls: ['./old-project-details.component.scss'],
})
export class OldProjectDetailsComponent implements OnInit {
  constructor(private oldJSPService: OldJSPService) {}

  ngOnInit() {
    this.oldJSPService.getOldProjectDetails();
  }
}

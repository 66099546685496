import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { User } from '../../../services/user-roles/user';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectSearch } from '../../../types/project-search-result';
import { distinctUntilChanged } from 'rxjs/operators';
import { CustomDateAdapter } from 'app/shared/date-utils';
import { DefaultListComponent } from '../default-list/default-list.component';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: [
    './project-list.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent, { provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class ProjectListComponent extends DefaultListComponent implements OnInit, AfterViewInit {
  salesStage = 3;
  isProject = true;
  completedUrl = '/project-details/';

  ngOnInit() {
    super.ngOnInit();

    if (this.route.snapshot.routeConfig.path.includes('old/new')) {
      this.router.navigate(['/project-list']);
    }

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
      });

    this.projectService.getUserDcResp().subscribe(data => {
      if (data) {
        if (data['dcResponsible'] !== 'null') {
          this.dcResponsible = data['dcResponsible'];
        }
        this.enumsService.getData().subscribe(
          enums => {
            this.populateDropdowns(enums);
          },
          error => {
            this.handleLoadEnumsError();
          }
        );
      }
    });
  }

  setDcResponsible() {
    return this.data[this.dcResponsible] !== undefined ? this.data[this.dcResponsible] : '';
  }

  transformData(projects: Array<ProjectSearch>): Array<ProjectSearch> {
    const today = new Date().getFullYear() * 12 + new Date().getMonth();

    projects.forEach(project => {
      project.startDate = this.dateFormatter.transform(project.startDate, this.dateFormatMmYyyy);
      project.endDate = this.dateFormatter.transform(project.endDate, this.dateFormatMmYyyy);
      project.lastUpdate = this.dateFormatter.transform(project.lastUpdate, this.dateFormatMmYyyy);
      if (project.avanade != null) {
        project.avanade ? (project.avanade = 'Yes') : (project.avanade = 'No');
      }
      if (project.lastReview) {
        const lastReview = new Date(project.lastReview);
        project.lastReview = this.dateFormatter.transform(
          project.lastReview,
          this.dateFormatMmYyyy
        );

        if (today - (lastReview.getFullYear() * 12 + lastReview.getMonth()) > 1) {
          project.color = '#ec407a';
        }
      } else if (project.lastReview == null) {
        project.color = '#ec407a';
      }
    });

    return projects;
  }
}

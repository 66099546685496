import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProjectDetails } from '../../../types/project-details';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectService } from '../../../services/project.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { User } from '../../../services/user-roles/user';
import { fieldsSettings } from '../../../types/seating-hardware';
import { CommonComponent } from '../../../shared/common/common.component';
import * as validationUtils from '../../../shared/validators';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { ReviewApproveDialogNewApproachComponent } from '../review-approve-dialog-new-approach/review-approve-dialog-new-approach.component';
import { ReviewApproveDialogComponent } from '../review-approve-dialog/review-approve-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-seating-hardware-common',
  templateUrl: './seating-hardware-common.component.html',
  styleUrls: ['./seating-hardware-common.component.scss'],
})
export class SeatingHardwareCommonComponent
  extends CommonComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('expansionPanel', /* TODO: add static flag */ { static: false })
  expansionPanel: MatExpansionPanel;
  @ViewChild(MatSort, /* TODO: add static flag */ { static: false }) sort: MatSort;
  @Input() projectDetails: ProjectDetails;

  fieldsSettings = fieldsSettings;

  eid: string;
  editingRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;
  statusCancelledOrClosed: boolean;

  dataSource = new MatTableDataSource();
  enums: any;
  data = {};
  originalWbs: any;
  loaded = false;
  useUniversalWbs: boolean;

  constructor(
    protected projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    private userRolesService: UserRolesService,
    protected dialog: MatDialog,
    protected fb: FormBuilder,
    protected enumsService: EnumsService,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.enumsService.getData().subscribe({
      next: enums => this.populateDropdowns(enums),
      error: error => this.handleLoadEnumsError(),
    });

    this.checkStatus();

    if (this.statusCancelledOrClosed == true) {
      this.form.get('useUniversalWbs').disable({ emitEvent: false });
    } else {
      this.form.get('useUniversalWbs').enable({ emitEvent: false });
    }

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
        this.eid = user.eid;

        if (!this.editingRole) {
          this.form.get('useUniversalWbs').disable({ emitEvent: false });
          this.form.get('wbs').disable({ emitEvent: false });
        }
      });

    this.form
      .get('useUniversalWbs')
      .valueChanges.subscribe(value => this.onUniversalWbsChange(value));

    this.getData();
    this.getUniversalWbs();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  populateDropdowns(enums) {
    this.enums = enums;
  }

  getData() {}

  addData(data) {
    this.dataSource.data = data;
  }

  handleGetData(data: any): void {
    this.loaded = true;
    this.addData(data);
  }

  setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        if (validation === 'valWbsRequired') {
          validators.push(validationUtils[validation](this.form.get('useUniversalWbs')));
        } else {
          validators.push(validationUtils[validation]());
        }
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  onWbsChange() {
    const controller = this.form.get('wbs');

    controller.markAsTouched();
    controller.markAsDirty();

    if (this.form.valid && this.originalWbs !== controller.value) {
      this.saveUniversalWbs(this.form.get('useUniversalWbs').value, controller.value);
    }
  }

  onUniversalWbsChange(value: boolean) {
    const controller = this.form.get('wbs');

    controller.markAsTouched();
    controller.markAsDirty();
    controller.updateValueAndValidity();

    if (this.form.valid) {
      this.saveUniversalWbs(value, controller.value);
    }

    this.useUniversalWbs = value;
  }

  // do NOT remove
  // impementation is in project-hardware/project-seating components
  // won't work without this empty function
  saveUniversalWbs(value, controller) {}

  reviewApproveInfrastructure() {
    const data = {
      eid: this.eid,
      projectId: this.projectDetails.projectId,
    };

    if (this.projectDetails.projectInfo.seatNewApproach) {
      const dialogRef = this.dialog.open(ReviewApproveDialogNewApproachComponent, {
        width: '900px',
        height: '650px',
        data: data,
        panelClass: 'review-approve-dialog-new-approach',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    } else {
      const dialogRef = this.dialog.open(ReviewApproveDialogComponent, {
        width: '900px',
        height: '650px',
        data: data,
        panelClass: 'review-approve-dialog',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  }

  getUniversalWbs() {
    this.projectService.getUniversalWbs(this.projectDetails.projectId).subscribe({
      next: response => this.handleGetUniversalWbs(response),
      error: error => this.handleError(error),
    });
  }

  handleGetUniversalWbs(data: any) {}

  handleEditWbs(data: any) {
    this.originalWbs = this.form.get('wbs').value;
    this.snackbar.success('Universal WBS has been saved');
  }

  checkStatus() {
    this.statusCancelledOrClosed =
      this.projectDetails.statusId === 5 || this.projectDetails.statusId === 6;
  }
}

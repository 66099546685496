import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { ProjectService } from 'app/services/project.service';
import { CommonComponent } from 'app/shared/common/common.component';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  DateAdapter,
  NativeDateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { MONTH_YEAR_FORMAT } from '../../types/date-format';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-cost-report',
  templateUrl: './project-cost-report.component.html',
  styleUrls: ['./project-cost-report.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MONTH_YEAR_FORMAT,
    },
  ],
})
export class ProjectCostReportComponent extends CommonComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exporting = false;
  exportForm: FormGroup;
  data = {};
  date = new FormControl(new Date());
  choosedMonth: number = new Date().getMonth() + 1;
  choosedYear: number = new Date().getFullYear();
  filteredOptions: Observable<any>;
  title: string = 'Project Cost Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    public fb: FormBuilder,
    private reportService: ReportService,
    public projectService: ProjectService,
    private dialog: MatDialog,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    this.snackbar.close();

    this.exportForm = this.fb.group({
      project: ['', []],
      period: ['', []],
    });

    this.projectService.getActiveProjects().subscribe(
      response => this.handleProjects(response),
      error => this.handleError(error)
    );
  }

  protected handleProjects(projects) {
    this.data['projects'] = projects;
    this.filteredOptions = this.exportForm.get('project').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value).map(value => value[1]))
    );
  }

  exportProjectCostReport() {
    this.exporting = true;
    const project = this._filter(this.exportForm.get('project').value)[0];
    this.reportService
      .exportProjectCostReport(project[0], this.choosedMonth, this.choosedYear)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any): void {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  chosenMonthHandler(date: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue = this.date.value;
    ctrlValue.setMonth(date.getMonth());
    ctrlValue.setFullYear(date.getFullYear());
    const dateValue: Date = ctrlValue;
    this.choosedMonth = new Date(dateValue).getMonth() + 1;
    this.choosedYear = new Date(dateValue).getFullYear();
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  private _filter(value: string) {
    if (
      this.data['projects'] == null ||
      this.data['projects'] === undefined ||
      this.data['projects'].length === 0
    ) {
      return [];
    }
    return this.data['projects'].filter(option =>
      option[1].toLowerCase().includes(value.toLowerCase())
    );
  }
}

import { Injectable } from '@angular/core';

import { REST } from './../../rest.endpoints';
import { BaseService } from './base.service';
import { retryWhen, delay, distinctUntilChanged, share, tap } from 'rxjs/operators';

@Injectable()
export class ClientListService extends BaseService {
  protected requestData(): void {
    this.request = this.http.get(REST.clientNotDeletedString).pipe(
      distinctUntilChanged(),
      tap(data => (this.data = data)),
      retryWhen(errors => errors.pipe(delay(this.reloadDelay), this.handleError)),
      share()
    );
  }
}

import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { CommonComponent } from 'app/shared/common/common.component';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { DialogData } from 'app/types/dialog-data';
import * as cards from '../types/aws-seating';
import { fieldsSettingsHistory } from '../types/aws-seating-history';
import { DatePipe, Location } from '@angular/common';
import { AwsSeatingPlanningTableComponent } from './aws-seating-planning-table/aws-seating-planning-table.component';
import { ProjectService } from 'app/services/project.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserRolesService } from 'app/services/user-roles/user-roles.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { AwsSeatingPlanningHistoryComponent } from './aws-seating-planning-history/aws-seating-planning-history.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { VersionDataService } from '../version-data.service';
import { AuthService } from '../services/auth.service';
import { AwsSeatingPlanningDetails } from '../types/aws-seating-details';

@Component({
  selector: 'app-aws-seating-planning',
  templateUrl: './aws-seating-planning.component.html',
  styleUrls: [
    './aws-seating-planning.component.scss',
    '../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AwsSeatingPlanningComponent extends CommonComponent implements OnInit {
  @ViewChildren('table') tableComponents: QueryList<AwsSeatingPlanningTableComponent>;
  @ViewChild('history') historyComponent: AwsSeatingPlanningHistoryComponent;

  period = new FormControl(new Date());

  tables = [];
  titles = ['requestedSeats', 'endDateChangeSeats'];

  historyData: AwsSeatingPlanningDetails[];
  listLoaded: Boolean;
  isSaved: Boolean;
  requests = {};
  role;
  admin: Boolean;
  enums;
  history: Boolean;
  dateFormatter = new DatePipe('en-US');
  dateFormatMmYyyy = 'MM/yyyy';
  locationControl = new FormControl();

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    public dialog: MatDialog,
    public app: AppComponent,
    public fb: FormBuilder,
    public router: Router,
    public projectService: ProjectService,
    public enumsService: EnumsService,
    public location: Location,
    public userRolesService: UserRolesService,
    authService: AuthService
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    this.listLoaded = false;
    this.isSaved = false;
    this.admin = false;
    this.history = false;
    this.snackbar.close();

    Object.keys(cards).forEach(element => {
      this.tables.push(cards[element]);
    });

    this.locationControl.valueChanges.subscribe(changes => {
      this.getRecords(changes);
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.handleRoles(data);
      });

    this.enumsService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(
        enums => {
          this.handleEnums(enums);
          this.projectService
            .getAllProjects()
            .pipe(distinctUntilChanged())
            .subscribe(
              response => {
                this.handleProjects(response);
                this.getRecords();
              },
              error => this.handleError(error)
            );
        },
        error => {
          this.handleLoadEnumsError();
        }
      );
  }

  getRecords(location?: number) {
    this.listLoaded = false;
    this.isSaved = false;
    if (this.history) {
      this.getRecordsByPeriod(location);
    } else {
      const searchParams = {};
      if (location !== undefined && location !== null) {
        searchParams['location'] = location;
      } else if (this.locationControl.value !== null && this.locationControl.value !== undefined) {
        searchParams['location'] = this.locationControl.value;
      }

      this.projectService.getAwsSeating(searchParams).subscribe(
        response => {
          this.handleRequests(response);
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  changeHistoryState() {
    this.locationControl.setValue(null, { emitEvent: false });
    this.listLoaded = false;
    this.isSaved = false;
    this.history = !this.history;
    this.getRecords();
  }

  getRecordsByPeriod(location?: number) {
    this.listLoaded = false;
    this.isSaved = false;
    const searchParams = {};
    if (location !== undefined && location !== null) {
      searchParams['location'] = location;
    } else if (this.locationControl.value !== null && this.locationControl.value !== undefined) {
      searchParams['location'] = this.locationControl.value;
    }
    searchParams['date'] = this.dateFormatter.transform(this.period.value, this.dateFormatMmYyyy);

    this.projectService.getAwsSeatingHistory(searchParams).subscribe(
      response => {
        this.handleHistory(response);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  monthSelectedHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    datepicker.close();
    this.period.setValue(normalizedMonth);
    this.getRecordsByPeriod();
  }

  handleHistory(response: AwsSeatingPlanningDetails[]) {
    this.historyData = response;
    this.fieldsSettings = fieldsSettingsHistory;
    this.listLoaded = true;
  }

  handleEnums(enums) {
    this.enums = enums;
    this.data['locationEnum'] = enums['employee-location'].filter(
      value => value.id === 1 || value.id === 3 || value.id === 5 || value.id === 6
    ); // BA, BUCH, BUD, KE
  }

  handleRoles(data) {
    data.roles.forEach(role => {
      if (role === 'ROLE_MAPPED_ADMIN_ROLE') {
        this.role = [1, 3, 5, 6];
        this.admin = true;
      } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR') {
        this.role = [1, 6];
      } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR_BUCH') {
        this.role = [3];
      } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR_BUD') {
        this.role = [5];
      }
    });
  }

  handleRequests(data: any) {
    let i = 0;
    Object.keys(data).forEach(key => {
      this.requests[i] = [];
      data[key].forEach(element => {
        this.requests[i].push(element);
      });
      i++;
    });
    this.listLoaded = true;
  }

  handleProjects(data: any) {
    this.data['projectEnum'] = this.transformProjectsAndOpportunities(data);
  }

  transformProjectsAndOpportunities(data: Array<any>) {
    const projectsAndOpportunitiesEnum = [];
    data.forEach(project => {
      projectsAndOpportunitiesEnum.push({ id: project['projectId'], text: project['projectName'] });
    });
    return projectsAndOpportunitiesEnum;
  }

  save() {
    this.isSaved = true;
    const requestBody = {};
    let valid = true;
    this.tableComponents.forEach(table => {
      requestBody[this.titles[table.tableIndex]] = [];
      table.rowsForm.controls.forEach(control => {
        if (
          (control.get('seatStatus').value === '2' || control.get('seatStatus').value === '6') &&
          control.touched
        ) {
          control.markAllAsTouched();
          control.updateValueAndValidity();
          if (control.valid && table.validateDates(control)) {
            requestBody[this.titles[table.tableIndex]].push(table.getFinalValue(control));
          } else {
            valid = false;
            if (control.get('seat').invalid) {
              control.get('seat').markAsTouched();
              control.get('seat').updateValueAndValidity();
              this.snackbar.error('Incorrect seat number format.');
              return;
            }
            if (control.get('awsComment').invalid) {
              this.snackbar.error('AWS Comment is missing.');
              this.isSaved = false;
            }
          }
        }
      });
    });

    if (valid && requestBody !== null) {
      this.projectService.updateAwsSeating(requestBody).subscribe(
        // change update path to saveRequested in rest if needed
        response => this.handleUpdateResponse(),
        error => this.handleUpdateError(error)
      );
    }
  }

  handleUpdateResponse() {
    this.snackbar.success('Records succesfully updated!');
    setTimeout(() => {
      this.getRecords();
    }, 1000);
  }

  handleUpdateError(error: Error) {
    this.snackbar.error('An error occured!');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

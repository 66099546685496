// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
mat-sidenav-container,
.my-content {
  margin: 0;
  width: 100%;
  height: 100%;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
}

body {
  background-color: #f1f1f1;
  color: #212121;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  /*************** GENERAL ***************/
  /*************** ERROR MESSAGES ***************/
  /*************** FAB ***************/
  /*************** ICONS ***************/
  /*************** TOOLBAR (TO-DO: move to the toolbar style) ***************/
  /*************** INPUTS ***************/
  /*************** LOADING ***************/
  /*************** TABS ***************/
  /*************** BUTTONS ***************/
  /*************** HEADINGS ***************/
}
body .container-page {
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  max-width: 1160px;
  margin: 0 auto;
  flex-direction: column;
}
body .space-filler {
  flex: 1 1 auto;
}
body .mat-input-error {
  font-size: 12px;
}
body .mat-mdc-button .material-icons {
  color: #808066;
}
body .material-icons-text {
  font-family: Helvetica, serif;
  font-weight: bolder;
  font-size: 16px;
}
body .my-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  font-weight: 400;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.36);
}
body .my-toolbar .material-icons {
  color: #ffffff;
}
body .my-toolbar .mat-toolbar-row {
  font-size: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  color: #ffffff;
}
body .my-toolbar .mat-toolbar-row section {
  display: inline-flex;
  align-items: center;
}
body .my-toolbar .mat-toolbar-row section .toolbar-title {
  font-size: 18px;
  font-weight: 500;
}
body .my-toolbar #employee-detail-search {
  display: inline-flex;
}
body .my-toolbar-simple {
  padding-right: 0;
}
body .my-toolbar-simple .toolbar-space-filler {
  flex: 1 1 auto;
  text-align: right;
}
body .my-toolbar-simple h2 {
  color: #ffffff;
}
body .mat-mdc-form-field .mdc-text-field--filled {
  background-color: transparent !important;
}
body .mat-mdc-form-field .mdc-text-field--filled .mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
body .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  padding: 0;
}
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  border-top: 0.5em solid transparent;
}
body .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}
body .small-option {
  font-size: 12px;
  min-height: 3em;
}
body .small-input.mat-mdc-form-field {
  width: 100%;
}
body .small-input.mat-mdc-form-field mat-label,
body .small-input.mat-mdc-form-field mat-select {
  font-size: 12px;
}
body .small-input.mat-mdc-form-field mat-label .mat-mdc-select-trigger,
body .small-input.mat-mdc-form-field mat-select .mat-mdc-select-trigger {
  font-size: 12px;
  margin-top: 18px;
  height: 15px;
}
body .small-input.mat-mdc-form-field mat-label .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper,
body .small-input.mat-mdc-form-field mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  align-self: flex-start;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  border-top: 0;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 45px;
  height: 45px;
  padding: 0.3em 0;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mdc-text-field__input {
  max-height: 12px;
  margin-top: 18px;
  font-size: 12px;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix label {
  width: 100%;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  align-self: flex-end;
  padding-bottom: 5px;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-icon-button {
  height: 20px;
  width: 20px;
  padding: 2px;
  display: flex;
  align-items: center;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-icon-button svg {
  height: 26px;
  width: 26px;
}
body .small-input.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 20px;
  width: 20px;
  margin: auto;
}
body .not-loaded {
  display: none;
}
body .mat-mdc-tab-group .mat-mdc-tab-header {
  background-color: #ffffff;
  text-transform: uppercase;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-header-pagination {
  display: none;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container {
  position: fixed;
  top: 64px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.36);
  background-color: #ffffff;
  width: 100%;
  color: #1976d2;
  z-index: 8;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  flex-grow: 0;
  min-width: 160px;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  background-color: #e5e5e5;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab__text-label {
  color: #212121;
}
body .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab__text-label .mat-icon {
  margin-right: 10px;
}
body .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .container-page {
  margin-top: 110px;
  padding-top: 30px;
  padding-bottom: 8px;
}
body .mat-divider {
  border-top-width: 2px;
}
body .mat-mdc-raised-button {
  height: 40px;
  padding: 2px 16px 0 12px;
  box-sizing: border-box;
}
body .mat-mdc-raised-button .material-icons {
  color: #ec407a;
}
body .mat-mdc-raised-button .btn-title {
  font-weight: 500;
  margin-left: 8px;
  font-size: 18px;
  color: #ec407a;
}
body h2,
body h3,
body h4 {
  font-weight: normal;
  color: #212121;
  text-shadow: none;
  box-shadow: none;
  margin: 0;
}
body h2.mandatory,
body h3.mandatory,
body h4.mandatory {
  color: #ec407a;
}
body h2 {
  font-size: 20px;
  font-weight: 500;
}
body h3 {
  font-size: 18px;
}

::ng-deep .cdk-overlay-pane {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.item-holder-days {
  width: 45%;
  float: left;
  margin-bottom: 16px;
}
.item-holder-days .table-days {
  width: 100%;
  align-items: center;
}
.item-holder-days .table-days td {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';

const dateFormatter = new DatePipe('en-US');
const dateFormat = 'yyyy-MM';

export const createExcelReport = (data: any, fileName: string) => {
  const ext = 'xlsx';
  const workBook = XLSX.utils.book_new();

  workBook.Props = {
    Title: fileName,
    Author: 'DSMT',
    CreatedDate: new Date(),
    ModifiedDate: new Date(),
  };

  const workSheet = XLSX.utils.aoa_to_sheet([
    [
      'Personal Number',
      'Domain ID / Full Name',
      'Previous CY Vacation Balance (days)',
      'Overall Vacation Balance (days)',
      'Actual Overtime Hours (hours)',
      'Overtimes Vacation Taken (hours)',
      'Previous CY Overtime Status (hours)',
      'Actual On-Call Hours (hours)',
      'Reported Period',
      'Import Result',
      'Reason',
    ],
  ]);
  workSheet['!cols'] = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 30 },
  ];

  //  header style

  const headerStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: '4000a1f1' } },
  };
  for (let col = 1; col <= 7; col++) {
    const cell = XLSX.utils.encode_cell({ c: col - 1, r: 0 });
    workSheet[cell].s = headerStyle;
  }

  //  row styles

  data.forEach((row, index) => {
    const rowData = [
      row.personalNumber,
      row.fullName,
      row.oldVacationBalance,
      row.overallVacationBalance,
      row.actualOvertime,
      row.overtimeVacation,
      row.previousCYOverimeStatus,
      row.actualOnCall,
      dateFormatter.transform(row.reportedPeriod, dateFormat),
      row.importResult,
      row.importReason,
    ];
    XLSX.utils.sheet_add_aoa(workSheet, [rowData], { origin: -1 });

    if ((index + 1) % 2 === 0) {
      const rowStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: '4087CEFA' } },
      };
      for (let col = 1; col <= 7; col++) {
        const cell = XLSX.utils.encode_cell({ c: col - 1, r: index + 1 });
        if (workSheet[cell]) workSheet[cell].s = rowStyle;
      }
    }
  });

  workBook.SheetNames.push(fileName);
  workBook.Sheets[fileName] = workSheet;

  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const excelBlob: Blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  if (fileName.indexOf('.' + ext) === -1) {
    fileName = fileName + '.' + ext;
  }

  saveAs(excelBlob, fileName);
};

import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { employeeDetailsEnumMapping } from './../../../types/enum.mapping';
import { CardItemComponent } from './../card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-card-item-enum-multiple',
  templateUrl: './card-item-enum-multiple.component.html',
  styleUrls: [
    '../card-item.component.scss',
    './card-item-enum-multiple.component.scss',
    '../../../shared/card-item-enum-multiple.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class CardItemEnumMultipleComponent extends CardItemComponent implements OnInit, OnChanges {
  constructor(
    enumsService: EnumsService,
    snackbar: SnackbarPanelComponent,
    private route: ActivatedRoute
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();

    this.route.params.subscribe(params => {
      this.init();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //  exiting edit mode

    if (
      changes.editing &&
      changes.editing.currentValue === false &&
      changes.editing.firstChange === false
    ) {
      this.init();
    }
  }

  init() {
    this.enumsService.getData().subscribe(enums => {
      this.enums = enums;
      this.isEnum = true;

      if (this.disabled) {
        this.control.get(this.id).disable({ onlySelf: true, emitEvent: false });
      }

      const enumName = employeeDetailsEnumMapping.get(this.id);
      this.enumeration = this.enums[enumName];

      if (this.enumeration == null) {
        console.error(`missing enum ${this.id}`);
      }
    });
  }
}

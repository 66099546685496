import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'projectId',
    label: 'ID',
    validation: ['valInteger'],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'name',
    label: 'Project Name',
    validation: ['valString20'],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'client',
    label: 'Client',
    validation: ['valString20'],
    type: '',
    values: ['', []],
  },
  {
    cardType: 'enum',
    id: 'salesStage',
    data: 'salesStageEnum',
    label: 'Sales Stage',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'ddcIndustryGroup',
    label: 'DATC Industry',
    validation: ['valString40'],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'dcResponsibleName',
    label: 'ATC Responsible Name',
    validation: ['valString40'],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'startDate',
    label: 'Start Date',
    validation: ['valDateMonth'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'endDate',
    label: 'End Date',
    validation: ['valDateMonth'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'lastUpdate',
    label: 'Last Update',
    validation: ['valDateMonth'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'lastReview',
    label: 'Last Review',
    validation: ['valDateMonth'],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: 'enum',
    id: 'avanade',
    data: 'avanadeEnum',
    label: 'Avanade',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: 'enum',
    id: 'status',
    data: 'statusEnum',
    label: 'Status',
    validation: ['valString20'],
    type: '',
    values: ['', []],
  },
];

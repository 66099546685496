import { Injectable } from '@angular/core';

import { REST } from './../../rest.endpoints';
import { BaseService } from './base.service';
import { delay, retryWhen, take, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ActiveEmployeesAllCareerCounselorsService extends BaseService {
  protected requestData(): void {
    this.request = this.http.get(REST.employeeDetailsActiveEmployeesAllCareerCounselors).pipe(
      distinctUntilChanged(),
      retryWhen(errors => errors.pipe(delay(this.reloadDelay), take(3), this.handleError))
    );
  }
}

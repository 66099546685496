import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { REST } from 'rest.endpoints';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss'],
})
export class LoginComponentComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['component']) {
        localStorage.setItem('back_link', params['component']);
      }

      const token = params['token'];
      if (token) {
        this.authService.setSession(token);
        this.updateRoles();
        let link: string = localStorage.getItem('back_link') || '/welcome-page';
        this.router.navigateByUrl(link).then(() => {
          localStorage.removeItem('back_link');
        });
      } else {
        this.authService.logout();
        window.location.href = REST.loginUrl;
      }
    });
  }

  updateRoles() {
    return this.http.post(REST.updateRoles, []).subscribe();
  }
}

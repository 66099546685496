import { Component, OnInit, ViewChild } from '@angular/core';
import { AddNewProjectComponent } from '../add-new-project.component';
import { fieldsSettings } from 'app/types/new-lead';
import { Common } from 'app/shared/common';
import * as validationUtils from '../../../shared/validators';
import { ProjectDetails } from 'app/types/project-details';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { LeadMandatoryFieldsComponent } from '../lead-mandatory-fields/lead-mandatory-fields.component';

@Component({
  selector: 'app-add-new-lead',
  templateUrl: './add-new-lead.component.html',
  styleUrls: [
    '../../../shared/add-new.scss',
    './add-new-lead.component.scss',
    '../add-new-project.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AddNewLeadComponent extends AddNewProjectComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false })
  declare mandatoryChild: LeadMandatoryFieldsComponent;
  projectDetails = new ProjectDetails();
  back() {
    this.snackbar.close();
    this.redirectToAddNewProjectView();
  }

  ngOnInit() {
    this.snackbar.close();
    const [fieldFormMandatory] = [new Object()];
    const [fieldFormOptional] = [new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    this.newProjectForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    this.setValidators();
    this.setFields();
    this.setDefaultValues();
    this.subscribeSpecialEnums();

    Object.keys(this.newProjectForm.controls).forEach(group => {
      const groupControl = this.newProjectForm.get(group);
      Object.keys(groupControl['controls']).forEach(controler => {
        this.projectDetails[controler] = groupControl.get(controler).value;
      });
    });

    this.setDefaultValues();
    this.disableField(this.newProjectForm.get('mandatory').get('subIndustryId'), true);
  }

  subscribeSpecialEnums() {
    this.industrySubscription = this.newProjectForm
      .get('mandatory')
      .get('clientGroupId')
      .valueChanges.subscribe(value => this.onIndustryChange(true));
  }

  unsubscribeSpecialEnums() {
    this.industrySubscription.unsubscribe();
    // this.commitmentSubscription.unsubscribe();
  }

  setPredefinedValues(newProject) {
    const date = new Date();
    newProject.statusId = 3;
    newProject.projectInfo.salesStage = 1;
    newProject.projectInfo.nbmStatus = 7;
    newProject.reqQualification.dcResponsibleRoleId = 1;
    newProject.reqQualification.lastUpdateDate = date;
    newProject.reqQualification.reqDate = date;
    newProject.projectSchedule.commitment = 5;
  }

  save() {
    this.saving = true;
    const form = this.newProjectForm;
    const newProject = {
      client: {},
      projectInfo: {},
      reqQualification: {},
      projectSchedule: {},
    };

    this.mandatoryChild.unsubscribeSpecialEnums();
    this.unsubscribeSpecialEnums();

    this.setPredefinedValues(newProject);

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        let part = null;

        fieldsSettings.mandatory.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        fieldsSettings.optional.forEach(setting => {
          if (controlerName === setting.id) {
            part = setting.part;
          }
        });

        const controler = groupControl.get(controlerName);

        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.enabled && controler.value !== null) {
          if (part) {
            if (controler.value instanceof Date) {
              newProject[part][controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[part][controlerName] = controler.value;
            }
          } else {
            if (controler.value instanceof Date) {
              newProject[controlerName] = Common.timezoneFix(controler.value);
            } else {
              newProject[controlerName] = controler.value;
            }
          }
        }
      });
    });

    if (Object.keys(newProject.client).length === 0) {
      delete newProject.client;
    }

    if (Object.keys(newProject.projectInfo).length === 0) {
      delete newProject.projectInfo;
    }

    if (Object.keys(newProject.reqQualification).length === 0) {
      delete newProject.reqQualification;
    }

    if (Object.keys(newProject.projectSchedule).length === 0) {
      delete newProject.projectSchedule;
    }

    this.mandatoryChild.subscribeSpecialEnums();

    if (form.valid) {
      newProject.reqQualification['client'] = newProject.client['clientName'];

      this.normalizeDCRespName(newProject);
      this.projectService.addProject(newProject).subscribe(
        res => {
          this.handleSucces(res);
        },
        err => {
          this.handleError(err);
        }
      );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
    }
  }

  setValidators() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        const validators = [];
        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });
        this.newProjectForm.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields() {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach(setting => {
        if (setting.disabled) {
          this.newProjectForm
            .get(set)
            .get(setting.id)
            .disable({ onlySelf: true, emitEvent: false });
        }
      });
    });
  }

  setDefaultValues() {}

  handleSucces(response) {
    this.snackbar.success('Lead created - redirecting to lead details');
    this.done = 1;
    this.saving = false;
    setTimeout(() => {
      this.router.navigate(['/lead-details', response.projectId]);
    }, 1500);
  }

  handleError(error: any) {
    // Error response can be different probably depends on the situation
    console.log(error);
    let body: any;
    let errorMessage: any;
    if (error.error instanceof Array && error.error.length > 2) {
      body = error.error[1];
      if (body.field === 'reqQualification.dcResponsible') {
        errorMessage = 'Please correct ATC Responsible Name' + ' - ' + body.code;
      } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
        errorMessage = 'Please correct ATC Responsible Deputy' + ' - ' + body.code;
      } else {
        errorMessage = body.code;
      }
    } else if (error.error instanceof Array && error.error.length > 1) {
      body = error.error[0];
      if (body.field === 'reqQualification.dcResponsible') {
        errorMessage = 'ATC Responsible Name' + ' ' + body.code;
      } else if (body.field === 'reqQualification.dcResponsibleDeputy') {
        errorMessage = 'ATC Responsible Deputy' + ' ' + body.code;
      } else {
        errorMessage = body.code;
      }
    } else {
      body = error.error;
      this.newProjectForm['clientName'].errorMessage =
        'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    }
    // const errorMessage = 'Could not create project/opportunity: ' + body.field + ' ' + body.code;
    this.saving = false;
    this.snackbar.error(errorMessage);
  }

  onCommitmentChange(value) {
    this.resetValidatorsForMandatory(null);
  }

  onIndustryChange(checked: boolean) {
    const mandatory = this.newProjectForm.get('mandatory');
    if (checked) {
      this.enableField(mandatory.get('subIndustryId'), true);
    }
  }
}

import { NewProject } from './new-form';

// pri prehadzovani policiek z mandatory do optional a naopak nezabudnite prehodit aj v opportunity-to-project.mapping ak tam je
export const fieldsSettings: NewProject = {
  mandatory: [
    {
      cardType: 'input',
      id: 'projectName',
      label: 'Opportunity Name',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'startDate',
      label: 'Start Date',
      part: 'projectSchedule',
      validation: ['valDate', 'valRequired'],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'endDate',
      label: 'End Date',
      part: 'projectSchedule',
      validation: ['valDate', 'valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      data: 'clientEnum',
      id: 'clientName',
      label: 'Client',
      part: 'client',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'clAccLeadContact',
      label: 'Client Account Lead',
      part: 'reqQualification',
      validation: ['valRequired', 'valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'commitmentEnum',
      id: 'commitment',
      label: 'Probability / Commitment',
      part: 'projectSchedule',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      id: 'tgSalesId',
      label: 'TG Sales',
      data: 'tgSalesEnum',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'clientGroupEnum',
      id: 'clientGroupId',
      label: 'Industry',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'subIndustryEnum',
      id: 'subIndustryId',
      label: 'Sub-Industry',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'regionEnum',
      id: 'regionId',
      label: 'Region',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'countryEnum',
      id: 'countryId',
      label: 'Country',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'platformEnum',
      id: 'platformId',
      label: 'Primary Platform',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'primaryAccountableLocationEnum',
      id: 'primaryAccountableLocationId',
      label: 'Primary Accountable Location',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: [1, []],
    },
    {
      cardType: 'select',
      data: 'primaryWorkLocEnum',
      id: 'primaryWorkLocId',
      label: 'Primary Work Location',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'workLocation',
      label: 'Work Location Country',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'dcResponsible',
      label: 'ATC Responsible Name',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'operationalRespEnum',
      id: 'operationalRespId',
      label: 'Operation Responsibility',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'end2End',
      label: 'E2E',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'slider',
      id: 'localProject',
      label: 'Local Project',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'select-multi',
      data: 'servGroupEnum',
      id: 'serviceGroupIds',
      label: 'Service Groups',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'swfReqTravel',
      label: 'Required To Travel',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'autosearch',
      id: 'solArchContact',
      label: 'Solution Architect',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'salBidContact',
      label: 'Sales Lead',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'sapOpNumber',
      label: 'MMS ID',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'newBusinessMeeting',
      label: 'New Business Meeting',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'select',
      id: 'nbmStatus',
      data: 'nbmStatus',
      label: 'NBM Status',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: [7, []],
    },
    {
      cardType: 'input',
      id: 'condition',
      label: 'Condition',
      part: 'projectInfo',
      validation: ['valRequired', 'valString500'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'expectedFte',
      label: 'Expected FTE',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select-multi',
      data: 'sourcingLocation',
      id: 'sourcingLocation',
      label: 'Sourcing Location',
      type: '',
      part: 'projectInfo',
      validation: ['valRequired'],
    },
    {
      cardType: 'select-multi',
      data: 'offeringNameEnum',
      id: 'offeringNameId',
      label: 'Offering Name',
      part: 'reqQualification',
      validation: ['valRequired'],
      values: [[], []],
    },
    {
      cardType: 'input',
      id: 'expectedDedicatedSeats',
      label: 'Expected Dedicated Seats',
      part: 'projectInfo',
      validation: ['valPositive'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'expectedSharedSeats',
      label: 'Expected Shared Seats',
      part: 'projectInfo',
      validation: ['valPositive'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'workCategoryEnum',
      id: 'workCategoryId',
      label: 'Work Category',
      part: 'projectInfo',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'deliveryVmuDescription',
      label: 'Opportunity Description',
      part: 'projectInfo',
      validation: ['valRequired', 'valString500'],
      values: ['', []],
    },
    {
      cardType: 'hidden',
      id: 'lastUpdateDate',
      label: 'Last Update Date',
      part: 'reqQualification',
      validation: [],
    }, // not visible, data from lead
    {
      cardType: 'hidden',
      id: 'dcResponsibleRoleId',
      label: 'DC Responsible Role Id',
      part: 'reqQualification',
      validation: [],
    }, // not visible, data from lead
  ],
  optional: [
    {
      cardType: 'autosearch',
      id: 'qaDirContact',
      label: 'QA Director',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'othContact',
      label: 'Other Contact',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'othContactRole',
      label: 'Other Contact Role',
      part: 'reqQualification',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'delLeadContact',
      label: 'Delivery Lead',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      id: 'projManContact',
      label: 'Project Manager',
      part: 'reqQualification',
      validation: ['valEidOrMail'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'sharedServiceEnum',
      id: 'sharedService',
      label: 'One-To-Many Shared Services Name',
      part: 'projectInfo',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'slider',
      id: 'highProfitabilityDeal',
      label: 'High Profitability Deal',
      type: '',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'slider',
      id: 'growthOpportunity',
      label: 'Growth Opportunity',
      type: '',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'slider',
      id: 'upskilling',
      label: 'Upskilling',
      type: '',
      part: 'projectInfo',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'hidden',
      id: 'piComment',
      data: 'piComment',
      label: 'Notes',
      type: '',
      part: 'projectInfo',
      validation: [],
    }, // not visible, data from lead
  ],
};

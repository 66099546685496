import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { CommonDetailsComponent } from '../common-details/common-details.component';
import { LeadCardComponent } from 'app/project/cards/lead-card/lead-card.component';
import { distinctUntilChanged } from 'rxjs/operators';
import * as cards from '../../../types/lead-cards-content';
import { bannerCard } from '../../../types/lead-banner-card';
import { User } from 'app/services/user-roles/user';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss', '../project-details/project-details.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class LeadDetailsComponent extends CommonDetailsComponent implements OnInit {
  @ViewChildren('card') declare cardComponents: QueryList<LeadCardComponent>;

  urlDetails = '/lead-details';
  errorMessage = 'Invalid Lead ID route.';
  bannerCard = bannerCard;

  ngOnInit() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.route.fragment.subscribe(fragment => {
      this.selectedTab = this.chooseTab(fragment);
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
        this.historyRole = user.getRoles().adminOrHr ? true : false;
        this.eid = user.eid;
        this.hasDomainId = user.hasDomainId;
      });

    this.loadClient();
    // Workaround for navigating back to List
    // TODO: router routing on tabbed components instead of using fragments
    this.pLocation.onPopState(() => {
      this.router.navigate([this.urlList]);
    });
  }

  chooseTab(fragment: string): number {
    if (fragment === 'attachments') {
      return 1;
    } else if (fragment === 'history') {
      return 2;
    } else {
      return 0;
    }
  }

  onTabChange(e) {
    // If tab was changed then check for unfinished work on projectCards
    if (this.selectedTab === 0 && e.index > this.selectedTab) {
      this.canDeactivate();
    }
    this.selectedTab = e.index;
    const url = this.router.url
      .replace('#demands', '')
      .replace('#seating-hw', '')
      .replace('#attachments', '')
      .replace('#history', '')
      .replace('#reporting', '');

    if (this.selectedTab === 1) {
      this.location.go(url.concat('#attachments'));
    } else if (this.selectedTab === 2) {
      this.location.go(url.concat('#history'));
    } else {
      this.location.go(url);
    }
  }

  protected populateDropdowns(enums) {
    this.enums = JSON.parse(JSON.stringify(enums));
    this.enums['probability'] = enums['probability'].filter(
      value => value.id > 0 && value.id < 100
    );

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  getProjectDetails(searchedId) {
    this.projectService.getProjectDetails(searchedId).subscribe(
      response => {
        this.handleProjectDetailsSuccess(response);
      },
      error => this.handleProjectDetailsError(error)
    );
  }

  upgradeToOpportunity() {
    if (this.projectDetails) {
      // for errors in console
      if (this.projectDetails.statusId < 5) {
        this.router.navigate([
          'project-details/add-new-opportunity',
          this.projectDetails.projectId,
        ]);
      } else {
        this.snackbar.error('Any changes to closed / cancelled projects are forbidden.');
      }
    }
  }

  upgradeToProject() {
    if (this.projectDetails) {
      // for errors in console
      if (this.projectDetails.statusId < 5) {
        this.router.navigate([
          'project-details/add-new-project',
          this.projectDetails.projectId,
          'lead',
          'project',
        ]);
      } else {
        this.snackbar.error('Any changes to closed / cancelled projects are forbidden.');
      }
    }
  }
}

import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver-es';
import * as XLSX from 'xlsx';

const dateFormatter: DatePipe = new DatePipe('en-US');
const dateFormat = 'MM/dd/yyyy';

export const createExcelReport = (data: any, fileName: string) => {
  const ext = 'xlsx';
  const workBook = XLSX.utils.book_new();

  workBook.Props = {
    Title: fileName,
    Author: 'DSMT',
    CreatedDate: new Date(),
    ModifiedDate: new Date(),
  };
  const workSheet = XLSX.utils.aoa_to_sheet([
    [
      'DomainId',
      'Absence start date',
      'Absence end date',
      'Absence status',
      'Absence type',
      'Import result',
      'Result reason',
    ],
  ]);

  workSheet['!cols'] = [
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 40 },
  ];

  // Header style
  const headerStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: '4000a1f1' } },
  };
  for (let col = 1; col <= 7; col++) {
    const cell = XLSX.utils.encode_cell({ c: col - 1, r: 0 });
    workSheet[cell].s = headerStyle;
  }

  // Row styles
  data.forEach((row, index) => {
    const rowData = [
      row.domainId,
      dateFormatter.transform(row.startDate, dateFormat),
      dateFormatter.transform(row.endDate, dateFormat),
      row.status,
      row.type,
      row.importResult,
      row.resultReason,
    ];
    XLSX.utils.sheet_add_aoa(workSheet, [rowData], { origin: -1 });

    if ((index + 1) % 2 === 0) {
      const rowStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: '4087CEFA' } },
      };
      for (let col = 1; col <= 7; col++) {
        const cell = XLSX.utils.encode_cell({ c: col - 1, r: index + 1 });
        workSheet[cell].s = rowStyle;
      }
    }
  });

  workBook.SheetNames.push(fileName);
  workBook.Sheets[fileName] = workSheet;

  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const excelBlob: Blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  if (fileName.indexOf('.' + ext) === -1) {
    fileName = fileName + '.' + ext;
  }

  saveAs(excelBlob, fileName);
};

import { Report } from './new-form';

export const fieldsSettings: Report = {
  mandatory: [
    {
      cardType: 'select',
      data: 'statusEnum',
      id: 'statusId',
      label: 'Status',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'reqDate',
      label: 'Request Date',
      part: 'reqQualification',
      validation: ['valDate'],
      values: [new Date(), []],
    },
  ],
};

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { AwsSeatingPlanningDetails } from 'app/types/aws-seating-details';
import { ProjectCard } from 'app/types/card';
import { DatePipe } from '@angular/common';
import { CommonComponent } from 'app/shared/common/common.component';
import * as validationUtils from '../../shared/validators';
import { Common } from 'app/shared/common';

@Component({
  selector: 'app-aws-seating-planning-table',
  templateUrl: './aws-seating-planning-table.component.html',
  styleUrls: [
    './aws-seating-planning-table.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class AwsSeatingPlanningTableComponent extends CommonComponent implements OnInit, OnChanges {
  @Input() declare fieldsSettings: ProjectCard[];
  @Input() tableData: AwsSeatingPlanningDetails[];
  @Input() tableIndex: number;
  @Input() enumsData: {};
  @Input() role: number[];
  @Input() enums: any;

  displayedColumns: String[];
  dataSource = new MatTableDataSource();
  titles = ['Requested', 'Seat End Date Change', 'Bench requests', 'Bench List'];
  rowsForm: FormArray;
  dcResponsible;
  loaded: Boolean;

  dateFormatter = new DatePipe('en-US');

  ngOnInit(): void {
    super.ngOnInit();
    this.data = JSON.parse(JSON.stringify(this.enumsData));
    this.setDefaultColumns();

    this.populateDropdowns(this.enums);
    this.createForm();

    this.dataSource.data = this.handleDatasourceData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.tableData.isFirstChange) {
      this.dataSource.data = this.handleDatasourceData();
    }
  }

  handleDatasourceData() {
    const tmpData = [];

    this.tableData.forEach(element => {
      this.fieldsSettings.forEach(setting => {
        if (setting.type === 'enum' && setting.cardType !== 'editable') {
          const filtered = this.data[setting.data].filter(
            option => option.id === element[setting.id]
          )[0];
          element[setting.id] = filtered !== undefined ? filtered['text'] : '';
        }
      });
      tmpData.push(element);
    });
    return tmpData;
  }

  protected populateDropdowns(enums) {
    this.data['buildingEnum'] = enums['seating-building-floor'];
    this.data['seatRegex'] = enums['seating-building-floor-regex'];
  }

  setDefaultColumns() {
    const data: String[] = [];
    this.fieldsSettings.forEach((setting: ProjectCard) => {
      if (setting.cardType !== 'hidden') {
        data.push(setting.id);
      }
    });

    this.displayedColumns = data;
  }

  createForm() {
    this.rowsForm = this.fb.array([]);
    this.form = this.fb.group({
      rowsData: this.rowsForm,
    });

    this.tableData.forEach(element => {
      const row = this.fb.group({}, { updateOn: 'change' });
      this.fieldsSettings.forEach(setting => {
        const validators = [];

        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });

        // Add seat regex validation, based on building
        if (setting.id === 'seat') {
          const buildingEnum = this.data['buildingEnum'].filter(
            option => option.id === element['buildingFloor']
          )[0];
          if (buildingEnum !== undefined) {
            const newRegex = this.data['seatRegex'].filter(
              option => option.id === buildingEnum.idRegex
            )[0];
            validators.push(validationUtils['valSeat'](newRegex['regex']));

            element['seatLabel'] = newRegex !== undefined ? newRegex['seatNumberFormat'] : 'Seat';
          } else {
            // reset seat and building if buildingEnum not valid
            element['seat'] = '';
            element['seatLabel'] = 'Seat';
            element['buildingFloor'] = null;
          }
        }

        row.addControl(
          setting.id,
          this.fb.control(element[setting.id], { updateOn: 'change', validators: validators })
        );
      });
      row
        .get('buildingFloor')
        .valueChanges.subscribe(changes => this.onBuildingFloorChange(row, changes));
      row
        .get('seatStatus')
        .valueChanges.subscribe(changes => this.onSeatStatusChange(row, changes));
      this.rowsForm.push(row);
    });
    this.data['buildingEnum'] = this.data['buildingEnum'].filter(value =>
      this.role.includes(value['idLocation'])
    );
  }

  onSeatStatusChange(row: FormGroup, changes: number): void {
    if (changes == 6) {
      row.get('awsComment').addValidators(Validators.required);
      row.get('awsComment').updateValueAndValidity();
    } else {
      row.get('awsComment').clearValidators();
      row.get('awsComment').updateValueAndValidity();
    }
  }

  onBuildingFloorChange(row: FormGroup, changes: number) {
    const validators = [];
    const setting = this.fieldsSettings.find(option => option.id === 'seat');
    setting.validation.forEach(validation => {
      validators.push(validationUtils[validation]());
    });

    const buildingEnum = this.data['buildingEnum'].find(option => option.id === changes);
    const newRegex = this.data['seatRegex'].filter(option => option.id === buildingEnum.idRegex)[0];
    validators.push(validationUtils['valSeat'](newRegex['regex']));
    row.get('seatLabel').setValue(newRegex !== undefined ? newRegex['seatNumberFormat'] : 'Seat');
    validators.push(validationUtils['valRequired']);
    row.get('seat').clearValidators();
    row.get('seat').addValidators(validators);
    row.get('seat').markAsTouched();
    row.get('seat').updateValueAndValidity();
  }

  getFinalValue(control: AbstractControl): any {
    const finalControl = {};
    this.fieldsSettings.forEach(setting => {
      if (
        (setting.cardType !== 'action' && setting.cardType === 'editable') ||
        setting.id === 'seatFrom' ||
        setting.id === 'buildingFloor' ||
        setting.id === 'id'
      ) {
        // if(setting.cardType !== 'action'){
        if (setting.type === 'date') {
          finalControl[setting.id] = Common.timezoneFix(control.get(setting.id).value);
        } else {
          finalControl[setting.id] = control.get(setting.id).value;
        }
      }
    });
    return finalControl;
  }

  validateDates(control: AbstractControl): Boolean {
    const dateFrom = new Date(control.get('seatFrom').value);
    const dateTo = new Date(control.get('seatTo').value);
    if (dateTo < dateFrom) {
      // control.setErrors({'incorrect': true});
      control.get('seatTo').setErrors({ incorrect: true });
      control.get('seatTo').markAsTouched();
      control.updateValueAndValidity();
      this.snackbar.error('Seat To date is before Seat From date!');
      return false;
    }
    return true;
  }
}

import { BannerCard } from './employee-banner-card';

export const bannerCard: Array<BannerCard> = [
  { cardType: 'enum', label: 'Gender', id: 'genderId', type: '', icon: '' },
  { cardType: 'enum', label: 'Employee Type', id: 'employeeTypeId', type: '', icon: '' },
  { cardType: 'enum', label: 'Talent Segment', id: 'talentSegmentId', type: '', icon: '' },
  {
    cardType: '',
    label: 'Project Assignment',
    id: 'projectAssignment',
    type: '',
    icon: 'assignment',
  },
  { cardType: '', label: 'Available as of', id: 'availableAsOf', type: 'date', icon: 'event' },
  { cardType: '', label: 'Cost Center', id: 'cctypeId', type: '', icon: 'access_time' },
  { cardType: 'enum', label: 'Domain', id: 'employeeDomainId', type: '', icon: 'business' },
  { cardType: 'enum', label: 'Management Level', id: 'newCareerLevelId', type: '', icon: 'flag' },
  { cardType: 'enum', label: 'Travel', id: 'travelId', type: '', icon: 'flight' },
  { cardType: '', label: 'Updated On', id: 'updatedOn', type: '', icon: '' },
];

export const benchCard: Array<BannerCard> = [
  { cardType: 'enum', label: 'Employee Type', id: 'employeeTypeId', type: '', icon: '' },
  { cardType: '', label: 'Enterprise ID', id: 'enterpriseDomainId', type: '', icon: '' },
  { cardType: 'enum', label: 'Platform', id: 'platformId', type: '', icon: 'assignment' },
  {
    cardType: 'enum',
    label: 'Platform Lead',
    id: 'platformLeadId',
    type: '',
    icon: 'person_outline',
  },
  { cardType: 'enum', label: 'Management Level', id: 'newCareerLevelId', type: '', icon: 'flag' },
  { cardType: 'enum', label: 'Domain', id: 'employeeDomainId', type: '', icon: 'business' },
  { cardType: '', label: 'People Lead', id: 'careerCounsellorEid', type: '', icon: 'person' },
  { cardType: '', label: 'Availability (FTE)', id: 'availability', type: '', icon: 'access_time' },
];

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'bool',
    id: 'useUniversalWbs',
    label: 'Use universal WBS',
    validation: [],
    values: [false, []],
  },
  {
    cardType: 'input',
    id: 'wbs',
    label: 'WBS',
    validation: ['valString8', 'valWbsRequired'],
    values: ['', []],
  },
  {
    cardType: 'bool',
    id: 'projectSeatsDemand',
    label: 'Project Seats Demand',
    validation: [],
    values: [false, []],
  },
];

import { distinctUntilChanged } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ProjectDetails } from '../../../types/project-details';
import { ProjectCard } from '../../../types/card';
import { ProjectCardItemComponent } from '../../project-card-item/project-card-item.component';
import { ProjectCardItemComboboxComponent } from '../../project-card-item/project-card-item-combobox/project-card-item-combobox.component';
import { ProjectCardItemBoolComponent } from '../../project-card-item/project-card-item-bool/project-card-item-bool.component';
import { ProjectService } from '../../../services/project.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { Router } from '@angular/router';
import * as cards from '../../../types/project-cards-content';
import { User } from '../../../services/user-roles/user';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { Common } from '../../../shared/common';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { DialogData } from '../../../types/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { ProjectCardItemEnumComponent } from '../../project-card-item/project-card-item-enum/project-card-item-enum.component';
import { NewClientDialogComponent } from '../../new-client-dialog/new-client-dialog.component';
import { ClientListService } from '../../../services/client-list.service';
import * as validationUtils from '../../../shared/validators';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss', '../../../shared/card.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardComponent implements OnInit, OnChanges {
  protected modelValue: any = null;
  saving: boolean;
  adding: boolean;
  editing: boolean;
  projectOriginVal: ProjectDetails;
  valid: boolean;
  commitmentValue: number;
  color = '';
  eidChange = false;
  cardHeaderIconArray = [
    'star',
    'domain',
    'person',
    'assignment',
    'location_city',
    'insert_invitation',
    'supervisor_account',
    'supervisor_account',
    'airplanemode_on',
    'work',
    'laptop',
    'power_settings_new',
    'content_paste',
  ];
  projectCards = [];

  editingRole: boolean;

  dates = [];
  dataRefresher: any;

  @ViewChildren('item') private cardItems: QueryList<ProjectCardItemComponent>;

  @Input() projectCard: ProjectCard[];

  @Input() projectDetails: ProjectDetails;

  @Input() index: number;

  @Input() anotherCard: any;

  @Input() clientEnum;

  @Input() tabChanged: boolean;

  @Output() anotherCardNotify = new EventEmitter();
  @Output() clientNotify = new EventEmitter();

  formGroup: FormGroup;

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public snackbar: SnackbarPanelComponent,
    private router: Router,
    private userRolesService: UserRolesService,
    private clientListService: ClientListService
  ) {}

  init() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.projectOriginVal = Object.assign({}, this.projectDetails);
    this.projectOriginVal.client = Object.assign({}, this.projectDetails.client);
    this.projectOriginVal.projectInfo = Object.assign({}, this.projectDetails.projectInfo);
    this.projectOriginVal.projectSchedule = Object.assign({}, this.projectDetails.projectSchedule);
    this.projectOriginVal.reqQualification = Object.assign(
      {},
      this.projectDetails.reqQualification
    );

    const primaryContact = 'PrimaryContact';

    const group = {};
    this.projectCard.forEach(item => {
      if (item.type !== 'read' || item.id === 'offeringResp') {
        group[item.id] = ['', []];
      }
    });
    this.formGroup = this.fb.group(group);

    this.initEditingRole();

    if (this.formGroup.get('regionId')) {
      this.formGroup.get('regionId').valueChanges.subscribe(value => this.onRegionChanges(value));
    }

    if (this.formGroup.get('platformId')) {
      this.formGroup
        .get('platformId')
        .valueChanges.subscribe(value => this.onPlatformChanges(value));
    }

    if (this.formGroup.get('clientGroupId')) {
      this.formGroup
        .get('clientGroupId')
        .valueChanges.subscribe(value => this.onIndustryGroupChanges(value));
    }

    if (this.formGroup.get('offeringNameId')) {
      this.formGroup
        .get('offeringNameId')
        .valueChanges.subscribe(value => this.onOfferingNameChanges(value));
    }

    if (this.formGroup.get('workCategoryId')) {
      this.formGroup
        .get('workCategoryId')
        .valueChanges.subscribe(value => this.onWorkCategoryChanges(value));
    }

    if (this.formGroup.get('commitment')) {
      this.formGroup
        .get('commitment')
        .valueChanges.subscribe(value => this.onCommitmentChanges(value));
    }

    if (this.formGroup.get('clAccLeadContact')) {
      this.formGroup
        .get('clAccLeadContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('clAccLead' + primaryContact))
        );
    }

    if (this.formGroup.get('techAccLeadContact')) {
      this.formGroup
        .get('techAccLeadContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('techAccLead' + primaryContact))
        );
    }

    if (this.formGroup.get('delLeadContact')) {
      this.formGroup
        .get('delLeadContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('delLead' + primaryContact))
        );
    }

    if (this.formGroup.get('projManContact')) {
      this.formGroup
        .get('projManContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('projMan' + primaryContact))
        );
    }

    if (this.formGroup.get('salBidContact')) {
      this.formGroup
        .get('salBidContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('salBid' + primaryContact))
        );
    }

    if (this.formGroup.get('qaDirContact')) {
      this.formGroup
        .get('qaDirContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('qaDir' + primaryContact))
        );
    }

    if (this.formGroup.get('othContact')) {
      this.formGroup
        .get('othContact')
        .valueChanges.subscribe(value =>
          this.onContactChanges(value, this.formGroup.get('oth' + primaryContact))
        );
    }

    if (this.formGroup.get('dcResponsibleRoleId')) {
      this.formGroup
        .get('dcResponsibleRoleId')
        .valueChanges.subscribe(value => this.onDcResponsibleRoleChanges(value));
    }

    if (this.formGroup.get('operationalRespId')) {
      this.formGroup
        .get('operationalRespId')
        .valueChanges.subscribe(value => this.onOperationalResponsibilityChanges(value));
    }

    if (this.formGroup.get('end2End')) {
      this.formGroup
        .get('end2End')
        .valueChanges.subscribe(value =>
          this.onE2Echanges(
            value,
            this.projectDetails.reqQualification.dcResponsibleRoleId,
            this.projectDetails.projectInfo.operationalRespId
          )
        );
    }

    if (this.formGroup.get('dmsId')) {
      this.formGroup
        .get('dmsId')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => this.onDMSwizardIdChanges(value));
    }
  }

  ngOnInit() {
    this.init();
  }

  populateClient(event: any) {
    // fill fields that are responsible for client
    this.formGroup.get('diamondClient').setValue(event.diamondClient);
    this.projectDetails.client = event;
  }

  onDcResponsibleRoleChanges(value) {
    if (value) {
      this.formGroup.get('operationalRespId').enable({ onlySelf: true, emitEvent: false });
      this.formGroup.get('end2End').enable({ onlySelf: true, emitEvent: false });
    }
  }

  onOperationalResponsibilityChanges(value) {
    if (value) {
      this.formGroup.get('dcResponsibleRoleId').enable({ onlySelf: true, emitEvent: false });
      this.formGroup.get('end2End').enable({ onlySelf: true, emitEvent: false });
    }
  }

  onE2Echanges(value, role, responsibility) {
    this.formGroup.get('dcResponsibleRoleId').enable({ onlySelf: true, emitEvent: false });
    this.formGroup.get('operationalRespId').enable({ onlySelf: true, emitEvent: false });
    if (value === '') {
      return;
    }
    if (value) {
      if (role === 'ISM') {
        this.snackbar.error(
          `E2E is not valid for this DC Responsible Role: ${role} and all Operational Responsibilities`
        );
      }
    } else {
      if ((role === 'ISM' && responsibility === 2) || (role === 'PDL' && responsibility === 1)) {
        this.snackbar.error(
          `Non E2E is not valid for this DC Responsible Role: ${role} and current Operational Responsibility`
        );
      }
    }
  }

  onContactChanges(value, control) {
    if (control !== null) {
      value
        ? control.enable({ onlySelf: true, emitEvent: false })
        : control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  // TODO: REFACTOR should not use a global variable
  onCommitmentChanges(value) {
    if (value < 100) {
      this.commitmentValue = value;
    }
    if (value) {
      this.cardItems?.forEach(item => {
        if (item.id === 'dcResponsibleRoleId' && item instanceof ProjectCardItemComboboxComponent) {
          item.changeDcResponsibleRole(value, this.projectDetails.projectInfo.operationalRespId);
          item.setDcRespRoleValidationOnCommitment(value, item.id);
        }

        if (
          (item.id === 'end2End' && item instanceof ProjectCardItemBoolComponent) ||
          (item.id === 'operationalRespId' && item instanceof ProjectCardItemEnumComponent)
        ) {
          item.setDcRespRoleValidationOnCommitment(value, item.id);
        }
      });
    }
  }

  onPlatformChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'domainId' && item instanceof ProjectCardItemEnumComponent) {
          item.changeDomain(value);
          return true;
        }
        return false;
      });
    }
  }

  onIndustryGroupChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'subIndustryId' && item instanceof ProjectCardItemEnumComponent) {
          item.changeSubIndustry(value);
          return true;
        }
        return false;
      });
    }
  }

  onRegionChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'countryId' && item instanceof ProjectCardItemEnumComponent) {
          item.changeCountry(value);
          return true;
        }
        return false;
      });
    }
  }

  onOfferingNameChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'offeringResp' && item instanceof ProjectCardItemComponent) {
          item.changeOfferingResponsible(value);
          return true;
        }
        return false;
      });
    }
  }

  onWorkCategoryChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'sharedService' && item instanceof ProjectCardItemEnumComponent) {
          item.changeSharedService(value);
        }
        /*if (item.id === 'sharedServiceResourcePoolId' && item instanceof ProjectCardItemComponent) {
															 item.changeSharedServiceResourcePool(value);
														  }*/
        if (item.id === 'unchargedProject' && item instanceof ProjectCardItemBoolComponent) {
          item.changeInternalMO(value);
        }
        return false;
      });
    }
  }

  onDMSwizardIdChanges(value) {
    this.cardItems?.some(item => {
      if (item.id === 'dmsId') {
        // if there is SOME value that is atleast long as needed and its different from the last one
        if (value && value.length > 8 && item.control.value.dmsId !== value) {
          item.setDMSmyWizardFormatValidation(item.id);
          // if there is not a value and its different from the last one ( means the field is blank )
        } else if ((!value || value === '') && item.control.value.dmsId !== value) {
          item.clearValidatorByField(item.id);
        }
      }
      return false;
    });
  }

  /*
   * Resets editing state once project details changed and checks editing permissions for counselors
   * Prevents calling these updates on first change since we also do this in ngInit
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectDetails && !changes.projectDetails.firstChange) {
      this.projectOriginVal = Object.assign({}, this.projectDetails);
      this.projectOriginVal.client = Object.assign({}, this.projectDetails.client);
      this.projectOriginVal.projectInfo = Object.assign({}, this.projectDetails.projectInfo);
      this.projectOriginVal.projectSchedule = Object.assign(
        {},
        this.projectDetails.projectSchedule
      );
      this.projectOriginVal.reqQualification = Object.assign(
        {},
        this.projectDetails.reqQualification
      );
      this.initEditingRole();
    }
  }

  initEditingRole() {
    this.editing = false;

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        const status = this.projectDetails.statusId;
        this.editingRole = !user.getRoles().editing || status === 5 || status === 6 ? false : true;
      });
  }

  onCancel() {
    if (!this.saving) {
      // Reset properties in this card to original values
      this.projectCard.forEach(card => {
        if (this.projectOriginVal.client[card.id] !== undefined) {
          this.projectDetails.client[card.id] = this.projectOriginVal.client[card.id];
        } else if (this.projectOriginVal.projectInfo[card.id] !== undefined) {
          this.projectDetails.projectInfo[card.id] = this.projectOriginVal.projectInfo[card.id];
        } else if (this.projectOriginVal.projectSchedule[card.id] !== undefined) {
          this.projectDetails.projectSchedule[card.id] =
            this.projectOriginVal.projectSchedule[card.id];
        } else if (this.projectOriginVal.reqQualification[card.id] !== undefined) {
          this.projectDetails.reqQualification[card.id] =
            this.projectOriginVal.reqQualification[card.id];
        } else {
          this.projectDetails[card.id] = this.projectOriginVal[card.id];
        }
      });

      this.editing = false;
      this.anotherCardNotify.emit(this);
    }
  }

  editWarningDialog() {
    const changeString = this.index !== this.anotherCard.index ? 'Keep changes' : 'Save changes';
    const data: DialogData = {
      title: 'Warning',
      content:
        'Unfinished work on Project Details card! Do you want to ' + changeString + ' or discard? ',
      action1: 'Discard',
      action2: changeString,
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (result) {
        this.anotherCard.onCancel();

        // Enable closing of single card when discarding while leaving page
        if (this !== this.anotherCard) {
          this.editing = true;
          this.cdRef.detectChanges();
          this.anotherCardNotify.emit(this);
        }
      } else {
        // When save changes is being clicked then save unfinished card
        if (changeString === 'Save changes') {
          this.onSubmit();
        }
      }
    });
    return afterClosed;
  }

  onAdding() {
    const dialogRef = this.dialog.open(NewClientDialogComponent, {
      width: '300px',
    });

    //  after saving new client reloads data and set a new client in enum field
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientListService.deleteCache();
        this.clientListService.getData().subscribe(data => {
          this.editing = true;

          this.cardItems.some(item => {
            if (item.id === 'clientId' && item instanceof ProjectCardItemEnumComponent) {
              item.changeClient(data);
              return true;
            }
            return false;
          });

          // after saving the client, we have to update the cached enum,
          // so we create new JSON object with client's ID and name and push it into existing enum array
          // const jsonStr = '{"id": ' + result.client.clientId + ', "text": "' + result.client.clientName + '"}';
          // const jsonObj = JSON.parse(jsonStr);
          // just push the string of a name
          this.clientEnum.push(result.client.clientName);

          this.projectDetails.client.clientName = result.client.clientName; // added
          this.projectDetails.client.clientId = result.client.clientId;
          this.projectDetails.client.diamondClient = result.client.diamondClient;
          this.projectDetails.client.deleted = result.client.deleted;
          // probably not needed due to calling service again
          // this.clientNotify.emit(this);
        });
      }
    });
  }

  onEditing() {
    if (this.editing) {
      return;
    }

    if (this.anotherCard && !this.tabChanged && this.anotherCard.editing) {
      this.editWarningDialog();
      return;
    }

    this.projectService.getDemands(this.projectDetails.projectId, true).subscribe(demands => {
      demands.forEach(element => {
        const date = new Date(element['supplyFrom']);
        if (date.getFullYear() !== 1970) this.dates.push(date);
      });
    });

    this.projectService
      .getDemands(this.projectDetails.projectId, false)
      .subscribe(inactiveDemands => {
        inactiveDemands.forEach(element => {
          const date = new Date(element['supplyFrom']);
          if (date.getFullYear() !== 1970) this.dates.push(date);
        });
      });

    this.editing = true;
    this.cdRef.detectChanges();
    this.anotherCardNotify.emit(this);
    this.checkStatusControl(this.formGroup.get('statusId'));

    this.cardItems.forEach(item => {
      if (item.type !== 'read') {
        this.setValidators(item);
      }
    });
  }

  setValidators(item) {
    const validators = [];
    item.validation.forEach(validation => {
      validators.push(validationUtils[validation]());
    });
    this.formGroup.controls[item.id].setValidators(validators);
  }

  onSubmit() {
    const othContact = this.formGroup.get('othContact');
    const othContactRole = this.formGroup.get('othContactRole');
    const othContactVal = othContactRole?.value;
    if (othContactVal && (!othContact.value || othContact.invalid)) {
      this.snackbar.error('To add Other Contact Role, there must be valid Other Contact ');
      return;
    }
    if (othContactRole?.touched && othContactVal == '') {
      othContactRole.setValue(null);
    }

    if (!this.saving) {
      let send = false;
      let dateChange = false;
      this.eidChange = false;
      this.cardItems.forEach(item => {
        if (item.type !== 'read') {
          if (item.id === 'dmsId') {
            item.modelValue !== ''
              ? item.setDMSmyWizardFormatValidation(item.id)
              : item.clearValidatorByField(item.id);
          }
          if (item.id === 'workCategoryId') {
            if (item.modelValue === 4 || item.modelValue === 8) {
              this.formGroup.controls['sharedService'].setValidators([
                validationUtils['valRequiredSharedService'](),
              ]);
            }
          }
          this.formGroup.controls[item.id].markAsTouched();
          this.formGroup.controls[item.id].updateValueAndValidity({
            onlySelf: false,
            emitEvent: false,
          });
        }
      });

      if (this.formGroup.valid) {
        const update = {
          client: {},
          projectInfo: {},
          reqQualification: {},
          projectSchedule: {},
        };

        update['projectId'] = this.projectDetails.projectId;
        this.cardItems.forEach(cardItem => {
          if (cardItem.isChanged) {
            send = true;
            if (cardItem.modelValue instanceof Date) {
              if (this.dates.length > 0 && cardItem.id === 'startDate') {
                dateChange = true;
                cardItem.modelValue = new Date(Math.min.apply(null, this.dates));
                cardItem.projectDetails.projectSchedule.startDate = cardItem.modelValue;
              }

              if (cardItem.part) {
                update[cardItem.part][cardItem.id] = Common.timezoneFix(cardItem.modelValue);
              } else {
                update[cardItem.id] = Common.timezoneFix(cardItem.modelValue);
              }
            } else {
              if (cardItem.part === 'client' && cardItem.id === 'clientName') {
                update[cardItem.part][cardItem.id] = cardItem.modelValue;
                // Specific workaround for control selecting only ID a not clientName
                // this.projectDetails.client.clientName = this.clientEnum
                //   .filter(val => val.id === cardItem.modelValue)
                //   .map(value => value.text)[0];
              } else {
                if (cardItem.part) {
                  update[cardItem.part][cardItem.id] = cardItem.modelValue;
                } else {
                  update[cardItem.id] = cardItem.modelValue;
                }
              }
            }
          }
        });

        if (Object.keys(update.client).length === 0) {
          delete update.client;
        } else {
          // client should be explicitaly set due to DB differences
          update.client = this.projectDetails.client;
        }

        if (Object.keys(update.projectInfo).length === 0) {
          delete update.projectInfo;
        } else {
          update.projectInfo['tgSalesId'] = this.projectDetails.projectInfo.tgSalesId;
        }

        if (Object.keys(update.reqQualification).length === 0) {
          delete update.reqQualification;
        }

        if (Object.keys(update.projectSchedule).length === 0) {
          delete update.projectSchedule;
        }

        if (send) {
          this.saving = true;

          if (dateChange) {
            const data: DialogData = {
              title: 'Project Start Date',
              content:
                '<div>Your change will be ignored.</div>' +
                '<div>Project start date will be aligned with oldest supply start date.</div>',
              action1: 'OK',
            };
            const dialogRef = this.dialog.open(DialogComponent, { data });
            dialogRef.afterClosed().subscribe(result => {
              console.log(result);
            });
          }

          this.projectService.editProjectDetails(this.projectDetails.projectId, update).subscribe(
            res => {
              this.handleSubmit(res);
            },
            err => {
              this.handleError(err, update);
            }
          );
        } else {
          this.editing = false;
          this.anotherCardNotify.emit(this);
        }
      } else {
        this.errorWithValues();
      }
    }
  }

  errorWithValues() {
    this.snackbar.error('Please correct invalid or missing data marked in red.');
  }

  // After selecting status Cancelled or Rejected, user cannot change it back
  checkStatusControl(statusId: any) {
    if (statusId) {
      this.formGroup.get('statusId').enable({ onlySelf: true, emitEvent: false });
    }
  }

  handleSubmit(response) {
    this.projectOriginVal = Object.assign({}, this.projectDetails);
    this.projectOriginVal.client = Object.assign({}, this.projectDetails.client);
    this.projectOriginVal.projectInfo = Object.assign({}, this.projectDetails.projectInfo);
    this.projectOriginVal.projectSchedule = Object.assign({}, this.projectDetails.projectSchedule);
    this.projectOriginVal.reqQualification = Object.assign(
      {},
      this.projectDetails.reqQualification
    );
    this.saving = false;
    this.editing = false;
    this.anotherCardNotify.emit(this);
    if (this.projectDetails.projectSchedule.commitment === 100) {
      this.commitmentValue
        ? this.snackbar.success('Opportunity Updated to Project')
        : this.snackbar.success('Project Updated');

      this.cardItems.forEach(cardItem => {
        if (cardItem.isChanged) {
          cardItem.changeFirstValue();
        }
      });
      setTimeout(() => {
        if (window.location.href.includes('opportunity') && this.commitmentValue) {
          this.router.navigate(['/project-details', this.projectDetails.projectId]);
        }
      }, 1000);
    } else {
      this.snackbar.success('Project Updated');

      this.cardItems.forEach(cardItem => {
        if (cardItem.isChanged) {
          cardItem.changeFirstValue();
        }
      });
    }
    window.location.reload();
  }

  handleError(error: Response | any, update: any) {
    let itemName, message;
    let name;
    if (update.reqQualification) {
      if (update.reqQualification.dcResponsible) {
        name = 'ATC Responsible Name';
      } else if (update.reqQualification.dcResponsibleDeputy) {
        name = 'ATC Responsible Deputy';
      }
    } else {
      name = 'Contact';
    }
    this.saving = false;
    const body = error;
    if (body.error && body.error.length > 0) {
      message = body.error[0].code;
      const itemId = body.error[0].field;

      this.projectCards.forEach(card => {
        card.forEach(item => {
          const fieldId = item.part + '.' + item.id;
          if (fieldId === itemId) {
            return (itemName = item.label);
          }
        });
      });

      if (itemId === 'ContactEnterpriseId') {
        if (update.reqQualification === undefined) {
          this.snackbar.error(message);
        } else {
          const reqQualificationFields = Object.keys(update.reqQualification);
          const reqQualificationValues = Object.keys(update.reqQualification).map(
            k => update.reqQualification[k]
          );
          for (let i = 0; i < reqQualificationFields.length; i++) {
            if (
              typeof reqQualificationValues[i] === 'string' &&
              reqQualificationValues[i].localeCompare(message, 'en', { sensitivity: 'base' }) === 0
            ) {
              name = reqQualificationFields[i];
              if (name === 'clAccLeadContact') {
                name = 'Client Account Lead';
              } else if (name === 'techAccLeadContact') {
                name = 'Technology Service Lead';
              } else if (name === 'delLeadContact') {
                name = 'Delivery Lead';
              } else if (name === 'projManContact') {
                name = 'Project Manager';
              } else if (name === 'qaDirContact') {
                name = 'QA Director';
              } else if (name === 'othContact') {
                name = 'Other Contact';
              }
              this.snackbar.error(
                'Please correct ' +
                  name +
                  ' name - used Enterprise ID is not recognized as Accenture employee.'
              );
              break;
            } else {
              this.snackbar.error('Please correct Contact Enterprise ID (firstname.lastname)');
              break;
            }
          }
        }
      } else {
        if (update.reqQualification) {
          if (
            update.reqQualification.dcResponsible ||
            update.reqQualification.dcResponsibleDeputy
          ) {
            this.snackbar.error('Please correct ' + name + ' - ' + message);
          }
        } else {
          this.snackbar.error(itemName ? itemName + ' ' + message : message);
        }
      }
    } else {
      if (body.status === 500 || body.status === 400) {
        if (
          update.reqQualification &&
          (update.reqQualification.dcResponsible || update.reqQualification.dcResponsibleDeputy)
        ) {
          this.snackbar.error(
            'Please correct ' +
              name +
              ' - only DATC Enterprise ID or value from drop-down are allowed.'
          );
        } else {
          this.snackbar.error(
            'Please correct ' +
              name +
              ' name. Only Accenture Enterprise ID (firstname.lastname) or Avanade Email Adress (firstname.lastname@avanade.com) may be inserted.'
          );
        }
      } else {
        this.snackbar.error(
          'Please correct ' +
            name +
            ' name - used Enterprise ID is not recognized as Accenture employee.'
        );
      }
    }
  }
}

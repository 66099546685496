import { Injectable } from '@angular/core';
export interface DemandMgmtLDAP {
  editing: boolean;
  employeeEdit: boolean;
  employeeDelete: boolean;
  creating: boolean;
  deleting: boolean;
  importing: boolean;
  user: boolean;
  cc: boolean;
  aws: boolean;
  onlyView: boolean;
  bench: boolean;
  hiring: boolean;
  headCountPlaning: boolean;
  myWizartReport: boolean;
  tools: boolean;
  adminOrHr: boolean;
  sidenavLinks: Array<{ id: number; displayedName: string; icon: string; url: string }>;
  reportsLinks: Array<{ id: number; displayedName: string; url: string }>;
}

export class DemandMgmtLDAPPermissions implements DemandMgmtLDAP {
  editing = false;
  employeeEdit = false;
  employeeDelete = false;
  creating = false;
  deleting = false;
  importing = false;
  user = false;
  cc = false;
  onlyView = false;
  aws = false;
  bench = false;
  hiring = false;
  headCountPlaning = false;
  tools = false;
  adminOrHr = false;
  myWizartReport = false;
  sidenavLinks = [];
  reportsLinks = [];
}

@Injectable()
export class UserPermissions {
  addedLinks = [];
  addedReports = [];
  permissions = new DemandMgmtLDAPPermissions();
  cc = ['ROLE_CARRER_COUNCELOR_ROLE'];
  aws = [
    'MAPPED_SEAT_PLANNING_COORDINATOR',
    'MAPPED_SEAT_PLANNING_COORDINATOR_BUCH',
    'MAPPED_SEAT_PLANNING_COORDINATOR_BUD',
  ];
  myWizard = ['MAPPED_DEX_ROLE'];
  hiring = [
    'MAPPED_RECRUITING_ROLE',
    'MAPPED_PLATFORM_LEAD_ROLE',
    'MAPPED_SUBCONTRACTOR_COORDINATOR_ROLE',
  ];
  bench = ['MAPPED_BENCH_ROLE'];
  headCountPlaning = ['MAPPED_HC_PLAN_EDITOR_ROLE'];
  tools = ['MAPPED_MANAGER_LEVEL_ROLE', 'ROLE_MAPPED_USER_ROLE', 'ROLE_MAPPED_HR_ROLE'];
  editNothing = [
    'MAPPED_DEX_ROLE',
    'MAPPED_ABSENCES_OVERTIMES_REPORT_ROLE',
    'MAPPED_PLATFORM_LEAD_ROLE',
    'MAPPED_HC_PLAN_EDITOR_ROLE',
    'MAPPED_BENCH_ROLE',
    'MAPPED_INFRACOST_REMINDER_ROLE',
    'MAPPED_SUBCONTRACTOR_COORDINATOR_ROLE',
    'ROLE_CARRER_COUNCELOR_ROLE',
    'MAPPED_RECRUITING_ROLE',
    'MAPPED_SEAT_PLANNING_COORDINATOR',
    'MAPPED_SEAT_PLANNING_COORDINATOR_BUCH',
    'MAPPED_SEAT_PLANNING_COORDINATOR_BUD',
  ];
  onlyView = ['MAPPED_MANAGER_LEVEL_ROLE'];
  editProject = ['ROLE_MAPPED_USER_ROLE'];
  editAll = ['ROLE_MAPPED_ADMIN_ROLE', 'ROLE_MAPPED_HR_ROLE'];

  links = [
    { id: 1, displayedName: 'Project List', icon: 'description', url: 'project-list' }, // 0
    { id: 2, displayedName: 'Employee Details', icon: 'assignment_ind', url: 'employee-details' }, // 1
    { id: 3, displayedName: 'Confirm Resources', icon: 'done', url: 'confirm-resources' }, // 2
    { id: 4, displayedName: 'Opportunity List', icon: 'list_alt', url: 'opportunity-list' }, // 3
    {
      id: 5,
      displayedName: 'Infrastructure Review',
      icon: 'business',
      url: 'infrastructure-review',
    }, // 4
    { id: 6, displayedName: 'Hiring Requests', icon: 'next_week', url: 'hiring-requests' }, // 5
    {
      id: 7,
      displayedName: 'AWS Seating Planing',
      icon: 'people_outline',
      url: 'aws-seating-planning',
    }, // 6
    { id: 8, displayedName: 'Bench Management', icon: 'event_seat', url: 'bench-management' }, // 7
  ];

  reports = [
    { id: 1, displayedName: 'Project Summary Report', url: 'project-summary-report' }, // 0
    { id: 2, displayedName: 'Scheduling Report', url: 'scheduling-report' }, // 1
    { id: 3, displayedName: 'Cost Center Report', url: 'cost-center-report' }, // 2
    { id: 4, displayedName: 'Data Freshness Report', url: 'data-freshness-report' }, // 3
    { id: 5, displayedName: 'Hiring Requests Report', url: 'hiring-requests-report' }, // 4
    { id: 6, displayedName: 'Language Skills Usage Report', url: 'language-skills-usage-report' }, // 5
    { id: 7, displayedName: 'NBM Report', url: 'nbm-report' }, // 6
    { id: 8, displayedName: 'NS & Metrics Report', url: 'nsmetrics-report' }, // 7
    { id: 9, displayedName: 'Open Demands Report', url: 'opendemands-report' }, // 8
    { id: 10, displayedName: 'Open Positions Report', url: 'open-positions-report' }, // 9
    { id: 11, displayedName: 'Opportunity Summary Report', url: 'opportunity-summary-report' }, // 10
    { id: 12, displayedName: 'Platform Report', url: 'platform-report' }, // 11
    { id: 13, displayedName: 'Project Cost Report', url: 'project-cost-report' }, // 12
    { id: 14, displayedName: 'Project Seating-HW Cost Report', url: 'aws-seating-report' }, // 13
    { id: 15, displayedName: "Projects' Roll-Offs Overview", url: 'projects-roll-offs-overview' }, // 14
    { id: 16, displayedName: 'Supply Summary Report', url: 'supply-summary-report' }, // 15
    { id: 17, displayedName: 'Training Data Report', url: 'training-data-report' }, // 16
    { id: 18, displayedName: 'Vacation And Overtime Report', url: 'vacationandovertime-report' }, // 17
  ];

  user_role = {
    links: [0, 1, 3, 4],
    reports: [0, 1, 3, 5, 6, 8, 9, 10, 12, 13, 14, 15],
  };

  hr_role = {
    links: [0, 1, 2, 3, 4],
    reports: [0, 1, 2, 3, 5, 6, 8, 9, 10, 12, 14, 15],
  };

  cc_role = {
    links: [1],
    reports: [],
  };

  bench_role = {
    links: [7],
    reports: [],
  };

  seat_planning_BA_role = {
    links: [6],
    reports: [13],
  };

  seat_planning_Buch_role = {
    links: [6],
    reports: [13],
  };

  seat_planning_Bud_role = {
    links: [6],
    reports: [13],
  };

  infracost_reminder_role = {
    links: [],
    reports: [],
  };

  hc_plan_editor_role = {
    links: [],
    reports: [],
  };

  platform_lead_role = {
    links: [5],
    reports: [4, 11],
  };

  recruiting_role = {
    links: [5],
    reports: [4],
  };

  subco_coord_role = {
    links: [5],
    reports: [4],
  };

  manager_level_role = {
    links: [0, 1, 3],
    reports: [0, 1],
  };

  absences_overtimes_role = {
    links: [],
    reports: [17],
  };

  dex_role = {
    links: [],
    reports: [7],
  };

  constructor() {
    this.permissions = new DemandMgmtLDAPPermissions();
  }

  getSidenowLinks(data): DemandMgmtLDAP {
    const links = new Set();
    const reports = new Set();
    const roles = data['roles'];
    if (roles.some(r => this.aws.indexOf(r) >= 0)) {
      this.permissions.aws = true;
    }
    if (roles.some(r => this.myWizard.indexOf(r) >= 0)) {
      this.permissions.myWizartReport = true;
    }
    if (roles.some(r => this.cc.indexOf(r) >= 0)) {
      this.permissions.cc = true;
    }
    if (roles.some(r => this.bench.indexOf(r) >= 0)) {
      this.permissions.bench = true;
    }
    if (roles.some(r => this.hiring.indexOf(r) >= 0)) {
      this.permissions.hiring = true;
    }
    if (roles.some(r => this.headCountPlaning.indexOf(r) >= 0)) {
      this.permissions.headCountPlaning = true;
    }
    if (roles.some(r => this.tools.indexOf(r) >= 0)) {
      this.permissions.tools = true;
    }
    if (roles.some(r => this.onlyView.indexOf(r) >= 0)) {
      this.permissions.onlyView = true;
    }
    if (roles.some(r => this.editProject.indexOf(r) >= 0)) {
      this.permissions.creating = true;
      this.permissions.deleting = true;
      this.permissions.editing = true;
      this.permissions.onlyView = true;
    }
    if (roles.some(r => this.editAll.indexOf(r) >= 0)) {
      this.permissions.creating = true;
      this.permissions.deleting = true;
      this.permissions.editing = true;
      this.permissions.employeeDelete = true;
      this.permissions.employeeEdit = true;
      this.permissions.importing = true;
      this.permissions.onlyView = true;
      this.permissions.adminOrHr = true;
      this.permissions.tools = true;
      this.permissions.bench = true;
      this.permissions.hiring = true;
      this.permissions.headCountPlaning = true;
      this.permissions.aws = true;
      this.permissions.myWizartReport = true;
    }

    // Case of Admin Role
    if (roles.includes('ROLE_MAPPED_ADMIN_ROLE')) {
      for (let i = 0; i < Object.keys(this.links).length; i++) {
        links.add(this.links[i]);
      }
      for (let i = 0; i < Object.keys(this.reports).length; i++) {
        reports.add(this.reports[i]);
      }
      this.permissions.user = true;
    } else {
      roles.forEach(role => {
        if (role === 'ROLE_MAPPED_HR_ROLE') {
          this.hr_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.hr_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'ROLE_MAPPED_USER_ROLE') {
          this.permissions.user = true;
          this.user_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.user_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'ROLE_CARRER_COUNCELOR_ROLE') {
          this.cc_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.cc_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_SUBCONTRACTOR_COORDINATOR_ROLE') {
          this.subco_coord_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.subco_coord_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_PLATFORM_LEAD_ROLE') {
          this.platform_lead_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.platform_lead_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_INFRACOST_REMINDER_ROLE') {
          this.infracost_reminder_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.infracost_reminder_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_HC_PLAN_EDITOR_ROLE') {
          this.hc_plan_editor_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.hc_plan_editor_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_BENCH_ROLE') {
          this.bench_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.bench_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_RECRUITING_ROLE') {
          this.recruiting_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.recruiting_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR') {
          this.seat_planning_BA_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.seat_planning_BA_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR_BUCH') {
          this.seat_planning_Buch_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.seat_planning_Buch_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_SEAT_PLANNING_COORDINATOR_BUD') {
          this.seat_planning_Bud_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.seat_planning_Bud_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_MANAGER_LEVEL_ROLE') {
          this.manager_level_role.links.forEach(n => {
            links.add(this.links[n]);
          });
          this.manager_level_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_ABSENCES_OVERTIMES_REPORT_ROLE') {
          this.absences_overtimes_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        } else if (role === 'MAPPED_DEX_ROLE') {
          this.dex_role.reports.forEach(n => {
            reports.add(this.reports[n]);
          });
        }
      });
    }

    this.permissions.sidenavLinks = Array.from(links).sort((a: any, b: any) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
    this.permissions.reportsLinks = Array.from(reports).sort((a: any, b: any) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
    return this.permissions;
  }
}

import { DemandMgmtLDAP, UserPermissions } from '../../types/user-roles';

export class User {
  eid: string;
  name: string;
  role: DemandMgmtLDAP;
  hasDomainId: Boolean;
  userPermissions: UserPermissions;
  constructor(data: Object) {
    this.userPermissions = new UserPermissions();
    this.eid = data['eid'];
    this.name = data['name'];
    this.role = this.userPermissions.getSidenowLinks(data);
    this.hasDomainId = data['hasDomainId'];
  }

  getRoles(): DemandMgmtLDAP {
    return this.role;
  }
}

import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { EnumsService } from '../../services/enumerations/enums.service';
import { EmployeeDetailsService } from '../employee-details/employee-details.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { Common } from '../../shared/common';
import { CommonComponent } from '../../shared/common/common.component';
import * as validationUtils from '../../shared/validators';
import { fieldsSettings } from '../../types/import-absences';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-import-absence-dialog',
  templateUrl: './import-absence-dialog.component.html',
  styleUrls: ['./import-absence-dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class ImportAbsenceDialogComponent extends CommonComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  editing = false;
  actionText: string;
  id: number;

  constructor(
    protected fb: FormBuilder,
    public dialogRef: MatDialogRef<ImportAbsenceDialogComponent>,
    private enumsService: EnumsService,
    private employeeDetailsService: EmployeeDetailsService,
    public snackbar: SnackbarPanelComponent,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.dialogData.absence != null) {
      this.editing = true;
    }
    this.actionText = this.dialogData.isEditing ? 'Edit Absence' : 'Create Absence';

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    this.data['typeEnum'] = enums['employee-absence'].filter(element => {
      // Vacation, Illness, LOA, Parental Leave
      if (element.id === 1 || element.id === 2 || element.id === 3 || element.id === 4) {
        return element;
      }
    });
    this.data['statusEnum'] = enums['employee-absence-status'];

    if (this.editing) {
      this.id = this.dialogData.absence.id;

      this.form.get('typeId').setValue(this.dialogData.absence.typeId);
      this.form.get('statusId').setValue(this.dialogData.absence.statusId);
      this.form.get('startDate').setValue(this.dialogData.absence.startDate);
      this.form.get('endDate').setValue(this.dialogData.absence.endDate);
    }

    enums['employee-absence-status'].forEach(value => {
      if (value.text === 'Approved') {
        this.form.get('statusId').setValue(value.id);
        if (!this.editing) this.form.get('statusId').disable();
      }
    });
  }

  setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        if (validation === 'valFromToDates') {
          validators.push(validationUtils[validation](this.form.get('endDate')));
        } else if (validation === 'valToFromDates') {
          validators.push(validationUtils[validation](this.form.get('startDate')));
        } else {
          validators.push(validationUtils[validation]());
        }
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  create(create: boolean) {
    if (create) {
      const absence = {
        employeeDetailId: this.dialogData.employeeDetailId,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.value instanceof Date) {
          absence[controlerName] = Common.timezoneFix(this.form.get(controlerName).value);
        } else {
          absence[controlerName] = this.form.get(controlerName).value;
        }
      });

      if (this.form.valid) {
        if (!this.editing) {
          this.employeeDetailsService.importAbsence(absence).subscribe(
            response => {
              this.handleAbsence(response);
            },
            error => {
              this.handleError(error);
            }
          );
        } else {
          this.employeeDetailsService.updateAbsence(this.id, absence).subscribe(
            response => {
              this.handleAbsence(response);
            },
            error => {
              this.handleError(error);
            }
          );
        }
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  handleAbsence(response) {
    this.dialogRef.close(true);
    this.snackbar.success('Absence has been saved');
  }

  handleError(error) {
    this.snackbar.error('Problem with saving absence');
  }
}

import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { BannerCard } from '../../types/employee-banner-card';
import { FormatCardByType } from '../../pipes/format-card-by-type.pipe';
import { FormatCardEnum } from '../../pipes/format-card-enum.pipe';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { CommonFieldsComponent } from '../add-new-employee/common-fields/common-fields.component';
import { EmployeeDetails } from 'app/types/employee-details';

@Component({
  selector: 'app-employee-banner-card',
  templateUrl: './employee-banner-card.component.html',
  styleUrls: ['./employee-banner-card.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class EmployeeBannerCardComponent
  extends CommonFieldsComponent
  implements OnInit, OnChanges
{
  @Input() bannerCard: BannerCard[];
  @Input() declare employeeDetails: EmployeeDetails;
  @Input() benchManagement: boolean = false;
  @ViewChild('bannerIconsContainer', { static: true }) bannerIconsContainer;

  formatCardEnum: FormatCardEnum;
  formatCardByType: FormatCardByType;
  costCenterType = '';

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.employeeDetails.previousValue &&
      changes.employeeDetails.currentValue !== changes.employeeDetails.previousValue
    ) {
      this.handleCostCenter();
    }
  }

  populateDropdowns(enums) {
    this.data['costCentrum'] = enums['employee-cost-centrum'];
    this.data['employeeDomain'] = enums['employee-domain'];
    this.data['talent'] = enums['employee-talent-segment'];
    this.data['type'] = enums['employee-type'];
    this.data['travel'] = enums['employee-travel'];
    this.data['newCareerLevel'] = enums['employee-new-career-level'];
    this.data['platform'] = enums['platform'];

    // get platform lead eid from lead email
    this.data['platformLead'] = this.data['platform'].map(option => ({
      id: option.id,
      text: option.leadEmail.split('@')[0],
    }));
    this.handleCostCenter();
  }

  handleCostCenter() {
    let costCenterType;
    let costCenterRate;
    let costCenterText;
    this.costCenterType = '-';
    if (this.employeeDetails.updateCc) {
      costCenterType = this.data['costCentrum'].filter(
        value => this.employeeDetails.updatedCostCenterId === value.id
      );
    } else {
      if (this.employeeDetails.costCentersId) {
        costCenterType = this.data['costCentrum'].filter(
          value => this.employeeDetails.costCentersId === value.id
        );
      }
    }

    if (costCenterType && costCenterType.length) {
      costCenterRate = costCenterType[0].rate;
      costCenterText = costCenterType[0].text;
    }

    if (costCenterText) {
      this.costCenterType = costCenterText;
    } else {
      this.costCenterType = '-';
    }
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DemandCommonFieldsComponent } from '../demand-common-fields/demand-common-fields.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-demand-mandatory-fields',
  templateUrl: './demand-mandatory-fields.component.html',
  styleUrls: ['./demand-mandatory-fields.component.scss'],
})
export class DemandMandatoryFieldsComponent extends DemandCommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: FormGroup;

  platformSubscription: any;
  domainSubscription: any;
  isDcso: any;

  ngOnInit() {
    super.ngOnInit();

    // this.expansionPanel.open();
    this.mandatory.valueChanges.subscribe(changes => this.onFormChanges(changes, this.mandatory));
  }

  subscribeSpecialEnums() {
    this.platformSubscription = this.mandatory
      .get('platformId')
      .valueChanges.subscribe(value => this.onPlatformChange(value));
    this.domainSubscription = this.mandatory
      .get('domainId')
      .valueChanges.subscribe(value => this.onDomainChange(value));
    this.isDcso = this.mandatory
      .get('dcso')
      .valueChanges.subscribe(value => this.onDCSOchange(value));
  }

  public unsubscribeSpecialEnums() {
    this.platformSubscription.unsubscribe();
    this.domainSubscription.unsubscribe();
    this.isDcso.unsubscribe();
  }

  onDomainChange(domainId: number) {
    const domains = this.enums['domain-skill'].filter(value => value.idPle === domainId);

    if (domains.length > 0) {
      const idsSkills = [];

      domains.forEach(domain => {
        idsSkills.push(domain.idSkill);
      });
    }
  }

  onPlatformChange(platformId: number, loading?: boolean) {
    this.data['domainEnum'] = this.enums['domain'].filter(
      value => value.platformId === platformId && value.deleted !== true
    );

    if (this.data['domainEnum'].length > 0 && !loading) {
      this.mandatory.get('domainId').setValue(null);
    }
  }

  onDCSOchange(value: Boolean) {
    this.projectService.getAttachments(this.projectId).subscribe((data: any) => {
      if (data) {
        let hasDcso = false;
        data.forEach(attachment => {
          if (attachment.dcso) {
            hasDcso = true;
          }
        });
        if (value && !hasDcso) {
          this.mandatory.get('dcso').setValue(false);
          this.snackbar.error('DCSO cannot be checked when there is no attachment with DCSO flag.');
        }
      }
    });
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);
    this.reverse(this.enums['probability']);

    //this.data['proficiencyEnum'] = enums['proficiency'];
    this.data['platformEnum'] = enums['platform'].filter(value => value.deleted !== true);
    if (
      this.mandatory.get('platformId').value !== '' ||
      this.mandatory.get('platformId').value !== null
    ) {
      this.onPlatformChange(this.mandatory.get('platformId').value, true);
    } else {
      this.data['domainEnum'] = this.enums['domain'].filter(value => value.deleted !== true);
    }
    //this.data['primarySkillEnum'] = enums['primary-skill'];
    this.data['probabilityEnum'] = this.enums['probability'].filter(value => value.id > 0);
    this.data['sourcingChannelEnum'] = enums['sourcing-channel'];
    this.data['sourcingLocationEnum'] = enums['employee-location'];
    this.data['rateTypeEnum'] = enums['rate-type'];
    //this.data['pcNotebookEnum'] = enums['pc-notebook'];
    this.data['careerLevelEnum'] = enums['employee-new-career-level'];

    this.route.queryParams.subscribe(params => {
      if (!params['id'] && !params['ids']) {
        this.getProjectDetails(params['projectId']);
      } else if (params['id']) {
        this.getDemandDetails(params['id']);
      }
    });
  }

  handleProjectDetailsSuccess(projectDetails: any): void {
    let val: number = projectDetails.reqQualification.primaryAccountableLocationId;
    let primaryText = '';
    this.enums['primary-accountable-location'].forEach(element => {
      if (element.id === projectDetails.reqQualification.primaryAccountableLocationId) {
        primaryText = element.text;
      }
    });
    if (projectDetails.projectSchedule.commitment < 100) {
      this.mandatory
        .get('startDateDemand')
        .setValue(new Date(projectDetails.projectSchedule.startDate));
    }
    this.mandatory.get('demandRateTypeId').setValue(projectDetails.projectInfo.rateTypeId);
    this.mandatory.get('platformId').setValue(projectDetails.reqQualification.platformId);
    this.data['domainEnum'] = this.enums['domain'].filter(
      value =>
        value.platformId === projectDetails.reqQualification.platformId && value.deleted !== true
    );
    this.mandatory.get('domainId').setValue(projectDetails.reqQualification.domainId);
    this.mandatory.get('probabilityId').setValue(projectDetails.projectSchedule.commitment);
    this.mandatory.get('sourcingChannelIds').setValue([1]);
    this.data['sourcingLocationEnum'].forEach(element => {
      if (element.text === primaryText) {
        val = element.id;
      }
    });
    this.mandatory.get('sourcingLocationId').setValue(val);
  }

  getProjectDetails(searchedId) {
    this.projectService.getProjectDetails(searchedId).subscribe(
      response => {
        this.handleProjectDetailsSuccess(response);
      },
      error => this.handleError(error)
    );
  }

  handleDemandDetails(demandDetails: any) {
    this.mandatory.get('platformId').setValue(demandDetails.platformId);
    this.data['domainEnum'] = this.enums['domain'].filter(
      value => value.platformId === demandDetails.platformId && value.deleted !== true
    );
    this.mandatory.get('domainId').setValue(demandDetails.domainId);
  }

  getDemandDetails(id) {
    this.projectService.getDemandDetails(id).subscribe(
      demandDetails => {
        this.handleDemandDetails(demandDetails);
      },
      error => this.handleError(error)
    );
  }

  private swap(enumeration: any, pos1: any, pos2: any) {
    const pom = enumeration[pos1];
    enumeration[pos1] = enumeration[pos2];
    enumeration[pos2] = pom;
  }

  private reverse(enumeration: any) {
    for (let i = 0; i < enumeration.length / 2; i++) {
      this.swap(enumeration, i, enumeration.length - i - 1);
    }
  }
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EmployeeDetailsService } from '../employee-details/employee-details.service';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { createExcelReport } from './import-absences-excel-builder';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-import-absences',
  templateUrl: './import-absences.component.html',
  styleUrls: [
    './import-absences.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ImportAbsencesComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput', { static: true }) fileInput;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = [
    'domainId',
    'startDate',
    'endDate',
    'status',
    'type',
    'importResult',
    'resultReason',
  ];
  dataSource = new MatTableDataSource();

  file: File;
  fileName: String;

  constructor(
    private employeeDetailsService: EmployeeDetailsService,
    private snackbar: SnackbarPanelComponent,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {}

  back() {
    this.snackbar.close();
    this.location.back();
  }

  ngOnInit() {
    this.snackbar.close();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  chooseAbsences() {
    this.fileInput.nativeElement.click();
  }

  loadAbsences(e) {
    this.file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!this.file) {
      return;
    }

    const reader = new FileReader();
    this.fileName = this.file.name;
    reader.readAsDataURL(this.file);
  }

  import() {
    if (this.fileName) {
      this.snackbar.info('Importing absences');
      this.employeeDetailsService.importAbsences(this.file).subscribe(
        res => this.handleAbsences(res),
        err => this.handleError(err)
      );
    }
  }

  handleAbsences(response) {
    this.snackbar.success('Import successful');
    this.dataSource.data = response;
    this.fileName = null;
    this.fileInput.nativeElement.value = null;
  }

  handleError(error: Response) {
    const body = error;
    this.snackbar.error(body['error']);
  }

  saveFile() {
    createExcelReport(this.dataSource.data, 'Import_Absences_Export');
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProjectService } from '../services/project.service';
import { CommonComponent } from '../shared/common/common.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { ResourcesOpenDemandsComponent } from './resources-open-demands/resources-open-demands.component';
import { ResourcesExtendibleHardlocksComponent } from './resources-extendible-hardlocks/resources-extendible-hardlocks.component';
import { ResourcesSearchComponent } from './resources-search/resources-search.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-confirm-resources',
  templateUrl: './confirm-resources.component.html',
  styleUrls: ['./confirm-resources.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ConfirmResourcesComponent extends CommonComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(ResourcesSearchComponent) searchComponent;
  @ViewChild(ResourcesOpenDemandsComponent) openDemandsComponent;
  @ViewChild(ResourcesExtendibleHardlocksComponent) extendibleHardlocksComponent;

  @Input() model: any;
  selectedTab: number;
  loading: Boolean;
  message: any;
  demandsLoaded: Boolean;
  changedObjects: any;

  constructor(
    public app: AppComponent,
    protected fb: FormBuilder,
    protected router: Router,
    protected location: Location,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    private dialog: MatDialog,
    authService: AuthService
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    this.demandsLoaded = false;
    this.selectedTab = this.router.url.indexOf('#history') !== -1 ? 1 : 0;
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.selectedTab === 0) {
      this.location.go(this.router.url.replace('#history', ''));
    } else if (this.router.url.indexOf('#history') === -1) {
      this.location.go(this.router.url.concat('#history'));
    }
  }

  receiveMessage($event) {
    if ($event === 'loading') {
      this.loading = true;
      this.openDemandsComponent.openDemandsSearchParamsOrder = [];
      this.extendibleHardlocksComponent.hardlocksSearchParamsOrder = [];
      this.extendibleHardlocksComponent.deleted = false;
      this.extendibleHardlocksComponent.ended = false;
    } else if ($event === 'search') {
      this.searchComponent.searchProject(this.searchComponent.project);
    } else {
      this.message = $event;
      this.loading = false;
      this.demandsLoaded = true;
    }
  }

  receiveSearchMessage($event) {
    if (this.openDemandsComponent.form.dirty || this.extendibleHardlocksComponent.form.dirty) {
      this.editWarningDialog().subscribe(value1 => {
        this.searchComponent.searchProject($event);
      });
    } else {
      this.searchComponent.searchProject($event);
    }
  }

  save() {
    this.changedObjects = [[], [], [], []];
    if (this.openDemandsComponent.save() && this.extendibleHardlocksComponent.save()) {
      this.changedObjects[0] = this.openDemandsComponent.changedObject;
      this.changedObjects[1] = this.extendibleHardlocksComponent.changedObjects[0];
      this.changedObjects[2] = this.extendibleHardlocksComponent.changedObjects[1];
      this.changedObjects[3] = this.extendibleHardlocksComponent.changedObjects[2];
      if (
        this.changedObjects[0].length > 0 ||
        this.changedObjects[1].length > 0 ||
        this.changedObjects[2].length > 0 ||
        this.changedObjects[3].length > 0
      ) {
        this.loading = true;
        return this.projectService
          .saveChangedSupplies(this.searchComponent.project.projectId, this.changedObjects)
          .subscribe(
            response => {
              this.handleSaveResponse(response);
            },
            error => this.handleError(error)
          );
      }
    } else {
      this.snackbar.error('Please correct invalid data marked in red.');
    }
  }

  handleSaveResponse(response) {
    let errorMessage = '';
    if (response.length === 0) {
      this.snackbar.success('Confirm Resources updated!');
      this.searchComponent.searchProject(this.searchComponent.project);
    } else {
      this.loading = false;
      this.openDemandsComponent.changedObject = [];
      this.extendibleHardlocksComponent.changedObjects = [[], [], []];
      response.forEach(r => {
        if (r[1] === false) {
          if (r[6] === true || r[7] === true) {
            errorMessage += "Supply FTE needed is higher than employee's FTE.";
            this.snackbar.error(errorMessage);
          }
          if (r[3] === true) {
            errorMessage += 'FTE for ' + r[0] + ' is not in the range 0 (excluding) through 1. ';
            this.snackbar.error(errorMessage);
          }
          if (r[4] === true) {
            errorMessage += 'Start date can not be earlier than employee ' + r[0] + ' hire date. ';
            this.snackbar.error(errorMessage);
          }
          if (r[5] === true) {
            errorMessage += 'Supply To can not be earlier than Supply From';
            this.snackbar.error(errorMessage);
          }
          if (r[2] !== '') {
            this.collidingHardlocksWarning(r[0], r[2]);
          }
          if (errorMessage !== '') {
            this.snackbar.error(errorMessage);
          }
        } else {
          this.snackbar.success('Confirm Resources updated!');
          this.searchComponent.searchProject(this.searchComponent.project);
        }
      });
    }
  }

  collidingHardlocksWarning(supply, projects) {
    const data: DialogData = {
      title: 'Warning',
      content: supply + ' has colliding hardlocks on projects: ' + projects,
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    return afterClosed;
  }

  editWarningDialog() {
    const data: DialogData = {
      title: 'Warning',
      content: 'Unfinished work on Confirm Resources page! Do you want to save changes? ',
      action1: 'Discard',
      action2: 'Save changes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (!result) {
        this.save();
      }
    });
    return afterClosed;
  }

  protected readonly top = top;
}

import { EmployeeCard } from '../types/card';

export const card_0: Array<EmployeeCard> = [
  {
    cardType: '',
    id: 'firstName',
    label: 'First Name',
    type: '',
    validation: ['valRequired', 'valString20'],
  },
  {
    cardType: '',
    id: 'lastName',
    label: 'Last Name',
    type: '',
    validation: ['valRequired', 'valString20'],
  },
  {
    cardType: 'enum',
    id: 'employeeTypeId',
    label: 'Employee Type',
    type: '',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'statusId',
    label: 'Status',
    type: '',
    validation: ['valRequired', 'valOtherStatus'],
  },
  {
    cardType: '',
    id: 'personalNumber',
    label: 'Personal Number',
    type: '',
    validation: ['valRequiredPersonalNumber', 'valInteger', 'valString9'],
  },
  {
    cardType: '',
    id: 'enterpriseDomainId',
    label: 'Domain ID',
    type: '',
    validation: ['valRequiredEId', 'valString40'],
  },
  {
    cardType: 'bool',
    id: 'trainee',
    label: 'Trainee',
    type: '',
    validation: [],
  },
  {
    cardType: '',
    id: 'traineeEndDate',
    label: 'Trainee End Date',
    type: 'date',
    validation: ['valDate'],
    disabled: true,
  },
  {
    cardType: 'enum',
    id: 'homeOfficeScenarioId',
    label: 'Home Office Scenario',
    type: '',
    validation: [],
  },
];

export const card_1: Array<EmployeeCard> = [
  {
    cardType: 'autosearch',
    id: 'careerCounsellorEid',
    label: 'People Lead ID',
    type: '',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'positionId',
    label: 'Position',
    type: '',
    validation: ['valRequired'],
  },
  {
    cardType: 'enum',
    id: 'platformId',
    label: 'Platform',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'employeeDomainId',
    label: 'Domain',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'acquisitionId',
    label: 'Acquisition',
    type: '',
    validation: [],
  },
];

export const card_2: Array<EmployeeCard> = [
  {
    cardType: 'enum',
    id: 'travelId',
    label: 'Travel',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'rotationPreferencesId',
    label: 'Rotation Preferences',
    type: '',
    validation: [],
  },
  {
    cardType: 'combobox',
    id: 'rotationPreferencesProjectName',
    label: 'Rotation Preferences Project Name',
    type: '',
    validation: [],
  },
  {
    cardType: '',
    id: 'rotationPreferencesComment',
    label: 'Rotation Preferences Comment',
    type: '',
    validation: [],
  },
];

export const card_3: Array<EmployeeCard> = [
  {
    cardType: '',
    id: 'hireDate',
    label: 'Hire Date',
    type: 'date',
    validation: ['valDate', 'valRequiredForNoCc'],
  },
  {
    cardType: '',
    id: 'fte',
    label: 'FTE',
    type: '',
    validation: ['valRequiredForNoCc', 'valFlInterval'],
  },
  {
    cardType: 'enum',
    id: 'locationId',
    label: 'Home Location',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'billcodeId',
    label: 'Billcode',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'employeeActivityTypeId',
    label: 'Activity Type',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'newCareerLevelId',
    label: 'Management Level',
    type: '',
    validation: [],
  },
  {
    cardType: '',
    id: 'lastPromotionDate',
    label: 'Last Promotion Date',
    type: 'date',
    validation: ['valDate'],
  },
];

export const card_4: Array<EmployeeCard> = [
  {
    cardType: 'bool',
    id: 'overtimesRestricted',
    label: 'Overtimes Restricted',
    type: '',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'universityId',
    label: 'University',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'englishId',
    label: 'English',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'germanId',
    label: 'German',
    type: '',
    validation: ['valRequiredForNoCc'],
  },
  {
    cardType: 'enum',
    id: 'frenchId',
    label: 'French',
    type: '',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'italianId',
    label: 'Italian',
    type: '',
    validation: [],
  },
];

export const card_5: Array<EmployeeCard> = [
  {
    cardType: 'enum',
    id: 'costCentersId',
    label: 'Cost Center',
    type: 'read',
    validation: [],
  },
  {
    cardType: 'bool',
    id: 'updateCc',
    label: 'CC Manual Update',
    type: '',
    validation: [],
  },
  {
    cardType: 'enum',
    id: 'updatedCostCenterId',
    label: 'Cost Center Modified',
    type: '',
    validation: ['valRequiredCc'],
  },
  { cardType: 'bool', id: 'active', label: 'Active', type: '', validation: [] },
  {
    cardType: '',
    id: 'availability',
    label: 'Availability (Fte)',
    type: '',
    validation: [],
    disabled: true,
  },
  {
    cardType: '',
    id: 'availableAsOf',
    label: 'Available as of',
    type: 'date',
    validation: ['valDate'],
    disabled: true,
  },
  {
    cardType: '',
    id: 'endDate',
    label: 'End Date',
    type: 'date',
    validation: ['valDate'],
  },
];

export const card_6: Array<EmployeeCard> = [
  {
    cardType: '',
    id: 'notes',
    label: 'Notes',
    type: '',
    validation: ['valString150'],
  },
];

import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { benchCard } from 'app/types/employee-banner-card-content';
import { EmployeeDetails } from 'app/types/employee-details';

type DummyData = {
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  g: string;
  h: string;
  i: string;
  j: string;
};

@Component({
  selector: 'app-bench-tasks',
  templateUrl: './bench-tasks.component.html',
  styleUrls: [
    './bench-tasks.component.scss',
    '../../../app/shared/tables-management/table-common-style.scss',
  ],
})
export class BenchTasksComponent implements OnInit {
  @Input() employeeDetails: EmployeeDetails;

  // Employee banner
  benchCard = benchCard;

  // Table
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];

  // Paginator
  totalElements = 0;
  pageIndex = 0;
  pageSize = 15;
  pageSizeOptions = [15, 30, 50];

  constructor() {}

  ngOnInit(): void {
    const dummyData: DummyData[] = [...new Array(10)].map((el, idx) => ({
      a: `TestA-${idx + 1}`,
      b: `TestB-${idx + 1}`,
      c: `TestC-${idx + 1}`,
      d: `TestD-${idx + 1}`,
      e: `TestE-${idx + 1}`,
      f: `TestF-${idx + 1}`,
      g: `TestG-${idx + 1}`,
      h: `TestH-${idx + 1}`,
      i: `TestI-${idx + 1}`,
      j: `TestJ-${idx + 1}`,
    }));
    this.dataSource.data = dummyData;
  }

  page(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // this.prepareSearch();
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-softlocks-hardlocks',
  templateUrl: './project-softlocks-hardlocks.component.html',
  styleUrls: ['./project-softlocks-hardlocks.component.scss', '../../shared/add-new.scss'],
})
export class ProjectSoftlocksHardlocksComponent implements OnInit {
  softlocked: any;
  softlocks: any;
  id: number;

  constructor(
    protected dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
  }

  back() {
    this.location.back();
  }

  addToSoftlockedNotify(data) {
    this.softlocked = data;
  }

  addToSoftlocksNotify(data) {
    this.softlocks = data;
  }
}

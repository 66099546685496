import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  { cardType: '', id: 'delete', label: 'Delete', validation: [], type: '', values: ['', []] },
  { cardType: '', id: 'role', label: 'Role', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'platformName',
    label: 'Platform',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'demandFte', label: 'FTE', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'startDateDemand',
    label: 'Start Date',
    validation: [],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'rollOffDateDemand',
    label: 'Roll-off Date',
    validation: [],
    type: 'date',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'careerLevel',
    label: 'Management Level',
    validation: [],
    type: '',
    values: ['', []],
  },
  {
    cardType: '',
    id: 'mySchedulingId',
    label: 'myScheduling ID',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'name', label: 'Name', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'supplyFTE',
    label: 'Supply FTE',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'supplyFrom', label: 'From', validation: [], type: 'date', values: ['', []] },
  { cardType: '', id: 'supplyTo', label: 'To', validation: [], type: 'date', values: ['', []] },
  { cardType: '', id: 'notes', label: 'Demand Notes', validation: [], type: '', values: ['', []] },
];

export const demandsGroupSettings: Array<ProjectCard> = [
  {
    cardType: '',
    id: 'mySchedulingId',
    label: 'myScheduling ID',
    validation: [],
    type: '',
    values: ['', []],
  },
  { cardType: '', id: 'name', label: 'Name', validation: [], type: '', values: ['', []] },
  {
    cardType: '',
    id: 'supplyFTE',
    label: 'Supply FTE',
    validation: [],
    type: '',
    values: ['', []],
    disabled: true,
  },
  {
    cardType: '',
    id: 'supplyFrom',
    label: 'From',
    validation: [],
    type: 'date',
    values: ['', []],
    disabled: true,
  },
  {
    cardType: '',
    id: 'supplyTo',
    label: 'To',
    validation: [],
    type: 'date',
    values: ['', []],
    disabled: true,
  },
];

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#project-search {
  background-color: #ffffff;
  margin-top: 50px;
  margin-left: 50px;
  padding: 15px;
  max-width: 400px;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.36);
  display: flex;
}

mat-form-field {
  width: 95%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';

import { REST } from 'rest.endpoints';
import { BaseService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { distinctUntilChanged, delay, retryWhen, take, share, tap } from 'rxjs/operators';

@Injectable()
export class ClientService extends BaseService {
  protected requestData(name: string): void {
    const httpParams = new HttpParams().append('clientName', name);
    this.request = this.http.get(REST.clientGetByName, { params: httpParams }).pipe(
      distinctUntilChanged(),
      tap(data => (this.data = data)),
      retryWhen(errors => errors.pipe(delay(this.reloadDelay), this.handleError)),
      share()
    );
  }
}

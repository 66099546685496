import { distinctUntilChanged } from 'rxjs/operators';
import {
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { EmployeeDetails } from '../../types/employee-details';
import { EmployeeCard } from '../../types/card';
import { CardItemComponent } from '../card-item/card-item.component';
import { EmployeeDetailsService } from '../employee-details/employee-details.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { Location } from '@angular/common';
import * as cards from '../../types/employee-cards-content';
import * as validationUtils from '../../shared/validators';
import { User } from '../../services/user-roles/user';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { CardItemEnumComponent } from '../card-item/card-item-enum/card-item-enum.component';
import { CardItemEnumMultipleComponent } from '../card-item/card-item-enum-multiple/card-item-enum-multiple.component';
import { Common } from '../../shared/common';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { DialogData } from '../../types/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { CounseleesSearchService } from '../../services/counselees-search.service';
import { SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss', '../../shared/card.scss'],
  providers: [SnackbarPanelComponent],
})
export class CardComponent implements OnInit, OnChanges {
  protected modelValue: any = null;
  saving: boolean;
  editing: boolean;
  employeeOriginVal: EmployeeDetails;
  valid: boolean;
  color = '';
  domainIdTemp = '';
  eidChange = false;
  cardHeaderIconArray = [
    'person',
    'assignment',
    'directions_car',
    'work',
    'flag',
    'access_time',
    'content_paste',
  ];
  employeeCards = [];

  employeeEditingRole: boolean;
  editingRole: boolean;
  editableByCC: boolean;

  @ViewChildren('item') private cardItems: QueryList<CardItemComponent>;

  @Input() employeeCard: EmployeeCard[];

  @Input() employeeDetails: EmployeeDetails;

  @Input() originCostCentersId: any;

  @Input() index: number;

  @Input() anotherCard: any;

  @Output() notify = new EventEmitter();

  formGroup: FormGroup;

  constructor(
    private employeeDetailsService: EmployeeDetailsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public snackbar: SnackbarPanelComponent,
    private location: Location,
    private userRolesService: UserRolesService,
    private counseleesSearchService: CounseleesSearchService
  ) {}

  init() {
    Object.keys(cards).forEach(element => {
      this.employeeCards.push(cards[element]);
    });

    this.employeeOriginVal = Object.assign({}, this.employeeDetails);
    this.domainIdTemp = this.employeeOriginVal.enterpriseDomainId;
    const group = {};
    this.employeeCard.forEach(item => {
      if (item.type !== 'read') {
        group[item.id] = ['', []];
      }
    });
    this.formGroup = this.fb.group(group);

    this.initEditingRole();

    if (this.formGroup.get('platformId')) {
      this.formGroup
        .get('platformId')
        .valueChanges.subscribe(value => this.onPlatformChanges(value));
      //this.formGroup.get('platformId').valueChanges.subscribe(() => this.addDigitalUnit());
    }
    if (this.formGroup.get('locationId')) {
      this.formGroup
        .get('locationId')
        .valueChanges.subscribe(value => this.onLocationChanges(value));
    }
    if (this.formGroup.get('billcodeId')) {
      this.formGroup
        .get('billcodeId')
        .valueChanges.subscribe(value => this.onBillCodeChanges(value));
    }
    if (this.formGroup.get('updateCc')) {
      this.formGroup.get('updateCc').valueChanges.subscribe(value => this.onCCChanges(value));
    }
    if (this.formGroup.get('rotationPreferencesId')) {
      this.formGroup
        .get('rotationPreferencesId')
        .valueChanges.subscribe(value => this.onRotationPreferencesChanges(value));
    }
    if (this.formGroup.get('statusId')) {
      this.formGroup
        .get('statusId')
        .valueChanges.subscribe(value => this.enableAndDisableDomaindIdAndPersonalNumFields(value));
    }
    if (this.formGroup.get('trainee')) {
      this.formGroup
        .get('trainee')
        .valueChanges.subscribe(value => this.enableAndDisableTraineeEndDate(value));
    }
  }

  ngOnInit() {
    this.init();
  }

  /*
   * Resets editing state once employee details changed and checks editing permissions for counselors
   * Prevents calling these updates on first change since we also do this in ngInit
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.employeeDetails && !changes.employeeDetails.firstChange) {
      this.employeeOriginVal = Object.assign({}, this.employeeDetails);
      this.initEditingRole();
      this.onEmployeeChanges();
    }
  }

  initEditingRole() {
    this.editing = false;
    this.editableByCC = false;

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.editingRole = user.getRoles().editing;
        this.employeeEditingRole = user.getRoles().employeeEdit;
        this.checkEditableByCC(user);
      });
  }

  /*
   * CC role / User role with CC role should be able to edit travel details only for their counselees
   */
  checkEditableByCC(user) {
    const isUserOrCC = user.getRoles().cc || user.getRoles().user;
    const isTravelCard = this.employeeCard.some(item => {
      return item.id === 'travelId';
    });

    if (isUserOrCC && isTravelCard) {
      this.counseleesSearchService
        .getData()
        .pipe(distinctUntilChanged())
        .subscribe((counselees: Array<any>) => {
          this.editableByCC =
            counselees.filter(counselee => this.employeeDetails.enterpriseDomainId === counselee.id)
              .length > 0;
        });
    }
  }

  onEmployeeChanges() {
    this.cardItems?.forEach(item => {
      if (item.id === 'updatedCostCenterId' && item instanceof CardItemEnumComponent) {
        this.formGroup.get('updatedCostCenterId').enable();
        item.changeCostCenterModifiedEnum(this.employeeOriginVal.locationId);
        this.formGroup
          .get('updatedCostCenterId')
          .setValue(this.employeeOriginVal.updatedCostCenterId);
      }
      if (item.id === 'employeeDomainId' && item instanceof CardItemComponent) {
        this.onEditing();
        this.onCancel();
      }
      if (item.id === 'employeeActivityTypeId' && item instanceof CardItemEnumComponent) {
        this.onEditing();
        item.changeActivityType(
          this.employeeDetails.billcodeId,
          this.formGroup.get('locationId').value,
          this.employeeDetails.employeeActivityTypeId
        );
        this.onCancel();
      }
      if (
        (item.id === 'trainee' || item.id === 'traineeEndDate') &&
        item instanceof CardItemComponent
      ) {
        this.onEditing();
        this.onCancel();
      }
    });
  }

  onCCChanges(value) {
    if (value) {
      this.employeeDetails.costCentersId = this.employeeOriginVal.cctypeId;
      this.formGroup.get('updatedCostCenterId').enable();
    } else if (value === false) {
      this.employeeDetails.costCentersId = this.originCostCentersId;
      this.formGroup.get('updatedCostCenterId').disable();
      this.formGroup.get('updatedCostCenterId').setValue(null);
    }
  }

  enableAndDisableDomaindIdAndPersonalNumFields(value?: number) {
    if (value) {
      this.formGroup.get('personalNumber').setValue(this.employeeDetails.personalNumber);
      this.formGroup.get('enterpriseDomainId').setValue(this.employeeDetails.enterpriseDomainId);
      // Committed, Contract Signed and Offer Pending
      if (value === 2 || value === 3 || value === 8) {
        this.formGroup.get('enterpriseDomainId').disable();
        this.formGroup.get('personalNumber').disable();
        // Contractor
      } else if (value === 6) {
        this.formGroup.get('personalNumber').disable();
        this.formGroup
          .get('enterpriseDomainId')
          .setValidators([
            validationUtils['valRequiredEId'](this.employeeDetails),
            validationUtils['valString40'](),
          ]);
        this.formGroup.get('enterpriseDomainId').enable();
        // Trainee, Trainee Unlimited and Internship
      } else if (value === 7 || value === 9 || value === 10) {
        this.formGroup.get('personalNumber').disable();
        this.formGroup
          .get('enterpriseDomainId')
          .setValidators([validationUtils['valRequired'](), validationUtils['valString40']()]);
        this.formGroup.get('enterpriseDomainId').enable();
        // Started
      } else if (value === 4) {
        this.formGroup
          .get('personalNumber')
          .setValidators([
            validationUtils['valRequiredPersonalNumber'](this.employeeDetails),
            validationUtils['valInteger'](),
            validationUtils['valString9'](),
          ]);
        this.formGroup
          .get('enterpriseDomainId')
          .setValidators([
            validationUtils['valRequiredEId'](this.employeeDetails),
            validationUtils['valString40'](),
          ]);
        this.formGroup.get('personalNumber').enable();
        this.formGroup.get('enterpriseDomainId').enable();
        // other remaining status like Inactive, Terminated...
      } else {
        this.formGroup.get('personalNumber').clearValidators();
        this.formGroup.get('enterpriseDomainId').clearValidators();
        this.formGroup.get('personalNumber').enable();
        this.formGroup.get('enterpriseDomainId').enable();
      }
    }
  }

  enableAndDisableTraineeEndDate(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'traineeEndDate' && item instanceof CardItemComponent) {
          this.formGroup.get('traineeEndDate').addValidators([validationUtils['valRequired']()]);
          this.formGroup.get('traineeEndDate').enable();
          this.formGroup.get('traineeEndDate').updateValueAndValidity();
          item.changeDisabledState(false);
          return true;
        }
        return false;
      });
    } else {
      this.formGroup.get('traineeEndDate').removeValidators([validationUtils['valRequired']()]);
      this.formGroup.get('traineeEndDate').markAsPristine();
      this.formGroup.get('traineeEndDate').markAsUntouched();
      this.formGroup.get('traineeEndDate').disable();
    }
  }

  onLocationChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'billcodeId' && item instanceof CardItemEnumComponent) {
          item.changeBillCodes(value);
          return true;
        }
        return false;
      });
    }
  }

  onBillCodeChanges(value) {
    if (value) {
      this.cardItems?.forEach(item => {
        if (item.id === 'newCareerLevelId' && item instanceof CardItemEnumComponent) {
          item.changeCarrierLevel(value);
        }
        if (item.id === 'employeeActivityTypeId' && item instanceof CardItemEnumComponent) {
          if (this.formGroup.get('locationId').value != null) {
            item.changeActivityType(
              value,
              this.formGroup.get('locationId').value,
              this.employeeOriginVal.employeeActivityTypeId
            );
          }
        }
      });
    }
  }

  onPlatformChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'employeeDomainId' && item instanceof CardItemEnumComponent) {
          if (this.editing) {
            item.changeDomains(value);
          } else {
            item.changeDomains(value, this.employeeOriginVal.employeeDomainId);
          }
          return true;
        }
        return false;
      });
    }
  }

  onRotationPreferencesChanges(value) {
    if (value) {
      this.cardItems?.some(item => {
        if (item.id === 'rotationPreferencesCommentId' && item instanceof CardItemComponent) {
          const ctrl = this.formGroup.get('rotationPreferencesCommentId');
          ctrl.markAsTouched();
          ctrl.updateValueAndValidity();
          item.updateErrorText();
          return true;
        }
        return false;
      });
    }
  }

  setValues(discard: boolean) {
    this.employeeCard.forEach(card => {
      if (this.formGroup.get(card.id) && !discard) {
        this.formGroup.get(card.id).setValue(this.formGroup.get(card.id).value);
      } else if (this.formGroup.get(card.id)) {
        this.formGroup.get(card.id).setValue(this.employeeOriginVal[card.id]);
      }
    });
  }

  onCancel(showDiscard?: boolean) {
    let cancel = false;

    if (!this.saving) {
      if (showDiscard) {
        this.employeeCard.forEach(card => {
          if (this.formGroup.get(card.id)) {
            if (this.formGroup.get(card.id).value != this.employeeOriginVal[card.id] && !cancel) {
              cancel = true;
              this.onCancelWarningDialog();
              return;
            }
          }
        });

        if (cancel) {
          return;
        }
      }
      // Reset properties in this card to original values
      this.employeeCard.forEach(card => {
        this.employeeDetails[card.id] = this.employeeOriginVal[card.id];
      });
      this.cardItems.forEach(item => {
        if (item.id === 'employeeDomainId' && item instanceof CardItemEnumComponent) {
          item.changeDomains(
            this.employeeDetails.platformId,
            this.employeeDetails.employeeDomainId
          );
        }
      });

      this.editing = false;
      this.notify.emit(this);
    }
  }

  onCancelWarningDialog() {
    const data: DialogData = {
      title: 'Warning',
      content: 'First you must finish editing current card!',
      action1: 'Discard',
      action2: 'Keep changes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (result) {
        this.editing = false;
        this.cdRef.detectChanges();
        this.notify.emit(this);
        // Enable closing of single card when discarding while leaving page
        this.employeeCard.forEach(card => {
          this.employeeDetails[card.id] = this.employeeOriginVal[card.id];
        });
        this.setValues(true);
      }
    });
    return afterClosed;
  }

  editWarningDialog() {
    const data: DialogData = {
      title: 'Warning',
      content: 'First you must finish editing current card!',
      action1: 'Discard',
      action2: 'Keep changes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (result) {
        this.anotherCard.onCancel();

        // Enable closing of single card when discarding while leaving page
        if (this !== this.anotherCard) {
          this.editing = true;
          this.cdRef.detectChanges();
          this.notify.emit(this);
        }
      }
    });
    return afterClosed;
  }

  onEditing() {
    if (this.editing) {
      return;
    }

    if (this.anotherCard && this.anotherCard.editing) {
      this.editWarningDialog();
      return;
    }

    this.editing = true;
    this.cdRef.detectChanges();
    this.notify.emit(this);
    this.cardItems.forEach(item => {
      item.setEdited(true);
    });
  }

  onSubmit() {
    if (!this.saving) {
      let send = false;
      this.eidChange = false;
      this.cardItems.forEach(item => {
        if (item.type !== 'read') {
          this.formGroup.controls[item.id].markAsTouched();
          this.formGroup.controls[item.id].updateValueAndValidity({
            onlySelf: false,
            emitEvent: false,
          });
        }
      });

      if (this.formGroup.valid) {
        const update = {};
        update['id'] = this.employeeDetails.id;
        this.cardItems.forEach(cardItem => {
          if (cardItem.isChanged) {
            if (cardItem.id === 'enterpriseDomainId') {
              this.eidChange = true;
              this.domainIdTemp = this.formGroup.get('enterpriseDomainId').value;
              if (this.formGroup.get('enterpriseDomainId').enabled === false) {
                update[cardItem.id] = null;
                this.domainIdTemp = null;
                this.eidChange = false;
              }
            }
            send = true;
            if (cardItem.modelValue instanceof Date) {
              update[cardItem.id] = Common.timezoneFix(cardItem.modelValue);
            } else if (typeof cardItem.modelValue === 'string') {
              update[cardItem.id] =
                isNaN(cardItem.modelValue as any) || cardItem.modelValue === ''
                  ? cardItem.modelValue.trim()
                  : Number(cardItem.modelValue);
              if (cardItem.id === 'enterpriseDomainId' && update[cardItem.id] === 0) {
                update[cardItem.id] = '';
              }
            } else {
              update[cardItem.id] = cardItem.modelValue;
            }
          }
        });

        if (send) {
          this.saving = true;
          this.employeeDetailsService
            .editEmployeeDetails(this.employeeDetails.id, update)
            .subscribe(
              res => {
                this.handleSubmit(res);
              },
              err => {
                this.handleError(err);
              }
            );
        } else {
          this.editing = false;
          this.notify.emit(this);
        }
      } else {
        this.errorWithValues();
      }
    }
  }

  errorWithValues() {
    this.snackbar.error('Please correct invalid or missing data marked in red.');
  }

  handleSubmit(response) {
    this.employeeOriginVal = Object.assign({}, this.employeeDetails);
    this.saving = false;
    this.editing = false;
    this.notify.emit(this);
    this.snackbar.success('Employee updated');

    this.cardItems.forEach(cardItem => {
      if (cardItem.isChanged) {
        cardItem.changeFirstValue();
      }
    });

    if (this.eidChange) {
      this.location.go('/employee-details/' + this.employeeDetails.enterpriseDomainId);
    }
  }

  handleError(error: Response | any) {
    let message = '';

    this.saving = false;
    const body = error;
    if (body.error.length > 1) {
      const fields = body.error;

      this.employeeCards.forEach(card => {
        card.forEach(item => {
          // check the validation error fields with the one thats being selected from the card if it fits
          fields.forEach(field => {
            if (field.field === item.id) {
              message += item.label + field.code + ', ';
            }
          });
        });
      });
      // slicing due to commas
      message = message.slice(0, -2);
      this.snackbar.error('Validation Errors => ' + message);
    } else if (body.error.length === 1) {
      const field = body.error[0].field;
      this.employeeCards.forEach(card => {
        card.forEach(item => {
          // if its the field just match it with the id
          if (field === item.id) {
            message += item.label + field.code;
          }
        });
      });
      this.snackbar.error('Validation Error => ' + message);
    } else {
      this.snackbar.error('Failed to save employee status: ' + body.status);
    }
  }
}

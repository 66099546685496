import { BannerCard } from './employee-banner-card';

export const bannerCard: Array<BannerCard> = [
  { cardType: '', label: 'Project ID', id: 'projectId', type: '', icon: '' },
  { cardType: 'enum', label: 'Status', id: 'statusId', type: '', icon: '' },
  {
    cardType: '',
    part: 'reqQualification',
    label: 'Last Updated Date',
    id: 'lastUpdateDate',
    type: '',
    icon: '',
  },
  {
    cardType: '',
    part: 'reqQualification',
    label: 'Last Updated By',
    id: 'lastUpdatedBy',
    type: '',
    icon: '',
  },
  { cardType: 'enum', part: 'client', label: 'Client', id: 'clientId', type: '', icon: 'star' },
  {
    cardType: 'enum',
    part: 'reqQualification',
    label: 'DATC Industry',
    id: 'clientGroupId',
    type: '',
    icon: 'domain',
  },
  {
    cardType: 'enum',
    part: 'projectInfo',
    label: 'Primary Work Location',
    id: 'primaryWorkLocId',
    type: '',
    icon: 'location_city',
  },
  {
    cardType: 'enum',
    part: 'projectInfo',
    label: 'Rate Type',
    id: 'rateTypeId',
    type: '',
    icon: 'schedule',
  },
  {
    cardType: 'enum',
    part: 'projectInfo',
    label: 'Operation Responsibility',
    id: 'operationalRespId',
    type: '',
    icon: 'flag',
  },
  {
    cardType: '',
    part: 'projectSchedule',
    label: 'End Date',
    id: 'endDate',
    type: 'date',
    icon: 'event_busy',
  },
];

import { ClientService } from './../../../services/client.service';
import { ClientListService } from './../../../services/client-list.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ProjectCardItemComponent } from './../project-card-item.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ContactService } from '../../../services/contact.service';
import { DcResponsibleService } from '../../../services/dcresponsible.service';
import * as validationUtils from '../../../shared/validators';

import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-project-card-item-autosearch',
  templateUrl: './project-card-item-autosearch.component.html',
  styleUrls: [
    '../project-card-item.component.scss',
    './project-card-item-autosearch.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardItemAutosearchComponent extends ProjectCardItemComponent implements OnInit {
  filteredValues: Observable<any[]>;
  values: any;
  @Output()
  clientReturned = new EventEmitter<any>();

  constructor(
    private contactService: ContactService,
    private dcResponsibleService: DcResponsibleService,
    private clientListService: ClientListService,
    private clientService: ClientService,
    enumsService: EnumsService,
    snackbar: SnackbarPanelComponent
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
  }

  init() {
    if (
      this.id === 'contactEId' ||
      this.id === 'techAccLeadContact' ||
      this.id === 'clAccLeadContact' ||
      this.id === 'qaDirContact' ||
      this.id === 'othContact' ||
      this.id === 'delLeadContact' ||
      this.id === 'projManContact' ||
      this.id === 'salBidContact' ||
      this.id === 'salesLead'
    ) {
      this.contactService.getData().subscribe(data => (this.values = data));
    } else if (this.id === 'dcResponsible' || this.id === 'dcResponsibleDeputy') {
      this.dcResponsibleService.getData().subscribe(data => {
        this.values = data;
      });
    } else if (this.id === 'clientName') {
      this.clientListService.getData().subscribe(data => (this.values = data));
    }

    // With slicing the results (to 50 assuming no more than 50 people would have atleast 3 characters the same) , performance improved
    this.filteredValues = this.control.controls[this.id].valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map(value => {
        const threshold = this.id === 'clientName' ? 1 : 3;
        if (value !== null) {
          return value.length >= threshold ? this.filterStates(value).slice(0, 50) : null;
        }
      })
    );
    if (this.control.controls.dcResponsible) {
      this.control.controls.dcResponsible.valueChanges.subscribe(value => {
        if (value) {
          this.setValidators('dcResponsible');
        } else {
          this.clearValidations('dcResponsible');
        }
      });
    }
    if (this.control.controls.dcResponsibleDeputy) {
      this.control.controls.dcResponsibleDeputy.valueChanges.subscribe(value => {
        if (value) {
          this.setValidators('dcResponsibleDeputy');
        } else {
          this.control.controls['dcResponsibleDeputy'].clearValidators();
        }
      });
    }
  }

  addEidToDuplicates(values) {
    const alreadySeen = [];

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]] === false) {
        alreadySeen[values[0][i]] = true;
        continue;
      }
      if (alreadySeen[values[0][i]] !== true) {
        alreadySeen[values[0][i]] = false;
        continue;
      }
    }

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]]) {
        values[0][i] = values[0][i] + ' (' + values[1][i] + ')';
      }
    }
  }

  filterStates(searchedValue: string) {
    if (this.id === 'dcResponsible' || this.id === 'dcResponsibleDeputy') {
      this.addEidToDuplicates(this.values);
      return this.values[0]
        .filter(value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1)
        .sort();
    } else {
      return this.values.filter(
        value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1
      );
    }
  }

  setValidators(fieldId: string) {
    const validators = [];
    if (
      (!this.control.controls[fieldId].validator && fieldId === 'dcResponsibleDeputy') ||
      (!this.control.controls[fieldId].validator && fieldId === 'dcResponsible')
    ) {
      validators.push(validationUtils['valDcResponsibleAndDeputy'](this.projectDetails));
      validators.push(validationUtils['valDcResponsibleAndDeputyFormat'](this.values));
      if (fieldId === 'dcResponsible') {
        validators.push(validationUtils['valRequired']);
      }
      this.control.controls[fieldId].setValidators(validators);
    }
  }

  clearValidations(fieldId: string) {
    this.control.controls[fieldId].clearValidators();
    if (fieldId && fieldId === 'dcResponsible') {
      this.control.controls[fieldId].setValidators(validationUtils['valRequired']());
    }
  }

  onSelectionChanged(value: any) {
    if (this.id === 'clientName') {
      this.clientService.getData(value).subscribe(data => {
        // if client object from BE exists then emit to prefill fields on client card
        if (data !== undefined && data !== null) {
          this.clientReturned.emit(data);
        }
      });
      this.clientService.deleteCache();
    }
  }
}

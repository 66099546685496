import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { EmployeeDetailsService } from 'app/employee/employee-details/employee-details.service';
import { UserRolesService } from 'app/services/user-roles/user-roles.service';
import { fieldsSettings } from '../../types/new-bench-tracking';
import * as validationUtils from '../../shared/validators';
import { ProjectCard } from 'app/types/card';
import { BenchTrackingOptionalFieldsComponent } from './bench-tracking-optional-fields/bench-tracking-optional-fields.component';
import { BenchTrackingMandatoryFieldsComponent } from './bench-tracking-mandatory-fields/bench-tracking-mandatory-fields.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { EmployeeDetails } from 'app/types/employee-details';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { User } from 'app/services/user-roles/user';
import { BenchTracking, BenchTrackingInput } from 'app/types/bench-tracking';
import { BenchService } from 'app/services/bench.service';
import { Common } from 'app/shared/common';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { getISOWeek, getYear } from 'date-fns';
import { EnumList } from '../../types/enum-types/common/allEnum';
import { PlatformEnum } from '../../types/enum-types/PlatformEnum';

@Component({
  selector: 'app-add-new-bench-tracking',
  templateUrl: './add-new-bench-tracking.component.html',
  styleUrls: ['./add-new-bench-tracking.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class AddNewBenchTrackingComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false })
  mandatoryChild: BenchTrackingMandatoryFieldsComponent;
  @ViewChild('optionalChild', { static: false })
  optionalChild: BenchTrackingOptionalFieldsComponent;

  id: number;
  user: User;
  trackingDetails: BenchTracking;
  employeeDetails: EmployeeDetails;
  newBenchTrackingForm: FormArray<FormGroup>;
  saving = false;
  done = false;

  platformLeadDomainId: string;

  subscriptions: Array<Subscription> = [];

  constructor(
    private benchService: BenchService,
    private userRolesService: UserRolesService,
    private employeeDetailsService: EmployeeDetailsService,
    private enumsService: EnumsService,
    private snackbar: SnackbarPanelComponent,
    private router: Router,
    private route: ActivatedRoute,
    public app: AppComponent,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      const benchTrackingDetails$ = this.id
        ? this.benchService.getBenchTrackingDetails(this.id)
        : of(null);

      benchTrackingDetails$
        .pipe(
          switchMap(trackingDetails => {
            if (trackingDetails) {
              this.handleTrackingDetails(trackingDetails);
            }
            return this.userRolesService.getData().pipe(distinctUntilChanged());
          })
        )
        .subscribe({
          next: data => {
            this.user = new User(data);
            this.getEmployeeDetails(this.user.eid);
          },
          error: error => {
            if (this.id) {
              this.handleTrackingDetailsError(error);
            } else {
              // Handle errors from userRolesService.getData() if needed
            }
          },
        });
    });
  }

  getTrackingDetails(id: number) {
    this.benchService.getBenchTrackingDetails(id).subscribe({
      next: trackingDetails => this.handleTrackingDetails(trackingDetails),
      error: error => this.handleTrackingDetailsError(error),
    });
  }

  handleTrackingDetails(trackingDetails: BenchTracking) {
    this.trackingDetails = trackingDetails;
    if (this.trackingDetails.actualEffort !== 0) {
      return;
    }
    this.trackingDetails.actualEffort = null;
    this.trackingDetails.taskCategoryId = null;
    this.trackingDetails.taskDescription = '';
    this.trackingDetails.requestedByEnterpriseDomainId = '';
  }

  // Requests
  getEmployeeDetails(enterpriseDomainId: string) {
    this.employeeDetailsService
      .getEmployeeDetails(enterpriseDomainId)
      .pipe(distinctUntilChanged())
      .subscribe({
        next: employeeDetails => this.handleEmployeeDetails(employeeDetails),
        error: error => this.handleEmployeeDetailsError(error, error['status']),
      });
  }

  // Success handlers
  handleEmployeeDetails(employeeDetails: EmployeeDetails) {
    this.employeeDetails = employeeDetails;
    this.getPlatformLead(employeeDetails.platformId);
    this.setForm(true);
  }

  getPlatformLead(platformId: number) {
    this.enumsService.getData().subscribe((enums: EnumList) => {
      const platform = enums.platform.find((option: PlatformEnum) => option.id === platformId);
      this.platformLeadDomainId = platform.leadEmail.split('@')[0];
    });
  }

  getBenchWeeklyCapacity(week: number, year: number, formGroup: FormGroup) {
    this.benchService.getBenchWeeklyCapacity(week, year).subscribe({
      next: benchWeeklyCapacity => this.handleBenchWeeklyCapacity(benchWeeklyCapacity, formGroup),
      error: error => this.handleBenchWeeklyCapacityError(error),
    });
  }

  handleBenchWeeklyCapacity(benchWeeklyCapacity: number, formGroup: FormGroup) {
    const benchWeeklyCapacityControl = formGroup.get('mandatory').get('weeklyCapacity');
    benchWeeklyCapacityControl.setValue(benchWeeklyCapacity);
  }

  // Error handlers
  handleEmployeeDetailsError(error: Response | string, errorCode: number) {
    const errorText = error instanceof Response ? error.statusText : error;
    const err: Error = new Error(errorText);
    this.snackbar.error('Could not load Employee Details');
    this.employeeDetails = null;
  }

  handleTrackingDetailsError(error: Response | string) {
    const errorText = error instanceof Response ? error.statusText : error;
    const err: Error = new Error(errorText);
    this.snackbar.error('Could not load Bench Tracking Details');
  }

  handleBenchWeeklyCapacityError(error: Response | string) {
    const errorText = error instanceof Response ? error.statusText : error;
    const err: Error = new Error(errorText);
    this.snackbar.error('Could not get Bench Weekly Capacity');
    this.saving = false;
  }

  // Forms
  setForm(init: boolean = false) {
    const [fieldFormMandatory, fieldFormOptional] = [{}, {}];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    fieldsSettings.optional.forEach(setting => {
      fieldFormOptional[setting.id] = setting.values;
    });

    const trackingFormGroup = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
      optional: this.fb.group(fieldFormOptional),
    });

    if (init) {
      this.newBenchTrackingForm = this.fb.array([trackingFormGroup]);
    } else {
      !this.id && this.newBenchTrackingForm.push(trackingFormGroup);
    }

    this.patchValues(trackingFormGroup);
    this.setValidators(trackingFormGroup);
    this.setFields(trackingFormGroup);
    init && this.subscribeSpecialEnums();
  }

  patchValues(tackingFormGroup: FormGroup) {
    if (!this.trackingDetails) {
      const currentDate = new Date();
      this.getBenchWeeklyCapacity(getISOWeek(currentDate), getYear(currentDate), tackingFormGroup);
      return;
    }

    Object.keys(tackingFormGroup.controls).forEach(group => {
      const groupControl = tackingFormGroup.get(group);
      Object.keys(groupControl['controls']).forEach(controllerName => {
        const controller = groupControl.get(controllerName);

        const trackingDetail = this.trackingDetails[controllerName];
        if (trackingDetail) {
          if (controllerName === 'week') {
            controller.setValue(
              JSON.stringify({
                week: this.trackingDetails.week,
                year: this.trackingDetails.year,
              })
            );
            return;
          }
          controller.setValue(+trackingDetail || trackingDetail);
          return;
        }

        if (controllerName === 'requestedBy') {
          if (this.trackingDetails.taskCategoryId > 2) {
            controller.disable({ onlySelf: true, emitEvent: false });
          }
          controller.setValue(this.trackingDetails.requestedByEnterpriseDomainId, {
            onlySelf: true,
            emitEvent: false,
          });
        }
      });
    });
  }

  setValidators(tackingFormGroup: FormGroup) {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach((setting: ProjectCard) => {
        const validators = [];
        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });
        tackingFormGroup.get(set).get(setting.id).setValidators(validators);
      });
    });
  }

  setFields(tackingFormGroup: FormGroup) {
    Object.keys(fieldsSettings).forEach(set => {
      fieldsSettings[set].forEach((setting: ProjectCard) => {
        const idControl = tackingFormGroup.get(set).get(setting.id);
        if (setting.disabled) {
          idControl.disable({ onlySelf: true, emitEvent: false });
        }
        if (setting.id === 'taskCategoryId') {
          this.subscriptions.push(
            idControl.valueChanges.subscribe((value: number) =>
              this.onCategoryChange(tackingFormGroup, value)
            )
          );
        }
        if (setting.id === 'week') {
          this.subscriptions.push(
            idControl.valueChanges.subscribe(() => this.onWeekChange(tackingFormGroup))
          );
        }
      });
    });
  }

  onCategoryChange(formGroup: FormGroup, taskCategoryId: number) {
    const control = formGroup.get('mandatory').get('requestedBy');
    if (taskCategoryId < 3 && control.disabled) {
      control.enable({ onlySelf: true, emitEvent: false });
      control.setValue('');
    } else if (taskCategoryId >= 3 && control.enabled) {
      control.disable({ onlySelf: true, emitEvent: false });
      control.setValue(this.platformLeadDomainId);
    }
  }

  onWeekChange(formGroup: FormGroup) {
    const { week, year } = JSON.parse(formGroup.get('mandatory').get('week').value);
    this.getBenchWeeklyCapacity(week, year, formGroup);
  }

  subscribeSpecialEnums() {}

  // Actions
  save() {
    this.saving = true;
    const benchTrackingList: Array<BenchTrackingInput> = [];

    this.newBenchTrackingForm.controls.forEach(trackingFormGroup => {
      const benchTracking: BenchTrackingInput = {} as BenchTrackingInput;

      Object.keys(trackingFormGroup.controls).forEach(group => {
        const groupControl = trackingFormGroup.get(group);

        Object.keys(groupControl['controls']).forEach(controllerName => {
          const controller = groupControl.get(controllerName);

          controller.markAsTouched();
          controller.markAsDirty();
          controller.updateValueAndValidity();

          if (controllerName === 'week') {
            const { week, year } = JSON.parse(controller.value || '{}');
            if (week && year) {
              benchTracking.week = week;
              benchTracking.year = year;
            }
          } else {
            benchTracking[controllerName] = +controller.value || controller.value;
          }
        });
      });

      benchTrackingList.push(benchTracking);
    });

    if (!this.newBenchTrackingForm.valid) {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
      this.saving = false;
      return;
    }

    if (this.id) {
      this.editBenchTracking(this.id, benchTrackingList.pop());
    } else {
      this.createNewBenchTracking(benchTrackingList);
    }
  }

  createNewBenchTracking(benchTrackingList: Array<BenchTrackingInput>) {
    this.benchService.addNewBenchTracking(benchTrackingList).subscribe({
      next: newBenchTrackingList => this.handleNewBenchTracking(),
      error: error => this.handleBenchTrackingError(),
    });
  }

  editBenchTracking(id: number, benchTracking: BenchTrackingInput) {
    this.benchService.editBenchTracking(id, benchTracking).subscribe({
      next: updatedBenchTracking => this.handleNewBenchTracking(),
      error: error => this.handleBenchTrackingError(),
    });
  }
  handleNewBenchTracking() {
    this.snackbar.success(
      `Bench Tracking ${this.id ? 'updated' : 'created'} - redirecting to Bench Tracking`
    );
    this.done = true;
    this.saving = false;
    setTimeout(() => {
      this.router.navigate(['/bench-management']).then();
    }, 1500);
  }

  handleBenchTrackingError() {
    this.snackbar.error(`Could not ${this.id ? 'updated' : 'created'} new Bench Tracking`);
    this.saving = false;
  }

  back() {
    this.snackbar.close();
    this.checkIfTouched().subscribe(result => {
      if (result) {
        this.router.navigate(['/bench-management']).then();
      }
    });
  }

  checkIfTouched(): Observable<boolean> {
    let touched = false;
    this.newBenchTrackingForm.controls.forEach(trackingFormGroup => {
      if (trackingFormGroup.controls) {
        Object.keys(trackingFormGroup.controls).forEach(group => {
          const groupControl = trackingFormGroup.get(group);
          Object.keys(groupControl['controls']).forEach(controllerName => {
            if (groupControl.get(controllerName).touched) {
              touched = true;
            }
          });
        });
      }
    });

    if (touched && !this.done) {
      return Common.editWarningDialog(this.dialog);
    }
    return of(true);
  }

  removeForm(index: number) {
    if (this.id) return;
    Common.deleteDialog(this.dialog).subscribe((result: boolean) => {
      if (result) this.newBenchTrackingForm.removeAt(index);
    });
  }
}

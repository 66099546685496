import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {
  private static date: DatePipe = new DatePipe('en-US');

  transform(value: string): string {
    return DateFormat.date.transform(value, 'MM/dd/yyyy');
  }
}

import { Component, OnInit } from '@angular/core';
import { SeatingHardwareCommonComponent } from '../seating-hardware-common/seating-hardware-common.component';
import { SeatDialogComponent } from '../seat-dialog/seat-dialog.component';
import { Common } from '../../../shared/common';

@Component({
  selector: 'app-project-seating',
  templateUrl: './project-seating.component.html',
  styleUrls: [
    './project-seating.component.scss',
    '../seating-hardware-common/seating-hardware-common.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
})
export class ProjectSeatingComponent extends SeatingHardwareCommonComponent implements OnInit {
  displayedColumns = [
    'empNameFte',
    'buildingFloorText',
    'seat',
    'seatFrom',
    'seatTo',
    'seatStatusText',
    'pdlComment',
    'awsComment',
    'wbs',
    'action',
  ];

  useSeatingUniversalWbs: boolean;
  addNewSeatDisabled: boolean;
  emptyFlexibleSeating: string;
  isExpanded = true;

  ngOnInit() {
    super.ngOnInit();
    this.getData();

    this.form
      .get('projectSeatsDemand')
      .valueChanges.subscribe(value => this.onProjectSeatsDemandChange(value));

    const seatsRequested: boolean = this.projectDetails.projectInfo.seatsRequested;
    this.form.get('projectSeatsDemand').setValue(seatsRequested, { emitEvent: false });
    this.addNewSeatDisabled = !seatsRequested;
    if (!this.projectDetails.projectInfo.seatsRequested) {
      this.isExpanded = false;
    }
  }

  getData(): void {
    this.loaded = false;

    this.projectService.getSeatings(this.projectDetails.projectId).subscribe({
      next: response => this.handleGetData(response),
      error: error => this.handleError(error),
    });
  }

  handleDeleteSeat(): void {
    this.getData();
    this.snackbar.success('Seat has been canceled');
  }

  handleRemoveEmployeeFromSeat(data: any) {
    this.getData();
    this.snackbar.success('Employee has been removed from seat');
  }

  addSeat() {
    const data = {
      projectId: this.projectDetails.projectId,
      editing: false,
      employees: this.getEmployees(),
    };

    const dialogRef = this.dialog.open(SeatDialogComponent, {
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  removeEmployeeFromSeat(row: any) {
    if (row.supplyId !== null) {
      Common.deleteDialog(this.dialog).subscribe(result => {
        if (result) {
          this.projectService.removeEmployeeFromSeat(row.id, null).subscribe({
            next: response => this.handleRemoveEmployeeFromSeat(response),
            error: error => this.handleError(error),
          });
        }
      });
    }
  }

  cancelSeat(row: any) {
    const update = Object.assign({}, row);

    update.buildingFloor = row.buildingFloor.id;
    update.seatStatus = 3;

    this.projectService.editSeating(row.id, update).subscribe({
      next: response => this.handleDeleteSeat(),
      error: error => this.handleError(error),
    });
  }

  getEmployees(): Array<any> {
    const employees = [];

    this.dataSource.data.forEach(row => {
      if (row['employeeDetailId']) {
        employees.push(row['employeeDetailId']);
      }
    });

    return employees;
  }

  editSeat(row: any) {
    const data = {
      projectId: this.projectDetails.projectId,
      row: row,
      editing: true,
      universalWbs: this.form.get('useUniversalWbs').value,
      employees: this.getEmployees(),
    };

    const dialogRef = this.dialog.open(SeatDialogComponent, {
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  saveUniversalWbs(useSeatingUniversalWbs, seatingUniversalWbs) {
    const change = {
      useSeatingUniversalWbs: useSeatingUniversalWbs,
      seatingUniversalWbs: seatingUniversalWbs,
    };

    this.useSeatingUniversalWbs = useSeatingUniversalWbs;

    if (this.useSeatingUniversalWbs) {
      this.form.get('wbs').enable();
    } else {
      this.form.get('wbs').disable();
    }

    this.projectService.editUniversalWbs(this.projectDetails.projectId, change).subscribe(
      response => this.handleEditWbs(response),
      error => this.handleError(error)
    );
  }

  handleGetUniversalWbs(data: any): void {
    this.originalWbs = data.seatingUniversalWbs;

    this.form.get('useUniversalWbs').setValue(data.useSeatingUniversalWbs, { emitEvent: false });
    this.form.get('wbs').setValue(data.seatingUniversalWbs, { emitEvent: false });

    this.useSeatingUniversalWbs = this.form.get('useUniversalWbs').value;
    if (!this.useSeatingUniversalWbs) {
      this.form.get('wbs').disable({ emitEvent: false });
    } else {
      this.form.get('wbs').enable({ emitEvent: false });
    }
  }

  addData(data) {
    const sorted = data.sort((a, b) => {
      return a.buildingFloorStage - b.buildingFloorStage;
    });
    this.dataSource.data = sorted;
  }

  populateDropdowns(enums) {
    this.data['buildingFloorEnum'] = enums['seating-building-floor'].values;
    this.data['seatStatusEnum'] = enums['seating-status'].values;
  }

  handleChangeProjectSeatsDemand(data) {
    this.snackbar.success('Project Seats Demand has been changed');
  }

  onProjectSeatsDemandChange(value: boolean) {
    if (this.dataSource.data.length === 0 && this.emptyFlexibleSeating === 'true') {
      this.addNewSeatDisabled = !value;
      this.projectService.saveSeatsRequested(this.projectDetails.projectId, value).subscribe(
        response => {
          this.handleChangeProjectSeatsDemand(response);
        },
        error => this.handleError(error)
      );
    } else {
      this.snackbar.error('Project has already requested seats.');
      this.form.get('projectSeatsDemand').setValue(true, { emitEvent: false });
    }
  }

  receiveMessage($event) {
    if ($event === 'yes') {
      this.form.get('projectSeatsDemand').setValue(true, { emitEvent: false });
      this.addNewSeatDisabled = false;
    } else {
      this.form.get('projectSeatsDemand').setValue(false, { emitEvent: false });
      this.addNewSeatDisabled = true;
      this.isExpanded = false;
    }
  }

  setEmptyFlexibleSeating($event) {
    this.emptyFlexibleSeating = $event;
  }
}

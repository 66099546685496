// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logo {
  position: relative;
  padding: 18px 0 0 32px;
  height: 64px;
  letter-spacing: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  background-color: #2196f3;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}
.logo::after {
  position: absolute;
  width: 56px;
  height: 56px;
  top: -8px;
  left: 120px;
  border-top: 24px solid rgb(0, 0, 0);
  border-right: 24px solid rgb(0, 0, 0);
  content: "";
  transform: rotate(45deg);
  opacity: 0.2;
}

h3 {
  font-size: 16px;
  font-weight: 500;
}

.mdc-list {
  padding: 15px 0 0 0;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 40px;
}

.mat-mdc-list-item a {
  font-weight: 500;
  padding-left: 14px;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
}

.mat-mdc-menu-item {
  line-height: 32px;
  height: 32px;
  padding: 0 24px;
  overflow: initial;
  min-height: 32px;
}

::ng-deep .mat-mdc-menu-item-text {
  font-size: 14px !important;
}

.mat-mdc-list-item .active {
  color: #1976d2;
}

a:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}

.mat-mdc-list-item a .mat-icon {
  padding-right: 15px;
  vertical-align: middle;
}

.mat-mdc-list-item .active .mat-icon {
  color: #1976d2;
}

.label-container,
.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #95989a;
  padding: 0 32px;
}

.label-container {
  padding-top: 20px;
}

.user a {
  height: 24px;
}

.material-icons {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button {
  width: 100%;
  height: initial;
  border-radius: 0;
  line-height: initial;
  padding-top: 10px;
  padding-bottom: 10px;
}

::ng-deep .mat-mdc-button-persistent-ripple {
  border-radius: 0 !important;
}

.mat-mdc-icon-button:hover {
  background-color: #f1f1f1;
}

.mat-mdc-icon-button h3 {
  display: inline-block;
  width: 190px;
  text-align: left;
}

::ng-deep .reports-menu {
  position: fixed;
  top: 64px;
  left: 279px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonFieldsComponent } from '../common-fields/common-fields.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-top-fields',
  templateUrl: './top-fields.component.html',
  styleUrls: ['./top-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class TopFieldsComponent extends CommonFieldsComponent implements OnInit {
  @Input() top: FormGroup;

  ngOnInit() {
    super.ngOnInit();
    this.top.valueChanges.subscribe(changes => this.onFormChanges(changes));
  }

  onFormChanges(changes) {
    super.onFormChanges(changes, this.top);
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.data['employeeTypesEnum'] = enums['employee-type'].filter(element => {
      if (element.id !== 5 && element.id !== 6) {
        return element;
      }
    });
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectCommonFieldsComponent } from '../project-common-fields/project-common-fields.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-lead-optional-fields',
  templateUrl: './lead-optional-fields.component.html',
  styleUrls: ['./lead-optional-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class LeadOptionalFieldsComponent extends ProjectCommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  ngOnInit() {
    super.ngOnInit();

    // this.expansionPanel.open();
    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes));
  }

  populateDropdowns(enums) {
    super.populateDropdowns(enums);
  }

  protected onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.optional.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }
}

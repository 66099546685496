import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, AbstractControl } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { HiringRequestCommonFieldsComponent } from '../hiring-request-common-fields/hiring-request-common-fields.component';
import { fieldsSettings } from 'app/types/new-hiring-request';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-hiring-request-mandatory-fields',
  templateUrl: './hiring-request-mandatory-fields.component.html',
  styleUrls: ['./hiring-request-mandatory-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class HiringRequestMandatoryFieldsComponent
  extends HiringRequestCommonFieldsComponent
  implements OnInit
{
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() mandatory: AbstractControl;
  @Output() typeChangeSubco: EventEmitter<any> = new EventEmitter();

  typeSubscription: any;
  platformSubscription: any;
  profileSubscription: any;
  projectSubscription: any;

  filteredValues: any;

  ngOnInit(): void {
    super.ngOnInit();

    this.mandatory.valueChanges.subscribe(changes => this.onFormChanges(changes, this.mandatory));

    // this.disableFields();
    this.subscribeSpecialEnums();
    this.filteredValues = this.data['projectNameEnum'];
  }

  protected populateDropdowns(enums) {
    super.populateDropdowns(enums);
    this.data['platformEnum'] = enums['platform'].filter(value => value.deleted !== true);
    this.data['domainEnum'] = enums['domain'];
    this.data['locationEnum'] = enums['employee-location'];
    this.data['typeEnum'] = enums['open-demands-type'];
    this.data['profileEnum'] = enums['open-demands-profile'];
    this.data['statusEnum'] = enums['open-demands-status'];
    this.data['travelPreferencesEnum'] = enums['employee-travel'];
    this.data['hiringPriorityEnum'] = enums['hiring-requests-priority'];
    this.data['requestQualificationEnum'] = enums['request-qualification-industry'];
  }

  disableFields() {
    fieldsSettings.mandatory.forEach(setting => {
      if (setting.disabled) {
        this.mandatory.get(setting.id).disable();
      }
    });
  }

  subscribeSpecialEnums() {
    this.typeSubscription = this.mandatory
      .get('typeId')
      .valueChanges.subscribe(value => this.onTypeChange(value));
    this.platformSubscription = this.mandatory
      .get('platformId')
      .valueChanges.subscribe(value => this.onPlatformChange(value));
    this.profileSubscription = this.mandatory
      .get('profileId')
      .valueChanges.subscribe(value => this.onProfileChange(value));
    this.projectSubscription = this.mandatory
      .get('projectId')
      .valueChanges.subscribe(value => this.onProjectChange(value));
  }

  public unsubscribeSpecialEnums() {
    this.typeSubscription.unsubscribe();
    this.profileSubscription.unsubscribe();
    this.platformSubscription.unsubscribe();
  }

  protected onTypeChange(value: any) {
    if (value === 3) {
      this.typeChangeSubco.emit(true);
    } else {
      this.typeChangeSubco.emit(false);
    }
  }

  protected onProjectChange(project: any) {
    if (this.data['projectNameEnum'] != null) {
      const regex = new RegExp(project, 'i');
      this.filteredValues = this.data['projectNameEnum'].filter(project => {
        return regex.test(project.text);
      });
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.filteredValues = this.data['projectNameEnum'];
    if (this.filteredValues != null) {
      const filteredProject = this.filteredValues.filter(
        project => project.text === event.option.value
      );
      const projectId = filteredProject[0].id;
      if (this.data['requestQualificationEnum'] != null) {
        let filteredDataIndustry = this.data['requestQualificationEnum'].filter(
          item => item.id === projectId
        );
        if (
          filteredDataIndustry[0]['clientGroupId'] == 6 ||
          filteredDataIndustry[0]['clientGroupId'] == 10
        ) {
          this.disableMandatoryField('mySchedulingId');
        } else {
          this.enableMandatoryField('mySchedulingId');
        }
      }
    }
  }

  onInputFocus() {
    if (this.mandatory.get('projectId').value == '') {
      this.filteredValues = this.data['projectNameEnum'];
    }
  }

  protected onPlatformChange(platformGroup: any) {
    this.mandatory.get('domainId').enable({ emitEvent: false });
    this.data['domainEnum'] = this.enums['domain'].filter(
      value => value.platformId === platformGroup && value.id < 1000
    );
  }

  protected onProfileChange(profileId: any) {
    //TODO: finish the filter
    this.mandatory.get('typeId').enable({ emitEvent: false });
    if (this.mandatory.get('profileId').value === 1) {
      this.data['typeEnum'] = this.enums['open-demands-type'];
    } else {
      this.data['typeEnum'] = this.enums['open-demands-type'].filter(value => value.id !== 2);
    }
  }

  enableSubcoFields() {
    this.enableMandatoryField('travelPreferences');
    this.enableMandatoryField('workLocation');
    this.enableMandatoryField('subcoContractDuration');
    this.enableMandatoryField('yearsOfExperience');
  }

  disableSubcoFields() {
    this.disableMandatoryField('travelPreferences');
    this.disableMandatoryField('workLocation');
    this.disableMandatoryField('subcoContractDuration');
    this.disableMandatoryField('yearsOfExperience');
  }

  enableMandatoryField(name: string) {
    this.mandatory.get(name).enable();
    this.mandatory.get(name).setValidators(Validators.required);
    this.mandatory.get(name).updateValueAndValidity();
  }

  disableMandatoryField(name: string) {
    this.mandatory.get(name).disable();
    this.mandatory.get(name).clearValidators();
    this.mandatory.get(name).updateValueAndValidity();
  }

  removeStudentDemandType() {
    this.data['typeEnum'] = this.enums['open-demands-type'].filter(value => value.id !== 2);
  }
}

import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  AfterViewInit,
  Injectable,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectService } from '../../../services/project.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-review-approve-dialog',
  templateUrl: './review-approve-dialog.component.html',
  styleUrls: [
    './review-approve-dialog.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class ReviewApproveDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('table1', { read: MatSort, static: false }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: false }) sort2: MatSort;
  @ViewChild('table3', { read: MatSort, static: false }) sort3: MatSort;

  seatsDisplayedColumns = ['info', 'days', 'rate', 'sum'];
  seatsDataSource = new MatTableDataSource();

  hardwareDesktopsDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareDesktopsDataSource = new MatTableDataSource();

  hardwareNotebooksDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareNotebooksDataSource = new MatTableDataSource();

  @Input() form: FormGroup;

  seatsHardwareLoaded;
  projectId: number;
  eid: string;
  wbs: string;
  seatsCost = 0;
  hardwareNotebooksCost = 0;
  hardwareDesktopsCost = 0;
  totalCost = 0;
  seatsWbs: Array<Object>;
  hardwareNotebooksWbs: Array<Object>;
  hardwareDesktopsWbs: Array<Object>;
  month: any;
  year: any;
  validity = false;
  loadedData: Object;
  approved: Boolean;
  isDisabled: boolean;
  data = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<ReviewApproveDialogComponent>,
    private projectService: ProjectService,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.projectId = this.dialogData.projectId;
    this.eid = this.dialogData.eid;

    this.form = this.fb.group({
      month: ['', []],
    });

    this.form.get('month').valueChanges.subscribe(value => this.onMonthChange(value));

    const d = new Date();
    d.setDate(1);
    const actualMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const actualYear = d.getFullYear();
    console.log(actualMonth);

    d.setMonth(d.getMonth() - 1);

    const previousMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const previousYear = d.getFullYear();
    console.log(previousMonth);
    d.setMonth(d.getMonth() + 2);

    const nextMonth = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const nextYear = d.getFullYear();
    console.log(nextMonth);
    this.data['month'] = [
      {
        id: 0,
        text: previousMonth + ' / ' + previousYear + ' (reporting period)',
      },
      {
        id: 1,
        text: actualMonth + ' / ' + actualYear,
      },
      {
        id: 2,
        text: nextMonth + ' / ' + nextYear,
      },
    ];

    this.getInitDate(this.projectId);
  }

  ngAfterViewInit() {
    this.seatsDataSource.sort = this.sort1;
    this.hardwareNotebooksDataSource.sort = this.sort2;
    this.hardwareDesktopsDataSource.sort = this.sort3;
  }

  getInitDate(id) {
    this.projectService.getApprovalDate(id).subscribe(
      response => this.handleReviewApprovalDate(response),
      error => this.handleError(error)
    );
  }

  onMonthChange(value) {
    this.data['month'].forEach(val => {
      if (val.id === value) {
        this.month = val.text.substring(0, 2);
        this.year = val.text.substring(5, 9);

        this.getSeatsHardware(this.month, this.year);
      }
    });
  }

  getSeatsHardware(month, year) {
    this.seatsHardwareLoaded = false;

    this.projectService.getReviewSeatingsHardware(this.projectId, month, year).subscribe(
      response => this.handleSeatsHardware(response),
      error => this.handleError(error)
    );
  }

  private handleSeatsHardware(data: any): void {
    console.log(data);
    this.loadedData = data;
    if (data.isApproved) {
      this.approved = data.isApproved;
      this.totalCost = data.totalSum;
      this.validity = data.validity;
      this.seatsHardwareLoaded = true;
      return;
    }
    this.approved = false;
    this.loadedData['seatingCharges'].forEach(s => {
      if (s.rate === 360) {
        s.rate = 0;
      }
    });
    this.seatsHardwareLoaded = true;
    this.seatsDataSource.data = data.seatingCharges;
    this.hardwareNotebooksDataSource.data = data.notebooksCharges.concat(data.ignoredNotebooks);
    this.hardwareDesktopsDataSource.data = data.desktopsCharges.concat(data.ignoredDesktops);

    this.seatsWbs = [];

    Object.keys(data.groupedSeatingSums).forEach(key => {
      this.seatsWbs.push({
        name: key,
        sum: data.groupedSeatingSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
    });

    this.hardwareDesktopsWbs = [];

    Object.keys(data.groupedDesktopsSums).forEach(key => {
      this.hardwareDesktopsWbs.push({
        name: key,
        sum: data.groupedDesktopsSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
    });

    this.hardwareNotebooksWbs = [];

    Object.keys(data.groupedNotebooksSums).forEach(key => {
      this.hardwareNotebooksWbs.push({
        name: key,
        sum: data.groupedNotebooksSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
    });

    this.seatsCost = data.seatingsSum;
    this.hardwareNotebooksCost = data.notebooksSum;
    this.hardwareDesktopsCost = data.desktopsSum;
    this.totalCost = data.totalSum;
    this.validity = data.validity;
  }

  private handleError(error) {
    this.snackbar.error('problem with approving');
  }

  handleApproveSeatsHardware(response) {
    this.isDisabled = false;
    this.snackbar.success('Seats and hardware has been aproved');
    this.dialogRef.close(true);
  }

  handleReviewApprovalDate(data) {
    const format = new Date(data.reviewDate);
    this.month =
      format.getMonth() + 1 < 10
        ? '0' + (format.getMonth() + 1).toString()
        : (format.getMonth() + 1).toString();
    this.year = format.getFullYear().toString();
    const date = this.month + ' / ' + this.year;
    this.data['month'].forEach(element => {
      if (element.text.substring(0, 9) === date) {
        this.form.get('month').setValue(element.id, { emitEvent: true });
      }
    });
    this.getSeatsHardware(this.month, this.year);
  }

  create(create: boolean) {
    if (create) {
      this.isDisabled = create;
      console.log(this.loadedData);
      this.projectService.approveSeatsHardware(this.projectId, this.loadedData).subscribe(
        response => this.handleApproveSeatsHardware(response),
        error => this.handleError(error)
      );
    } else {
      this.isDisabled = false;
      this.dialogRef.close(false);
    }
  }
}

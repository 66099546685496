import { Component, OnInit } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { HiringRequestSearch } from 'app/types/hiring-request-search';
import { HiringRequestDetails } from 'app/types/hiring-request-details';
import { hiringRequestsEnumMapping } from 'app/types/enum.mapping';
import { HiringRequestsCommonComponent } from '../hiring-requests-common/hiring-requests-common.component';
import * as validationUtils from 'app/shared/validators';
import { Common } from 'app/shared/common';
import { UserProfile } from '../../types/user-profile';
import { US_DATE_FORMAT } from '../../types/date-format';
import { openDemands, OpenDemandsListDto } from '../../types/openDemands';
import { EnumList } from '../../types/enum-types/common/allEnum';
import { CommonEnumDelete } from '../../types/enum-types/common/commonEnumStage';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { AppComponent } from '../../app.component';
import { ProjectService } from '../../services/project.service';
import { EnumsService } from '../../services/enumerations/enums.service';

@Component({
  selector: 'app-hiring-requests-active',
  templateUrl: './hiring-requests-active.component.html',
  styleUrls: [
    './hiring-requests-active.component.scss',
    '../hiring-requests-common/hiring-requests-common.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: US_DATE_FORMAT,
    },
  ],
})
export class HiringRequestsActiveComponent extends HiringRequestsCommonComponent implements OnInit {
  recruitingRole: Boolean;

  editableColumns = [];
  plRoleEditable = ['plComment', 'updatedTargetDate', 'projectId', 'statusId'];
  recruitingRoleEditable = [
    'statusId',
    'newJoinerName',
    'requisitionId',
    'assignedTo',
    'specialEstimatedHireDate',
    'updateddHireDate',
    'commonDemandId',
    'mySchedulingId',
  ];

  constructor(
    router: Router,
    route: ActivatedRoute,
    authService: AuthService,
    snackbar: SnackbarPanelComponent,
    fb: FormBuilder,
    userRolesService: UserRolesService,
    app: AppComponent,
    dialog: MatDialog,
    projectService: ProjectService,
    enumsService: EnumsService,
    location: Location
  ) {
    super(
      router,
      route,
      authService,
      snackbar,
      fb,
      userRolesService,
      app,
      dialog,
      projectService,
      enumsService,
      location
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.snackbar.close();

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe((data: UserProfile) => {
        this.getRoles(data);
        this.handleRoles(this.recruitingRole);
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  getRoles(data: UserProfile) {
    this.recruitingRole = data.roles.some((role: string) => role === 'MAPPED_RECRUITING_ROLE');
  }

  handleRoles(recruiting: Boolean) {
    this.editableColumns = recruiting ? this.recruitingRoleEditable : this.plRoleEditable;
  }

  onNewJoinerNameChange(control: FormGroup) {
    const newJoinerNameControl = control.controls['newJoinerName'];
    const updatedHireDateControl = control.controls['updateddHireDate'];

    if (newJoinerNameControl.value) {
      updatedHireDateControl.setValue(null, { onlySelf: true });
      updatedHireDateControl.setValidators(Validators.required);
    } else {
      updatedHireDateControl.removeValidators(Validators.required);
    }

    updatedHireDateControl.updateValueAndValidity();
  }

  prepareSearchParams(): HiringRequestSearch {
    return {
      history: false,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
  }

  save(control: FormGroup, row: HiringRequestDetails) {
    if (!control.valid) {
      Object.keys(control.controls).forEach(key => {
        if (control.get(key).invalid) {
          this.snackbar.error(
            this.fieldsSettings.find(setting => setting.id === key).label + ' is Invalid.'
          );
        }
      });
      return;
    }

    const original: openDemands = this.originalData.find(
      (option: openDemands) => option.id === control.value['id']
    );
    const details: openDemands = structuredClone(original);

    const keys = Object.keys(control.controls);
    for (const key of keys) {
      if (
        key == 'mySchedulingId' &&
        control.value[key] != null &&
        (control.value[key].length != 7 || !/^\d+$/.test(control.value[key]))
      ) {
        this.snackbar.error('Please provide myScheduling ID (7 digits).');
        return;
      }

      if (key === 'actions' || key === 'rtScStComment' || control.get(key).pristine) {
        continue;
      }

      if (key === 'platform') {
        details.platformId = this.data.platformEnum.find(
          option => option.text === control.value[key]
        )['id'];
        continue;
      }

      if (key === 'domain') {
        details.domainId = this.data.domainEnum.find(option => option.text === control.value[key])[
          'id'
        ];
        continue;
      }

      if (
        ['profileId', 'typeId', 'locationId', 'additionalLanguageSkillId', 'priorityId'].indexOf(
          key
        ) > -1 &&
        control.value[key] !== undefined
      ) {
        details[key] = this.data[key].find(option => option.text === control.value[key])['id'];
        continue;
      }

      if (
        this.fieldsSettings.find(setting => setting.id === key).type === 'date' &&
        control.value[key] !== null
      ) {
        details[key] = Common.timezoneFix(control.value[key]);
        continue;
      }

      details[key] = control.value[key];
    }

    if (details.newJoinerName !== null) {
      details.statusId = 2; // set status to Position Fulfilled
    }

    this.projectService
      .updateOpenDemand(details)
      .pipe(distinctUntilChanged())
      .subscribe({
        next: response => {
          this.handleUpdateResponse(control, response, original, row);
        },
        error: error => this.handleUpdateError(control, error),
      });
  }

  reset(control: FormGroup) {
    const original = this.originalData.find(option => option.id === control.value['id']);

    Object.keys(control.controls).forEach(key => {
      control.controls[key].setValue(original[key], { onlySelf: true });
    });
    control.markAsPristine();
  }

  copy(control: FormGroup) {
    const routerLink = '/hiring-requests/add-new-request';
    this.router.navigate([routerLink], { queryParams: { id: control.value.id } }).then();
  }

  handleUpdateError(control: FormGroup, error: Error) {
    this.snackbar.error('Update not successful.');
  }

  handleUpdateResponse(
    control: FormGroup,
    response: any,
    original: any,
    row: HiringRequestDetails
  ) {
    this.snackbar.success('Hiring request successfully updated.');
    control.markAsPristine();

    if (response.statusId !== 1) {
      row.editable = false;
    }

    Object.keys(original).forEach(key => {
      original[key] = response[key];
      row[key] = response[key];
    });

    const enumNamesToLoad = [
      'statusId',
      'locationId',
      'typeId',
      'additionalLanguageSkillId',
      'profileId',
      'priorityId',
    ]; // domain platform

    enumNamesToLoad.forEach(enumName => {
      this.enumsService.setEnumText(row, enumName, hiringRequestsEnumMapping);
    });

    if (row.projectId !== null) {
      const project = this.data.projectEnum.find(option => option.id === row.projectId);
      row.projectName = project !== undefined ? project.text : '';
    }
  }

  emptyForm() {
    const header = this.rowsForm.controls[0] as FormGroup;
    this.setFieldSettings();
    this.rowsForm.push(header);
  }

  handleOpenDemands(data: OpenDemandsListDto): void {
    this.totalElements = data.totalElements;
    if (this.firstLoad) {
      const platformValue = data.platform;
      if (platformValue !== undefined) {
        this.headerForm.get('platform').setValue(platformValue);
      }
      this.firstLoad = false;
    } else {
      const index = this.pageSizeOptions.indexOf(this.totalElements);
      if (index !== -1) {
        this.pageSizeOptions.splice(index, 1);
      }
      this.emptyForm();
    }
    this.pageSizeOptions.push(this.totalElements);
    this.paginator.pageSizeOptions = this.pageSizeOptions;
    this.originalData = [...data.openDemands];
    this.dataSource.data = this.transformData(JSON.parse(JSON.stringify(data.openDemands)));
  }

  transformData(requests: Array<any>): Array<HiringRequestDetails> {
    const enumNamesToLoad = [
      'locationId',
      'typeId',
      'additionalLanguageSkillId',
      'profileId',
      'priorityId',
    ]; // domain platform

    this.data.projectEnum.forEach(project => {
      if (!project.text.includes('(project)') && !project.text.includes('(opportunity)')) {
        project.text += project.salesStage === 3 ? ' (project)' : ' (opportunity)';
      }
    });

    for (const request of requests) {
      enumNamesToLoad.forEach(enumName => {
        this.enumsService.setEnumText(request, enumName, hiringRequestsEnumMapping);
      });
      request.creationDate = this.dateFormatter.transform(
        request.creationDate,
        this.dateFormatMmDdYyyy
      );
      request.estimatedHireDate = this.dateFormatter.transform(
        request.estimatedHireDate,
        this.dateFormatMmDdYyyy
      );
      request.targetDate = this.dateFormatter.transform(
        request.targetDate,
        this.dateFormatMmDdYyyy
      );

      request.platform = this.data.platformEnum.find(option => option.id === request.platformId)[
        'text'
      ];

      request.domain = this.data.domainEnum.find(option => option.id === request.domainId)['text'];
      request.statusId = this.data.statusEnum.find(option => option.id === request.statusId)['id'];

      if (request.projectId !== null) {
        const project = this.data['projectEnum'].find(option => option.id === request.projectId);
        request.projectName = project !== undefined ? project.text : '';
      }
      request.rtStScComment = request.rtComment + '/' + request.stComment + '/' + request.scComment;
      request.copyable = !(request.typeId === 'subco' || this.recruitingRole);
      request.editable = true;

      const row = this.fb.group({}, { updateOn: 'change' });

      this.fieldsSettings.forEach(setting => {
        const validators = [];

        setting.validation.forEach(validation => {
          validators.push(validationUtils[validation]());
        });

        row.addControl(
          setting.id,
          this.fb.control(request[setting.id], {
            updateOn: setting.id === 'newJoinerName' ? 'change' : 'blur',
            validators: validators,
          })
        );

        if (setting.id === 'newJoinerName') {
          row
            .get('newJoinerName')
            .valueChanges.subscribe(changes => this.onNewJoinerNameChange(row));
        }
      });
      this.rowsForm.push(row);
    }
    this.listLoaded = true;
    return requests;
  }

  populateDropdowns(enums: EnumList): void {
    super.populateDropdowns(enums);

    enums['open-demands-status'].forEach((element: CommonEnumDelete) => {
      if (element.id === 1 || element.id === (this.recruitingRole ? 3 : 4)) {
        this.data.statusEnum.push(element);
      }
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'app/services/auth.service';
import { VersionDataService } from 'app/version-data.service';
import { ReportService } from 'app/services/report.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { DialogData } from 'app/types/dialog-data';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { distinctUntilChanged } from 'rxjs';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fieldsSettings } from 'app/types/report';

@Component({
  selector: 'app-training-data-report',
  templateUrl: './training-data-report.component.html',
  styleUrls: ['./training-data-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class TrainingDataReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportOptionsForm: FormGroup;

  exporting = false;
  title: string = 'Training Data Report';

  constructor(
    private authService: AuthService,
    private versionDataService: VersionDataService,
    private reportService: ReportService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {
    this.snackbar.close();
    if (!this.authService.isLoggedIn()) {
      this.router
        .navigate(['/login-component'], { queryParams: { component: this.location.path() } })
        .then();
    }

    const [fieldFormMandatory] = [new Object()];

    fieldsSettings.mandatory.forEach(setting => {
      fieldFormMandatory[setting.id] = setting.values;
    });

    this.exportOptionsForm = this.fb.group({
      mandatory: this.fb.group(fieldFormMandatory),
    });
  }

  exportTrainingDataReport() {
    this.exporting = true;
    this.reportService
      .exportTrainingDataReport()
      .pipe(distinctUntilChanged())
      .subscribe({
        next: response => this.handleExportSucces(response),
        error: error => this.handleExportError(error),
      });
  }

  handleExportSucces(result: any) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }
}

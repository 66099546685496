import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { AppComponent } from '../../app.component';
import { EnumsService } from '../../services/enumerations/enums.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import * as validationUtils from '../../shared/validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opportunity-summary-report',
  templateUrl: './opportunity-summary-report.component.html',
  styleUrls: ['./opportunity-summary-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class OpportunitySummaryReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  dateFormatter = new DatePipe('en-US');
  dateFormat = 'MM/dd/yyyy';
  exporting = false;
  exportForm: FormGroup;
  data = {};
  title: string = 'Opportunity Summary Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.snackbar.close();
    this.exportForm = this.fb.group({
      platform: ['', []],
      numberOfMonths: ['', []],
      reportDate: ['', []],
      location: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.exportForm
      .get('numberOfMonths')
      .setValidators([validationUtils['valRequired'](), validationUtils['valInteger']()]);
  }

  protected populateDropdowns(enums) {
    this.data['employeeLocation'] = enums['employee-location'];
    this.data['platform'] = enums['platform'];

    const all = { id: 0, stage: 0, text: 'All', deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }
    if (this.data['platform'].findIndex(item => item.id === all.id) < 0) {
      this.data['platform'].unshift(all);
    }

    this.exportForm.get('platform').setValue(0);
    this.exportForm.get('numberOfMonths').setValue(4);
    let today = new Date();
    this.exportForm.get('reportDate').setValue(today);
    this.exportForm.get('location').setValue(0);
  }

  exportOpportunitySummaryReport() {
    if (this.exportForm.valid) {
      this.exporting = true;
      const platform = this.exportForm.get('platform').value;
      const numberOfMonths = this.exportForm.get('numberOfMonths').value;
      const reportDate = this.dateFormatter.transform(
        this.exportForm.get('reportDate').value,
        this.dateFormat
      );
      const location = this.exportForm.get('location').value;

      this.reportService
        .exportOpportunitySummaryReport(platform, numberOfMonths, reportDate, location)
        .pipe(distinctUntilChanged())
        .subscribe(
          result => this.handleExportSucces(result),
          err => this.handleExportError(err)
        );
    } else {
      this.snackbar.error('Please correct invalid or missing data marked in red.');
    }
  }

  handleExportSucces(result: any): void {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any): void {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

export class ProjectDetails {
  projectId: number;
  statusId: number;
  client: {
    clientId: number;
    clientName: string;
    diamondClient: boolean;
    deleted: boolean;
    g150: boolean;
  };
  projectInfo: {
    deliveryMethodIds: Array<number>;
    sourcingLocation: Array<number>;
    approxAvgFteReq: number;
    commentId: number;
    contractId: number;
    dcso: boolean;
    dcsoValidationDate: Date;
    deliveryVmuDescription: string;
    digital: number;
    dmsId: string;
    duration: number;
    ebsReported: boolean;
    ecpProgram: boolean;
    end2End: boolean;
    functionalScope: string;
    hwUniversalWbs: number;
    localProject: number;
    manDay: number;
    myschedulingID: number;
    operationalRespId: number;
    peakFte: number;
    piComment: string;
    primaryWorkLocId: number;
    projectName: string;
    rateTypeId: number;
    sapOpNumber: string;
    scDataCenter: boolean;
    seatFloorId: number;
    seatingUniversalWbs: number;
    seatNewApproach: boolean;
    seatsRequested: boolean;
    selectedSolutionFactoryValues: string;
    sharedService: number;
    sharedServiceResourcePool: number;
    softwarePackages: string;
    solutionFactory: number;
    swfReqTravel: boolean;
    techEnvCdc: boolean;
    unchargedProject: boolean;
    useHwUniversalWbs: boolean;
    useSeatingUniversalWbs: boolean;
    workCategoryId: number;
    workLocation: string;
    reportingLevelManual: number;
    reportingRequiredManual: boolean;
    reportingExceptionManual: boolean;
    reportingDrr: number;
    reportingPpqa: number;
    expectedDedicatedSeats: number;
    expectedSharedSeats: number;
    actualFte: number;
    highProfitabilityDeal: boolean;
    growthOpportunity: boolean;
    upskilling: boolean;
    newSeatingRequests: boolean;
    tgSalesId: number;
    serviceGroupIds: Array<number>;
    pyramidIndex: number;
  };
  projectSchedule: {
    commitment: number;
    endDate: Date;
    mailSent: number;
    opportunityToProjectDate: Date;
    startDate: Date;
  };
  reqQualification: {
    altContactId: number;
    clAccLeadContact: string;
    clAccLeadPrimaryContact: boolean;
    clientGroupId: number;
    subIndustryId: number;
    client: string;
    clientId: number;
    commitmentDate: Date;
    country: string;
    countryId: number;
    dcResponsibleDeputy: string;
    dcResponsibleDeputyEid: string;
    dcResponsible: string;
    dcResponsibleRole: string;
    dcResponsibleEid: string;
    dcResponsibleRoleId: number;
    delLeadContact: string;
    delLeadPrimaryContact: boolean;
    domainId: number;
    engContactId: number;
    firstContactId: number;
    firstHlDate: Date;
    lastUpdateDate: Date;
    lastUpdatedBy: string;
    offeringNameId: number;
    offeringResponsibleId: number;
    offeringResp: string;
    othContact: string;
    othPrimaryContact: boolean;
    othContactRole: string;
    platformId: number;
    primaryAccountableLocationId: number;
    primaryContact: number;
    projChampId: number;
    projManContactId: number;
    projManContact: string;
    projectManagerId: number;
    propLeadContactId: number;
    qaDirContact: string;
    salesLead: string;
    qaDirPrimaryContact: boolean;
    regionId: number;
    reqDate: Date;
    salBidContact: string;
    salBidPrimaryContact: boolean;
    solArchContact: string;
    techAccLeadContact: string;
    techAccLeadPrimaryContact: boolean;
  };
}

import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as validationUtils from '../../../shared/validators';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-seat';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../../services/project.service';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { Common } from '../../../shared/common';

@Component({
  selector: 'app-seat-dialog',
  templateUrl: './seat-dialog.component.html',
  styleUrls: ['./seat-dialog.component.scss', './../project-seating-hardware.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class SeatDialogComponent implements OnInit {
  fieldsSettings = fieldsSettings;
  form: FormGroup;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  currentDate: Date = new Date();

  data = {};
  regex: String;
  disabled = true;
  enums: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<SeatDialogComponent>,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private enumsService: EnumsService
  ) {}

  ngOnInit() {
    this.getSeatingEmployees();

    this.enumsService.getData().subscribe(
      enums => {
        this.enums = enums;
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    const fields = new Object();

    if (this.dialogData.editing) {
      this.fieldsSettings.forEach(setting => {
        fields[setting.id] = [this.dialogData.row[setting.id], []];
      });
    } else {
      this.fieldsSettings.forEach(setting => {
        fields[setting.id] = setting.values;
      });
    }

    this.form = this.fb.group(fields);
    this.setFields();
    this.setValidators();
    this.form.valueChanges.subscribe(changes => {
      this.onFormChanges(changes);
    });
    this.form.get('buildingFloor').valueChanges.subscribe(value => {
      this.getRegexByBuildingFloor(value);
      this.setValidators();
      this.form.get('seat').markAsTouched();
      this.form.get('seat').markAsDirty();
      this.form.get('seat').updateValueAndValidity();
    });
  }

  getRegexByBuildingFloor(buildingFloorId) {
    this.enums['seating-building-floor'].forEach(value => {
      if (buildingFloorId && value.id === buildingFloorId) {
        if (value.idRegex) {
          this.enums['seating-building-floor-regex'].forEach(val => {
            if (val.id === value.idRegex) {
              this.regex = val.regex;
            }
          });
        }
      }
    });
  }

  minValidDate() {
    return this.dialogData.editing ? this.currentDate : null;
  }

  employeeHasSeat(employeeDetailId: number) {
    return this.dialogData.employees.some(item => item === employeeDetailId);
  }

  setFields() {
    fieldsSettings.forEach(setting => {
      if (setting.disabled) {
        this.form.get(setting.id).disable({ onlySelf: true, emitEvent: false });
      }
    });

    if (this.dialogData.editing) {
      this.form.get('buildingFloor').disable({ onlySelf: true, emitEvent: false });
      this.form.get('seat').disable({ onlySelf: true, emitEvent: false });
      this.form.get('seatFrom').disable({ onlySelf: true, emitEvent: false });
    }
  }

  getSeatingEmployees() {
    this.projectService.getSeatingEmployees(this.dialogData.projectId).subscribe(
      response => this.handleSeatingEmployees(response),
      error => this.handleError(error)
    );
  }

  handleSeatingEmployees(response) {
    const employeeNameEnum = new Array();

    response.forEach(element => {
      employeeNameEnum.push({
        id: element.id,
        text: element.empNameFte,
        employeeDetailId: element.employeeDetailId,
      });
    });

    this.data['employeeNameEnum'] = employeeNameEnum;
    this.disabled = false;
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  setValidators() {
    this.fieldsSettings.forEach(setting => {
      const validators = [];

      setting.validation.forEach(validation => {
        if (validation === 'valFromToDates') {
          validators.push(validationUtils[validation](this.form.get('seatTo')));
        } else if (validation === 'valToFromDates') {
          validators.push(validationUtils[validation](this.form.get('seatFrom')));
        } else if (validation === 'valSeat') {
          validators.push(validationUtils[validation](this.regex));
        } else {
          validators.push(validationUtils[validation]());
        }
      });

      this.form.get(setting.id).setValidators(validators);
    });
  }

  onFormChanges(changes) {
    Object.keys(changes).forEach(change => {
      const control = this.form.controls[change];
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  create(create: boolean) {
    if (create) {
      const seat = {
        projectId: this.dialogData.projectId,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();
        if (controler.value instanceof Date) {
          seat[controlerName] = Common.timezoneFix(controler.value);
        } else {
          seat[controlerName] = controler.value;
        }
        if (controlerName === 'seat') {
          seat[controlerName] = seat[controlerName].toUpperCase();
        }
      });

      if (this.form.valid) {
        if (this.form.get('employeeNameEnum')) {
          seat['employeeDetailId'] = this.data['employeeNameEnum'].filter(
            val => val.id === this.form.get('supplyId').value
          )[0].employeeDetailId;
        }

        if (this.dialogData.editing) {
          this.projectService.editSeating(this.dialogData.row.id, seat).subscribe(
            res => this.handleSeating(res),
            err => this.handleError(err)
          );
        } else {
          this.projectService.addSeating(seat).subscribe(
            res => this.handleSeating(res),
            err => this.handleError(err)
          );
        }
      } else {
        this.handleError(this.errors);
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  private handleSeating(response) {
    this.snackbar.success('Seat has been saved');
    this.dialogRef.close(true);
  }

  private handleError(error) {
    if (this.form.get('wbs').invalid || this.form.get('wbs').value.includes(' ')) {
      this.snackbar.error('Incorrect WBS (please also check for empty spaces)');
    } else if (this.form.get('seatTo').invalid) {
      this.snackbar.error('Seat To date must be today or in the future only');
    } else {
      this.snackbar.error('Please fix invalid fields (marked in red)');
    }
  }

  populateDropdowns(enums) {
    this.data['buildingFloorEnum'] = enums['seating-building-floor'];
    this.data['seatStatusEnum'] = enums['seating-status'];
    // for now its employee-location IT MIGHT CHANGE IN THE FUTURE
    // filter has been added because some locations don't have building floors
    this.data['seatingLocationEnum'] = enums['employee-location'].filter(
      location =>
        this.data['buildingFloorEnum'].filter(floor => floor.idLocation === location.id).length !==
        0
    );
  }
}

import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { fieldsSettingsWizard } from '../../types/wizard';
import { fieldsSettingsQa } from '../../types/qa-open';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { Location } from '@angular/common';
import { EnumsService } from '../../services/enumerations/enums.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { User } from '../../services/user-roles/user';

@Component({
  selector: 'app-update-wizard',
  templateUrl: './update-wizard.component.html',
  styleUrls: ['../../shared/add-new.scss', './update-wizard.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class UpdateWizardComponent implements OnInit {
  newDemandForm: FormGroup;
  data = {};
  success: boolean;
  reportingLevel: any;
  reportingRequired: any;
  reportingException: any;
  projectId: number;
  ppqa: number;
  type: string;
  header: string;
  drr: number;
  enums: any;
  fieldFormMandatory = {};

  myWizarsReportRole: boolean;
  user: User;

  @ViewChild('expansionPanel', { static: false }) mandatoryPanel: MatExpansionPanel;

  constructor(
    private route: ActivatedRoute,
    public snackbar: SnackbarPanelComponent,
    private location: Location,
    private router: Router,
    private fb: FormBuilder,
    private projectService: ProjectService,
    protected enumsService: EnumsService,
    private userRolesService: UserRolesService
  ) {}

  ngOnInit() {
    const fieldFormMandatory = {};

    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.type = params['type'];
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);
        this.myWizarsReportRole = this.user.getRoles().myWizartReport;
      });

    if (this.type === 'myWizard') {
      this.fieldFormMandatory = fieldsSettingsWizard;
      this.header = 'Update myWizard reporting';
    } else {
      this.fieldFormMandatory = fieldsSettingsQa;
      this.header = 'Update QA Open Actions';
    }

    this.projectService
      .getMyWizardReporting(this.projectId)
      .subscribe(response => this.handleReporting(response));
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }

  protected populateDropdowns(enums) {
    this.enums = enums;
    this.data['levelsEnum'] = enums['reporting_level_manual'];
  }

  handleReporting(data: any): void {
    this.reportingLevel = data.reportingLevel;
    this.reportingRequired = data.reportingRequired;
    this.reportingException = data.exception;
    this.ppqa = data.reportingPpqa;
    this.drr = data.reportingDrr;

    this.fieldFormMandatory['mandatory'].forEach(setting => {
      this.fieldFormMandatory[setting.id] = setting.values;
    });

    this.newDemandForm = this.fb.group({
      mandatory: this.fb.group(this.fieldFormMandatory),
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    Object.keys(this.newDemandForm.controls).forEach(group => {
      const groupControl = this.newDemandForm.get(group);
      if (this.type === 'myWizard') {
        groupControl
          .get('reportingRequiredManual')
          .setValue(this.reportingRequired, { onlySelf: true, emitEvent: false });
        groupControl
          .get('reportingLevelManual')
          .setValue(this.reportingLevel, { onlySelf: true, emitEvent: false });
        groupControl
          .get('reportingExceptionManual')
          .setValue(this.reportingException, { onlySelf: true, emitEvent: false });
      } else {
        groupControl.get('reportingDrr').setValue(this.drr, { onlySelf: true, emitEvent: false });
        groupControl.get('reportingPpqa').setValue(this.ppqa, { onlySelf: true, emitEvent: false });
      }
    });
  }

  save() {
    const form = this.newDemandForm;
    const newWizard = {};
    let isValid = true;

    Object.keys(form.controls).forEach(group => {
      const groupControl = form.get(group);
      Object.keys(groupControl['controls']).forEach(controlerName => {
        const controler = groupControl.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.enabled) {
          if (controlerName === 'reportingPpqa') {
            if (parseInt(controler.value, 10) !== 0 && !Number(parseInt(controler.value, 10))) {
              this.snackbar.error('PPQA can only be a number');
              isValid = false;
            }
          } else if (controlerName === 'reportingDrr') {
            if (parseInt(controler.value, 10) !== 0 && !Number(parseInt(controler.value, 10))) {
              this.snackbar.error('DRR can only be a number');
              isValid = false;
            }
          }
          newWizard[controlerName] = controler.value;
        }
      });
    });

    if (isValid) {
      this.projectService.editReporting(this.projectId, newWizard).subscribe(
        res => {
          this.handleSucces(res);
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  handleSucces(res: any) {
    this.snackbar.success('MyWizard reporting updated - redirecting to reporting tab');
    this.redirectToReporting();
  }

  redirectToReporting() {
    this.success = true;

    setTimeout(() => {
      if (window.location.href.includes('opportunity')) {
        this.router.navigate(['/opportunity-details', this.projectId], { fragment: 'reporting' });
      } else {
        this.router.navigate(['/project-details', this.projectId], { fragment: 'reporting' });
      }
    }, 1500);
  }

  disableSettings() {
    return !this.newDemandForm.controls.mandatory.value.mandatory.reportingExceptionManual;
  }

  handleError(error: Response) {
    const body = error;
    let errorMessage;

    errorMessage = 'Wrong input values';

    this.snackbar.error(errorMessage);
  }
}

export class DemandDetails {
  id: number;
  alignSeats: boolean;
  softlockCount: number;
  role: number;
  platformId: number;
  domainId: number;
  supplyName: string;
  supplyFrom: Date;
  supplyTo: Date;
  supplyLevel: number;
  demandFte: number;
  startDateDemand: Date;
  rollOffDateDemand: Date;
  pcNtbDemandId: number;
  careerLevelId: number;
  sourcingLocationId: number;
  sourcingChannelIds: Array<number>;
  germanRequired: boolean;
  frenchRequired: boolean;
  mySchedulingID: number;
  dcso: boolean;
  demandRateTypeId: number;
  roleDescription: string;
  notes: string;
  probabilityId: number;
  createdOn: Date;
  lastModified: Date;
  canceled: boolean;
  employeeDetailId: number;
  employeeFullName: string;
  employeeHashed: boolean;
  color?: string;
  projectPriority: number;
}

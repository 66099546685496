import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectDetailsComponent } from './project/details/project-details/project-details.component';
import { EmployeeDetailsComponent } from './employee/employee-details/employee-details.component';
import { AddNewEmployeeComponent } from './employee/add-new-employee/add-new-employee.component';
import { ImportAbsencesComponent } from './employee/import-absences/import-absences.component';
import { ProjectListComponent } from './project/lists/project-list/project-list.component';
import { OpportunityDetailsComponent } from './project/details/opportunity-details/opportunity-details.component';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { AddNewProjectComponent } from './project/add-new-project/add-new-project.component';
import { AddNewProjectViewComponent } from './project/add-new-project/add-new-project-view/add-new-project-view.component';
import { AddNewDemandComponent } from './project/add-new-demand/add-new-demand.component';
import { UpdateWizardComponent } from './project/update-wizard/update-wizard.component';
import { ProjectSoftlocksHardlocksComponent } from './project/project-softlocks-hardlocks/project-softlocks-hardlocks.component';
import { OpportunityListComponent } from './project/lists/opportunity-list/opportunity-list.component';
import { WelcomePageComponent } from './project/welcome-page/welcome-page.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { OldProjectDetailsComponent } from './old-dsmt-components/old-project-details/old-project-details.component';
import { NsmetricsReportComponent } from './report/nsmetrics-report/nsmetrics-report.component';
import { ConfirmResourcesComponent } from './confirm-resources/confirm-resources.component';
import { NBMReportComponent } from './report/nbm-report/nbm-report.component';
import { VacationandovertimeReportComponent } from './report/vacationandovertime-report/vacationandovertime-report.component';
import { OpenDemandsReportComponent } from './report/opendemands-report/opendemands-report.component';
import { DataFreshnessReportComponent } from './report/data-freshness-report/data-freshness-report.component';
import { ProjectSummaryReportComponent } from './report/project-summary-report/project-summary-report.component';
import { SchedulingReportComponent } from './report/scheduling-report/scheduling-report.component';
import { AddNewLeadComponent } from './project/add-new-project/add-new-lead/add-new-lead.component';
import { LeadDetailsComponent } from './project/details/lead-details/lead-details.component';
import { AddNewOpportunityComponent } from './project/add-new-project/add-new-opportunity/add-new-opportunity.component';
import { InfrastructureReviewComponent } from './project/infrastructure-review/infrastructure-review.component';
import { AwsSeatingReportComponent } from './report/awsseating-report/aws-seating-report.component';
import { SupplySummaryReportComponent } from './report/supply-summary-report/supply-summary-report.component';
import { OpenPositionsReportComponent } from './report/open-positions-report/open-positions-report.component';
import { PlatformReportComponent } from './report/platform-report/platform-report.component';
import { HiringRequestsComponent } from './hiring-requests/hiring-requests.component';
import { AddNewHiringRequestComponent } from './hiring-requests/add-new-hiring-request/add-new-hiring-request.component';
import { CostCenterReportComponent } from './report/cost-center-report/cost-center-report.component';
import { OpportunitySummaryReportComponent } from './report/opportunity-summary-report/opportunity-summary-report.component';
import { HiringRequestsReportComponent } from './report/hiring-requests-report/hiring-requests-report.component';
import { ProjectCostReportComponent } from './report/project-cost-report/project-cost-report.component';
import { AwsSeatingPlanningComponent } from './aws-seating-planning/aws-seating-planning.component';
import { ProjectsRollOffsOverviewComponent } from './report/projects-roll-offs-overview/projects-roll-offs-overview.component';
import { LanguageSkillsUsageReportComponent } from './report/language-skills-usage-report/language-skills-usage-report.component';
import { ImportOvertimeComponent } from './employee/import-overtime/import-overtime.component';
import { BenchManagementComponent } from './bench-management/bench-management.component';
import { AddNewBenchTrackingComponent } from './bench-management/add-new-bench-tracking/add-new-bench-tracking.component';
import { AddNewBenchTaskComponent } from './bench-management/add-new-bench-task/add-new-bench-task.component';
import { TrainingDataReportComponent } from './report/training-data-report/training-data-report.component';
import { RoutingRoleGuard } from './services/routing-role.service';

const protectedRoutes: Routes = [
  {
    path: 'confirm-resources',
    component: ConfirmResourcesComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'notnew-project-details',
    component: OldProjectDetailsComponent,
  },
  {
    path: 'project-details/add-new-project',
    component: AddNewProjectComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-project-view',
    component: AddNewProjectViewComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-project/:projectId',
    component: AddNewProjectComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-project/:projectId/:typeFrom/:typeTo',
    component: AddNewProjectComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-lead',
    component: AddNewLeadComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-opportunity',
    component: AddNewOpportunityComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-opportunity/:projectId',
    component: AddNewOpportunityComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/add-new-demand',
    component: AddNewDemandComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/update-wizard',
    component: UpdateWizardComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'opportunity-details/add-new-demand',
    component: AddNewDemandComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'lead-details/add-new-demand',
    component: AddNewDemandComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'project-details/softlocks-hardlocks',
    component: ProjectSoftlocksHardlocksComponent,
  },
  {
    path: 'project-list',
    component: ProjectListComponent,
  },
  {
    path: 'infrastructure-review',
    component: InfrastructureReviewComponent,
  },
  {
    path: 'opportunity-list',
    component: OpportunityListComponent,
  },
  {
    path: 'project-details/:searchedId',
    component: ProjectDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'opportunity-details/:searchedId',
    component: OpportunityDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'lead-details/:searchedId',
    component: LeadDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employee-details',
    component: EmployeeDetailsComponent,
  },
  {
    path: 'employee-details/add-new-joiner',
    component: AddNewEmployeeComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'aws-seating-planning',
    component: AwsSeatingPlanningComponent,
  },
  {
    path: 'bench-management',
    component: BenchManagementComponent,
  },
  {
    path: 'bench-management/add-new-tracking',
    component: AddNewBenchTrackingComponent,
  },
  {
    path: 'bench-management/add-new-task',
    component: AddNewBenchTaskComponent,
  },
  {
    path: 'absences/import',
    component: ImportAbsencesComponent,
  },
  {
    path: 'overtime/import',
    component: ImportOvertimeComponent,
  },
  {
    path: 'employee-details/:searchedId',
    component: EmployeeDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'nsmetrics-report',
    component: NsmetricsReportComponent,
  },
  {
    path: 'nbm-report',
    component: NBMReportComponent,
  },
  {
    path: 'opendemands-report',
    component: OpenDemandsReportComponent,
  },
  {
    path: 'data-freshness-report',
    component: DataFreshnessReportComponent,
  },
  {
    path: 'open-positions-report',
    component: OpenPositionsReportComponent,
  },
  {
    path: 'vacationandovertime-report',
    component: VacationandovertimeReportComponent,
  },
  {
    path: 'project-summary-report',
    component: ProjectSummaryReportComponent,
  },
  {
    path: 'supply-summary-report',
    component: SupplySummaryReportComponent,
  },
  {
    path: 'language-skills-usage-report',
    component: LanguageSkillsUsageReportComponent,
  },
  {
    path: 'aws-seating-report',
    component: AwsSeatingReportComponent,
  },
  {
    path: 'scheduling-report',
    component: SchedulingReportComponent,
  },
  {
    path: 'platform-report',
    component: PlatformReportComponent,
  },
  {
    path: 'hiring-requests',
    component: HiringRequestsComponent,
  },
  {
    path: 'hiring-requests/add-new-request',
    component: AddNewHiringRequestComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'cost-center-report',
    component: CostCenterReportComponent,
  },
  {
    path: 'opportunity-summary-report',
    component: OpportunitySummaryReportComponent,
  },
  {
    path: 'hiring-requests-report',
    component: HiringRequestsReportComponent,
  },
  {
    path: 'project-cost-report',
    component: ProjectCostReportComponent,
  },
  {
    path: 'training-data-report',
    component: TrainingDataReportComponent,
  },
  {
    path: 'projects-roll-offs-overview',
    component: ProjectsRollOffsOverviewComponent,
  },
  {
    path: 'welcome-page',
    component: WelcomePageComponent,
  },
];

const routes: Routes = [
  ...protectedRoutes.map(route => ({ ...route, canActivate: [RoutingRoleGuard] })),
  {
    path: 'login-component',
    component: LoginComponentComponent,
  },
  {
    path: '',
    redirectTo: '/login-component',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/login-component',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectService } from 'app/services/project.service';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';

@Component({
  selector: 'app-review-charges',
  templateUrl: './review-charges.component.html',
  styleUrls: ['./review-charges.component.scss'],
})
export class ReviewChargesComponent implements OnInit, AfterViewInit {
  @ViewChild('table1', { read: MatSort, static: false }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: false }) sort2: MatSort;
  @ViewChild('table3', { read: MatSort, static: false }) sort3: MatSort;

  // @Input() form: FormGroup;
  @Input() dialogData: any;
  @Input() data: any;
  @Input() projectName: any;

  seatsDisplayedColumns = ['info', 'days', 'rate', 'sum'];
  seatsDataSource = new MatTableDataSource();

  hardwareDesktopsDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareDesktopsDataSource = new MatTableDataSource();

  hardwareNotebooksDisplayedColumns = ['info', 'serialNumber', 'days', 'rate', 'sum'];
  hardwareNotebooksDataSource = new MatTableDataSource();

  seatsHardwareLoaded;
  projectId: number;
  eid: string;
  wbs: string;
  seatsCost = 0;
  hardwareNotebooksCost = 0;
  hardwareDesktopsCost = 0;
  totalCost = 0;
  seatsWbs: Array<Object>;
  hardwareNotebooksWbs: Array<Object>;
  hardwareDesktopsWbs: Array<Object>;
  month: any;
  year: any;
  validity = false;
  loadedData: Object;
  approved: Boolean;
  isDisabled: boolean;
  choosed: boolean;
  wbsText: string;
  universalWbs = false;
  wbsNotSet = false;
  // data = {};

  constructor(
    private projectService: ProjectService,
    private snackbar: SnackbarPanelComponent,
    private fb: FormBuilder
  ) {}

  ngAfterViewInit() {
    this.seatsDataSource.sort = this.sort1;
    this.hardwareNotebooksDataSource.sort = this.sort2;
    this.hardwareDesktopsDataSource.sort = this.sort3;
  }

  ngOnInit() {
    this.handleSeatsHardware(this.data);
    this.choosed = !this.wbsNotSet;
    this.projectId = this.data.projectId;
  }

  private handleSeatsHardware(data: any): void {
    this.loadedData = data;
    if (data.isApproved) {
      this.approved = data.isApproved;
      this.totalCost = data.totalSum;
      this.validity = data.validity;
      this.seatsHardwareLoaded = true;
      return;
    }
    this.approved = false;
    this.loadedData['seatingCharges'].forEach(s => {
      if (s.rate === 360) {
        s.rate = 0;
      }
    });
    this.seatsHardwareLoaded = true;
    this.seatsDataSource.data = data.seatingCharges;
    this.hardwareNotebooksDataSource.data = data.notebooksCharges.concat(data.ignoredNotebooks);
    this.hardwareDesktopsDataSource.data = data.desktopsCharges.concat(data.ignoredDesktops);

    this.seatsWbs = [];

    Object.keys(data.groupedSeatingSums).forEach(key => {
      this.seatsWbs.push({
        name: key,
        sum: data.groupedSeatingSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
      if (key === 'WBS not set') {
        this.wbsNotSet = true;
      }
    });

    this.hardwareDesktopsWbs = [];

    Object.keys(data.groupedDesktopsSums).forEach(key => {
      this.hardwareDesktopsWbs.push({
        name: key,
        sum: data.groupedDesktopsSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
      if (key === 'WBS not set') {
        this.wbsNotSet = true;
      }
    });

    this.hardwareNotebooksWbs = [];

    Object.keys(data.groupedNotebooksSums).forEach(key => {
      this.hardwareNotebooksWbs.push({
        name: key,
        sum: data.groupedNotebooksSums[key],
        wbsnotset: key === 'WBS not set' ? true : false,
      });
      if (key === 'WBS not set') {
        this.wbsNotSet = true;
      }
    });

    this.seatsCost = data.seatingsSum;
    this.hardwareNotebooksCost = data.notebooksSum;
    this.hardwareDesktopsCost = data.desktopsSum;
    this.totalCost = data.totalSum;
    this.validity = data.validity;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'app/app.component';
import { ReportService } from 'app/services/report.service';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cost-center-report',
  templateUrl: './cost-center-report.component.html',
  styleUrls: ['./cost-center-report.component.scss', '../../shared/add-new.scss'],
  providers: [
    SnackbarPanelComponent,
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
  ],
})
export class CostCenterReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportForm: FormGroup;
  data = {};
  exporting = false;
  title: string = 'Cost Center Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.snackbar.close();

    this.exportForm = this.fb.group({
      location: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums: { [x: string]: any }) {
    this.data['employeeLocation'] = enums['employee-location'];
    const all = { id: 0, stage: 0, text: 'All', value: 0, deleted: false };

    if (this.data['employeeLocation'].findIndex((item: { id: number }) => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }

    this.exportForm.get('location').setValue(0);
  }

  exportCostCenterReport() {
    this.exporting = true;

    const location = this.exportForm.get('location').value;

    this.reportService
      .exportCostCenterReport(location)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

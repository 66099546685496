import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'app/app.component';
import { ReportService } from 'app/services/report.service';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { DatePipe, Location } from '@angular/common';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-positions-report',
  templateUrl: './open-positions-report.component.html',
  styleUrls: ['./open-positions-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class OpenPositionsReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportForm: FormGroup;
  data = {};

  dateFormatter = new DatePipe('en-US');
  dateFormat = 'YYYY-MM-dd';
  exporting = false;
  title: string = 'Open Positions Report';

  constructor(
    private versionDataService: VersionDataService,
    private snackbar: SnackbarPanelComponent,
    private location: Location,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit() {
    this.snackbar.close();

    this.exportForm = this.fb.group({
      platform: ['', []],
      location: ['', []],
      onHold: ['', []],
      dateFrom: ['', []],
      dateTo: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    this.data['employeeLocation'] = enums['employee-location'];
    this.data['platform'] = [];
    const all = { id: 0, stage: 0, text: 'All', value: 0, deleted: false };

    enums['platform'].forEach(e => {
      if (e.deleted != true) {
        this.data['platform'].push(e);
      }
    });
    if (this.data['platform'].findIndex(item => item.id === all.id) < 0) {
      this.data['platform'].unshift(all);
    }
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }

    const prague = { id: 3, stage: 3, text: 'Prague', deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === prague.id) < 0) {
      this.data['employeeLocation'].push(prague);
    }

    this.exportForm.get('location').setValue(0);
    this.exportForm.get('platform').setValue(0);
    this.exportForm.get('onHold').setValue(false);
    this.exportForm.get('dateFrom').setValue(new Date());
    this.exportForm
      .get('dateTo')
      .setValue(new Date(new Date().setMonth(new Date().getMonth() + 2)));
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }

  exportOpenPositionsReport() {
    this.exporting = true;

    const platform = this.exportForm.get('platform').value;
    const location = this.exportForm.get('location').value;
    const onHold = this.exportForm.get('onHold').value;
    const dateFrom = this.dateFormatter.transform(
      this.exportForm.get('dateFrom').value,
      this.dateFormat
    );
    const dateTo = this.dateFormatter.transform(
      this.exportForm.get('dateTo').value,
      this.dateFormat
    );

    this.reportService
      .exportOpenPositionsReport(platform, location, onHold, dateFrom, dateTo)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-cell {
  min-height: 38px;
  height: 38px;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
  max-width: 120px !important;
}

::ng-deep .mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-form-field-infix label {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export class EmployeeDetails {
  absence: string;
  absenceEndDate: string;
  absenceStartDate: string;
  active: boolean;
  availability: number;
  availableAsOf: Date;
  billcodeId: number;
  careerCounsellorId: number;
  careerCounsellorEid: string;
  careerLevel: number;
  cctypeId: number;
  costCentersId: number;
  daysOnBench: number;
  deleted: boolean;
  digitalPlatformId: number;
  digitalSpecialtyId: number;
  employeeActivityTypeId: number;
  employeeDomainId: number;
  employeeRoleId: number;
  employeeSecondaryDomainId: number;
  employeeTypeId: number;
  endDate: Date;
  englishId: number;
  frenchId: number;
  italianId: number;
  enterpriseDomainId: string;
  firstName: string;
  fte: number;
  fulltime: boolean;
  genderId: number;
  germanId: number;
  hireDate: Date;
  homeOfficeScenarioId: number;
  acquisitionId: number;
  id: number;
  industryId: number;
  lastName: string;
  lastPromotionDate: Date;
  lastUpdatedBy: string;
  locationId: number;
  mal: number;
  newCareerLevelId: number;
  notes: string;
  overtimesRestricted: boolean;
  personalNumber: number;
  platformId: number;
  positionId: number;
  primarySkillId: number;
  proficiencyId: number;
  projectAssignment: string;
  rotationPreferencesId: number;
  rotationPreferencesComment: string;
  rotationPreferencesProjectName: string;
  secondaryPlatformId: number;
  shared: boolean;
  statusId: number;
  supplyIndustryProficiencyId: number;
  supplyIndustrySkillId: number;
  trainee: boolean;
  traineeEndDate: Date;
  travelId: number;
  universityId: number;
  updateCc: boolean;
  updatedCostCenterId: number;
  updatedOn: Date;
  hashed: boolean;
}

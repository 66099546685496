import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { ProjectDetails } from '../../types/project-details';
import { ProjectCardItemComponent } from '../project-card-item/project-card-item.component';
import { ProjectService } from '../../services/project.service';
import { Router } from '@angular/router';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { EnumsService } from '../../services/enumerations/enums.service';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { User } from '../../services/user-roles/user';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-project-reporting',
  templateUrl: './project-reporting.component.html',
  styleUrls: [
    './project-reporting.component.scss',
    '../../shared/card.scss',
    '../../shared/card-item.scss',
  ],
})
export class ProjectReportingComponent implements OnInit {
  editing: boolean;
  projectOriginVal: ProjectDetails;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  color = '';
  reportingLevel: string;
  reportingRequired: string;
  exception: string;
  hiredFte: any;
  enums: any;
  month: string;
  year: number;
  user: User;
  adminRole: boolean;
  myWizardReportRole: boolean;
  drr: any;
  ppqa: any;
  totalFte: any;
  editable: boolean;
  valid: boolean;

  editingRole: boolean;
  reportingLoaded;
  @ViewChildren('item') private cardItems: QueryList<ProjectCardItemComponent>;

  @Input() projectDetails: ProjectDetails;

  constructor(
    private router: Router,
    public snackbar: SnackbarPanelComponent,
    private userRolesService: UserRolesService,
    protected enumsService: EnumsService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);
        if (this.user.getRoles().adminOrHr === true && this.user.getRoles().user === true) {
          this.adminRole = true;
        }
        this.myWizardReportRole = this.user.getRoles().myWizartReport;
      });
    this.reportingLoaded = false;
    this.editing = false;
    const actMonth = new Date().getMonth() - 1;
    const actYear = new Date().getFullYear();
    this.month = this.months[actMonth < 0 ? 11 : actMonth];
    this.year = actMonth < 0 ? actYear - 1 : actYear;
    this.color = 'red';

    this.projectService.getReporting(this.projectDetails.projectId).subscribe(
      response => this.handleReporting(response)
      // error => this.handleError(error)
    );
  }

  calculateReportingReq(): string {
    const myWizardId = this.projectDetails.projectInfo.dmsId;
    if (myWizardId != null && myWizardId !== '' && myWizardId !== 'NA-NA-NA') {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }

  calculateReportingLevel(): string {
    const myWizardId = this.projectDetails.projectInfo.dmsId;
    const parts = myWizardId.split('-');

    if (parts == null || parts.length < 3) {
      return 'N/A';
    }
    if (this.hasNumber(parts[0]) && 'NA' === parts[2]) {
      return 'E2E';
    }
    if (this.hasNumber(parts[0]) && this.hasNumber(parts[2])) {
      return 'Team';
    }
    return 'N/A';
  }

  handleReporting(data: any): void {
    this.reportingLoaded = true;
    this.exception = data.exception ? 'Yes' : 'No';

    if (this.exception === 'No') {
      this.reportingLevel = this.calculateReportingLevel();
      this.reportingRequired = this.calculateReportingReq();
    } else {
      this.reportingLevel = data.reportingLevel;
      this.reportingRequired = data.reportingRequired ? 'Yes' : 'No';
    }
    this.editable = this.adminRole || this.myWizardReportRole;
    if (data.reportingRequired === true) {
      this.hiredFte = data.hiredFte;
      this.ppqa = data.reportingPpqa !== null ? data.reportingPpqa : '-';
      this.drr = data.reportingDrr !== null ? data.reportingDrr : '-';
      this.totalFte = data.totalFte;
    } else {
      this.hiredFte = '-';
      this.totalFte = '-';
      this.ppqa = '-';
      this.drr = '-';
    }
    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
        enums['reporting_level_manual'].forEach(element => {
          if (element.id === this.reportingLevel) {
            this.reportingLevel = element.text;
          }
        });
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  onEditing() {
    this.editing = true;
  }

  onSubmit() {
    this.editing = false;
  }

  onCancel() {
    this.editing = false;
  }

  editMyWizard() {
    this.editing = true;
    this.router.navigate(['/project-details/update-wizard'], {
      queryParams: {
        projectId: this.projectDetails.projectId,
        type: 'myWizard',
      },
    });
  }

  editQaOpenAction() {
    this.editing = true;
    this.router.navigate(['/project-details/update-wizard'], {
      queryParams: {
        projectId: this.projectDetails.projectId,
        type: 'qa-open',
      },
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  protected populateDropdowns(enums) {
    this.enums = enums;
  }
}

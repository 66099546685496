import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectCardComponent } from '../../cards/project-card/project-card.component';
import { User } from '../../../services/user-roles/user';
import * as cards from '../../../types/project-cards-content';
import { DialogData } from '../../../types/dialog-data';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { CommonDetailsComponent } from '../common-details/common-details.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectDetailsComponent extends CommonDetailsComponent implements OnInit {
  @ViewChildren('card') declare cardComponents: QueryList<ProjectCardComponent>;

  hardlockedSupply: boolean;
  url = '../project-list';
  errorMessage = 'Invalid Project ID route.';
  urlDetails = '/project-details';

  ngOnInit() {
    Object.keys(cards).forEach(element => {
      this.projectCards.push(cards[element]);
    });

    this.route.fragment.subscribe(fragment => {
      this.selectedTab = this.chooseTab(fragment);
    });

    this.enumsService.getData().subscribe({
      next: enums => this.populateDropdowns(enums),
      error: error => this.handleLoadEnumsError(),
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
        this.historyRole = user.getRoles().adminOrHr;
        this.eid = user.eid;
        this.hasDomainId = user.hasDomainId;
      });

    this.loadClient();
    this.pLocation.onPopState(() => {
      this.router.navigate(['../project-list']).then();
    });
  }

  populateDropdowns(enums) {
    this.enums = JSON.parse(JSON.stringify(enums));
    this.enums = super.sortEnumValues(enums);
    this.enums['dcResponsibleRole'] = this.enums['dcResponsibleRole'].filter(role => role.id !== 1);
    this.enums['probability'] = this.enums['probability'].filter(value => value.id > 0);

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  handleProjectDetailsSuccess(projectDetails: any): void {
    this.projectDetails = projectDetails;
    this.checkStatus();
    this.getEndingSubcon(this.projectDetails.projectId);
  }

  multipleIdsNotify(ids: Array<number>) {
    this.ids = ids;
    if (ids.length === 1) {
      this.disabledFte = !this.hardlockedSupply;
      this.disabledCopyDemand = false;
    } else {
      this.disabledFte = true;
      this.disabledCopyDemand = true;
    }

    this.disabledEditMore = ids.length <= 1;
  }

  multipleNamesNotify(names: Array<string>) {
    this.names = names;

    if (names.length === 1) {
      this.hardlockedSupply = names[0] && names[0].length > 0;
    }
  }

  getEndingSubcon(searchedId: number) {
    this.projectService.getSubcoEnding(searchedId).subscribe({
      next: response => this.handleSubcoSuccess(response),
      error: error => this.handleProjectDetailsError(error),
    });
  }

  handleSubcoSuccess(numOfSubco: any): void {
    this.showReminder(numOfSubco);
  }

  getNthWorkDay(date: Date, nthWorkDay: number) {
    const year = date.getFullYear();
    const month = date.getMonth();
    let nthWorkDate: Date = null;
    let workDays = 0;

    for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
      const dateTemp = new Date(year, month, day);
      if (dateTemp.getDay() >= 1 && dateTemp.getDay() <= 5) {
        if (nthWorkDay === ++workDays) {
          nthWorkDate = dateTemp;
          break;
        }
      }
    }
    return nthWorkDate ? nthWorkDate.getDate() : null;
  }

  isDayBeforeNthWorkDay(date: Date, nthWorkDay: number) {
    const year = date.getFullYear();
    const month = date.getMonth();
    let workDays = 0;

    if (date.getDate() > nthWorkDay + 2 * Math.ceil(nthWorkDay / 5)) {
      return false;
    }

    for (let day = 1; day <= date.getDate(); day++) {
      const dateTemp = new Date(year, month, day);
      if (dateTemp.getDay() >= 1 && dateTemp.getDay() <= 5) {
        workDays++;
      }
    }

    return workDays <= nthWorkDay;
  }

  showReminder(numOfSubco: number) {
    const projectDetails = this.projectDetails;
    const twoWeeks = new Date();
    const today = new Date();
    const workDay = 8;
    let reminderData = '<div id="info">' + '<ul>';
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (
      this.isDayBeforeNthWorkDay(today, workDay) &&
      (projectDetails.projectInfo.reportingRequiredManual ||
        (!projectDetails.projectInfo.reportingRequiredManual &&
          projectDetails.projectInfo.dmsId !== '' &&
          projectDetails.projectInfo.dmsId !== 'NA-NA-NA'))
    ) {
      reminderData +=
        '<li>' +
        '<b>Submit</b> Narrative Status & Metrics by ' +
        months[today.getMonth()] +
        ' ' +
        this.getNthWorkDay(today, workDay) +
        'th, ' +
        today.getFullYear() +
        ' (<a href="https://ts.accenture.com/:f:/r/sites/Bratislava_Delivery_Center_Intranet/intranet/DEX/Learning/Tranings/myWizard%20reporting?csf=1&web=1"' +
        ' target="_blank">guide</a>)' +
        '</li>';
    }

    twoWeeks.setDate(twoWeeks.getDate() + 14);
    if (
      this.projectDetails.statusId === 3 &&
      new Date(projectDetails.projectSchedule.endDate).getTime() <= twoWeeks.getTime()
    ) {
      reminderData +=
        '<li>' +
        "<p>Please <b>prolong</b> the project's End Date or close the project." +
        '<br>To close the project in DSMT you need to roll-off everyone from the Demand tab, approve all seat and HW costs and change projects status to "Closed".</p>' +
        '</li>';
    }

    if (numOfSubco > 0) {
      reminderData +=
        '<li>' +
        '<p>' +
        numOfSubco +
        ' Subcontractor(s) will be rolled-off from your project soon. <br>' +
        'If the roll-off date should be prolonged please <b>update</b> it in DSMT now. If not, the closing list will be created to this roll-off date.</p>' +
        '</li>';
    }
    reminderData += '</ul>' + '</div>';
    if (reminderData.includes('p')) {
      const data: DialogData = {
        title: 'Reminder',
        content: reminderData,
        action1: 'Close',
      };
      const dialogRef = this.dialog.open(DialogComponent, { data });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  }

  prepareDownload(projectId: number) {
    this.projectService
      .demandsExport(projectId, 'xlsx')
      .pipe(distinctUntilChanged())
      .subscribe(result => {
        if (result) {
          const contentDispositionHeader = result.headers.get('Content-Disposition');
          const filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];
          const urlObject = window.URL.createObjectURL(result.body);
          const element = document.createElement('a');
          element.href = urlObject;
          element.download = filename;
          document.body.appendChild(element);
          element.click();
        }
      });
  }
}

import { Component, Inject, Injectable } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar-panel',
  templateUrl: './custom-snackbar-panel.component.html',
  styleUrls: [
    './custom-snackbar-panel.component.scss',
    '../snackbar-panel/snackbar-panel.component.scss',
  ],
})
@Injectable()
export class CustomSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-new-bench-task',
  templateUrl: './add-new-bench-task.component.html',
  styleUrls: ['./add-new-bench-task.component.scss'],
})
export class AddNewBenchTaskComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { REST } from '../../rest.endpoints';
import { addHours, isBefore } from 'date-fns';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  setSession(token): void {
    const expiresAt: Date = addHours(new Date(), 1);
    localStorage.setItem('id_token', token);
    sessionStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout(): void {
    localStorage.removeItem('id_token');
    sessionStorage.removeItem('expires_at');
  }

  public isLoggedIn(): boolean {
    return isBefore(new Date(), this.getExpiration());
  }

  getExpiration(): Date {
    const expiration: string = sessionStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return new Date(expiresAt);
  }

  refreshToken(): Observable<any> {
    return this.http.get(REST.refreshToken).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return throwError(() => new Error(error));
  }
}

import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'select',
    data: 'buildingFloorEnum',
    id: 'buildingFloor',
    label: 'Building Floor',
    validation: ['valRequired'],
    values: ['', []],
  },
  { cardType: 'input', id: 'seat', label: 'Seat', validation: ['valSeat'], values: ['', []] },
  {
    cardType: 'date',
    id: 'seatFrom',
    label: 'From',
    validation: ['valDate', 'valRequired', 'valFromToDates'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'seatTo',
    label: 'To',
    validation: ['valDate', 'valRequired', 'valToFromDates'],
    values: ['', []],
  },
  { cardType: 'input', id: 'pdlComment', label: 'PDL Comment', validation: [], values: ['', []] },
  {
    cardType: 'select',
    data: 'employeeNameEnum',
    id: 'supplyId',
    label: 'Employee Name',
    validation: [],
    values: ['', []],
  },
  { cardType: 'input', id: 'wbs', label: 'WBS', validation: ['valString8'], values: ['', []] },
  {
    cardType: 'select',
    data: 'seatStatusEnum',
    id: 'seatStatus',
    label: 'Status',
    validation: ['valRequired'],
    values: [1, []],
    disabled: true,
  },
  {
    cardType: 'input',
    id: 'awsComment',
    label: 'AWS Comment',
    validation: [],
    values: ['', []],
    disabled: true,
  },
];

import { Injectable } from '@angular/core';
import { applicationInfo } from 'app/settings/html-templates';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VersionDataService {
  appName: string = '';
  version: string = '';
  dataClassification: string = '';
  author: string = '';
  versionInfo: any = {};
  applicationInfo: string;
  constructor(private http: HttpClient) {
    this.http.get<any>('/api/version').subscribe(response => {
      this.versionInfo = response;
    });
  }

  showInfo() {
    return applicationInfo
      .replace('{{appName}}', this.versionInfo.appName)
      .replace('{{appVersion}}', this.versionInfo.version)
      .replace('{{appDataClassification}}', this.versionInfo.dataClassification)
      .replace('{{appAuthor}}', this.versionInfo.author);
  }
}

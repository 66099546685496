import { Component, Input, OnInit } from '@angular/core';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { EmployeeDetails } from '../../../types/employee-details';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-employee';

@Component({
  templateUrl: './common-fields.component.html',
  styleUrls: ['./common-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class CommonFieldsComponent implements OnInit {
  @Input() employeeDetails: EmployeeDetails;

  data = {};
  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  enums: any;

  constructor(
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit() {
    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.subscribeSpecialEnums();
  }

  protected onFormChanges(changes, part) {
    Object.keys(changes).forEach(change => {
      const control = part.controls[change];
      this.employeeDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  public subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}

  protected populateDropdowns(enums) {
    this.enums = enums;
  }
}

import { CommonModule } from './shared/common.module';
import { ProjectModule } from './project/project.module';
import { ReportModule } from './report/report.module';
import { NgModule } from '@angular/core';
import { AutofocusDirective } from './employee/employee-auto-search/autocomplete-search/autocomplete-search.directive';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { EmployeeDetailsService } from './employee/employee-details/employee-details.service';
import { EnumsService } from './services/enumerations/enums.service';
import { SuppliesService } from './services/supplies.service';
import { UserRolesService } from './services/user-roles/user-roles.service';
import { CounseleesSearchService } from './services/counselees-search.service';
import { ActiveEmployeesAllCareerCounselorsService } from './services/active-employees-all-career-counselors.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

import { AppComponent } from './app.component';
import { EmployeeDetailsComponent } from './employee/employee-details/employee-details.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { CardComponent } from './employee/card/card.component';
import { CardItemComponent } from './employee/card-item/card-item.component';
import { CardItemEnumMultipleComponent } from './employee/card-item/card-item-enum-multiple/card-item-enum-multiple.component';
import { CardItemEnumComponent } from './employee/card-item/card-item-enum/card-item-enum.component';
import { CardItemComboboxComponent } from './employee/card-item/card-item-combobox/card-item-combobox.component';
import { CardItemBoolComponent } from './employee/card-item/card-item-bool/card-item-bool.component';
import { CardItemAutosearchComponent } from './employee/card-item/card-item-autosearch/card-item-autosearch.component';

import { EmployeeAutoSearchComponent } from './employee/employee-auto-search/employee-auto-search.component';
import { AutocompleteSearchComponent } from './employee/employee-auto-search/autocomplete-search/autocomplete-search.component';
import { AddNewEmployeeComponent } from './employee/add-new-employee/add-new-employee.component';
import { CommonFieldsComponent } from './employee/add-new-employee/common-fields/common-fields.component';
import { TopFieldsComponent } from './employee/add-new-employee/top-fields/top-fields.component';
import { EmployeeBannerCardComponent } from './employee/employee-banner-card/employee-banner-card.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { MandatoryFieldsComponent } from './employee/add-new-employee/mandatory-fields/mandatory-fields.component';
import { OptionalFieldsComponent } from './employee/add-new-employee/optional-fields/optional-fields.component';
import { ImportAbsencesComponent } from './employee/import-absences/import-absences.component';
import { ImportAbsenceDialogComponent } from './employee/import-absence-dialog/import-absence-dialog.component';
import { EmployeeAssignmentsComponent } from './employee/employee-assignments/employee-assignments.component';
import { OpportunityListComponent } from './project/lists/opportunity-list/opportunity-list.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponentComponent } from './login-component/login-component.component';
import { HttpErrorFilterProvider } from './services/expiration-handler-interceptor';
import { OldProjectDetailsComponent } from './old-dsmt-components/old-project-details/old-project-details.component';
import { OldJSPService } from './services/old-jsp-service';
import { MatGridListModule } from '@angular/material/grid-list';

import { UserPermissions } from './types/user-roles';
import { APP_BASE_HREF } from '@angular/common';
import { ConfirmResourcesComponent } from './confirm-resources/confirm-resources.component';
import { ResourcesExtendibleHardlocksComponent } from './confirm-resources/resources-extendible-hardlocks/resources-extendible-hardlocks.component';
import { ResourcesHistoryComponent } from './confirm-resources/resources-history/resources-history.component';
import { ResourcesOpenDemandsComponent } from './confirm-resources/resources-open-demands/resources-open-demands.component';
import { ResourcesSearchComponent } from './confirm-resources/resources-search/resources-search.component';
import { ReviewApproveDialogNewApproachComponent } from './project/project-seating-hardware/review-approve-dialog-new-approach/review-approve-dialog-new-approach.component';
import { AwsSeatingPlanningComponent } from './aws-seating-planning/aws-seating-planning.component';
import { AwsSeatingPlanningTableComponent } from './aws-seating-planning/aws-seating-planning-table/aws-seating-planning-table.component';
import { AwsSeatingPlanningHistoryComponent } from './aws-seating-planning/aws-seating-planning-history/aws-seating-planning-history.component';
import { HiringRequestsComponent } from './hiring-requests/hiring-requests.component';
import { AddNewHiringRequestComponent } from './hiring-requests/add-new-hiring-request/add-new-hiring-request.component';
import { HiringRequestOptionalFieldsComponent } from './hiring-requests/add-new-hiring-request/hiring-request-optional-fields/hiring-request-optional-fields.component';
import { HiringRequestMandatoryFieldsComponent } from './hiring-requests/add-new-hiring-request/hiring-request-mandatory-fields/hiring-request-mandatory-fields.component';
import { HiringRequestCommonFieldsComponent } from './hiring-requests/add-new-hiring-request/hiring-request-common-fields/hiring-request-common-fields.component';
import { HiringRequestsHistoryComponent } from './hiring-requests/hiring-requests-history/hiring-requests-history.component';
import { HiringRequestsActiveComponent } from './hiring-requests/hiring-requests-active/hiring-requests-active.component';
import { ImportOvertimeComponent } from './employee/import-overtime/import-overtime.component';
import { BenchManagementComponent } from './bench-management/bench-management.component';
import { BenchTrackingComponent } from './bench-management/bench-tracking/bench-tracking.component';
import { BenchTasksComponent } from './bench-management/bench-tasks/bench-tasks.component';
import { AddNewBenchTrackingComponent } from './bench-management/add-new-bench-tracking/add-new-bench-tracking.component';
import { AddNewBenchTaskComponent } from './bench-management/add-new-bench-task/add-new-bench-task.component';
import { BenchTrackingMandatoryFieldsComponent } from './bench-management/add-new-bench-tracking/bench-tracking-mandatory-fields/bench-tracking-mandatory-fields.component';
import { BenchTrackingOptionalFieldsComponent } from './bench-management/add-new-bench-tracking/bench-tracking-optional-fields/bench-tracking-optional-fields.component';
import { BenchTrackingCommonFieldsComponent } from './bench-management/add-new-bench-tracking/bench-tracking-common-fields/bench-tracking-common-fields.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ProjectModule,
    HttpClientModule,
    MatGridListModule,
    ReportModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatTooltipModule,
  ],
  declarations: [
    AppComponent,
    SidenavComponent,
    EmployeeDetailsComponent,
    CardComponent,
    CardItemComponent,
    CardItemEnumComponent,
    CardItemEnumMultipleComponent,
    CardItemBoolComponent,
    CardItemAutosearchComponent,
    AddNewEmployeeComponent,
    EmployeeAutoSearchComponent,
    AutocompleteSearchComponent,
    EmployeeBannerCardComponent,
    CommonFieldsComponent,
    TopFieldsComponent,
    DialogComponent,
    MandatoryFieldsComponent,
    OptionalFieldsComponent,
    ImportAbsencesComponent,
    ImportAbsenceDialogComponent,
    ImportOvertimeComponent,
    EmployeeAssignmentsComponent,
    CardItemComboboxComponent,
    OpportunityListComponent,
    LoginComponentComponent,
    OldProjectDetailsComponent,
    AutofocusDirective,
    ConfirmResourcesComponent,
    HiringRequestsComponent,
    ResourcesExtendibleHardlocksComponent,
    ResourcesHistoryComponent,
    ResourcesOpenDemandsComponent,
    ResourcesSearchComponent,
    ReviewApproveDialogNewApproachComponent,
    AwsSeatingPlanningComponent,
    AwsSeatingPlanningTableComponent,
    AwsSeatingPlanningHistoryComponent,
    ReviewApproveDialogNewApproachComponent,
    AddNewHiringRequestComponent,
    HiringRequestOptionalFieldsComponent,
    HiringRequestMandatoryFieldsComponent,
    HiringRequestCommonFieldsComponent,
    HiringRequestsHistoryComponent,
    HiringRequestsActiveComponent,
    BenchManagementComponent,
    BenchTrackingComponent,
    BenchTasksComponent,
    AddNewBenchTrackingComponent,
    AddNewBenchTaskComponent,
    BenchTrackingMandatoryFieldsComponent,
    BenchTrackingOptionalFieldsComponent,
    BenchTrackingCommonFieldsComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    OldJSPService,
    SuppliesService,
    EmployeeDetailsService,
    EnumsService,
    UserRolesService,
    CounseleesSearchService,
    ActiveEmployeesAllCareerCounselorsService,
    CanDeactivateGuard,
    HttpErrorFilterProvider,
    UserPermissions,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

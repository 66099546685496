import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { AppComponent } from '../../app.component';
import { EnumsService } from '../../services/enumerations/enums.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scheduling-report',
  templateUrl: './scheduling-report.component.html',
  styleUrls: ['./scheduling-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class SchedulingReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  dateFormatter = new DatePipe('en-US');
  dateFormat = 'MM/dd/yyyy';
  locationValues: any[];
  exporting = false;
  exportForm: FormGroup;
  data = {};
  original_location = [];
  title: string = 'Scheduling Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private reportService: ReportService,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit() {
    this.snackbar.close();

    this.exportForm = this.fb.group({
      inactive: ['', []],
      supplyHistoryFrom: ['', []],
      supplyHistoryTo: ['', []],
      reportingDate: ['', []],
      country: ['', []],
      location: ['', []],
      platform: ['', []],
      slovakFormat: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    this.data['employeeLocation'] = enums['employee-location'];
    this.original_location = enums['employee-location'];
    this.data['platform'] = enums['platform'];
    this.data['employeeCountry'] = JSON.parse(JSON.stringify(enums['employee-country-enum']));

    const all = { id: 0, stage: 0, text: 'All', id_country: 0, deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }
    if (this.data['platform'].findIndex(item => item.id === all.id) < 0) {
      this.data['platform'].unshift(all);
    }
    if (this.data['employeeCountry'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeCountry'].unshift(all);
    }

    this.exportForm.get('inactive').setValue(false);
    let today = new Date();
    this.exportForm.get('supplyHistoryTo').setValue(today);
    this.exportForm.get('reportingDate').setValue(today);
    let yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);
    this.exportForm.get('supplyHistoryFrom').setValue(yearAgo);
    this.exportForm.get('platform').setValue(0);
    this.exportForm.get('location').setValue(0);
    this.exportForm.get('country').setValue(0);
    this.exportForm.get('slovakFormat').setValue(false);
  }

  exportSchedulingReport() {
    this.exporting = true;
    const inactive = this.exportForm.get('inactive').value;
    const supplyHistoryFrom = this.dateFormatter.transform(
      this.exportForm.get('supplyHistoryFrom').value,
      this.dateFormat
    );
    const supplyHistoryTo = this.dateFormatter.transform(
      this.exportForm.get('supplyHistoryTo').value,
      this.dateFormat
    );
    const reportingDate = this.dateFormatter.transform(
      this.exportForm.get('reportingDate').value,
      this.dateFormat
    );
    const country = this.exportForm.get('country').value;
    const location = this.exportForm.get('location').value;
    const platform = this.exportForm.get('platform').value;
    const slovakFormat = this.exportForm.get('slovakFormat').value;

    this.reportService
      .exportSchedulingReport(
        inactive,
        supplyHistoryFrom,
        supplyHistoryTo,
        reportingDate,
        platform,
        location,
        slovakFormat,
        country
      )
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  onOptionChange(event) {
    this.data['employeeLocation'] = this.original_location;
    const all = { id: 0, stage: 0, text: 'All', deleted: false };
    if (this.data['employeeLocation'].findIndex(item => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }

    this.data['employeeLocation'][0].id_country = event.value;
    let filtered = this.data['employeeLocation'].filter(item => item.id_country === event.value);
    this.data['employeeLocation'] = filtered;

    if (event.value == 0) {
      this.data['employeeLocation'] = this.original_location;
    }
    this.exportForm.get('location').setValue(0);
    this.exportForm.get('country').setValue(event.value);
  }

  onLocationChange(event) {
    let filtered_location = this.original_location.find(item => item.id === event.value);
    filtered_location['value'] = filtered_location.id_country;
    this.onOptionChange(filtered_location);
    this.exportForm.get('location').setValue(event.value);
  }

  handleExportSucces(result: any): void {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any): void {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class OldJSPService {
  constructor() {}

  getOldProjectDetails(): void {
    window.location.href = './old/project/projectList.do?type=Project';
  }

  getOldOpportunityDetails(): void {
    window.location.href = './old/project/projectList.do?type=Opportunity';
  }

  getOldDemandForm(url: any): void {
    window.location.href = url;
  }

  getOldInfrastructureReview(): void {
    window.location.href = './old/project/infrastructureReview.do';
  }

  getOldConfirmResources(): void {
    window.location.href = './old/project/confirmResources?new=y';
  }

  getHiringRequest(): void {
    window.location.href = './old/project/openDemands.do';
  }

  getOldRotationPlanPdlIsm(): void {
    window.location.href = './old/project/rotationPlanPdl.do';
  }

  getOldRotationPlanPl(): void {
    window.location.href = './old/project/rotationPlan.do?new=y';
  }

  getOldCompetencyAnalysis(): void {
    window.location.href = './old/project/competencyAnalysis.do?new=y';
  }

  getOldCompetencyDevelopment(): void {
    window.location.href = './old/project/competencyDevelopment.do?new=y';
  }

  getOldAwsSeating(): void {
    window.location.href = './old/project/awsSeating.do';
  }

  getOldHcPlan(): void {
    window.location.href = './old/project/hcPlan.do';
  }

  getOldTools(): void {
    window.location.href = './old/project/tools.do';
  }

  // -----REPORTS-----

  getOldSupplySummary(): void {
    window.location.href = './old/reports/supplySummary.do';
  }

  getAvailabilityReport(): void {
    window.location.href = './old/reports/availabilityReport.do';
  }

  getAwsSeatingReport(): void {
    window.location.href = './old/reports/awsSeatingReport.do';
  }

  getCompetencyAnalysisDevelopment(): void {
    window.location.href = './old/reports/competencyAnalysisDevelopment.do';
  }

  getCostCenterReport(): void {
    window.location.href = './old/reports/costCenterReport.do';
  }

  getFreshnessReport(): void {
    window.location.href = './old/reports/freshnessReport.do';
  }

  getDsfReport(): void {
    window.location.href = './old/reports/dsfReport.do';
  }

  getHcReport(): void {
    window.location.href = './old/reports/headCountsReport.do';
  }

  getHiringRequestReport(): void {
    window.location.href = './old/reports/hiringRequestReport.do';
  }

  getInfrastructureChargesReport(): void {
    window.location.href = './old/reports/infrastructureChargesReport.do';
  }

  getOpenDemandsReport(): void {
    window.location.href = './old/reports/openDemandsReport.do';
  }

  getOpenPositionsReport(): void {
    window.location.href = './old/reports/openPositionsReport.do';
  }

  getOpportunitySummaryReport(): void {
    window.location.href = './old/reports/opportunitySummary.do';
  }

  getPlatformReport(): void {
    window.location.href = './old/reports/capabilityReport.do';
  }

  getProjectCostReport(): void {
    window.location.href = './old/reports/projectCostReport.do';
  }

  getRecoverabilityReport(): void {
    window.location.href = './old/reports/recoverabilityReport.do';
  }

  getRotationPlanReport(): void {
    window.location.href = './old/reports/rotationPreferencesReport.do';
  }

  getSeatPlanningReport(): void {
    window.location.href = './old/reports/seatPlanning.do';
  }

  getProjectSummaryReport(): void {
    window.location.href = './old/reports/projectSummary.do';
  }

  getSharedServicesReport(): void {
    window.location.href = './old/reports/sharedServicesReport.do';
  }

  getSchedulingReport(): void {
    window.location.href = './old/reports/schedulingReport.do';
  }
}

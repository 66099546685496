import { distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { SuppliesService } from './../../../services/supplies.service';
import { CardItemComponent } from './../card-item.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { EnumsService } from './../../../services/enumerations/enums.service';
import { Hardlock } from '../../../types/hardlock';

@Component({
  selector: 'app-card-item-combobox',
  templateUrl: './card-item-combobox.component.html',
  styleUrls: ['../card-item.component.scss', './card-item-combobox.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class CardItemComboboxComponent extends CardItemComponent implements OnInit, OnChanges {
  comboboxValues: any[];

  constructor(
    snackbar: SnackbarPanelComponent,
    enumsService: EnumsService,
    private suppliesService: SuppliesService
  ) {
    super(enumsService, snackbar);
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
  }

  updateValues() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.employeeDetails && !changes.employeeDetails.firstChange) {
      this.init();
    }
  }

  init() {
    if (this.id === 'rotationPreferencesProjectName') {
      this.suppliesService
        .getData(this.employeeDetails.id.toString())
        .pipe(distinctUntilChanged())
        .subscribe(data => {
          this.comboboxValues = this.handleHardlocks(data);
        });
    }
  }

  handleHardlocks(supplies: Object): any[] {
    let hardlocks: Hardlock[];

    if (supplies['hardlocks'] != null && supplies['hardlocks'].length > 0) {
      const now: Date = new Date();
      hardlocks = supplies['hardlocks'].filter(
        hl => new Date(hl.supplyTo).getTime() >= now.getTime()
      );
    }

    const result =
      hardlocks === undefined || hardlocks.length === 0 ? [] : hardlocks.map(hl => hl.projectName);

    if (result.length === 0) {
      result.push('Bench');
    }

    return result;
  }
}

import { DatePipe, Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AuthService } from 'app/services/auth.service';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { ProjectService } from 'app/services/project.service';
import { UserRolesService } from 'app/services/user-roles/user-roles.service';
import { CommonComponent } from 'app/shared/common/common.component';
import { CustomDateAdapter } from 'app/shared/date-utils';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { HiringRequestSearch } from 'app/types/hiring-request-search';
import { fieldsSettings } from 'app/types/hiring-requests-column-settings';
import { distinctUntilChanged } from 'rxjs/operators';
import { openDemands, OpenDemandsListDto } from '../../types/openDemands';
import { ProjectNameDto } from '../../types/ProjectNameDto';
import { EnumList } from '../../types/enum-types/common/allEnum';
import { MatDatepicker } from '@angular/material/datepicker';
import { ProjectCard } from '../../types/card';
import { PlatformEnum } from '../../types/enum-types/PlatformEnum';
import { DomainEnum } from '../../types/enum-types/DomainEnum';
import { EmployeeLocationEnum } from '../../types/enum-types/EmployeeLocationEnum';
import { ProjectEnum } from '../../types/enum-types/projectEnum';
import { CommonEnumDelete } from '../../types/enum-types/common/commonEnumStage';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-hiring-requests-common',
  templateUrl: './hiring-requests-common.component.html',
  styleUrls: ['../../shared/tables-management/table-common-style.scss'],
  providers: [SnackbarPanelComponent, { provide: DateAdapter, useClass: CustomDateAdapter }],
})
export abstract class HiringRequestsCommonComponent extends CommonComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fieldsSettings = fieldsSettings;

  formNew: FormGroup;
  rowsForm: FormArray;
  @Input() headerForm: FormGroup;

  dateFormatter = new DatePipe('en-US');
  dateFormatMmDdYyyy = 'MM/dd/yyyy';

  creatingRole: boolean;
  editingRole: boolean;
  listLoaded = false;
  originalData: Array<openDemands>;
  dataSource = new MatTableDataSource();
  firstLoad = true;

  displayedColumns = [];

  totalElements = 0;
  totalElementsSearched = 0;
  pageSizeOptions = [15, 50, 100, this.totalElementsSearched];
  pageSize = 50;
  pageIndex = 0;
  declare data: {
    platformEnum: Array<PlatformEnum>;
    domainEnum: Array<DomainEnum>;
    locationEnum: Array<EmployeeLocationEnum>;
    typeEnum: Array<CommonEnumDelete>;
    profileEnum: Array<CommonEnumDelete>;
    statusEnum: Array<CommonEnumDelete>;
    additionalLanguageSkillEnum: Array<CommonEnumDelete>;
    hiringPriorityEnum: Array<CommonEnumDelete>;
    projectEnum: Array<ProjectEnum>;
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public snackbar: SnackbarPanelComponent,
    public fb: FormBuilder,
    public userRolesService: UserRolesService,
    public app: AppComponent,
    public dialog: MatDialog,
    public projectService: ProjectService,
    public enumsService: EnumsService,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.listLoaded = false;

    this.setDefaultColumns();
    this.populateHeaderForm();

    this.enumsService.getData().subscribe({
      next: (enums: EnumList) => {
        this.populateDropdowns(enums);
        this.projectService
          .getAllProjects()
          .pipe(distinctUntilChanged())
          .subscribe({
            next: (response: Array<ProjectNameDto>) => {
              this.handleProjects(response);
              this.afterProjectsLoaded();
            },
            error: error => this.handleError(error),
          });
      },
      error: error => this.handleLoadEnumsError(),
    });
  }

  afterProjectsLoaded(): void {
    if (this.isDataLoaded()) {
      const searchParams = this.prepareSearchParams();
      if (this.firstLoad) {
        searchParams.firstLoad = this.firstLoad;
      }
      this.searchOpenDemands(searchParams);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isDataLoaded(): boolean {
    if (!this.data) {
      return false;
    }
    return Object.values(this.data).some(array => Array.isArray(array) && array.length > 0);
  }

  setDefaultColumns() {
    this.displayedColumns = this.fieldsSettings
      .filter((setting: ProjectCard) => !setting.values[0])
      .map((setting: ProjectCard) => setting.id);
  }

  handleProjects(data: Array<ProjectNameDto>) {
    this.data.projectEnum = this.transformProjectsAndOpportunities(data);
  }

  page(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.listLoaded = false;
    this.prepareSearch();
  }

  setFieldSettings(): void {
    const fields: { [key: string]: any[] } = {};
    this.fieldsSettings.forEach((setting: ProjectCard) => {
      fields[setting.id] = setting.values;
    });
    this.headerForm = this.fb.group(fields);
    this.rowsForm = this.fb.array([]);
    this.formNew = this.fb.group({
      rowsData: this.rowsForm,
    });
  }

  populateHeaderForm(): void {
    this.setFieldSettings();
    this.rowsForm.push(this.headerForm);
  }

  transformProjectsAndOpportunities(
    data: Array<ProjectNameDto>
  ): { salesStage: number; id: number; text: string }[] {
    return data.map((project: ProjectNameDto) => ({
      id: project.projectId,
      text: project.projectName,
      salesStage: project.salesStage,
    }));
  }

  searchOpenDemands(searchParameters: HiringRequestSearch) {
    this.projectService.searchOpenDemands(searchParameters).subscribe({
      next: (response: OpenDemandsListDto) => {
        if (this.isDataLoaded()) {
          this.handleOpenDemands(response);
        }
      },
      error: error => this.handleError(error),
    });
  }

  abstract handleOpenDemands(data: OpenDemandsListDto): void;

  prepareSearch(toPageOne?: boolean): void {
    if (toPageOne) {
      //if filtering while on page > 1
      this.paginator.pageIndex = 0;
    }
    let searchParams = this.prepareSearchParams();
    const header = this.rowsForm.controls[0] as FormGroup;
    Object.keys(header.controls).forEach(key => {
      const control = header.controls[key];
      if (!(control.valid && control.value)) {
        return;
      }
      if (control.value instanceof Date) {
        searchParams[key] = this.dateFormatter.transform(control.value, this.dateFormatMmDdYyyy);
        return;
      }
      if (key === 'projectId') {
        searchParams.projectOpportunityName = control.value;
        return;
      }
      searchParams[key] = control.value;
    });

    this.listLoaded = false;
    this.searchOpenDemands(searchParams);
  }

  onDaySelectHead(pickerRef: MatDatepicker<any>): void {
    pickerRef.close();
    this.prepareSearch();
  }

  prepareSearchParams(): HiringRequestSearch {
    return {
      history: false,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
  }

  populateDropdowns(enums: EnumList): void {
    this.data.platformEnum = enums.platform;
    this.data.domainEnum = enums.domain;
    this.data.locationEnum = enums['employee-location'];
    this.data.typeEnum = enums['open-demands-type'];
    this.data.profileEnum = enums['open-demands-profile'];
    this.data.statusEnum = [];
    this.data.additionalLanguageSkillEnum = enums['open-demands-language-skill'];
    this.data.hiringPriorityEnum = enums['hiring-requests-priority'];
  }
}

import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../../app.component';
import { fieldsSettings } from '../../types/confirm-resources-column-settings';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormArray } from '@angular/forms';
import * as validationUtils from '../../shared/validators';
import { DatePipe, Location } from '@angular/common';
import { ProjectService } from '../../services/project.service';
import { CommonComponent } from '../../shared/common/common.component';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { HASHED_EMPLOYEE_STRING } from '../../../constants';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-resources-extendible-hardlocks',
  templateUrl: './resources-extendible-hardlocks.component.html',
  styleUrls: [
    './resources-extendible-hardlocks.component.scss',
    '../confirm-resources.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ResourcesExtendibleHardlocksComponent extends CommonComponent implements OnInit {
  @Input() childMessage: string;

  @Output() messageEvent = new EventEmitter<string>();

  dateFormatter = new DatePipe('en-US');
  dateFormat = 'MM/dd/yyyy';
  displayedHardlocksColumns = [
    'delete',
    'role',
    'platformName',
    'demandFte',
    'startDateDemand',
    'rollOffDateDemand',
    'mySchedulingId',
    'name',
    'supplyFTE',
    'supplyFrom',
    'supplyTo',
    'notes',
  ];
  dateColumns = ['startDate', 'rollOffDate', 'from', 'to'];
  hardlocksSearchParamsOrder = [];
  errors: { [key: string]: String } = {};
  hardlocksDataSource = new MatTableDataSource();
  extendibleHardlocks: any;
  endedHardlocks: any;
  deletedHardlocks: any;
  endedColor = '#1565c0';
  normalColor = '#000000';
  deletedColor = '#ff4081';
  fieldsSettings = fieldsSettings;
  ended: Boolean;
  deleted: Boolean;
  loading: Boolean;
  demandsLoaded: Boolean;
  changedObjects: any;

  constructor(
    public app: AppComponent,
    protected fb: FormBuilder,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    private dialog: MatDialog,
    router: Router,
    authService: AuthService,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit(): void {
    this.errors['supplyFTE'] = '(0, 1>';
    this.errors['supplyFrom'] = 'From < To';
    this.errors['supplyTo'] = 'From < To';
    this.demandsLoaded = false;
    this.form = this.fb.group({
      isDeleted: [false, []],
      isEnded: [false, []],
      role: ['', []],
      platformName: ['', []],
      demandFte: ['', []],
      name: ['', []],
      mySchedulingId: ['', []],
      supplyFTE: ['', []],
      notes: ['', []],
      hardlocksForm: this.fb.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.childMessage.firstChange !== true) {
      this.emptyFormArray(this.form.get('hardlocksForm') as FormArray);
      this.extendibleHardlocks = changes.childMessage.currentValue[1];
      this.deletedHardlocks = changes.childMessage.currentValue[2];
      this.endedHardlocks = changes.childMessage.currentValue[3];
      this.demandsLoaded = true;
      this.hardlocksDataSource.data = this.transformData(
        this.extendibleHardlocks,
        this.normalColor,
        true,
        1
      );
      this.hardlocksDataSource.filterPredicate = this.hardlocksPredicate();
      this.changedObjects = [[], [], []];
      this.form.markAsPristine();
    }
  }

  transformData(demands, color, sort, type) {
    if (sort) {
      demands.sort((a, b) => {
        if (a.firstName === HASHED_EMPLOYEE_STRING && b.firstName !== HASHED_EMPLOYEE_STRING) {
          return 1;
        } else if (
          (a.firstName !== HASHED_EMPLOYEE_STRING && b.firstName === HASHED_EMPLOYEE_STRING) ||
          (a.firstName === HASHED_EMPLOYEE_STRING && b.firstName === HASHED_EMPLOYEE_STRING)
        ) {
          return -1;
        } else {
          return a.lastName.localeCompare(b.lastName);
        }
      });
    }

    demands.forEach(demand => {
      this.addRowsToHardlocksArray(demand.iddemand, demand.idsupply, type, demand.mySchedulingID);
      if (demand.startDateDemand) {
        demand.startDateDemand = this.dateFormatter.transform(
          demand.startDateDemand,
          this.dateFormat
        );
      }
      if (demand.rollOffDateDemand) {
        demand.rollOffDateDemand = this.dateFormatter.transform(
          demand.rollOffDateDemand,
          this.dateFormat
        );
      }
      if (demand.supplyFrom) {
        demand.supplyFrom = this.dateFormatter.transform(demand.supplyFrom, this.dateFormat);
      }
      if (demand.supplyTo) {
        demand.supplyTo = this.dateFormatter.transform(demand.supplyTo, this.dateFormat);
      }
      if (demand.firstName) {
        demand.name = this.getCorrectName(demand.firstName, demand.lastName);
      }
      demand.color = color;
    });
    return demands;
  }

  addRowsToHardlocksArray(hardlocksId, supplyId, type, mySchedulingId) {
    const hardlocksArray: FormArray = this.form.get('hardlocksForm') as FormArray;
    const group = this.prepareSettingsHardlocks(hardlocksId, supplyId, type, mySchedulingId);
    if (type !== 3) {
      group
        .get('supplyFrom')
        .setValidators([validationUtils.valFromToDates(group.get('supplyTo'))]);
      group
        .get('supplyTo')
        .setValidators([validationUtils.valToFromDates(group.get('supplyFrom'))]);
      group
        .get('mySchedulingId')
        .setValidators([validationUtils['valInteger'](), validationUtils['valMax10Digits']()]);
    } else {
      group.get('supplyFrom').disable();
      group.get('supplyTo').disable();
      group.get('mySchedulingId').disable();
    }
    hardlocksArray.push(group);
  }

  prepareSettingsHardlocks(hardlocksId, supplyId, type, mySchedulingId) {
    return this.fb.group({
      demandId: [hardlocksId, []],
      supplyId: [supplyId, []],
      type: [type, []],
      mySchedulingId: [mySchedulingId, []],
      supplyFrom: [null],
      supplyTo: [null],
    });
  }

  getCorrectName(firstName, lastName) {
    if (firstName !== null && firstName !== undefined) {
      if (firstName === lastName || firstName === HASHED_EMPLOYEE_STRING) {
        return firstName;
      } else {
        return lastName + ' ' + firstName;
      }
    }
  }

  // setting filtering for columns
  hardlocksPredicate() {
    return (data: any): boolean => {
      if (
        (data.role &&
          data.role
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('role').value.toString().trim().toLowerCase())) ||
        (data.platformName &&
          data.platformName
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('platformName').value.toString().trim().toLowerCase())) ||
        data.demandFte
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(this.form.get('demandFte').value.toString().trim().toLowerCase()) ||
        (data.name &&
          data.name
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('name').value.toString().trim().toLowerCase())) ||
        (data.mySchedulingId &&
          data.mySchedulingId
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('mySchedulingId').value.toString().trim().toLowerCase())) ||
        data.supplyFTE
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(this.form.get('supplyFTE').value.toString().trim().toLowerCase()) ||
        (data.notes &&
          data.notes
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.form.get('notes').value.toString().trim().toLowerCase()))
      ) {
        return false;
      } else {
        return true;
      }
    };
  }

  isChecked() {
    let data;
    const form = this.form.get('hardlocksForm') as FormArray;
    // if form contains other data than extendible hardlocks, delete them
    if (form.length > this.extendibleHardlocks.length) {
      for (let i = form.length - 1; i >= this.extendibleHardlocks.length; i--) {
        form.removeAt(i);
      }
    }
    this.resetHardlocksFormArrays();
    this.hardlocksDataSource.data = this.extendibleHardlocks;
    if (this.ended) {
      data = this.extendibleHardlocks.concat(
        this.transformData(this.endedHardlocks, this.endedColor, true, 2)
      );
    } else {
      data = this.extendibleHardlocks;
      this.form.markAsPristine();
    }
    if (this.deleted) {
      const tmp = this.transformData(this.deletedHardlocks, this.deletedColor, true, 3);
      data = data.concat(tmp);
    } else {
      this.form.markAsPristine();
    }
    this.hardlocksDataSource.data = data;
  }

  resetHardlocksFormArrays() {
    (this.form.get('hardlocksForm') as FormArray).controls.forEach(element => {
      this.resetHardlocksFormArray(element);
      element.markAsPristine();
    });
  }

  resetHardlocksFormArray(element) {
    element.get('supplyFrom').setValue(null);
    element.get('supplyTo').setValue(null);
  }

  save() {
    this.changedObjects = [[], [], []];
    if (this.form.valid) {
      if (this.form.dirty) {
        (this.form.get('hardlocksForm') as FormArray).controls.forEach(element => {
          if (element.dirty) {
            if (element.get('type').value === 1) {
              this.changedObjects[0].push(
                this.transformDatesForBackend(
                  this.getUpdateObjectHardlocks(this.extendibleHardlocks, element)
                )
              );
            }
            if (element.get('type').value === 2) {
              this.changedObjects[1].push(
                this.transformDatesForBackend(
                  this.getUpdateObjectHardlocks(this.endedHardlocks, element)
                )
              );
            }
            if (element.get('type').value === 3) {
              this.changedObjects[2].push(
                this.transformDatesForBackend(
                  this.getUpdateObjectHardlocks(this.deletedHardlocks, element)
                )
              );
            }
          }
        });
      }
      return true;
    } else {
      return false;
    }
  }

  transformDatesForBackend(obj) {
    obj.startDateDemand = this.dateFormatter.transform(obj.startDateDemand, 'yyyy-MM-ddT00:mm:ss');
    obj.rollOffDateDemand = this.dateFormatter.transform(
      obj.rollOffDateDemand,
      'yyyy-MM-ddT00:mm:ss'
    );
    obj.supplyFrom = this.dateFormatter.transform(obj.supplyFrom, 'yyyy-MM-ddT00:mm:ss');
    obj.supplyTo = this.dateFormatter.transform(obj.supplyTo, 'yyyy-MM-ddT00:mm:ss');
    return obj;
  }

  getUpdateObjectHardlocks(values, element) {
    const tmp = values.find(
      x =>
        x.iddemand.toString() === element.get('demandId').value.toString() &&
        x.idsupply.toString() === element.get('supplyId').value.toString()
    );
    const obj = Object.assign({}, tmp);
    obj.mySchedulingID = this.setValueForUpdateObject(
      obj.mySchedulingID,
      element.get('mySchedulingId').value,
      true
    );
    obj.supplyFrom = this.setValueForUpdateObject(
      obj.startDateDemand,
      element.get('supplyFrom').value
    );
    obj.supplyTo = this.setValueForUpdateObject(
      obj.rollOffDateDemand,
      element.get('supplyTo').value
    );
    return obj;
  }

  setValueForUpdateObject(object, element, mySchedulingId?) {
    let tmp;
    if (!element && !mySchedulingId) {
      tmp = object;
    } else {
      tmp = element;
    }
    return tmp;
  }

  // used to remember order of filter params
  addParamToHardlocksOrder(id: string) {
    // get index of search param in order of searched params
    const index = this.hardlocksSearchParamsOrder.indexOf(id);
    // if length of values for specific param is bigger than 0, it  is valid value
    if (this.form.get(id).value.trim().length > 0) {
      // if search param is already in order
      if (index !== -1) {
        // delete old position of param
        this.deleteParamFromOrder(this.hardlocksSearchParamsOrder, index);
        // add it to the end
        this.hardlocksSearchParamsOrder.push(id);
        // if param is not in order, add it to the end
      } else {
        this.hardlocksSearchParamsOrder.push(id);
      }
      // delete param from order if his values is not valid, its length is 0
    } else {
      this.deleteParamFromOrder(this.hardlocksSearchParamsOrder, index);
    }
  }

  deleteParamFromOrder(order: string[], index) {
    if (index !== -1) {
      order.splice(index, 1);
    }
  }

  applyFiltersHardlocks() {
    this.resetHardlocksFormArrays();
    if (!this.hardlocksSearchParamsOrder.length) {
      this.hardlocksDataSource.filter = '';
    } else {
      this.hardlocksSearchParamsOrder.forEach((key: string) => {
        this.hardlocksDataSource.filter = this.form.get(key).value;
      });
    }
  }

  deleteHardlock(row) {
    this.editDeleteDialog(row);
  }

  editWarningDialog() {
    const data: DialogData = {
      title: 'Warning',
      content: 'Unfinished work on Confirm Resources page! Do you want to save changes? ',
      action1: 'Discard',
      action2: 'Save changes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (!result) {
        this.save();
      }
    });
    return afterClosed;
  }

  editDeleteDialog(row) {
    const data: DialogData = {
      title: 'Warning',
      content:
        'It isn’t possible to restore deleted hardlock. Are you sure you want to delete this hardlock?',
      action1: 'No',
      action2: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    const afterClosed = dialogRef.afterClosed();
    afterClosed.subscribe(result => {
      if (!result) {
        this.projectService.deleteHardlock(row.idsupply, null).subscribe(
          response => {
            this.messageEvent.emit('search');
          },
          error => this.handleError(error)
        );
      }
    });
    return afterClosed;
  }

  emptyFormArray(array): void {
    while (array.length > 0) {
      array.removeAt(0);
    }
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';

// Instead of importing all rxjs modules import just what we need
import { UserRolesService } from '../services/user-roles/user-roles.service';
import { CanDeactivateGuard } from '../services/can-deactivate-guard.service';

import { AppRoutingModule } from '../app-routing.module';

import { SnackbarPanelComponent } from '../shared/snackbar-panel/snackbar-panel.component';
import { FteFormat } from '../pipes/fte-format.pipe';
import { DateFormat } from '../pipes/date-format.pipe';
import { TimeFormat } from '../pipes/time-format.pipe';
import { DatePipe } from '@angular/common';
import { FormatCardBool } from '../pipes/format-card-bool.pipe';
import { FormatCardEnum } from '../pipes/format-card-enum.pipe';
import { FilterSelectedEnums } from '../pipes/filter-selected-enums.pipe';
import { FormatCardByType } from '../pipes/format-card-by-type.pipe';
import { EmpType } from '../pipes/employee-type.pipe';
import { EmpIdType } from '../pipes/employee-id-type.pipe';
import { BuildingFloorLocationOptGroup } from '../pipes/building-floor-location-pipe';
import { dialogDefaults } from '../settings/dialog-setting';
import { tooltipDefaults } from '../settings/tooltip-setting';
import { autocompleteDefaults } from '../settings/autocomplete-setting';
import { globalRippleConfig } from '../settings/global-setting';
import { toolbarComponent } from './toolbar/toolbar.component';
import { CustomSnackbarComponent } from './custom-snackbar-panel/custom-snackbar-panel.component';

@NgModule({
  declarations: [
    toolbarComponent,
    FormatCardByType,
    FormatCardBool,
    FormatCardEnum,
    FilterSelectedEnums,
    FteFormat,
    DateFormat,
    TimeFormat,
    EmpType,
    EmpIdType,
    BuildingFloorLocationOptGroup,
    SnackbarPanelComponent,
    CustomSnackbarComponent,
  ],
  providers: [
    UserRolesService,
    DatePipe,
    CanDeactivateGuard,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: dialogDefaults },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: autocompleteDefaults },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatNativeDateModule,
    MatCardModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    MatChipsModule,
    MatSortModule,
    MatDialogModule,
  ],
  exports: [
    toolbarComponent,
    FormatCardByType,
    FormatCardBool,
    FormatCardEnum,
    FilterSelectedEnums,
    FteFormat,
    DateFormat,
    TimeFormat,
    EmpType,
    EmpIdType,
    BuildingFloorLocationOptGroup,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatNativeDateModule,
    MatCardModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    MatChipsModule,
    MatSortModule,
    MatDialogModule,
  ],
})
export class CommonModule {}

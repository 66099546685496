import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnumsService } from 'app/services/enumerations/enums.service';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { HiringRequestDetails } from 'app/types/hiring-request-details';
import { fieldsSettings } from 'app/types/new-hiring-request';
import { errorMessages } from '../../../types/input-errors';

@Component({
  selector: 'app-hiring-request-common-fields',
  templateUrl: './hiring-request-common-fields.component.html',
  styleUrls: ['./hiring-request-common-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class HiringRequestCommonFieldsComponent implements OnInit {
  @Input() requestDetails: HiringRequestDetails;
  @Input() projectsAndOpportunities: [];

  fieldsSettings = fieldsSettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  enums: any;
  data = {};

  constructor(
    protected dialog: MatDialog,
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent
  ) {}

  ngOnInit(): void {
    this.enumsService.getData().subscribe({
      next: enums => this.populateDropdowns(enums),
      error: error => this.handleLoadEnumsError(error),
    });
  }

  protected onFormChanges(changes, part) {
    Object.keys(changes).forEach(change => {
      const control = part.controls[change];
      this.requestDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleLoadEnumsError(error) {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  protected populateDropdowns(enums) {
    this.enums = enums;
    this.data['projectNameEnum'] = this.projectsAndOpportunities;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectsAndOpportunities !== undefined) {
      this.projectsAndOpportunities = changes.projectsAndOpportunities.currentValue;
      this.data['projectNameEnum'] = this.projectsAndOpportunities;
    }
  }
}

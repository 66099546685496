import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { CommonDetailsComponent } from '../common-details/common-details.component';
import { OpportunityCardComponent } from 'app/project/cards/opportunity-card/opportunity-card.component';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: [
    './opportunity-details.component.scss',
    '../project-details/project-details.component.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class OpportunityDetailsComponent extends CommonDetailsComponent implements OnInit {
  @ViewChildren('card') declare cardComponents: QueryList<OpportunityCardComponent>;

  urlList = '../opportunity-list';
  urlDetails = '/opportunity-details';
  errorMessage = 'Invalid Opportunity ID route.';

  ngOnInit(): void {
    super.ngOnInit();
  }

  upgradeToProject() {
    if (this.projectDetails) {
      // for errors in console
      if (this.projectDetails.statusId < 5) {
        this.router.navigate([
          'project-details/add-new-project',
          this.projectDetails.projectId,
          'opportunity',
          'project',
        ]);
      } else {
        this.snackbar.error('Any changes to closed / cancelled projects are forbidden.');
      }
    }
  }

  protected populateDropdowns(enums) {
    this.enums = JSON.parse(JSON.stringify(enums));
    this.enums['probability'] = this.enums['probability'].filter(
      value => value.id > 0 && value.id < 100
    );

    this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });
  }
}

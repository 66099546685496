import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectDetails } from '../../types/project-details';
import { EnumsService } from '../../services/enumerations/enums.service';
import { Enumeration } from '../../types/enumeration';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import * as validationUtils from '../../shared/validators';
import { errorMessages } from '../../types/input-errors';

@Component({
  selector: 'app-project-card-item',
  templateUrl: './project-card-item.component.html',
  styleUrls: ['./project-card-item.component.scss', '../../shared/card-item.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectCardItemComponent implements OnInit {
  isEnum: boolean;
  enums: Map<string, Enumeration>;
  enumeration: Array<any>;
  firstValue: any;
  isChanged: boolean;

  errorMessages = errorMessages;

  error: string;

  @Input() property: string;

  @Input() modelValue: any;

  @Input() disabled: boolean;

  @Input() editing: boolean;

  @Input() id: string;

  @Input() part: string;

  @Input() validation: string[];

  @Input() label: string;

  @Input() type: string;

  @Input() projectDetails: ProjectDetails;

  @Input() control: FormGroup;

  @Output() modelChange = new EventEmitter();

  @Input('model')
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);

    if (this.modelValue !== this.firstValue) {
      this.isChanged = true;
    } else {
      this.isChanged = false;
    }

    // this.updateErrorText();
  }

  constructor(
    public enumsService: EnumsService,
    private snackbar: SnackbarPanelComponent
  ) {}

  updateErrorText() {
    if (this.control.controls[this.id]) {
      if (this.control.controls[this.id] && this.control.controls[this.id].errors) {
        this.error = this.errorMessages[Object.keys(this.control.controls[this.id].errors)[0]];
      } else {
        this.error = null;
      }
    }
  }

  ngOnInit() {
    if (this.projectDetails[this.part]) {
      this.control.controls[this.id]?.setValue(this.projectDetails[this.part][this.id]);
    } else {
      this.control.controls[this.id]?.setValue(this.projectDetails[this.id]);
    }
    this.control.controls[this.id]?.valueChanges.subscribe(val => {
      this.updateErrorText();
      this.modelChange.emit(val);
    });

    this.enumsService.getData().subscribe(
      data => {
        this.enums = data;
        this.enums['platform'] = data['platform'].filter(val => val.deleted !== true);
        this.isEnum = false;
        this.firstValue = this.modelValue;
        this.isChanged = false;

        if (this.disabled) {
          this.control.controls[this.id].disable({ onlySelf: true, emitEvent: false });
        }

        const validators = [];
        this.validation.forEach(validation => {
          validators.push(validationUtils[validation]);
        });

        if (this.type !== 'read') {
          if (
            (this.id === 'dcResponsibleRole' ||
              this.id === 'end2End' ||
              this.id === 'operationalRespId') &&
            this.projectDetails.projectSchedule.commitment === 100
          ) {
            validators.push(validationUtils['valDcResponsibleRole'](this.projectDetails));
          }
          try {
            this.control.controls[this.id].setValidators(validators);
          } catch {
            console.log('pass');
          }
        }

        let index = this.enums['dcResponsibleRole'].findIndex(
          obj => obj.id === 1 && obj.text === 'Opportunity Lead'
        );
        if (index !== -1) {
          this.enums['dcResponsibleRole'].splice(index, 1);
        }

        if (this.id === 'selectedSolutionFactoryValues' && this.modelValue) {
          const sharedServicesIds = this.modelValue.slice(0, -1).split(';');
          let domainsTexts = '';

          data['domain'].forEach(element => {
            if (sharedServicesIds.indexOf(element.id.toString()) >= 0) {
              domainsTexts += element.text + ', ';
            }
          });

          this.modelValue = domainsTexts.slice(0, -2);
        }

        // if (this.id === 'contactEId' && this.projectDetails.client.contactDeleted) {
        //   this.modelValue = null;
        // }

        if (this.id === 'sourcingLocationId') {
          const values = [];
          this.projectDetails.projectInfo.sourcingLocation.map(v => {
            values.push(v);
          });
          this.modelValue = values;
          this.modelChange.emit(values);
        }
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    if (this.property === 'offeringResponsibleId') {
    }
  }

  setDcRespRoleValidationOnCommitment(value: number, fieldId: any) {
    // If its a project then add validation otherwise remove it
    if (value === 100) {
      this.control.controls[fieldId].setValidators(
        validationUtils['valDcResponsibleRole'](this.projectDetails)
      );
      this.control.controls[fieldId].markAsTouched();
      this.control.controls[fieldId].updateValueAndValidity();
    } else {
      this.control.controls[this.id].clearValidators();
      this.control.controls[this.id].markAsUntouched();
    }
  }

  setDMSmyWizardFormatValidation(fieldId: any) {
    this.control.controls[fieldId].setValidators(validationUtils['valDMSmyWizardId']());
    this.control.controls[fieldId].markAsTouched();
    this.control.controls[fieldId].updateValueAndValidity();
  }

  clearValidatorByField(fieldId: any) {
    this.control.controls[fieldId].clearValidators();
    this.control.controls[fieldId].markAsUntouched();
  }

  changeOfferingResponsible(offeringNameIds: number[]) {
    let offeringResponsibles = '';
    offeringNameIds.forEach(id => {
      this.enumeration = this.enums['offering-name-domain-id'].filter(value => value.id === id);
      if (this.enumeration.length > 0) {
        this.enumeration.map(item => {
          if (item.id === id) {
            if (offeringResponsibles === '') {
              offeringResponsibles += item.firstName + ' ' + item.lastName;
            } else {
              offeringResponsibles += '; ' + item.firstName + ' ' + item.lastName;
            }
          }
        });
      }
    });
    this.control.get('offeringResp').setValue(offeringResponsibles);
  }

  /*changeSharedServiceResourcePool(workCategory: number) {
    if (workCategory === 1 || workCategory === 5 || workCategory === 3 || workCategory === 7) {
      this.control.get(this.id).enable();
    } else {
      this.control.get(this.id).disable();
    }
  }*/

  getEnumsService() {
    return this.enumsService;
  }

  changeFirstValue() {
    this.firstValue = this.modelValue;
  }

  handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { ReportService } from '../../services/report.service';
import { DialogData } from '../../types/dialog-data';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { AppComponent } from '../../app.component';
import { EnumsService } from '../../services/enumerations/enums.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-project-summary-report',
  templateUrl: './project-summary-report.component.html',
  styleUrls: ['./project-summary-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectSummaryReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exporting = false;
  exportForm: FormGroup;
  title: string = 'Project/Opportunity Summary Report';

  fields = {
    type: 0,
    inactive: false,
    location: '',
  };
  data = {};

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private reportService: ReportService,
    private enumsService: EnumsService,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit() {
    this.snackbar.close();
    this.exportForm = this.fb.group({
      type: ['', []],
      inactive: ['', []],
      location: ['', []],
    });

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  protected populateDropdowns(enums) {
    const typeEnum: { id: number; stage: number; text: string; value: number }[] = [
      { id: 1, stage: 1, text: 'All', value: 0 },
      { id: 2, stage: 2, text: 'Projects', value: 1 },
      { id: 3, stage: 3, text: 'Leads + Opportunities', value: 2 },
    ];

    const all = { id: 0, stage: 0, text: 'All', value: 0, deleted: false };

    this.data['typeEnum'] = typeEnum;
    this.data['employeeLocation'] = enums['employee-location'];

    if (this.data['employeeLocation'].findIndex((item: { id: number }) => item.id === all.id) < 0) {
      this.data['employeeLocation'].unshift(all);
    }

    this.exportForm.get('type').setValue(1);
    this.exportForm.get('inactive').setValue(false);
    this.exportForm.get('location').setValue(0);
  }

  exportProjectSummaryReport() {
    this.exporting = true;
    const type = this.exportForm.get('type').value;
    const inactive = this.exportForm.get('inactive').value;
    const location = this.exportForm.get('location').value;

    this.reportService
      .exportProjectSummaryReport(type, inactive, location)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result: any): void {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err: any): void {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  protected handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

import { DialogData } from '../types/dialog-data';
import { DialogComponent } from '../shared/dialog/dialog.component';

export class Common {
  static formatDate(inputString: any): Date {
    const arr = inputString.split(/[- :T]/);
    return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], 0);
  }

  static timezoneFix(inputDate: any): string {
    const timezoneOffset = new Date(inputDate).getTimezoneOffset() * 60000;
    const inputDateString = new Date(new Date(inputDate).getTime() - timezoneOffset).toISOString();

    return inputDateString.substr(0, 11) + '00' + inputDateString.substr(13);
  }

  static rateTypeWarningDialog(dialog, data) {
    const dialogRef = dialog.open(DialogComponent, { data });
    return dialogRef.afterClosed();
  }

  static editWarningDialog(dialog) {
    const data: DialogData = {
      title: 'Warning',
      content: 'Do you really want to exit this page?',
      action1: 'Yes',
      action2: 'No',
    };
    const dialogRef = dialog.open(DialogComponent, { data });
    return dialogRef.afterClosed();
  }

  static deleteDialog(dialog) {
    const data: DialogData = {
      title: 'Warning',
      content: 'Do you really want to do this action?',
      action1: 'Yes',
      action2: 'No',
    };
    const dialogRef = dialog.open(DialogComponent, { data });
    return dialogRef.afterClosed();
  }
}

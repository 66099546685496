// report-toolbar.component.ts
import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { AppComponent } from '../../app.component';
import { DialogData } from '../../types/dialog-data';
import { DialogComponent } from '../dialog/dialog.component';
import { VersionDataService } from '../../version-data.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class toolbarComponent {
  /**
   * adds a left hand title.
   */
  @Input() title: string;

  @Input() middleSection: TemplateRef<any>;

  @Input() insertSpacerAfterMiddle: boolean = false;

  /**
   * adds action on right button.
   */
  @Output() rightButton: EventEmitter<void> = new EventEmitter<void>();

  @Input() isRoundButton: boolean = false;

  @Input() isRightButtonDisabled: boolean = false;

  @Input() isRightButtonRendered: boolean = true;
  /**
   * adds title to right button
   */
  @Input() rightButtonTitle: string = 'Export';

  @Input() rightButtonIcon: string;

  /**
   * adds action on left button, default is opening side navigation
   */
  @Output() leftButton: EventEmitter<void> = new EventEmitter<void>();

  /**
   * adds action on left button, default is navigation triple bar, if its changed, leftButton action is also required
   */
  @Input() leftButtonIcon: string = 'menu';

  constructor(
    private dialog: MatDialog,
    private versionDataService: VersionDataService,
    public app: AppComponent
  ) {
    if (!this.isRoundButton && this.rightButtonIcon === undefined)
      this.rightButtonIcon = 'assignment_returned';
  }

  leftButtonAction() {
    this.leftButtonIcon !== 'menu' || this.leftButton.observed
      ? this.leftButton.emit()
      : this.app.sidenav.toggle();
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

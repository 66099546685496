import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { UserRolesService } from 'app/services/user-roles/user-roles.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from 'app/services/user-roles/user';
import { EmployeeDetailsService } from 'app/employee/employee-details/employee-details.service';
import { EmployeeDetails } from 'app/types/employee-details';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BenchManagementTabs } from 'app/types/bench-management';
import { Router } from '@angular/router';
import { BenchTrackingComponent } from './bench-tracking/bench-tracking.component';
import { VersionDataService } from '../version-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../types/dialog-data';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Component({
  selector: 'app-bench-management',
  templateUrl: './bench-management.component.html',
  styleUrls: ['./bench-management.component.scss'],
})
export class BenchManagementComponent implements OnInit {
  @ViewChild('tracking') trackingComponent: BenchTrackingComponent;

  readonly tabs = BenchManagementTabs;
  selectedTab: BenchManagementTabs = BenchManagementTabs.TRACKING;

  user: User;
  employeeDetails: EmployeeDetails;
  hasEnterpriseDomainId: boolean = false;
  isPlatformLead: boolean = false;

  exporting: boolean = false;

  constructor(
    private userRolesService: UserRolesService,
    private versionDataService: VersionDataService,
    private employeeDetailsService: EmployeeDetailsService,
    private dialog: MatDialog,
    private router: Router,
    public app: AppComponent
  ) {}

  ngOnInit() {
    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.user = new User(data);
        this.getEmployeeDetails(this.user.eid);
      });
  }

  getEmployeeDetails(enterpriseDomainId: string) {
    this.employeeDetailsService
      .getEmployeeDetails(enterpriseDomainId)
      .pipe(distinctUntilChanged())
      .subscribe(
        employeeDetails => {
          this.handleEmployeeDetails(employeeDetails);
        },
        error => this.handleError(error, error['status'])
      );
  }

  handleEmployeeDetails(employeeDetails: EmployeeDetails) {
    this.employeeDetails = employeeDetails;
    if (employeeDetails.enterpriseDomainId) {
      this.hasEnterpriseDomainId = true;
    }
  }

  handleError(error: Response | string, errorCode: number) {
    const errorText = error instanceof Response ? error.statusText : error;
    const err: Error = new Error(errorText);
    console.log(err);
    this.employeeDetails = null;
  }

  exportBenchTrackingList() {
    this.trackingComponent.exportBenchTrackingList();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
  }

  onPlatformLead(platformLead: boolean) {
    this.isPlatformLead = platformLead;
  }

  onExport(exporting: boolean) {
    this.exporting = exporting;
  }

  addTracking() {
    this.router.navigate([`${this.router.url}/add-new-tracking`]);
  }

  addTask() {
    this.router.navigate([`${this.router.url}/add-new-task`]);
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

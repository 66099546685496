import { distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  AfterViewInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonComponent } from '../../../shared/common/common.component';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../../services/project.service';
import { fieldsSettings } from '../../../types/hardlock-request';
import { RequestInfo } from '../../../types/request-info';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { User } from '../../../services/user-roles/user';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatChipOption, MatChipSelectionChange } from '@angular/material/chips';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-project-hardlocks',
  templateUrl: './project-hardlocks.component.html',
  styleUrls: ['./project-hardlocks.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectHardlocksComponent
  extends CommonComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChildren(MatChipOption) chipOptions!: QueryList<MatChipOption>;
  @ViewChild('hardlockExpansionPanel', { static: false }) hardlockExpansionPanel: MatExpansionPanel;
  @ViewChild('hardlocksExpansionPanel', { static: false })
  hardlocksExpansionPanel: MatExpansionPanel;
  @ViewChild('hardlockRequestHistoryExpansionPanel', { static: false })
  hardlockRequestHistoryPanel: MatExpansionPanel;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() softlock: any;
  @Output() addToSoftlocksNotify = new EventEmitter();

  fieldsSettings = fieldsSettings;
  requestInfo: RequestInfo;

  softlocked: any[] = [];
  softlocker: any;

  hardlockRequests: any[] = [];
  hardlock: any;

  hardlockEnable = true;
  isHardlocked = false;
  disableHardlockRequestData = false;
  emailContent: string;
  softlockedLoaded = false;
  hardlockRequestsLoaded = false;
  hardlocksRequestsHistoryDataLoaded = false;
  id: number;

  displayedHistoryColumns = [
    'requestBy',
    'requestDate',
    'requested',
    'responseBy',
    'responseDate',
    'status',
  ];
  hadlockColumnNames = [
    'Requested By',
    'Requested Date',
    'Hardlock requested for',
    'Response by',
    'Response date',
    'Status',
  ];
  dataHistorySource = new MatTableDataSource();

  editingRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;

  constructor(
    protected userRolesService: UserRolesService,
    protected projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    protected fb: FormBuilder,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    protected enumsService: EnumsService,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
      });

    this.enumsService.getData().subscribe({
      next: enums => this.populateData(enums),
      error: error => this.handleLoadEnumsError(),
    });

    this.projectService.getIsHardlocked().subscribe(value => (this.isHardlocked = value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.softlock.firstChange) {
      this.getSoftlocked(this.id);
    }
  }

  ngAfterViewInit() {
    this.dataHistorySource.sort = this.sort;
  }

  populateData(enums) {
    this.data['hardlockStateEnum'] = enums['hardlock-request-status'];
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.getHardlockRequests(this.id);
        this.getSoftlocked(this.id);
        this.getHardlocksRequestsHistory(this.id);
      }
    });
  }

  isHardlock(): boolean {
    return this.hardlockRequests.length === 0;
  }

  onSelectionChange(event: MatChipSelectionChange, softlock) {
    if (event.selected) {
      this.softlocker = softlock;
      softlock.selected = true;
      this.projectService.getHardlockEmail(softlock.supplyId).subscribe(data => {
        this.emailContent = data.toString();
      });
    } else {
      this.softlocker = undefined;
      this.hardlockEnable = false;
      this.cancel();
    }
  }

  onSelectionChangeHard(event: MatChipSelectionChange, hardlock) {
    if (event.selected) {
      this.hardlock = hardlock;
      this.emailContent = 'Email content...';
    } else {
      this.hardlock = undefined;
    }
  }

  requestHardlock() {
    const update = {
      cc: this.form.get('cc').value,
    };
    // remove softlocked employee from softlocks
    this.softlocked = this.softlocked.filter(obj => obj !== this.softlocker);
    this.projectService.requestHardlock(this.id, this.softlocker.supplyId, update).subscribe({
      next: data => this.handleRequestHardlockRequests(data),
      error: error => this.handleError(error),
    });
  }

  handleRequestHardlockRequests(data) {
    this.getHardlockRequests(this.id);
    this.cancel();
    this.snackbar.success('Hardlock requested');
  }

  cancelHardlock() {
    this.hardlockRequests.splice(this.hardlock, 1);
    this.projectService.cancelHardlock(this.id, this.hardlock.id).subscribe({
      next: data => this.handleCancelHardlockRequests(),
      error: error => this.handleError(error),
    });
    this.getSoftlocked(this.id);
    this.cancel();
    if (this.hardlockRequests.length === 0) {
      this.hardlockEnable = true;
    }
  }

  handleCancelHardlockRequests() {
    this.getHardlockRequests(this.id);
    this.cancel();
    this.snackbar.success('Hardlock canceled');
  }

  getHardlocksRequestsHistory(demandId) {
    this.projectService.getHardlockRequestsHistory(demandId).subscribe({
      next: data => {
        const states = this.data['hardlockStateEnum'];
        data.forEach(element => {
          const statusId = element.status;
          states.forEach(state => {
            if (state.id === statusId) {
              element.status = state.text;
            }
          });
        });
        this.dataHistorySource.data = data;
        this.hardlocksRequestsHistoryDataLoaded = true;
      },
      error: error => this.handleError(error),
    });
  }

  getHardlockRequests(demandId) {
    this.hardlockRequestsLoaded = false;

    this.projectService.getHardlockRequests(demandId).subscribe({
      next: data => {
        this.hardlockRequests = data;
        this.hardlockRequestsLoaded = true;
        if (data.length === 1) {
          this.disableHardlockRequestData = false;
          this.setRequestInfo(data[0]);
        } else {
          this.disableHardlockRequestData = true;
        }
      },
      error: error => this.handleError(error),
    });
  }

  getSoftlocked(id) {
    this.softlockedLoaded = false;
    this.projectService.getDemandSoftlocked(id).subscribe({
      next: data => this.handleSoftlocked(data),
      error: error => this.handleError(error),
    });
  }

  setRequestInfo(info) {
    this.requestInfo = {
      requested: info['requested'],
      requestedBy: info['requestBy'],
      requestedDate: this.datepipe.transform(info['requestDate'], 'yyyy-MM-dd HH:MM:SS'),
    } as RequestInfo;
  }

  handleSoftlocked(data) {
    const hlrIds = this.hardlockRequests.map(e => e.idSoftlock);
    // Used this filter instead of contains due to IE
    data = data.filter(obj => !hlrIds.some(el => el === obj.supplyId));
    this.softlocked = data;
    this.softlockedLoaded = true;
  }

  removeSoftlock(softlock) {
    this.projectService.moveFromSoftlocked(softlock.supplyId).subscribe({
      next: demandDetails => {
        this.getSoftlocked(this.id);
        this.addToSoftlocksNotify.emit(new Date());
        this.snackbar.success('Employee softlock removed');
      },
      error: error => this.handleError(error),
    });
  }

  disableRequest(): boolean {
    if (this.chipOptions === undefined) return true;
    return !this.chipOptions.some(e => e.selected === true);
  }

  disableSoftlocks(): boolean {
    return this.hardlockRequests.length !== 0;
  }
  cancel() {
    this.form.reset();
    this.emailContent = '';
    this.chipOptions.forEach(chipOption => {
      chipOption.deselect();
    });
  }
}

import { Component, OnInit, ViewChild, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectService } from '../../services/project.service';
import { DomSanitizer } from '@angular/platform-browser';
import { fieldsSettings } from '../../types/new-attachment';
import { CommonComponent } from '../../shared/common/common.component';
import { Common } from '../../shared/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss'],
  providers: [SnackbarPanelComponent],
})
@Injectable()
export class AttachmentDialogComponent extends CommonComponent implements OnInit {
  fieldsSettings = fieldsSettings;

  @ViewChild('fileInput', { static: false }) fileInput;

  file: File;
  fileName: String;

  projectId: number;
  eid: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    protected fb: FormBuilder,
    private projectService: ProjectService,
    protected snackbar: SnackbarPanelComponent,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.projectId = this.dialogData.projectId;
    this.eid = this.dialogData.uploadedBy;

    this.form.get('dcso').valueChanges.subscribe(value => this.onDcsoChange(value));

    this.form.get('credentials').valueChanges.subscribe(value => this.onCredentialsChange(value));
  }

  onDcsoChange(dcso: boolean) {
    if (dcso) {
      this.form.get('validity').enable();
      if (this.form.get('credentials').value) {
        this.form.get('credentials').setValue(false);
      }
      // this.form.get('credentialsUpdate').setValue(null);
      // this.form.get('credentialsUpdate').disable();
    } else {
      this.form.get('validity').setValue(null);
      this.form.get('validity').disable();
    }
  }

  onCredentialsChange(credentials: boolean) {
    if (credentials) {
      this.form.get('credentialsUpdate').enable();
      if (this.form.get('dcso').value) {
        this.form.get('dcso').setValue(false);
      }
      // this.form.get('validity').setValue(null);
      // this.form.get('validity').disable();
    } else {
      this.form.get('credentialsUpdate').setValue(null);
      this.form.get('credentialsUpdate').disable();
    }
  }

  chooseAttachment() {
    this.fileInput.nativeElement.click();
  }

  loadAttachment(e) {
    this.file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!this.file) {
      return;
    }

    const reader = new FileReader();
    this.fileName = this.file.name;
    reader.readAsDataURL(this.file);
  }

  create(create: boolean) {
    if (create) {
      const data = {
        uploadedBy: this.eid,
        projectId: this.projectId,
      };

      Object.keys(this.form.controls).forEach(controlerName => {
        const controler = this.form.get(controlerName);
        controler.markAsTouched();
        controler.markAsDirty();
        controler.updateValueAndValidity();

        if (controler.value instanceof Date) {
          data[controlerName] = Common.timezoneFix(this.form.get(controlerName).value);
        } else {
          data[controlerName] = this.form.get(controlerName).value;
        }
      });

      if (this.form.valid) {
        this.projectService.addAttachment(this.file, data).subscribe(
          result => this.handleAddAttachment(result),
          error => this.handleError(error)
        );
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  handleAddAttachment(result) {
    this.snackbar.success('Attachment has been saved');
    this.dialogRef.close(true);
  }
}

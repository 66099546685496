import { BehaviorSubject, Observable, of, throwError as _throw } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorFilter implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloned;
    if (!request.url.includes('refresh')) {
      cloned = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('id_token')),
      });
    } else {
      cloned = request;
    }
    return next.handle(cloned).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          return this.handle401Error(cloned, next);
        }
        return _throw(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        switchMap(token => {
          if (token) {
            let req;
            this.authenticationService.setSession(token['token']);
            this.tokenSubject.next(token['token']);
            req = request.clone({
              headers: request.headers.set(
                'Authorization',
                'Bearer ' + localStorage.getItem('id_token')
              ),
            });
            return next.handle(req);
          }
          return of(<any>this.authenticationService.logout());
        }),
        catchError(err => {
          this.authenticationService.logout();
          window.location.href = './login-component';
          return _throw(err.error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(request);
        })
      );
    }
  }
}

export const HttpErrorFilterProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorFilter,
  multi: true,
};

import { NewHiringRequest } from './new-form';

export const fieldsSettings: NewHiringRequest = {
  mandatory: [
    {
      cardType: 'select',
      data: 'platformEnum',
      id: 'platformId',
      label: 'Platform',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'domainEnum',
      id: 'domainId',
      label: 'Domain',
      validation: ['valRequired'],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'date',
      id: 'targetDate',
      label: 'Target Date',
      validation: ['valDate', 'valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'hiringPriorityEnum',
      id: 'priorityId',
      label: 'Hiring Priority',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'profileEnum',
      id: 'profileId',
      label: 'Profile',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'typeEnum',
      id: 'typeId',
      label: 'Type',
      validation: ['valRequired'],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'select',
      data: 'locationEnum',
      id: 'locationId',
      label: 'Location',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'textarea',
      id: 'mainSkill',
      label: 'Main Skill',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'autosearch',
      data: 'projectNameEnum',
      id: 'projectId',
      label: 'Project / Opportunity Name',
      validation: ['valRequired'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'mySchedulingId',
      label: 'myScheduling ID ',
      validation: [],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'select',
      data: 'travelPreferencesEnum',
      id: 'travelPreferences',
      label: 'Travel Preferences',
      validation: [],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'input',
      id: 'workLocation',
      label: 'Work Location',
      validation: [],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'input',
      id: 'subcoContractDuration',
      label: 'Subco Contract Duration (months)',
      validation: [],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'input',
      id: 'yearsOfExperience',
      label: 'Years of Experience',
      validation: [],
      values: ['', []],
      disabled: true,
    },
  ],

  optional: [
    {
      cardType: 'textarea',
      id: 'plComment',
      label: 'PL Comment',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'additionalLanguageSkillEnum',
      id: 'additionalLanguageSkillId',
      label: 'Additional Language Skill',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'industryEnum',
      id: 'industry',
      label: 'Industry',
      validation: [],
      values: ['', []],
      disabled: true,
    },
  ],
};

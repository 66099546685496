import { ProjectCard } from './card';

export const fieldsSettings: Array<ProjectCard> = [
  {
    cardType: 'select',
    id: 'typeId',
    label: 'Absence Type',
    data: 'typeEnum',
    validation: ['valRequired'],
    values: ['', []],
  },
  {
    cardType: 'select',
    id: 'statusId',
    label: 'Absence Status',
    data: 'statusEnum',
    validation: ['valRequired'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'startDate',
    label: 'Absence Start',
    validation: ['valDate', 'valRequired', 'valFromToDates'],
    values: ['', []],
  },
  {
    cardType: 'date',
    id: 'endDate',
    label: 'Absence End',
    validation: ['valDate', 'valRequired', 'valToFromDates'],
    values: ['', []],
  },
];

import { Component, Input } from '@angular/core';
import { ProjectDetails } from '../../types/project-details';

@Component({
  selector: 'app-project-seating-hardware',
  templateUrl: './project-seating-hardware.component.html',
  styleUrls: ['./project-seating-hardware.component.scss'],
})
export class ProjectSeatingHardwareComponent {
  @Input() projectDetails: ProjectDetails;
}

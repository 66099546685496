import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources-history',
  templateUrl: './resources-history.component.html',
  styleUrls: ['./resources-history.component.scss'],
})
export class ResourcesHistoryComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

export const probabilityCommitments =
  '<table id="commitment-details">' +
  '<thead>' +
  '<tr>' +
  '<th>Deal is at the following point</th>' +
  '<th>Sales stage should be no further than</th>' +
  '<th>QSA Winnability Score</th>' +
  '<th>SAP OM Win %</th>' +
  '</tr>' +
  '</thead>' +
  '<tbody>' +
  '<tr>' +
  '<td colspan="4" class="subheader">Stage 0 <(Unqualified)</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Deal is early in shaping stage</td>' +
  '<td>0</td>' +
  '<td>0%-9%</td>' +
  '<td>5%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Deal is being tested with client</td>' +
  '<td>0</td>' +
  '<td>10-14%</td>' +
  '<td>10%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Value proposition and competitive positioning for deal being shaped and tested with client</td>' +
  '<td>0</td>' +
  '<td>15%-24%</td>' +
  '<td>15%</td>' +
  '</tr>' +
  '<tr>' +
  '<td colspan="4" class="subheader">Stage 1 (Qualified by Accenture leadership)</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Competitive bid and uncertain if client will proceed</td>' +
  '<td>1</td>' +
  '<td>TBD</td>' +
  '<td>25%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Competitive bid and certain that client will proceed OR sole source and uncertain if client will proceed</td>' +
  '<td>1</td>' +
  '<td>TBD</td>' +
  '<td>30% or 40%</td>' +
  '</tr>' +
  '<tr>' +
  '<td colspan="4" class="subheader">Stage 2b (Approved proposal has been submitted to client)</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Multiple competitors (Accenture plus two or more others)</td>' +
  '<td>2b</td>' +
  '<td>TBD</td>' +
  '<td>30% or 40%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Two competitors (Accenture vs one other) and we are behind</td>' +
  '<td>2b</td>' +
  '<td>TBD</td>' +
  '<td>40%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Two competitors (Accenture vs one other) and we are equal</td>' +
  '<td>2b</td>' +
  '<td>TBD</td>' +
  '<td>50%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Two competitors (Accenture vs one other) and we are ahead</td>' +
  '<td>2b</td>' +
  '<td>TBD</td>' +
  '<td>60%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Accenture is sole (remaining) vendor in contention for deal and deal team is confident it will close</td>' +
  '<td>2b</td>' +
  '<td>TBD</td>' +
  '<td>70%</td>' +
  '</tr>' +
  '<tr>' +
  '<td colspan="4" class="subheader">Stage 3b (Approved contract has been submitted to client for negotiation)</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Accenture is sole (remaining) vendor in contention for deal and deal team highly confident it will close</td>' +
  '<td>3b</td>' +
  '<td>70-84%</td>' +
  '<td>70%</td>' +
  '</tr>' +
  '<tr>' +
  '<td>Accenture has received verbal or informal notification from client that we have won deal</td>' +
  '<td>3b</td>' +
  '<td>>=85%</td>' +
  '<td>90%</td>' +
  '</tr>' +
  '</tbody>' +
  '</table>';

export const applicationInfo =
  '<div id="info">' +
  '<p class="label">App Name:</p>' +
  '<p>{{appName}}</p>' +
  '<p class="label">Version:</p>' +
  '<p>{{appVersion}}</p>' +
  '<p class="label">Data Classification:</p>' +
  '<p>{{appDataClassification}}</p>' +
  '<p class="label">Author:</p>' +
  '<p>{{appAuthor}}</p>' +
  '</div>';

import { NewEmployee } from './new-form';

export const fieldsSettings: NewEmployee = {
  top: [
    {
      cardType: 'input',
      id: 'firstName',
      label: 'First Name',
      validation: ['valRequired', 'valString20'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'lastName',
      label: 'Last Name',
      validation: ['valRequired', 'valString20'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'employeeTypesEnum',
      id: 'employeeTypeId',
      label: 'Employee Type',
      validation: ['valRequired'],
      values: ['', []],
    },
  ],

  mandatory: [
    {
      cardType: 'select',
      data: 'statusEnum',
      id: 'statusId',
      label: 'Status',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'enterpriseDomainId',
      label: 'Domain ID',
      validation: ['valRequired', 'valString40'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'personalNumber',
      label: 'Personal Number',
      validation: ['valRequiredPersonalNumber', 'valInteger', 'valString9'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'homeLocationEnum',
      id: 'locationId',
      label: 'Home Location',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'billCodesEnum',
      id: 'billcodeId',
      label: 'Billcode',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'employeeActivityTypeEnum',
      id: 'employeeActivityTypeId',
      label: 'Activity Type',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'newCareerLevelEnum',
      id: 'newCareerLevelId',
      label: 'Management Level',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'travelEnum',
      id: 'travelId',
      label: 'Travel',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'platformEnum',
      id: 'platformId',
      label: 'Platform',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'domainEnum',
      id: 'employeeDomainId',
      label: 'Domain',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'universityEnum',
      id: 'universityId',
      label: 'University',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'englishEnum',
      id: 'englishId',
      label: 'English',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'germanEnum',
      id: 'germanId',
      label: 'German',
      validation: ['valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'fte',
      label: 'FTE',
      validation: ['valRequiredForNoCc', 'valFlInterval'],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'hireDate',
      label: 'Hire Date',
      validation: ['valDate', 'valRequiredForNoCc'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'positionEnum',
      id: 'positionId',
      label: 'Position',
      validation: ['valRequired'],
      values: ['', []],
    },
    { cardType: 'slider', id: 'active', label: 'Active', validation: [], values: [true, []] },
  ],

  optional: [
    {
      cardType: 'select',
      data: 'frenchEnum',
      id: 'frenchId',
      label: 'French',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'italianEnum',
      id: 'italianId',
      label: 'Italian',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'combo',
      data: 'careerCounsellorEidCombobox',
      id: 'careerCounsellorEid',
      label: 'People Lead ID',
      validation: [],
      values: ['', []],
    },
    {
      cardType: 'date',
      id: 'endDate',
      label: 'End Date',
      validation: ['valDate'],
      values: ['', []],
    },
    {
      cardType: 'input',
      id: 'notes',
      label: 'Notes',
      validation: ['valString150'],
      values: ['', []],
    },
    {
      cardType: 'select',
      data: 'homeOfficeScenarioEnum',
      id: 'homeOfficeScenarioId',
      label: 'Home Office Scenario',
      validation: [],
      values: [2, []],
    },
    { cardType: 'slider', id: 'trainee', label: 'Trainee', validation: [], values: [false, []] },
    {
      cardType: 'date',
      id: 'traineeEndDate',
      label: 'Trainee End Date',
      validation: ['valDate'],
      values: ['', []],
      disabled: true,
    },
    {
      cardType: 'select',
      data: 'acquisitionEnum',
      id: 'acquisitionId',
      label: 'Acquisition',
      validation: [],
      values: ['', []],
    },
  ],
};

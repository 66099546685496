import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { BannerCard } from '../../types/employee-banner-card';
import { FormatCardByType } from '../../pipes/format-card-by-type.pipe';
import { FormatCardEnum } from '../../pipes/format-card-enum.pipe';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import { ProjectCommonFieldsComponent } from '../add-new-project/project-common-fields/project-common-fields.component';

@Component({
  selector: 'app-project-banner-card',
  templateUrl: './project-banner-card.component.html',
  styleUrls: ['./project-banner-card.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectBannerCardComponent
  extends ProjectCommonFieldsComponent
  implements OnInit, OnChanges
{
  @Input() bannerCard: BannerCard[];
  @Input() clientEnum;

  formatCardEnum: FormatCardEnum;
  formatCardByType: FormatCardByType;

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientEnum) {
      this.data['client'] = changes.clientEnum.currentValue;
    }
  }

  populateDropdowns(enums) {
    this.data['client'] = this.clientEnum;
    this.data['status'] = enums['status-enum'];
    this.data['rateType'] = enums['rate-type'];
    this.data['region'] = enums['region'];
    this.data['operationalResp'] = enums['operational-responsibility'];
    this.data['clientGroup'] = enums['client-group'];
    this.data['primaryWorkLoc'] = enums['work-location'];
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectCommonFieldsComponent } from '../project-common-fields/project-common-fields.component';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';
import * as validationUtils from '../../../shared/validators';

@Component({
  selector: 'app-project-optional-fields',
  templateUrl: './project-optional-fields.component.html',
  styleUrls: ['./project-optional-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectOptionalFieldsComponent extends ProjectCommonFieldsComponent implements OnInit {
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @Input() optional: FormGroup;

  dcResponsibleDeputySubscription: any;

  data = {
    propLeadContact: [],
    salBidContact: [],
    projManContact: [],
    qaDirContact: [],
    othContact: [],
    dcResponsibleDeputy: [],
    techAccLeadContact: [],
  };

  filteredValues = {
    propLeadContact: new Observable<any[]>(),
    salBidContact: new Observable<any[]>(),
    projManContact: new Observable<any[]>(),
    othContact: new Observable<any[]>(),
    techAccLeadContact: new Observable<any[]>(),
  };

  ngOnInit() {
    super.ngOnInit();

    this.contactService.getData().subscribe(data => {
      this.data['propLeadContact'] = data;
      this.data['othContact'] = data;
      this.data['salBidContact'] = data;
      this.data['projManContact'] = data;
      this.data['qaDirContact'] = data;
      this.data['techAccLeadContact'] = data;
    });

    this.dcResponsibleService.getData().subscribe(data => {
      this.data['dcResponsibleDeputy'] = data;
    });

    // this.expansionPanel.open();
    this.optional.valueChanges.subscribe(changes => this.onFormChanges(changes, this.optional));

    this.fieldsSettings.optional.forEach(setting => {
      if (setting.cardType === 'autosearch') {
        this.filteredValues[setting.id] = this.optional.get(setting.id).valueChanges.pipe(
          startWith(null),
          debounceTime(500),
          map(value =>
            value ? this.filterStates(value, setting.id) : this.data[setting.id].slice()
          )
        );
        if (setting.id === 'dcResponsibleDeputy') {
          this.filteredValues[setting.id] = this.optional.get(setting.id).valueChanges.pipe(
            debounceTime(500),
            startWith(null),
            map(value => {
              if (value != null) {
                return value.length >= 3 ? this.filterStates(value, setting.id).slice(0, 50) : null;
              }
            })
          );
        }
      }
    });
  }

  public subscribeSpecialEnums() {
    this.dcResponsibleDeputySubscription = this.optional
      .get('dcResponsibleDeputy')
      .valueChanges.subscribe(value => this.onDcResponsibleDeputyChange(value));
  }

  public unsubscribeSpecialEnums() {}

  populateDropdowns(enums) {
    super.populateDropdowns(enums);

    this.data['offeringNameEnum'] = enums['offering-name'].filter(value => value.deleted !== true);
    this.data['sharedServiceEnum'] = enums['project-domain'];
    this.data['projectDeliveryEnum'] = enums['project-delivery'];
  }

  onDcResponsibleDeputyChange(value: any) {
    if (!value || value === '') {
      this.removeValidationFromDcResponsibleDeputy();
    } else {
      this.setValidatorsForDcResponsibleDeputy();
    }
  }

  setValidatorsForDcResponsibleDeputy() {
    if (!this.optional.get('dcResponsibleDeputy').validator) {
      const validators = [];
      validators.push(validationUtils['valDcResponsibleAndDeputy'](this.projectDetails));
      validators.push(
        validationUtils['valDcResponsibleAndDeputyFormat'](this.data['dcResponsibleDeputy'])
      );
      this.optional.get('dcResponsibleDeputy').setValidators(validators);
      this.optional.get('dcResponsibleDeputy').enable();
    }
  }

  removeValidationFromDcResponsibleDeputy() {
    this.optional.get('dcResponsibleDeputy').clearValidators();
  }
}

import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from './user-roles/user';
import { UserRolesService } from './user-roles/user-roles.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
class RoutingRoleService {
  constructor(
    private userRolesService: UserRolesService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      return this.router
        .navigate(['/login-component'], { queryParams: { component: state.url } })
        .then();
    }
    return new Observable<boolean>(observer => {
      this.userRolesService
        .getData()
        .pipe(distinctUntilChanged())
        .subscribe(data => {
          this.checkRoles(data, observer, state);
        });
    });
  }

  private checkRoles(data: Object, observer: any, state: RouterStateSnapshot) {
    const roles = new User(data).getRoles();
    const sidenavLinks = roles.sidenavLinks;

    // Here we set direct access for view sites
    if (state.url.indexOf('/login-component') !== -1 || state.url.indexOf('/welcome-page') !== -1) {
      observer.next(true);
    }
    if (
      [
        '/employee-details/add-new-joiner',
        '/project-details/add-new-project',
        '/project-details/add-new-demand',
        '/project-details/softlocks-hardlocks',
        '/opportunity-details/add-new-demand',
      ].includes(state.url)
    ) {
      observer.next(roles.creating);
    }

    if (state.url.includes('/absences/import')) {
      observer.next(roles.importing);
    }

    if (state.url.includes('/overtime/import')) {
      observer.next(roles.importing);
    }

    if (
      [
        '/project-list',
        'opportunity-list',
        '/project-details',
        '/opportunity-details',
        '/lead-details',
      ].includes(state.url)
    ) {
      observer.next(roles.creating || roles.onlyView);
    }

    if (state.url.includes('/employee-detail')) {
      observer.next(roles.employeeEdit || roles.onlyView || roles.cc);
    }

    if (state.url.includes('/old/project/awsSeating.do')) {
      observer.next(roles.aws);
    }

    if (state.url.includes('/aws-seating-planning')) {
      observer.next(roles.aws || roles.adminOrHr);
    }

    if (
      [
        '/old/project/openDemands.do',
        '/hiring-requests/add-new-request',
        '/hiring-requests',
      ].includes(state.url)
    ) {
      observer.next(roles.hiring);
    }

    if (state.url.includes('/old/project/hcPlan.do')) {
      observer.next(roles.headCountPlaning);
    }

    if (state.url.includes('/old/project/hcPlan.do')) {
      observer.next(roles.headCountPlaning);
    }

    if (state.url.includes('/old/project/tools.do')) {
      observer.next(roles.tools);
    }

    if (state.url.includes('/bench-management')) {
      observer.next(roles.bench);
    }

    if (
      [
        '/nsmetrics-report',
        '/nbm-report',
        '/opendemands-report',
        '/open-positions-report',
        '/data-freshness-report',
        '/vacationandovertime-report',
        '/project-summary-report',
        '/aws-seating-report',
        '/supply-summary-report',
        '/language-skills-usage-report',
        '/aws-seating-report',
        '/scheduling-report',
        '/platform-report',
        '/cost-center-report',
        '/opportunity-summary-report',
        '/hiring-requests-report',
        '/project-cost-report',
        '/training-data-report',
        '/projects-roll-offs-overview',
      ].includes(state.url)
    ) {
      observer.next(true);
    }

    observer.next(sidenavLinks.find(link => state.url.indexOf(link.url) !== -1));
  }
}

export const RoutingRoleGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  return inject(RoutingRoleService).canActivate(next, state);
};

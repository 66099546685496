export const opportunityProjectMapping = {
  mandatory: [
    { id: 'projectName', class: 'projectInfo', atribute: 'projectName' },
    { id: 'operationalRespId', class: 'projectInfo', atribute: 'operationalRespId' },
    { id: 'serviceGroupIds', class: 'projectInfo', atribute: 'serviceGroupIds' },
    { id: 'primaryWorkLocId', class: 'projectInfo', atribute: 'primaryWorkLocId' },
    { id: 'workLocation', class: 'projectInfo', atribute: 'workLocation' },
    { id: 'workCategoryId', class: 'projectInfo', atribute: 'workCategoryId' },
    { id: 'deliveryVmuDescription', class: 'projectInfo', atribute: 'deliveryVmuDescription' },
    { id: 'sapOpNumber', class: 'projectInfo', atribute: 'sapOpNumber' },
    { id: 'startDate', class: 'projectSchedule', atribute: 'startDate' },
    { id: 'endDate', class: 'projectSchedule', atribute: 'endDate' },
    { id: 'clientName', class: 'client', atribute: 'clientName' },
    { id: 'clAccLeadContact', class: 'reqQualification', atribute: 'clAccLeadContact' },
    {
      id: 'primaryAccountableLocationId',
      class: 'reqQualification',
      atribute: 'primaryAccountableLocationId',
    },
    { id: 'clientGroupId', class: 'reqQualification', atribute: 'clientGroupId' },
    { id: 'subIndustryId', class: 'reqQualification', atribute: 'subIndustryId' },
    { id: 'regionId', class: 'reqQualification', atribute: 'regionId' },
    { id: 'countryId', class: 'reqQualification', atribute: 'countryId' },
    { id: 'platformId', class: 'reqQualification', atribute: 'platformId' },
    { id: 'dcResponsible', class: 'reqQualification', atribute: 'dcResponsible' },
    { id: 'offeringNameId', class: 'reqQualification', atribute: 'offeringNameId' },
    { id: 'tgSalesId', class: 'projectInfo', atribute: 'tgSalesId' },
    { id: 'end2End', class: 'projectInfo', atribute: 'end2End' },
    { id: 'localProject', class: 'projectInfo', atribute: 'localProject' },
    { id: 'swfReqTravel', class: 'projectInfo', atribute: 'swfReqTravel' },
    { id: 'expectedFte', class: 'projectInfo', atribute: 'expectedFte' },
    { id: 'nbmStatus', class: 'projectInfo', atribute: 'nbmStatus' },
    { id: 'newBusinessMeeting', class: 'projectInfo', atribute: 'newBusinessMeeting' },
    { id: 'sourcingLocation', class: 'projectInfo', atribute: 'sourcingLocation' },
    { id: 'condition', class: 'projectInfo', atribute: 'condition' },
    { id: 'lastUpdateDate', class: 'reqQualification', atribute: 'lastUpdateDate' },
    { id: 'solArchContact', class: 'reqQualification', atribute: 'solArchContact' },
    { id: 'dcResponsibleRoleId', class: 'reqQualification', atribute: 'dcResponsibleRoleId' },
    { id: 'delLeadContact', class: 'reqQualification', atribute: 'delLeadContact' },
    { id: 'projManContact', class: 'reqQualification', atribute: 'projManContact' },
  ],
  optional: [
    { id: 'solArchContact', class: 'reqQualification', atribute: 'solArchContact' },
    { id: 'salBidContact', class: 'reqQualification', atribute: 'salBidContact' },
    { id: 'qaDirContact', class: 'reqQualification', atribute: 'qaDirContact' },
    { id: 'othContact', class: 'reqQualification', atribute: 'othContact' },
    { id: 'othContactRole', class: 'reqQualification', atribute: 'othContactRole' },
    { id: 'offeringResponsibleId', class: 'reqQualification', atribute: 'offeringResponsibleId' },
    { id: 'end2End', class: 'projectInfo', atribute: 'end2End' },
    { id: 'localProject', class: 'projectInfo', atribute: 'localProject' },
    { id: 'swfReqTravel', class: 'projectInfo', atribute: 'swfReqTravel' },
    { id: 'piComment', class: 'projectInfo', atribute: 'piComment' },
    { id: 'upskilling', class: 'projectInfo', atribute: 'upskilling' },
    { id: 'growthOpportunity', class: 'projectInfo', atribute: 'growthOpportunity' },
    { id: 'highProfitabilityDeal', class: 'projectInfo', atribute: 'highProfitabilityDeal' },
    { id: 'sharedService', class: 'projectInfo', atribute: 'sharedService' },
  ],
};

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bench-tracking {
  margin: 125px 15px 50px 15px;
}

.mat-mdc-progress-spinner {
  margin: 125px auto;
}

.table-wrapper {
  padding: 20px;
}

#paginator {
  margin-bottom: 10px;
}

.empty-effort {
  color: #ec407a;
}

.current-week {
  font-weight: bold;
}

.actions-header-cell {
  padding-top: 22px !important;
  padding-left: 20px !important;
}

.mat-mdc-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: auto;
}
.mat-mdc-table .mat-mdc-header-cell,
.mat-mdc-table .mat-mdc-cell {
  min-width: 14ch;
  padding: 8px;
}
.mat-mdc-table .mat-mdc-header-cell:nth-of-type(1),
.mat-mdc-table .mat-mdc-cell:nth-of-type(1) {
  max-width: 80px;
}

::ng-deep .mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-form-field-infix label {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export const employeeDetailsEnumMapping = new Map([
  ['travelId', 'employee-travel'],
  ['travelDetailId', 'employee-travel-detail'],
  ['universityId', 'employee-university'],
  ['englishId', 'employee-english'],
  ['frenchId', 'employee-french'],
  ['italianId', 'employee-italian'],
  ['platformId', 'platform'],
  ['positionId', 'employee-position'],
  ['newCareerLevelId', 'employee-new-career-level'],
  ['domainId', 'domain'],
  ['employeeDomainId', 'employee-domain'],
  ['germanId', 'employee-german'],
  ['locationId', 'employee-location'],
  ['cctypeId', 'employee-cost-centrum'],
  ['costCentersId', 'employee-cost-centrum'],
  ['updatedCostCenterId', 'employee-cost-centrum'],
  ['genderId', 'employee-gender'],
  ['employeeTypeId', 'employee-type'],
  ['statusId', 'employee-status'],
  ['employeeRoleId', 'employee-role'],
  ['billcodeId', 'bill-code'],
  ['rotationPreferencesId', 'employee-rotation-preferences'],
  ['talentSegmentId', 'employee-talent-segment'],
  ['digitalSpecialtyId', 'employee-digital-specialty'],
  ['employeeRoleFamilyId', 'employee-role-family'],
  ['empProfileId', 'employee-profile'],
  ['industryId', 'client-group'],
  ['employeeActivityTypeId', 'employee-activity-type'],
  ['weeklyHOPref', 'weekly_ho_preference'],
  ['homeOfficeScenarioId', 'employee-home-office-scenario'],
  ['acquisitionId', 'employee-acquisition'],
]);

export const projectsEnumMapping = new Map([
  ['germanId', 'employee-german'],
  ['travelId', 'employee-travel'],
  ['primaryAccountableLocationId', 'primary-accountable-location'],
  ['clientGroupId', 'client-group'],
  ['subIndustryId', 'sub-industries'],
  ['tgSalesId', 'tg-sales'],
  ['platformId', 'platform'],
  ['domainId', 'domain'],
  ['regionId', 'region'],
  ['countryId', 'country'],
  ['offeringNameId', 'offering-name'],
  ['offeringNameDomainId', 'offering-name-domain-id'],
  ['operationalRespId', 'operational-responsibility'],
  ['serviceGroupIds', 'service-request'],
  ['primaryWorkLocId', 'work-location'],
  ['commitment', 'probability'],
  ['statusId', 'status-enum'],
  ['nbmStatus', 'nbm_status'],
  ['workCategoryId', 'work-category'],
  ['sharedService', 'project-domain'],
  ['rateTypeId', 'rate-type'],
  ['dcResponsibleRoleId', 'dcResponsibleRole'],
  ['deliveryMethodIds', 'project-delivery'],
  ['buildingFloor', 'seating-building-floor'],
  ['building', 'seating-building'],
  ['seatStatus', 'seating-status'],
  ['pcNtbDemandId', 'pc-notebook'],
  ['demandPrimarySkillId', 'primary-skill'],
  ['demandProficiencyId', 'proficiency'],
  ['careerLevelId', 'employee-new-career-level'],
  ['sourcingLocationId', 'employee-country-enum'],
  ['sourcingLocationId2', 'employee-location'],
  ['programming1', 'programmer'],
  ['programming2', 'programmer'],
  ['programming3', 'programmer'],
  ['lvl1Programming', 'level'],
  ['lvl2Programming', 'level'],
  ['lvl3Programming', 'level'],
  ['application1', 'application'],
  ['application2', 'application'],
  ['application3', 'application'],
  ['lvl1Application', 'level'],
  ['lvl2Application', 'level'],
  ['lvl3Application', 'level'],
  ['language1', 'language'],
  ['language2', 'language'],
  ['language3', 'language'],
  ['lvl1Language', 'level'],
  ['lvl2Language', 'level'],
  ['lvl3Language', 'level'],
  ['sourcingChannelIds', 'sourcing-channel'],
  ['salesStage', 'sales-stage'],
]);

export const hiringRequestsEnumMapping = new Map([
  ['platformId', 'platform'],
  ['domainId', 'domain'],
  ['statusId', 'open-demands-status'],
  ['profileId', 'open-demands-profile'],
  ['typeId', 'open-demands-type'],
  ['locationId', 'employee-location'],
  ['additionalLanguageSkillId', 'open-demands-language-skill'],
  ['priorityId', 'hiring-requests-priority'],
  ['requestQualificationId', 'request-qualification-industry'],
]);

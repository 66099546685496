import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarPanelComponent } from 'app/shared/snackbar-panel/snackbar-panel.component';
import { ReportMandatoryFieldsComponent } from '../report/report-mandatory-fields/report-mandatory-fields.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { ReportService } from 'app/services/report.service';
import { VersionDataService } from '../../version-data.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { DialogData } from 'app/types/dialog-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-opendemands-report',
  templateUrl: './opendemands-report.component.html',
  styleUrls: ['./opendemands-report.component.scss', '../../shared/add-new.scss'],
  providers: [SnackbarPanelComponent],
})
export class OpenDemandsReportComponent implements OnInit {
  @ViewChild('mandatoryChild', { static: false }) mandatoryChild: ReportMandatoryFieldsComponent;

  exportOptionsForm: FormGroup;

  creatingRole: boolean;
  editingRole: boolean;
  exporting = false;
  title: string = 'Open Demands Report';

  constructor(
    private versionDataService: VersionDataService,
    public snackbar: SnackbarPanelComponent,
    private location: Location,
    private fb: FormBuilder,
    private reportService: ReportService,
    private dialog: MatDialog,
    public app: AppComponent,
    public router: Router
  ) {}

  ngOnInit() {
    this.snackbar.close();

    this.exportOptionsForm = this.fb.group({
      date: ['', []],
    });

    this.exportOptionsForm.get('date').setValue(new Date());
  }

  back() {
    this.snackbar.close();
    this.location.back();
  }

  exportOpenDemandsReport() {
    this.exporting = true;
    const day = this.exportOptionsForm.get('date').value.getUTCDate();
    const month = this.exportOptionsForm.get('date').value.getUTCMonth() + 1;
    const year = this.exportOptionsForm.get('date').value.getUTCFullYear();
    this.reportService
      .exportOpenDemandsReport(day, month, year)
      .pipe(distinctUntilChanged())
      .subscribe(
        result => this.handleExportSucces(result),
        err => this.handleExportError(err)
      );
  }

  handleExportSucces(result) {
    this.exporting = false;
    this.snackbar.success('Report obtained');
    const contenDispositionHeader = result.headers.get('Content-Disposition');
    const filename = contenDispositionHeader.split(';')[1].trim().split('=')[1];
    const urlObject = window.URL.createObjectURL(result.body);
    const element = document.createElement('a');
    element.href = urlObject;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  handleExportError(err) {
    this.exporting = false;
    this.snackbar.error('Report failed');
  }

  showInfo() {
    const data: DialogData = {
      title: 'Application Info',
      content: this.versionDataService.showInfo(),
      action1: 'Close',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

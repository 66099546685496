import { DemandColumnSettings } from './new-form';

export const fieldsSettings: DemandColumnSettings = {
  demand: [
    {
      cardType: 'checkbox',
      id: 'softlockCount',
      label: 'SL Count',
      validation: [],
      values: [false, []],
    },
    { cardType: 'checkbox', id: 'role', label: 'Role', validation: [], values: [false, []] },
    {
      cardType: 'checkbox',
      id: 'demandId',
      label: 'Demand ID',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'platformId',
      label: 'Platform',
      validation: [],
      values: [false, []],
    },
    { cardType: 'checkbox', id: 'domainId', label: 'Domain', validation: [], values: [false, []] },
    { cardType: 'checkbox', id: 'demandFte', label: 'FTE', validation: [], values: [false, []] },
    {
      cardType: 'checkbox',
      id: 'startDateDemand',
      label: 'Start Date',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'rollOffDateDemand',
      label: 'Roll-off Date',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'careerLevelId',
      label: 'Management Level',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'sourcingLocationId',
      label: 'Sourcing Location',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'sourcingChannelIds',
      label: 'Sourcing Channel',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'germanRequired',
      label: 'German Required',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'frenchRequired',
      label: 'French Required',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'mySchedulingID',
      label: 'myScheduling ID',
      validation: [],
      values: [false, []],
    },
    { cardType: 'checkbox', id: 'dcso', label: 'DCSO', validation: [], values: [false, []] },
    {
      cardType: 'checkbox',
      id: 'demandRateTypeId',
      label: 'Rate Type',
      validation: [],
      values: [false, []],
    },
  ],

  supply: [
    { cardType: 'checkbox', id: 'supplyName', label: 'Name', validation: [], values: [false, []] },
    { cardType: 'checkbox', id: 'supplyFrom', label: 'From', validation: [], values: [false, []] },
    { cardType: 'checkbox', id: 'supplyTo', label: 'To', validation: [], values: [false, []] },
    {
      cardType: 'checkbox',
      id: 'supplyLevel',
      label: 'Level',
      validation: [],
      values: [false, []],
    },
  ],

  other: [
    {
      cardType: 'checkbox',
      id: 'roleDescription',
      label: 'Role Description',
      validation: [],
      values: [false, []],
    },
    { cardType: 'checkbox', id: 'notes', label: 'Notes', validation: [], values: [false, []] },
    {
      cardType: 'checkbox',
      id: 'probabilityId',
      label: 'Probability',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'createdOn',
      label: 'Created On',
      validation: [],
      values: [false, []],
    },
    {
      cardType: 'checkbox',
      id: 'lastModified',
      label: 'Last Modified',
      validation: [],
      values: [false, []],
    },
  ],
};

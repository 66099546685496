import { distinctUntilChanged } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { EmployeeDetailsService } from '../employee-details/employee-details.service';
import { EnumsService } from '../../services/enumerations/enums.service';
import { MatDialog } from '@angular/material/dialog';
import { SuppliesService } from '../../services/supplies.service';
import { UserRolesService } from '../../services/user-roles/user-roles.service';
import { EmployeeDetails } from '../../types/employee-details';
import { bannerCard } from '../../types/employee-banner-card-content';
import { Hardlock } from '../../types/hardlock';
import { Absence } from '../../types/absence';
import { DialogData } from '../../types/dialog-data';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { ImportAbsenceDialogComponent } from '../import-absence-dialog/import-absence-dialog.component';
import { User } from '../../services/user-roles/user';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { HASHED_EMPLOYEE_STRING } from '../../../constants';

@Component({
  selector: 'app-employee-assignments',
  templateUrl: './employee-assignments.component.html',
  styleUrls: [
    './employee-assignments.component.scss',
    '../../shared/tables-management/table-common-style.scss',
  ],
})
export class EmployeeAssignmentsComponent implements OnInit, AfterViewInit {
  @Input() employeeDetails: EmployeeDetails;
  @ViewChild('table1', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: true }) sort2: MatSort;

  public HASHED_EMPLOYEE_STRING: string = HASHED_EMPLOYEE_STRING;

  hardlocks: Hardlock[];
  errorEmployeeDetails: Error;
  errorSupplies: Error;
  errorAbsences: Error;
  searchedId: any;
  paramsSubscription: any;
  dateFormatter = new DatePipe('en-US');
  dateFormat = 'MM/dd/yyyy';
  leaving: boolean;

  absencesDisplayedColumns = ['typeIdText', 'statusIdText', 'startDate', 'endDate', 'actions'];
  absencesDataSource = new MatTableDataSource();
  softlocksDisplayedColumns = [
    'projectName',
    'dcResponsibleName',
    'role',
    'supplyFte',
    'supplyFrom',
    'supplyTo',
    'priority',
    'order',
    'actions',
  ];
  softlocksDataSource = new MatTableDataSource();

  bannerCard = bannerCard;

  editingRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;
  projectLink = false;

  constructor(
    private suppliesService: SuppliesService,
    private employeeDetailsService: EmployeeDetailsService,
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private dialog: MatDialog,
    private userRolesService: UserRolesService,
    private createAbsenceDialog: MatDialog
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.evaluateParams(params);
    });

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().employeeEdit;
        this.deletingRole = user.getRoles().employeeDelete;

        if (user.getRoles().user) {
          this.projectLink = true;
        } else {
          this.projectLink = false;
        }
      });

    this.leaving = false;
  }

  ngAfterViewInit() {
    this.absencesDataSource.sort = this.sort1;
    this.softlocksDataSource.sort = this.sort2;
  }

  evaluateParams(params: Params): void {
    const searchedId = params['searchedId'];
    if (searchedId != null) {
      if (searchedId === '') {
        this.errorEmployeeDetails = this.handleError('Invalid Employee ID route.');
      } else {
        this.getEmployeeAssignments(searchedId);
      }
    }
  }

  getEmployeeAssignments(enterpriseDomainId) {
    this.getAbsences(enterpriseDomainId);
    this.getSupplies(enterpriseDomainId);
  }

  getSupplies(searchedId): void {
    this.suppliesService.deleteCache();
    this.suppliesService
      .getData(searchedId)
      .pipe(distinctUntilChanged())
      .subscribe(
        data => this.handleSupplies(data),
        error => (this.errorSupplies = this.handleSupplyError(error))
      );
  }

  getAbsences(searchedId): void {
    this.employeeDetailsService
      .getAbsences(searchedId)
      .pipe(distinctUntilChanged())
      .subscribe(
        absences => {
          this.handleAbsences(absences);
        },
        error => (this.errorAbsences = this.handleAbsencesError(error))
      );
  }

  processSoftlocks(softlocks: any): any {
    let lastPriority;
    let order;

    softlocks.forEach(softlock => {
      if (lastPriority !== softlock.priority) {
        order = 1;
      }

      softlock.order = softlock.priority + '.' + order++;
      lastPriority = softlock.priority;
    });

    return softlocks;
  }

  handleAbsences(absences: any): void {
    this.errorAbsences = null;
    this.absencesDataSource.data = absences;
  }

  deleteSupplyDialog(id) {
    const data: DialogData = {
      title: 'Delete softlock',
      content: 'Do you really want to delete this softlock?',
      action1: 'delete',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSupply(id);
      }
    });
  }

  deleteSupply(id) {
    this.employeeDetailsService
      .deleteSupply(id)
      .subscribe(response => this.handleDeleteSupply(response));
  }

  handleDeleteSupply(error) {
    const id =
      this.employeeDetails.enterpriseDomainId !== undefined
        ? this.employeeDetails.enterpriseDomainId
        : this.employeeDetails.id;
    this.getSupplies(id);
  }

  getColumnAbsencesNames() {
    const columnNames = [
      'Absence Type',
      'Absence Status',
      'Absence Start date',
      'Absence End date',
      'Actions',
    ];
    return columnNames;
  }

  getColumnSoftlocsNames() {
    const columnNames = [
      'Project',
      'DC Responsible Name',
      'Demand Role',
      'FTE',
      'Start Date',
      'Roll-off Date',
      'Priority',
      'Order',
      'Actions',
    ];
    return columnNames;
  }

  isFutureDate(supplyTo: string | Date): boolean {
    const supplyToDate = new Date(supplyTo).getTime();
    const currentDate = new Date().getTime();
    return supplyToDate > currentDate;
  }

  handleSupplies(supplies: Object): void {
    this.errorSupplies = null;
    if (supplies['softlocks'] != null && supplies['softlocks'].length > 0) {
      this.softlocksDataSource = new MatTableDataSource(
        this.processSoftlocks(supplies['softlocks'])
      );
    } else {
      this.softlocksDataSource = new MatTableDataSource();
    }

    if (supplies['hardlocks'] != null && supplies['hardlocks'].length > 0) {
      this.hardlocks = this.sortHardlocks(supplies['hardlocks']);
    } else {
      this.hardlocks = null;
    }
  }

  sortHardlocks(hardlocks) {
    hardlocks.sort((a, b) => {
      if (a.supplyTo && b.supplyTo) {
        const aSupplyTo = new Date(a.supplyTo).getTime();
        const bSupplyTo = new Date(b.supplyTo).getTime();

        if (
          aSupplyTo === bSupplyTo &&
          a.projectName === b.projectName &&
          a.supplyFrom &&
          b.supplyFrom
        ) {
          return new Date(b.supplyFrom).getTime() - new Date(a.supplyFrom).getTime();
        } else {
          return bSupplyTo - aSupplyTo;
        }
      }
    });

    return hardlocks;
  }

  insertAbsence(absence: Absence, isEditing?: boolean) {
    isEditing = isEditing === undefined || null ? false : isEditing;
    const data = {
      absence,
      employeeDetailId: this.employeeDetails.id,
      isEditing: isEditing,
    };
    const dialogRef = this.createAbsenceDialog.open(ImportAbsenceDialogComponent, {
      data: data,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getAbsences(this.employeeDetails.enterpriseDomainId || this.employeeDetails.id);
      }
    });
  }

  handleError(errorText: string): Error {
    this.hardlocks = null;

    const err: Error = new Error(errorText);
    this.errorAbsences = err;
    this.errorSupplies = err;
    this.errorEmployeeDetails = err;
    return err;
  }

  handleSupplyError(error: Response): Error {
    this.hardlocks = null;

    const err: Error = new Error(error.statusText);
    this.errorAbsences = null;
    this.errorSupplies = err;
    this.errorEmployeeDetails = err;
    return err;
  }

  handleAbsencesError(error: Response): Error {
    const err: Error = new Error(error.statusText);
    this.errorAbsences = err;
    this.errorSupplies = null;
    this.errorEmployeeDetails = err;
    return err;
  }

  ngOnDestroy(): void {
    this.leaving = true;
    this.paramsSubscription.unsubscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmployeeDetails } from '../../types/employee-details';
import { EnumsService } from '../../services/enumerations/enums.service';
import { Enumeration } from '../../types/enumeration';
import { SnackbarPanelComponent } from '../../shared/snackbar-panel/snackbar-panel.component';
import * as validationUtils from '../../shared/validators';
import { errorMessages } from '../../types/input-errors';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss', '../../shared/card-item.scss'],
  providers: [SnackbarPanelComponent],
})
export class CardItemComponent implements OnInit {
  isEnum: boolean;
  enums: Map<string, Enumeration>;
  enumeration: Array<any>;
  firstValue: any;
  isChanged: boolean;
  isEmployeeChanged: boolean;
  employeeIdScope: number;
  edited: boolean;

  errorMessages = errorMessages;

  error: string;

  private _control: FormGroup;

  get control() {
    return this._control;
  }

  @Input() set control(val: FormGroup) {
    this._control = val;
  }

  @Input() property: string;

  @Input() modelValue: any;

  @Input() disabled: boolean;

  @Input() editing: boolean;

  @Input() id: string;

  @Input() validation: string[];

  @Input() label: string;

  @Input() type: string;

  @Input() employeeDetails: EmployeeDetails;

  //@Input() control: FormGroup;
  // @Input() set control(val) {
  //   this._formGroup.next(val);
  // }

  // get control() {
  //   return this._formGroup.getValue();
  // }

  @Output() modelChange = new EventEmitter();

  @Input('model')
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);

    if (this.employeeDetails) {
      if (this.employeeIdScope !== this.employeeDetails.id) {
        this.isEmployeeChanged = true;
      }
    }

    if (this.modelValue !== this.firstValue) {
      this.isChanged = true;
    } else {
      this.isChanged = false;
      if (this.isEmployeeChanged) {
        this.isChanged = true;
      }
    }

    if (this.control !== undefined && this.type !== 'date') {
      //This was for refresh data in items after leaving from editing mode, but with it, it was
      //unable to edit manually date fields, bug for the future
      this.control.controls[this.id]?.setValue(this.model);
    }
  }

  constructor(
    public enumsService: EnumsService,
    private snackbar: SnackbarPanelComponent
  ) {
    this.isEmployeeChanged = false;
  }

  updateErrorText() {
    if (this.control.controls[this.id] && this.control.controls[this.id].errors) {
      this.error = this.errorMessages[Object.keys(this.control.controls[this.id].errors)[0]];
    } else {
      this.error = null;
    }
  }

  ngOnInit() {
    this.control.controls[this.id]?.setValue(this.model);
    this.control.controls[this.id]?.valueChanges.subscribe(val => {
      this.modelChange.emit(val);
      this.updateErrorText();
    });
    this.enumsService.getData().subscribe(
      data => {
        this.enums = data;
        this.isEnum = false;
        this.firstValue = this.modelValue;
        this.employeeIdScope = this.employeeDetails.id;
        this.isChanged = false;

        if (this.disabled) {
          this.control.controls[this.id].disable({ onlySelf: true, emitEvent: false });
        }
        const validators = [];
        this.validation.forEach(validation => {
          if (
            validation === 'valRequiredPersonalNumber' ||
            validation === 'valRequiredForNoCc' ||
            // validation === 'valOtherStatus' ||
            validation === 'valRequiredEId' ||
            validation === 'valDigitalPlatformRequired' ||
            validation === 'valRequiredCc'
          ) {
            try {
              validators.push(validationUtils[validation](this.employeeDetails));
            } catch {
              console.log('passed');
            }
          } else if (validation === 'valRequiredSkill' || validation === 'valRequiredPro') {
            validators.push(
              validationUtils[validation](this.employeeDetails, this.id, this.control.controls)
            );
          } else {
            validators.push(validationUtils[validation]);
          }
        });
        if (this.type !== 'read') {
          try {
            this.control.controls[this.id].setValidators(validators);
          } catch {
            console.log('pass');
          }
        }
      },
      error => {
        this.handleLoadEnumsError();
      }
    );
  }

  setEdited(val) {
    // if (this.edited === false && val === true) {
    this.control.controls[this.id]?.setValue(this.model);
    // }
    this.edited = val;
  }

  changeDisabledState(value) {
    this.disabled = value;
  }

  getEnumsService() {
    return this.enumsService;
  }

  changeFirstValue() {
    this.firstValue = this.modelValue;
  }

  handleLoadEnumsError() {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }
}

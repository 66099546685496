import { Component, Input, OnInit } from '@angular/core';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { ProjectDetails } from '../../../types/project-details';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { errorMessages } from '../../../types/input-errors';
import { fieldsSettings } from '../../../types/new-project';
import { fieldsSettings as leadSettings } from '../../../types/new-lead';
import { fieldsSettings as opportunitySettings } from '../../../types/new-opportunity';
import { ProjectService } from '../../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '../../../services/contact.service';
import { DcResponsibleService } from '../../../services/dcresponsible.service';
import { ClientListService } from '../../../services/client-list.service';
import { EnumList } from '../../../types/enum-types/common/allEnum';

@Component({
  templateUrl: './project-common-fields.component.html',
  styleUrls: ['./project-common-fields.component.scss'],
  providers: [SnackbarPanelComponent],
})
export class ProjectCommonFieldsComponent implements OnInit {
  @Input() projectDetails: ProjectDetails;

  fieldsSettings = fieldsSettings;
  leadSettings = leadSettings;
  opportunitySettings = opportunitySettings;
  errorMessages = errorMessages;
  errors: { [key: string]: any } = {};
  enums: any;
  data = {};

  constructor(
    protected dialog: MatDialog,
    protected enumsService: EnumsService,
    protected snackbar: SnackbarPanelComponent,
    protected projectService: ProjectService,
    protected contactService: ContactService,
    protected dcResponsibleService: DcResponsibleService,
    protected clientListService: ClientListService
  ) {}

  ngOnInit() {
    this.enumsService.getData().subscribe({
      next: (enums: EnumList) => this.populateDropdowns(enums),
      error: error => this.handleLoadEnumsError(error),
    });

    this.subscribeSpecialEnums();
  }

  addEidToDuplicates(values) {
    const alreadySeen = [];

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]] === false) {
        alreadySeen[values[0][i]] = true;
        continue;
      }
      if (alreadySeen[values[0][i]] !== true) {
        alreadySeen[values[0][i]] = false;
        continue;
      }
    }

    for (let i = 0; i < values[0].length; i++) {
      if (alreadySeen[values[0][i]]) {
        values[0][i] = values[0][i] + ' (' + values[1][i] + ')';
      }
    }
  }

  protected filterStates(searchedValue: string, field: string) {
    if (field === 'dcResponsible' || field === 'dcResponsibleDeputy') {
      this.addEidToDuplicates(this.data[field]);
      return this.data[field][0]
        .filter(value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1)
        .sort();
      // indexOf due to IE
    } else {
      return this.data[field]
        .filter(value => value.toLowerCase().indexOf(searchedValue.toLowerCase()) !== -1)
        .sort();
    }
  }

  protected onFormChanges(changes, part) {
    Object.keys(changes).forEach(change => {
      const control = part.controls[change];
      this.projectDetails[change] = control.value;
      this.errors[change] = '';
      if (control.dirty && control.errors) {
        this.errors[change] = this.errorMessages[Object.keys(control.errors)[0]];
      }
    });
  }

  protected handleLoadEnumsError(error) {
    this.snackbar.error(
      'We could not load enumeration, please check your connection and refresh page'
    );
  }

  public subscribeSpecialEnums() {}

  public unsubscribeSpecialEnums() {}

  protected populateDropdowns(enums: EnumList) {
    enums['region'].sort((a, b) => {
      return a.text >= b.text ? 1 : -1;
    });
    // if (enums['offering-name'] !== null) { // Not Applicable first
    //   enums['offering-name'].sort(function(x,y){ return x.id == 60 ? -1 : y.id == 60 ? 1 : 0; });
    // }
    // this.enums = enums;

    if (enums['offering-name'] !== null) {
      enums['offering-name'].sort(function (x, y) {
        if (x.text === 'Offering Not Used' || y.text === 'Offering Not Used') {
          return x.text === 'Offering Not Used' ? 1 : -1;
        }
        return x.text.localeCompare(y.text);
      });
    }

    if (enums['employee-country-enum'] !== null) {
      enums['employee-country-enum'].sort((a, b) => a.stage - b.stage);
    }

    this.enums = enums;
  }
}

import { ValidatorFn, AbstractControl } from '@angular/forms';

export function valFlInterval(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return typeof +control.value !== 'number' || !(control.value > 0 && control.value <= 1)
        ? { notFlInterval: 1 }
        : null;
    }
  };
}

export function valPositive(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return typeof +control.value !== 'number' || !(control.value >= 0)
        ? { notPositive: 1 }
        : null;
    }
  };
}

export function valRequired(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (Array.isArray(control.value)) {
      return control.value.length === 0 ? { required: true } : null;
    } else {
      return !control.value ? { required: true } : null;
    }
  };
}

export function valInteger(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return control.value < 0 || typeof +control.value !== 'number' || control.value % 1 !== 0
        ? { notInteger: 1 }
        : null;
    }
  };
}

export function valTaskEffort(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return typeof +control.value !== 'number' ||
        !String(control.value).match(/^\d{1,2}(?:\.\d{1,2})?$/) ||
        !(control.value > 0) ||
        control.value > 40
        ? { notTaskEffort: 1 }
        : null;
    }
  };
}

export function valMax10Digits(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return control.value < 0 || control.value.length > 10 || control.value % 1 !== 0
        ? { max10Digits: 1 }
        : null;
    }
  };
}

export function valIntOrTBD(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      if (
        (control.value < 0 || typeof +control.value !== 'number' || control.value % 1 !== 0) &&
        control.value !== 'TBD'
      ) {
        return { notInteger: 1 };
      } else {
        return null;
      }
    }
  };
}

export function valString8(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 8 ? { exceedLength8: 1 } : null;
    }
  };
}

export function valString9(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value && control.value.length > 0) {
      const length = control.value.length;
      return length > 9 ? { exceedLength9: 1 } : null;
    }
  };
}

export function valString20(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 20 ? { exceedLength20: 1 } : null;
    }
  };
}

export function valString40(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 40 ? { exceedLength40: 1 } : null;
    }
  };
}

export function valString100(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 100 ? { exceedLength100: 1 } : null;
    }
  };
}

export function valString150(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 150 ? { exceedLength150: true } : null;
    }
  };
}

export function valString255(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 255 ? { exceedLength255: true } : null;
    }
  };
}

export function valString500(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const length = control.value.length;
      return length > 500 ? { exceedLength500: true } : null;
    }
  };
}

export function valFromToDates(to): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value && to.value) {
      const dateFrom = new Date(control.value);
      const dateTo = new Date(to.value);

      return dateFrom > dateTo ? { higherDate: 1 } : null;
    }
  };
}

export function valOrigStartFromDates(startDate): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (startDate && control.value) {
      const oriStartDate = new Date(startDate);
      const newStartDate = new Date(control.value);

      return oriStartDate >= newStartDate ? { startDatesEquals: 1 } : null;
    }
  };
}

export function valToFromDates(from): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value && from.value) {
      const dateFrom = new Date(from.value);
      const dateTo = new Date(control.value);

      from.markAsDirty();
      from.updateValueAndValidity();

      return null;
    }
  };
}

export function valDate(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const date = new Date(control.value);
      return !date.getDate() ? { notDate: 1 } : null;
    }
  };
}

export function valDateMonth(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      if (control.value instanceof Date) {
        const date = new Date(control.value);
        return !date.getDate() ? { notDate: 1 } : null;
      } else {
        const dateString = control.value.split('/');
        const date = new Date(`${dateString[0]}/01/${dateString[1]}`);
        return !date.getDate() ? { notDate: 1 } : null;
      }
    }
  };
}

export function valEidOrMail(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      if (
        control.value.match('^[a-zA-Z0-9-]+([.][a-zA-Z0-9-]+)+@[a-zA-Z0-9-]+[.]com$') ||
        control.value.match('^[a-zA-Z0-9-]+([.][a-zA-Z0-9-]+)+$')
      ) {
        return null;
      }
      return { eidOrMail: 1 };
    }
  };
}

// export function valRequiredCondApproved(projectInfo): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } => {
//     if (projectInfo.nbmStatus === 5) {
//       return !control.value ? { required: 1 } : null;
//     } else {
//       return null;
//     }
//   };
// }

export function valDigitalPlatformRequired(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (
      employeeDetails.employeeDomainId === 86 ||
      employeeDetails.employeeDomainId === 189 ||
      employeeDetails.employeeDomainId === 192 ||
      employeeDetails.employeeDomainId === 190 ||
      employeeDetails.employeeDomainId === 187 ||
      employeeDetails.employeeDomainId === 43
    ) {
      return !control.value ? { required: 1 } : null;
    } else {
      return null;
    }
  };
}

export function valRequiredCc(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (employeeDetails.updateCc) {
      return !control.value ? { required: 1 } : null;
    } else {
      return null;
    }
  };
}

export function valRequiredEId(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (employeeDetails.employeeTypeId === 7 || employeeDetails.employeeTypeId === 3) {
      return null;
    } else {
      return !control.value ? { required: 1 } : null;
    }
  };
}

export function valRequiredPersonalNumber(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (employeeDetails.employeeTypeId === 1) {
      return !control.value || control.value.length === 0 ? { required: 1 } : null;
    } else {
      return null;
    }
  };
}

export function valRequiredForNoCc(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (employeeDetails.employeeTypeId !== 7) {
      return !control.value || control.value.length === 0 ? { required: 1 } : null;
    } else {
      return null;
    }
  };
}

export function valRequiredSkill(employeeDetails, id, otherControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (id === 'primarySkillId') {
      if (employeeDetails.proficiencyId !== null) {
        return !control.value ? { required: 1 } : null;
      } else if (employeeDetails.proficiencyId === null) {
        if (!otherControl['proficiencyId'].valid || control.value) {
          otherControl['proficiencyId'].markAsDirty();
          otherControl['proficiencyId'].updateValueAndValidity();
        }
      }
    }

    if (id === 'supplyIndustrySkillId') {
      if (employeeDetails.supplyIndustryProficiencyId !== null) {
        return !control.value ? { required: 1 } : null;
      } else if (employeeDetails.supplyIndustryProficiencyId === null) {
        if (!otherControl['supplyIndustryProficiencyId'].valid || control.value) {
          otherControl['supplyIndustryProficiencyId'].markAsDirty();
          otherControl['supplyIndustryProficiencyId'].updateValueAndValidity();
        }
      }
    }

    return null;
  };
}

export function valRequiredPro(employeeDetails, id, otherControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (id === 'proficiencyId') {
      if (employeeDetails.primarySkillId !== null) {
        return !control.value ? { required: 1 } : null;
      } else if (employeeDetails.primarySkillId === null) {
        if (!otherControl['primarySkillId'].valid || control.value) {
          otherControl['primarySkillId'].markAsDirty();
          otherControl['primarySkillId'].updateValueAndValidity();
        }
      }
    }

    if (id === 'supplyIndustryProficiencyId') {
      if (employeeDetails.supplyIndustrySkillId !== null) {
        return !control.value ? { required: 1 } : null;
      } else if (employeeDetails.supplyIndustrySkillId === null) {
        if (!otherControl['supplyIndustrySkillId'].valid || control.value) {
          otherControl['supplyIndustrySkillId'].markAsDirty();
          otherControl['supplyIndustrySkillId'].updateValueAndValidity();
        }
      }
    }

    return null;
  };
}

// export function valOtherStatus(employeeDetails): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } => {
//     if ((employeeDetails.statusId === 5 && employeeDetails.availableAsOf)) {
//       const rollOfDate = new Date(employeeDetails.availableAsOf);
//       const [year, month, date] = [rollOfDate.getFullYear(), rollOfDate.getMonth(), rollOfDate.getDate()];
//       const [currYear, currMonth, currDate] = [new Date().getFullYear(), new Date().getMonth(), new Date().getDate()];
//       return (year > currYear) ||
//         (year === currYear && month > currMonth) ||
//         (year === currYear && month === currMonth && date > currDate) ? { wrongStatus: 1 } : null;
//     }
//  };
// }

export function valRequiredIndustrySkill(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const supplyIndustryProficiencyId = employeeDetails.supplyIndustryProficiencyId;
    return supplyIndustryProficiencyId && !control.value ? { requiredIndustrySkill: 1 } : null;
  };
}

export function valRequiredIndustryPro(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const supplyIndustrySkillId = employeeDetails.supplyIndustrySkillId;
    return supplyIndustrySkillId && !control.value ? { requiredIndustryPro: 1 } : null;
  };
}

export function valRequiredPrimarySkill(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const proficiencyId = employeeDetails.proficiencyId;
    return proficiencyId && !control.value ? { requiredPrimarySkill: 1 } : null;
  };
}

export function valRequiredPrimaryPro(employeeDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const primarySkillId = employeeDetails.primarySkillId;
    return primarySkillId && !control.value ? { requiredPrimaryPro: 1 } : null;
  };
}

export function valSeat(regex): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return !control.value.match(regex) ? { notSeat: 1 } : null;
  };
}

export function valWbsRequired(universalWbs): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return universalWbs.value && !control.value ? { required: true } : null;
  };
}

export function valDcsoRequired(dcso): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return dcso.value && !control.value ? { required: true } : null;
  };
}

export function valCredentialsRequired(credentials): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return credentials.value && !control.value ? { required: true } : null;
  };
}

export function valTLT(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      return !(control.value >= 1 && control.value <= 3) ? { notTLT: 1 } : null;
    }
  };
}

export function valEmails(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      let error;

      control.value.split(';').forEach(part => {
        if (
          !part
            .replace(/\s/g, '')
            .match('^[a-zA-Z0-9_-]+([.]{1}[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_]+[.]{1}[A-Za-z]{2,4}$')
        ) {
          error = { notEmail: 1 };
        }
      });

      return error;
    }
  };
}

export function valRequiredSkillLevel(id, form): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let id2;

    if (id === 'programming1') {
      id2 = 'lvl1Programming';
    } else if (id === 'lvl1Programming') {
      id2 = 'programming1';
    } else if (id === 'programming2') {
      id2 = 'lvl2Programming';
    } else if (id === 'lvl2Programming') {
      id2 = 'programming2';
    } else if (id === 'programming3') {
      id2 = 'lvl3Programming';
    } else if (id === 'lvl3Programming') {
      id2 = 'programming3';
    } else if (id === 'application1') {
      id2 = 'lvl1Application';
    } else if (id === 'lvl1Application') {
      id2 = 'application1';
    } else if (id === 'application2') {
      id2 = 'lvl2Application';
    } else if (id === 'lvl2Application') {
      id2 = 'application2';
    } else if (id === 'application3') {
      id2 = 'lvl3Application';
    } else if (id === 'lvl3Application') {
      id2 = 'application3';
    } else if (id === 'language1') {
      id2 = 'lvl1Language';
    } else if (id === 'lvl1Language') {
      id2 = 'language1';
    } else if (id === 'language2') {
      id2 = 'lvl2Language';
    } else if (id === 'lvl2Language') {
      id2 = 'language2';
    } else if (id === 'language3') {
      id2 = 'lvl3Language';
    } else if (id === 'lvl3Language') {
      id2 = 'language3';
    }

    const other = form.get('optional').get(id2);

    if (other.value && !control.value) {
      return { required: 1 };
    } else if (!other.value && control.value) {
      if (other.valid) {
        other.markAsTouched();
        other.markAsDirty();
        other.updateValueAndValidity();
      }

      return null;
    } else if ((!other.value && !control.value) || (other.value && control.value)) {
      if (!other.valid) {
        other.markAsTouched();
        other.markAsDirty();
        other.updateValueAndValidity();
      }

      return null;
    }
  };
}

function getControlName(c: AbstractControl): string | null {
  const formGroup = c.parent.controls;
  return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
}

export function valDcResponsibleRole(projectDetails, add?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const role =
      add === true
        ? projectDetails.dcResponsibleRole
        : projectDetails.reqQualification.dcResponsibleRoleId;
    const end2End = add === true ? projectDetails.end2End : projectDetails.projectInfo.end2End;
    const operationalRespId =
      add === true
        ? projectDetails.operationalRespId
        : projectDetails.projectInfo.operationalRespId;
    const fieldName = getControlName(control);

    if (
      fieldName === 'dcResponsibleRoleId' &&
      control.value !== 'ISM' &&
      control.value !== 2 &&
      control.value !== 'PDL' &&
      control.value !== 3
    ) {
      return { dcResponsibleRoleISM: 1 };
    } else if (
      fieldName === 'dcResponsibleRoleId' &&
      (control.value === 'ISM' || control.value === 2) &&
      ((end2End && operationalRespId === 1) || operationalRespId === 2)
    ) {
      return { dcResponsibleRoleISM: 1 };
      // (end2EndOrOperRespChange) If role is ISM and End2End is True Or End2End is false and operResp is Managed Delivery
    } else if (
      (role === 'ISM' || role === 2) &&
      ((fieldName === 'end2End' &&
        (control.value === true || (control.value === false && operationalRespId === 2))) ||
        (fieldName === 'operationalRespId' &&
          ((control.value === 1 && end2End) || control.value === 2)))
    ) {
      return { dcResponsibleRoleISM: 1 };
    }
    return null;
  };
}

export function valDcResponsibleAndDeputy(projectDetails): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const dc =
      projectDetails.reqQualification !== undefined
        ? projectDetails.reqQualification.dcResponsible
        : projectDetails.dcResponsible;
    const deputy =
      projectDetails.reqQualification !== undefined
        ? projectDetails.reqQualification.dcResponsibleDeputy
        : projectDetails.dcResponsibleDeputy;
    const fieldName = getControlName(control);
    if (control.value !== '') {
      if (
        (fieldName === 'dcResponsible' && control.value === deputy) ||
        (fieldName === 'dcResponsibleDeputy' && control.value === dc)
      ) {
        return { dcResponsibleAndDeputy: 1 };
      }
    }
    return null;
  };
}

export function valDcResponsibleAndDeputyFormat(values): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    // if the provided/non-provided value from enum values is not equal or present/empty
    const fieldName = getControlName(control);
    const result = values.some(
      value =>
        value === control.value ||
        (fieldName === 'dcResponsibleDeputy' && control.value === '') ||
        String(control.value).includes('.') ||
        String(control.value).includes(', ')
    );
    if (!result) {
      return { dcResponsibleAndDeputyFormat: 1 };
    }
    return null;
  };
}

export function valDMSmyWizardId(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const regExpString = new RegExp('^((([0-9]{6}-|(NA)-){2}([0-9]{6}|(NA)))|([0-9]{6}-SF))$');
    // if the value of ID is longer than required
    if (control.value.length > 20) {
      return { myWizardDMSIdLength: 1 };
      // if the value is not empty and suits the regexp
    } else if (control.value.length !== 0 && !regExpString.test(control.value)) {
      return { myWizardDMSIdFormat: 1 };
    } else {
      return null;
    }
  };
}

export function valRequiredSharedService(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (Array.isArray(control.value)) {
      return control.value.length === 0 ? { requiredSharedService: true } : null;
    } else {
      return !control.value ? { requiredSharedService: true } : null;
    }
  };
}

// export function valProlongationProbability(id, form): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any} => {
//     let otherField;
//     const rollOfDate = new Date(form.get('mandatory').get('rollOffDateDemand').value);
//     if both fields are empty, consider them valid
//     if (!form.get('optional').get('prolongationDate').value && !form.get('optional').get('prolongationProbabilityId').value) {
//       return null;
//     }

//     if (id === 'prolongationProbabilityId') {
//       otherField = form.get('optional').get('prolongationDate');
//       if ((control.value && !otherField.value)
//       || (otherField.value && otherField.value <= rollOfDate)) {
//         return { prolongationDate: 1 };
//       }
//       if (!otherField.valid) {
//         otherField.updateValueAndValidity();
//       }
//     } else if (id === 'prolongationDate') {
//       otherField = form.get('optional').get('prolongationProbabilityId');
//       if (!otherField.value) {
//         return { prolongationProbability: 1 };
//       } else if (control.value <= rollOfDate) {
//         return { prolongationDate: 1 };
//       }
//       if (!otherField.valid) {
//         otherField.updateValueAndValidity();
//       }
//     }
//     return null;
//   };
// }

import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { CommonComponent } from '../../../shared/common/common.component';
import { FormBuilder } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarPanelComponent } from '../../../shared/snackbar-panel/snackbar-panel.component';
import { fieldsSettings } from '../../../types/softlocks-filter';
import { ProjectService } from '../../../services/project.service';
import { EnumsService } from '../../../services/enumerations/enums.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRolesService } from '../../../services/user-roles/user-roles.service';
import { User } from '../../../services/user-roles/user';
import { projectsEnumMapping } from '../../../types/enum.mapping';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable, of } from 'rxjs';
import { EmployeeDetailsService } from '../../../employee/employee-details/employee-details.service';
import { EmpOption } from '../../../types/emp-search-option';
import { AuthService } from '../../../services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-softlocks',
  templateUrl: './project-softlocks.component.html',
  styleUrls: [
    './project-softlocks.component.scss',
    '../../../shared/tables-management/table-common-style.scss',
  ],
  providers: [SnackbarPanelComponent],
})
export class ProjectSoftlocksComponent
  extends CommonComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild('softlocksExpansionPanel', { static: true })
  softlocksExpansionPanel: MatExpansionPanel;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() softlock: any;
  @Output() addToSoftlockedNotify = new EventEmitter();

  displayedColumns = [
    'name',
    'availableAsOf',
    'fte',
    'travel',
    'german',
    'currentProject',
    'level',
    'action',
  ];
  columnSoftlockNames = [
    'Name',
    'Available',
    'FTE',
    'Travel',
    'German',
    'Current project',
    'Level',
    'Actions',
  ];
  dataSource = new MatTableDataSource();

  editingRole: boolean;
  creatingRole: boolean;
  deletingRole: boolean;

  fieldsSettings = fieldsSettings;
  filteredOptions: Observable<any>;
  chosenEmployee: string;
  minEidLength = 3;
  useNameForFilter: boolean;
  filtering: boolean;
  id: number;
  softlocks: Array<any>;
  enums: any;

  softlocksLoaded = false;
  isHardlocked = false;
  hardlockName: string;
  softlocking: number;
  demandInfo = [];

  constructor(
    protected userRolesService: UserRolesService,
    protected projectService: ProjectService,
    protected employeeDetailsService: EmployeeDetailsService,
    protected snackbar: SnackbarPanelComponent,
    protected fb: FormBuilder,
    private route: ActivatedRoute,
    protected enumsService: EnumsService,
    authService: AuthService,
    router: Router,
    location: Location
  ) {
    super(snackbar, fb, authService, router, location);
  }

  ngOnInit() {
    super.ngOnInit();

    this.enumsService.getData().subscribe(
      enums => {
        this.populateDropdowns(enums);
      },
      error => {
        this.handleLoadEnumsError();
      }
    );

    this.userRolesService
      .getData()
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        const user = new User(data);
        this.creatingRole = user.getRoles().creating;
        this.editingRole = user.getRoles().editing;
        this.deletingRole = user.getRoles().deleting;
      });

    this.softlocksExpansionPanel.open();
    this.form
      .get('employeeId')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(name => {
        this.searchAutocomplete(name);
      });
  }

  populateByFilter() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.getFilterData(this.id);
      }
    });
  }

  getFilterData(id) {
    this.projectService.getDemandDetails(id).subscribe(
      demandDetails => {
        this.handleDemandDetails(demandDetails);
      },
      error => this.handleError(error)
    );
  }

  handleDemandDetails(demandDetails: any) {
    const enumNamesToLoad = [
      'platformId',
      'domainId',
      'pcNtbDemandId',
      'careerLevelId',
      'commitment',
    ];

    console.log(demandDetails);
    this.demandInfo['role'] = demandDetails.role;
    this.demandInfo['fte'] = demandDetails.demandFte;
    this.demandInfo['platformId'] = demandDetails.platformId;
    this.demandInfo['domainId'] = demandDetails.domainId;
    this.demandInfo['startDate'] = demandDetails.startDateDemand;
    this.demandInfo['rollOffDate'] = demandDetails.rollOffDateDemand;
    this.demandInfo['pcNtbDemandId'] = demandDetails.pcNtbDemandId;
    this.demandInfo['careerLevelId'] = demandDetails.careerLevelId;
    this.demandInfo['commitment'] = demandDetails.probabilityId;
    this.demandInfo['priority'] = demandDetails.projectPriority;

    enumNamesToLoad.forEach(enumName =>
      this.enumsService.setEnumText(this.demandInfo, enumName, projectsEnumMapping)
    );

    this.form.get('platformId').setValue(demandDetails.platformId, { onlySelf: true });
    this.form.get('domainId').setValue(demandDetails.domainId, { onlySelf: true });
    this.form.get('careerLevelId').setValue(demandDetails.careerLevelId, { onlySelf: true });
    this.form
      .get('sourcingLocationId')
      .setValue(demandDetails.sourcingLocationId, { onlySelf: true });
    this.data['domainEnum'] = this.enums['domain'].filter(
      value => value.platformId === this.form.get('platformId').value || value.text === 'All'
    );

    this.prepareSearch();
    this.form.get('platformId').valueChanges.subscribe(value => this.onPlatformChange(value));
    if (demandDetails.employeeDetailId && demandDetails.employeeFullName) {
      this.hardlockName = demandDetails.employeeFullName;
      this.projectService.setIsHardlocked(true);
      this.isHardlocked = true;
    } else {
      this.projectService.setIsHardlocked(false);
      this.isHardlocked = false;
    }
  }

  onPlatformChange(platform: number) {
    this.data['domainEnum'] = this.enums['domain'].filter(
      value => value.platformId === platform || value.text === 'All'
    );
    if (this.data['domainEnum'].length > 0) {
      this.form.get('domainId').setValue(null, { onlySelf: true });
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  onFormChanges(changes) {
    if (!this.useNameForFilter) {
      this.prepareSearch();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.softlock.firstChange) {
      this.prepareSearch();
    }
  }

  prepareSearch() {
    const searchParams = new Object();

    Object.keys(this.form.controls).forEach(key => {
      if (this.form.controls[key].valid && this.form.controls[key].value) {
        if (key == 'employeeId') {
          // displayed in diffrent format
          searchParams[key] = this.chosenEmployee;
        } else {
          searchParams[key] = this.form.controls[key].value;
        }
      }
    });

    this.getSoftlocks(this.id, searchParams);
  }

  getSoftlocks(id, filter) {
    this.softlocking = null;
    this.softlocksLoaded = false;

    this.projectService.getDemandSoftlocks(id, filter).subscribe(
      data => this.handleSoftlocks(data),
      error => this.handleError(error)
    );
  }

  handleSoftlocks(data) {
    const enumNamesToLoad = ['travelId', 'germanId', 'careerLevelId'];

    data.forEach(softlock => {
      enumNamesToLoad.forEach(enumName =>
        this.enumsService.setEnumText(softlock, enumName, projectsEnumMapping)
      );
    });
    this.dataSource.data = data;
    this.softlocksLoaded = true;
  }

  populateDropdowns(enums) {
    this.enums = enums;
    // add All option
    if (!(this.enums['domain'][0].text === 'All')) {
      this.enums['domain'].unshift({
        id: null,
        stage: null,
        text: 'All',
        proficiency: null,
        deleted: null,
      });
    }
    if (!(this.enums['employee-new-career-level'][0].text === 'All')) {
      this.enums['employee-new-career-level'].unshift({
        id: null,
        stage: null,
        text: 'All',
        proficiency: null,
        deleted: null,
      });
    }

    this.data['platformEnum'] = this.enums['platform'].filter(value => value.deleted !== true);
    this.data['domainEnum'] = this.enums['domain'].filter(value => value.deleted !== true);
    this.data['careerLevelEnum'] = this.enums['employee-new-career-level'];
    this.data['placesEnum'] = this.enums['employee-location'];

    this.populateByFilter();
  }

  isSoftlocking(row): boolean {
    return row.employeeId === this.softlocking;
  }

  moveToSoftlocked(row) {
    this.softlocking = row.employeeId;

    this.projectService.moveToSoftlocked(this.id, row.employeeId).subscribe(
      demandDetails => this.handleMoveToSoftlocked(),
      error => this.handleError(error)
    );
  }

  handleMoveToSoftlocked() {
    this.prepareSearch();
    this.addToSoftlockedNotify.emit(new Date());
    this.snackbar.success('Employee softlocked');
  }

  handleEdit(): void {
    this.snackbar.success('Changes has been saved');
  }

  changeFilter($event: MatSlideToggleChange) {
    this.useNameForFilter = $event.checked;
    this.enableDisableFormFields();
  }

  private enableDisableFormFields() {
    if (this.useNameForFilter) {
      this.form.controls['employeeId'].enable({ emitEvent: false });
      this.form.controls['platformId'].disable({ emitEvent: false });
      this.form.controls['domainId'].disable({ emitEvent: false });
      this.form.controls['careerLevelId'].disable({ emitEvent: false });
      this.form.controls['sourcingLocationId'].disable({ emitEvent: false });
      if (
        this.form.get('employeeId').value != null &&
        this.form.get('employeeId').value.length >= this.minEidLength
      ) {
        this.searchAutocomplete(this.form.get('employeeId').value);
      }
    } else {
      this.form.controls['employeeId'].disable({ emitEvent: false });
      this.form.controls['platformId'].enable({ emitEvent: false });
      this.form.controls['domainId'].enable({ emitEvent: false });
      this.form.controls['careerLevelId'].enable({ emitEvent: false });
      this.form.controls['sourcingLocationId'].enable({ emitEvent: false });
      this.prepareSearch();
    }
  }

  private searchAutocomplete(searchedEnterpriseDomainId: string) {
    if (searchedEnterpriseDomainId.length >= this.minEidLength) {
      this.employeeDetailsService
        .getActiveEmployeeDetailsList(searchedEnterpriseDomainId)
        .subscribe(
          enterpriseDomainIds => this.handleEnterpriseDomainIds(enterpriseDomainIds),
          error => this.handleError(error)
        );
    } else {
      this.filteredOptions = of();
    }
  }

  handleEnterpriseDomainIds(enterpriseDomainIds) {
    this.filteredOptions = of(enterpriseDomainIds);
  }

  onSelectedOption(value) {
    this.chosenEmployee = value;
    this.filteredOptions.subscribe(options => {
      const currentOption = options.filter(option => option.id === value)[0];
      this.form.controls['employeeId'].setValue(currentOption.lastNameFirstName, {
        emitEvent: false,
      });

      this.prepareSearch();
    });
  }

  displayFn(option: string | EmpOption): string {
    return typeof option === 'object' ? option.name : option;
  }
}
